import { dashBoardModes } from "common/dashDashBoardModes";
import { screenTypes } from "commonStores/userInfoStore/screenTypes";

export function getRedirectTo(dashboardMode) {

    let redirectTo;

    if (dashboardMode === dashBoardModes.MODE_BIZ) {
        redirectTo = screenTypes.SCREEN_BIZ_PURCHASES;
    } else if (dashboardMode === dashBoardModes.MODE_ORG) {
        redirectTo = screenTypes.SCREEN_ORG_LOADINGS;
    } else if (dashboardMode === dashBoardModes.MODE_MOV) {
        redirectTo = screenTypes.SCREEN_MOV_MAIN;
    }

    return redirectTo;
}