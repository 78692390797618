import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../../index";
import { t } from "common/localization/translate";
import { handleBlockScroll} from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const MonthInput = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();
  const { limitationTimeError, limitationParams } = store;

  const handleInputChange = (event) => {
    if (event.target.value < 0) {
      store.limitationTimeError = true;
      return;
    }
    store.limitationTimeError = false;
    store.limitationParams = {...limitationParams, "months": Number(event.target.value)};
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      value={store.limitationParams ? store.limitationParams.months : ""}
      label={t("screenAddNewBusiness.NumberOfMonths")}
      type="number"
      onChange={handleInputChange}
      onWheel={handleBlockScroll}
      className={classes.input}
      error={limitationTimeError}
      helperText={limitationTimeError ? t("screenAddNewBusiness.NegativeNumberError"): ""}
    />
  );
});