import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { observer } from 'mobx-react-lite';
import { makeStyles } from "@material-ui/styles";
import { t } from 'common/localization/translate';

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 16,
            fontFamily: 'Assistant',
            fontWeight: 'bold'
        }
    },
}));

export const SuccessEnterCodesPopUp = observer((props) => {
    const { store } = props;
    const { exceptionalCodesAmount, validCodesAmount, totalCodesLoaded } = store;

    const classes = useStyles();
    const handleClose = () => {
        store.isSuccessEnterCodes = false;
    };

    const exceptionalReport = exceptionalCodesAmount > 0 ? t('suppliers.supplierHome.exceptionalsReprt') : '';

    return (
        <div>
            <Dialog open={store.isSuccessEnterCodes} >
                <DialogTitle className={classes.title} >{t("suppliers.supplierHome.verifyCodesPopUp")}</DialogTitle>
                <DialogContent>
                    <DialogContentText> {t('suppliers.supplierHome.successLoadCodes', {exceptionalCodesAmount, validCodesAmount, totalCodesLoaded})}
                    <br></br> 
                    {exceptionalReport}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus >{t("suppliers.supplierHome.verifyCodesOkPopUp")}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
})