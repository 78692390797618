import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from "react-router";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { screenTypes } from 'commonStores/userInfoStore/screenTypes'
import { Icon } from 'componentsRumble/images/images';
import { sendAnalyticsEvent } from 'common/analyticsEvent';
import { analyticsEvents } from 'common/analyticsEvent/events';

class SideMenuOrgComponent extends Component {

    onClickLoadings = () => {
        this.props.history.push(screenTypes.SCREEN_ORG_LOADINGS);
        sendAnalyticsEvent(analyticsEvents.EVENT_ORG_SIDEBAR_PRESSED_LOADINGS);
    }

    onClickPurchases = () => {
        this.props.history.push(screenTypes.SCREEN_ORG_PURCHASES);
        sendAnalyticsEvent(analyticsEvents.EVENT_ORG_SIDEBAR_PRESSED_PURCHASES);
    }

    render() {
        const { location } = this.props;
        const { pathname } = location;
        return <List>
            <ListItem selected={pathname === screenTypes.SCREEN_ORG_LOADINGS} button key='loadings' onClick={this.onClickLoadings}>
                <ListItemIcon><Icon src="https://ucarecdn.com/d8e32451-edd5-47e4-8b5e-d6143f7d3272/charges.png" /></ListItemIcon>
                <ListItemText primary='טעינות' />
            </ListItem>
            <ListItem selected={pathname === screenTypes.SCREEN_ORG_PURCHASES} button key='purchases' onClick={this.onClickPurchases}>
                <ListItemIcon> <Icon src="https://ucarecdn.com/e003ffad-a178-4017-a12e-5e78593bb480/Purchases.png" /></ListItemIcon>
                <ListItemText primary='כניסות' />
            </ListItem>
        </List>
    }
}
export const SideMenuOrg = withRouter(inject("rootStore")(observer(SideMenuOrgComponent)));
