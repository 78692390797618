import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { t } from 'common/localization/translate';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold'
}
}));

export const SuppliersDropDown = observer((props) => {
  const { isGetCoupons, store } = props;
  const classes = useStyles();

  const handleChange = (event) => {
    store.chosenSupplier = event.target.value;
    if(isGetCoupons){
      store.getCouponsBySupplier(store.chosenSupplier);
    }
  };

  if (!store.suppliersList) {
    return null;
  }
  const menuItems = store.suppliersList.map(item => <MenuItem value={item.supplier_id}>{item.name}</MenuItem>);

  return (
    <Grid item xs={12} spacing={3}>
      <FormControl className={classes.formControl}>
      <InputLabel className={classes.label} shrink>
      {t("suppliers.projectManagerHome.suppliers")}
            </InputLabel>
        <Select value={store.chosenSupplier} onChange={handleChange} displayEmpty className={classes.selectEmpty} >
          {menuItems}
        </Select>
      </FormControl>
    </Grid>
  );
})