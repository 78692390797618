import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles(theme => ({
  root: {
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },
  rootForSpinner: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: props => props.height,
  },
  colorPrimary: {
    color: "white"
  }
}));

export const LoadingSpinner = props => {
  const isSmallScreen = useMediaQuery("(max-height:1000px)");
  const {
    isFetching,
    children,
    classNameForSpinner,
    className,
    isWhiteCircle,
    isFullHeight
  } = props;
  const height = isFullHeight
    ? "100%"
    : isSmallScreen && !isFetching
    ? "auto"
    : "100%";
  const classes = useStyles({ height });
  const circularClasses = isWhiteCircle
    ? { colorPrimary: classes.colorPrimary }
    : null;
  return isFetching ? (
    <div
      className={classNameForSpinner || classes.rootForSpinner}
      height={height}
    >
      <Fade in={isFetching}>
        <CircularProgress color="primary" classes={circularClasses} />
      </Fade>
    </div>
  ) : (
    <div className={className || classes.rootForSpinner}>
      <Fade in={!isFetching} timeout={{ enter: 500 }}>
        {children}
      </Fade>
    </div>
  );
};
