import { createBusinessDashboardUserErrorCodes } from "./createBusinessDashboardUserErrorCodes";
import { t } from "common/localization/translate";

export function getErrorCreateUserTextByErrorCode(errorCode) {
  if (!errorCode) {
    return;
  }
  switch (errorCode) {
    case createBusinessDashboardUserErrorCodes.ERROR_TYPE_INVALID_EMAIL:
      return t("screenBusinessDashboardUser.errors.invalidEmail");
    case createBusinessDashboardUserErrorCodes.ERROR_TYPE_INVALID_CONTACT_NAME:
      return t("screenBusinessDashboardUser.errors.emptyContactName");
    case createBusinessDashboardUserErrorCodes.ERROR_TYPE_EMPTY_BUSINESS_ID:
      return t("screenBusinessDashboardUser.errors.emptyBusinessId");
    case createBusinessDashboardUserErrorCodes.ERROR_TYPE_USER_ALREADY_WITH_PERMISSION:
      return t("screenBusinessDashboardUser.errors.userAlreadyWithPermission");  
    default:
      return t("screenBusinessDashboardUser.errors.general");
  }
}
