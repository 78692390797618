import React from "react";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { calcActiveDurationTimeInMinutes } from "../../../../../../logic/calcActiveDurationTime";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const maxActivationDurationTime = 5300000;

export const ActiveDurationInput = observer(() => {
  const store = useAddNewCouponTypeStore();
  const { timeType, activeDurationMinutesError } = store;

  const handleChange = (event) => {
    if(!event.target.value) {
      store.activeDurationMinutesEmptyError = t("addNewCouponType.ActiveDurationTime");
      return;
    }
    if (event.target.value < 0) {
      store.activeDurationMinutesEmptyError = null;
      store.activeDurationMinutesError = true;
      return;
    }
    store.activeDurationMinutesEmptyError = null;
    store.activeDurationMinutesError = false;
    store.activeDurationMinutes = Math.round(calcActiveDurationTimeInMinutes(event.target.value, timeType));
    if (store.activeDurationMinutes > maxActivationDurationTime) {
      store.activeDurationMinutes = maxActivationDurationTime;
      return;
    }
  };

  return (
    <TextField
      required
      label={t("addNewCouponType.TimeInputPlaceholder")}
      variant="outlined"
      type="number"
      onChange={handleChange}
      onWheel={handleBlockScroll}
      error={activeDurationMinutesError}
      helperText={activeDurationMinutesError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
    />
  );
});
