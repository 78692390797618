import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { observer } from "mobx-react";
import { useScreenSendNotificationStore } from "../store/index";
import { NotificationDetails } from './notificationDetails';
import { SendButtons } from './sendButtons';
import { UserDetails } from './userDetails';
import { Grid, Divider } from '@material-ui/core';
import { SuccessSendNotificationPopUp } from './successSendNotificationPopUp';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth:500,
        "& > *": {
          height: "100%",
          padding: theme.spacing(0.5),
        }
    },
    divider :{
        marginTop:10,
        marginBottom:10,
        backgroundColor: theme.palette.primary.main
    }
}));

export const NotificationCard = observer(() => {
    const classes = useStyles();
    const store = useScreenSendNotificationStore();

    if(!store.userName){
        return null;
    }

    return (
        <Grid className={classes.container}>
        <Card>
            <CardContent>
                <UserDetails />
                <Divider className={classes.divider}/>  
                <NotificationDetails />
            </CardContent>
            <SendButtons />
            <SuccessSendNotificationPopUp/>
        </Card>
        </Grid>
    );
})