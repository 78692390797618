import React from "react";
import { observer } from "mobx-react";
import { TotalNumbersSocialNetworks } from './totalNumbersSocialNetworks'
import { TotalNumbersPerformence } from './totalNumbersPerformence'
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
    height: "100%",
  },
  container: {
    "& > *": {
      height: "100%",
      padding: theme.spacing(1.5)
    }
  },
}));
export const GeneralTotalNumbersCubesRow = observer(() => {
  const classes = useStyles()
  return (

    <Grid className={classes.container} container spacing={3}>
      <div>
        <TotalNumbersPerformence />
        <TotalNumbersSocialNetworks />
      </div>
    </Grid>
  );
});
