import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditAuctionStore } from "screens/controlPanel/editAuction/store";
import { t } from "common/localization/translate";
import { handleBlockScroll} from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const CostToBusinessEdit = observer(() => {
  const classes = useStyles();
  const store = useEditAuctionStore();

  const handleInputChange = (event) => {
    if (!event.target.value) {
      store.costToBusinessEmptyError = t("addNewAuction.addNewAuctionCostToBusiness");
      store.costToBusiness = null;
      return;
    }
    store.costToBusinessEmptyError = null;
    store.costToBusiness = event.target.value;
  };

  return (
    <TextField
      required
      variant="outlined"
      fullWidth
      label={t("addNewAuction.addNewAuctionCostToBusiness")}
      type="number"
      onChange={handleInputChange}
      onWheel={handleBlockScroll}
      className={classes.input}
      value={store.costToBusiness? store.costToBusiness : ""}
    />
  );
});