import React from "react";
import { makeStyles, Grid } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useAddNewAuctionStore } from "screens/controlPanel/addNewAuction/store";
import { t } from "common/localization/translate";
import { createEncodedLogo } from "../../../addNewBuisness/store/components/form/components/GeneralInfo/createEncodedLogo";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
  },
  div: {
      marginTop: 10,
      marginBottom: 20,
  }
}));

export const AuctionImage = observer(() =>{
  const classes = useStyles();
  const store = useAddNewAuctionStore();

  const handleInputChange = async (event) => {
    if (!event.target.files[0]) {
      store.auctionImageEmptyError = (t("AddNewAuction.AddNewAuctionImage"));
      return;
    }
    store.auctionImageEmptyError = null;
    store.auctionImage = await createEncodedLogo(event.target.files[0]);
}

  return (
    <Grid className={classes.div}>
      <label className={classes.label}>
        {"* " + t("AddNewAuction.AddNewAuctionImage")}
      </label>
      <br />
      <input type="file" onChange={handleInputChange} />
    </Grid>
  );
});