import { NO_OPTION_SELECTED, MIN_MONTH, MAX_MONTH, MIN_YEAR } from "../consts";

export function decSelectedKeyMonth(component) {
    if (component.selectedKeyMonth === NO_OPTION_SELECTED) {
        return;
    }
    if (component.selectedKeyMonth === MIN_MONTH) {
        if (component.selectedKeyYear === MIN_YEAR) {
            return;
        }
        component.selectedKeyYear--;
        component.selectedKeyMonth = MAX_MONTH
    } else {
        component.selectedKeyMonth--;
    }
    if (component.isYearOrMonthNull) {
        return;
    }
    component.updateStartEndDate();
}


