import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import { handleBlockScroll} from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const Coins = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();

  const handleInputChange = (event) => {
    if (event.target.value < 0) {
      store.coinsError = true;
      return;
    }
    if (!event.target.value) {
      store.coinsError = false;
      store.coinsEmptyError = t("addNewCouponType.Coins");
      return;
    }
    store.coinsEmptyError = null;
    store.coinsError = false;
    store.coins = event.target.value;
  };

  return (
    <TextField
      required
      variant="outlined"
      fullWidth
      label={t("addNewCouponType.Coins")}
      type="number"
      onChange={handleInputChange}
      onWheel={handleBlockScroll}
      className={classes.input}
      error={store.coinsError}
      helperText={store.coinsError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
    />
  );
});
