import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";

export const IsChallengeCheckbox = observer((props) => {
  const store = useAddNewCouponTypeStore();

  const handleChange = (event) => {
    store.isChallenge = event.target.checked;
    if(!store.isChallenge){
      store.challengeTypeSelected = null;
    }
  };

  return (
    <FormControlLabel
      control={
      <Checkbox
      checked={props.value}
      onChange={handleChange} />}
      label={t("addNewCouponType.IsChallenge")}
    />
  );
});