
export const getTranslationTextsArticle = (articleData, language, articleSection) => {
    if(articleData.translation_texts) {
        if(articleData.translation_texts[articleSection]) {
            return articleData.translation_texts[articleSection][language];
        }
    }
    return "";
}

export const getTranslationTextsQuestion = (question, language) => {
    if(question.translation_texts) {
       return question.translation_texts[language];
    }
    return "";
}

export const getTranslationTextsAllLanguages = (articleData) => {
    if(articleData.translation_texts) {
       return articleData.translation_texts;
    }
}
