import React from "react";
import { decorate, action, flow, observable, computed } from "mobx";
import { getScreenData as getScreenDataServer } from "./server/getScreenData";
import { getPurchasesTableData as getPurchasesTableDataLogic } from "./logic/getPurchasesTableData";
import { getNowInMoment, subtractFromDate } from "common/dateAndTime/moment";

const ScreenCouponsInInventoryStoreContext = React.createContext({});
export const ScreenCouponsInInventoryStoreProvider =
  ScreenCouponsInInventoryStoreContext.Provider;
export const useScreenCouponsInInventoryStore = () =>
  React.useContext(ScreenCouponsInInventoryStoreContext);

class ScreenCouponsInInventoryStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }

  initProperties() {
    this.isFetching = true;
    this.couponsPurchasesCount = null;
    this.endDate = getNowInMoment();
    this.startDate = subtractFromDate(this.endDate, 7, "days");
  }

  getScreenData = flow(function*(isAllTime) {
    this.isFetching = true;
    const segmentId = this.rootStore.userInfoStore.currentSegment
      ? this.rootStore.userInfoStore.currentSegment.segmentId
      : null;
    const startDateForReq = isAllTime ? null : this.startDate;
    const endDateForReq = isAllTime ? null : this.endDate;

    const couponsPurchasesCount = yield getScreenDataServer(
      segmentId,
      startDateForReq,
      endDateForReq
    );
    this.setCouponsPurchasesCount(couponsPurchasesCount);
    this.isFetching = false;
  });

  setCouponsPurchasesCount(couponsPurchasesCount) {
    this.couponsPurchasesCount = couponsPurchasesCount;
  }

  setStartDate(startDate) {
    this.startDate = startDate;
  }

  setEndDate(endDate) {
    this.endDate = endDate;
  }

  get purchasesTableData() {
    return getPurchasesTableDataLogic(this);
  }
}

decorate(ScreenCouponsInInventoryStore, {
  isFetching: observable,
  couponsPurchasesCount: observable,
  startDate: observable,
  endDate: observable,
  purchasesTableData: computed,
  initProperties: action.bound,
  getScreenData: action.bound,
  setCouponTypeCounts: action.bound,
  setStartDate: action.bound,
  setEndDate: action.bound
});

export function createScreenCouponsInInventoryStore(rootStore) {
  const store = new ScreenCouponsInInventoryStore(rootStore);
  return store;
}
