export function handleErrorCheck(store){
  const { startAuctionDateEmptyError, endAuctionDateEmptyError, isSprintAuction, startSprintDateEmptyError, stepsBidEmptyError, businessEmptyError, costToBusinessEmptyError, selectedSegmentsEmptyError, auctionImageEmptyError, auctionTitleEmptyError, auctionDescriptionEmptyError } = store;

  if (startAuctionDateEmptyError || endAuctionDateEmptyError || stepsBidEmptyError || businessEmptyError || costToBusinessEmptyError || selectedSegmentsEmptyError || auctionImageEmptyError || auctionTitleEmptyError || auctionDescriptionEmptyError || (isSprintAuction && startSprintDateEmptyError)) {
    store.isEmptyFieldErrorPopUpOpen = true;
    return true;
  }

  return false;
};
