import React from "react";
import { observer } from "mobx-react";
import { CountInput } from "./CountInput";
import { MonthInput } from "./MonthInput";

export const PurchaseCountInCalendarMonthLimitation = observer(() => {

  return (
    <div>
      <CountInput />
      <MonthInput />
    </div>
  );
});
