import React from "react";
import { decorate, action, observable } from "mobx";
import { switchUserSegment } from "./server/switchUserSegment";
import { getSegments } from "./server/getSegmentsList";
import { handleServerError } from "./logic/handleServerError";
import { t } from "common/localization/translate";

const UpdateSegment = React.createContext({});
export const UpdateSegmentStoreProvider = UpdateSegment.Provider;
export const userUpdateSegmentStore = () => React.useContext(UpdateSegment);

class UpdateSegmentStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }

  initProperties() {
    this.userIdForChange = null;
    this.newSegmentId = null;
    this.textReasonForChange = null;
    this.popUpErrorContent = null;
    this.segmentList = [];
    this.selectSegmentEmptyError = t("updateUserSegment.selectSegmentEmptyError");
    this.userIdEmptyError = t("updateUserSegment.UserIdEmptyError");
    this.reasonEmptyError = t("updateUserSegment.ReasonForChangeEmptyError");
    this.getSegmentList();
  }

  async getSegmentList() {
    this.segmentList = await getSegments();
    this.isFetchingSegments = false;
  };

  async submitUpdateUserSegment() {
    this.isFetching = true;
    const res = await switchUserSegment({
      userIdForChange: this.userIdForChange,
      newSegmentId: this.newSegmentId,
      textReasonForChange: this.textReasonForChange,
    });
    if (res.isSuccess) {
      this.isSuccessUpdateUserSegment= true;
    } else {
      this.popUpErrorContent = handleServerError(res.errorCode);
      this.isErrorPopUpOpen = true;
    }
    this.isFetching = false;

  }
}

decorate(UpdateSegmentStore, {
  userIdForChange: observable,
  newSegmentId: observable,
  textReasonForChange: observable,
  isErrorPopUpOpen: observable,
  isSuccessUpdateUserSegment: observable,
  selectSegmentEmptyError: observable,
  userIdEmptyError: observable,
  reasonEmptyError: observable,
  popUpErrorContent: observable,
  segmentList: observable,
  isFetching: observable,
  initProperties: action.bound,
});

export function createUpdateSegmentStore(rootStore) {
  const store = new UpdateSegmentStore(rootStore);
  return store;
}