import React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../../store";
import { t } from "common/localization/translate";
import { TextField } from '@material-ui/core';



export const OtpErrors = observer(() => {
    const store = useScreenHomeStore();
    const handleText = (e) => {
        store.otpCode = e.target.value;
    }

    return (
            <Grid  item xs={12} spacing={3}>
                <TextField type="number" error={store.validateOtpCodeText} helperText={store.validateOtpCodeText}  placeholder={t("suppliers.supplierHome.otpCode")} onChange={handleText} />
            </Grid>
    )
});
