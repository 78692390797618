import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";

export const IsChallengeCheckbox = observer(() => {
  const store = useEditCouponTypeStore();

  const handleChange = (event) => {
    store.isChallenge = event.target.checked;
  };

  return (
    <FormControlLabel
      control={
      <Checkbox
      checked={store.isChallenge ? true : false}
      onChange={handleChange} />}
      label={t("addNewCouponType.IsChallenge")}
    />
  );
});