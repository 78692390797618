import React from "react";
import { makeStyles, Select, MenuItem } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../../..";

const useStyles = makeStyles(() => ({
  input: {
    marginLeft: 20,
    marginBottom: 10,
    minWidth: 200,
    maxWidth: 300,
  },
}));

export const AnswerSelect = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();

  const handleChange = (event) => {
    store.selectedAnswer = event.target.value;
    store.isLead = store.selectedAnswer.lead_data ? true : false;
    store.isCorrectAnswer = store.correctAnswerId == store.selectedAnswer.answer_id ? true: false;
  };

  const answerOptions = store.answerArray.map((answer) => (
    <MenuItem value={answer}>{answer.body}</MenuItem>
  ));

  return (
    <Select
      onChange={handleChange}
      value={store.selectedAnswer}
      className={classes.input}
    >
      {answerOptions}
    </Select>
  );
});
