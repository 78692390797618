import React from "react";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";

export const InsertCodeInput = observer(() => {
  const store = useEditCouponTypeStore();

  const handleChange = (event) => {
  store.codeGenerationParameters = {[event.target.name]: event.target.value};
  };

  return (
      <TextField
        fullWidth
        variant="outlined"
        name="CouponCodesFromInput"
        multiline
        rows={3}
        onChange={handleChange}
        label={t("addNewCouponType.ExternalCouponInputLabel")}
        placeholder={t("addNewCouponType.ExternalCouponCodeInputPlaceholder")}
      />
  );
});
