import React from "react";
import { CssBaseline, Typography, Container } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { t } from "common/localization/translate";
import { EditBalanceButton } from "./components/editBalanceButton";
import { UserIdInput } from "./components/userIdInput";
import { CoinsNumberUpdate } from "./components/coinNumberUpdate";
import { BalanceChangeReason } from "./components/balanceChangeReason";
import { EditUserBalanceSuccessPopUp } from "../popUps/editUserBalanceSuccess";
import { EditUserBalanceErrorPopUp } from "../popUps/editUserBalanceError";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 30,
    flex: "auto",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: 10,
  },
}));

export const Form = observer(() => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Grid className={classes.paper}>
        <Typography className={classes.title} component="h1" variant="h1">
          {t("editBalance.title")}
        </Typography>
        <form className={classes.form} noValidate>
          <UserIdInput />
          <CoinsNumberUpdate />
          <BalanceChangeReason />
          <EditBalanceButton />
          <EditUserBalanceSuccessPopUp />
          <EditUserBalanceErrorPopUp />
        </form>
      </Grid>
    </Container>
  );
});
