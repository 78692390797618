import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import { Button } from '@material-ui/core';
import { t } from "common/localization/translate";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
    button: {
        position: 'absolute',
        paddingTop: '10px'
    },
    icon :{
        paddingRight: "3px"
    }
}));

export const SupplierSupportButton = (({ userName }) => {
    const classes = useStyles();
    const description = t("suppliers.sideBar.support:description", { userName }).replace(/(\n)/g, '%0D%0A');
    const subject = t("suppliers.sideBar.support:subject");
    const toEmail = "alon@yuvital.com";

    return (
        <Button className={classes.button}
            onClick={() => window.open(`mailto:${toEmail}?subject=${subject}&body=${description}`, '_blank')}>
            <HelpIcon className={classes.icon}/> 
            {t("suppliers.sideBar.support:title")}
        </Button>
    )
});