import React from "react";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../..";
import { t } from "common/localization/translate";
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(() => ({
    input: {
      marginBottom: 10,
    },
  }));
  
  export const ArticleImageSmallUrl = observer(() => {
    const classes = useStyles();
    const store = useEditArticleStore();
    const linkContent = store.articleImageSmallUrl ? <Link href={store.articleImageSmallUrl} target="_blank">{t("editBusiness.currentImage")}</Link> : null;
  
    return (
      <div className={classes.input}>
        {linkContent}
      </div>
    );
  });