import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import { useScreenEditUserInfoStore } from "../../../store";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  errorText: {
    marginTop: "60px",
    fontSize: "17px",
    fontFamily: "sans-serif",
    color: theme.palette.primary.main,
  },
}));

export const RemovedSpecialCharactersText = observer((props) => {
  const store = useScreenEditUserInfoStore();
  const classes = useStyles();
  if(!store.isRemovedSpecialCharacters){
    return null;
  }
  return <p className={classes.errorText}>{t('screenEditUserInfo.removedSpecialCharacters', { email: store.userEmail})}</p>;
});
