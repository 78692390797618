import React from "react";
import { DescriptiveCategorySelect } from "./components/DescriptiveCategorySelect";
import { DescriptiveTagSelect } from "./components/DescriptiveTagSelect";
import { Grid } from "@material-ui/core";

export const DescriptiveTagSelector = () => {
  return (
      <Grid item xs={12}>
        <DescriptiveCategorySelect />
        <DescriptiveTagSelect />
      </Grid>
  );
};