import { decorate, action, flow, observable } from 'mobx';
import { getFitCreditsLoadings as doGetFitCreditLoadings } from './server/getFitCreditLoadings';
import { getStartOfMonth, getNow } from 'common/dateAndTime';

export class StoreLoadings {

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.initProperties();
    }

    initProperties() {
        this.startAt = getStartOfMonth();
        this.endAt = getNow();
        this.fitCreditLoadings = [];
        this.countOfLoads = 0;
        this.totalBillForOrganization = 0;
    }

    getFitCreditLoadings = flow(function* () {
        const data = yield doGetFitCreditLoadings(this.startAt, this.endAt);
        this.fitCreditLoadings = data.fitCreditLoadings;
        this.countOfLoads = data.countOfLoads;
        this.totalBillForOrganization = data.totalBillForOrganization;
    });

    setStartAt(date) {
        this.startAt = date;
    }

    setEndAt(date) {
        this.endAt = date;
    };

    onChangeRange(startDate, endDate) {
        this.startAt = startDate;
        this.endAt = endDate;
        this.getFitCreditLoadings();
    }

}

decorate(StoreLoadings, {
    fitCreditLoadings: observable,
    startAt: observable,
    endAt: observable,
    countOfLoads: observable,
    totalBillForOrganization: observable,
    getFitCreditLoadings: action.bound,
    setStartAt: action.bound,
    setEndAt: action.bound,
    onChangeRange: action.bound,
    initProperties: action.bound
});