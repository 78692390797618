import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { observer } from "mobx-react";
import { useAddNewAuctionStore } from "screens/controlPanel/addNewAuction/store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 500,
      "& > * + *": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
      },
    },
    tooltip: {
      fontSize: 14,
    },
    toolTipIcon: {
      color: theme.palette.primary.main
    }
  })
);

export const SegmentSelect = observer(() => {
    const classes = useStyles();
    const store = useAddNewAuctionStore();
    const { segmentsList } = store;

    const handleChange = (event, values) => {
      if(values.length === 0) {
        store.selectedSegmentsEmptyError = t("screenAddNewBusiness.SegmentChoice");
        return;
      }
      store.selectedSegmentsEmptyError = null;
      store.selectedSegments = (values.map(value => Number(value.segment_id)));
    };

    return (
      <Grid className={classes.root}>
            <InputLabel >
            {t("screenAddNewBusiness.SegmentChoice") + " *"}
            </InputLabel>
        <Autocomplete
          multiple
          options={segmentsList}
          getOptionLabel={option => `${option.name} - ${option.segment_id}`}
          filterSelectedOptions
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </Grid>
    );
});