import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { DescriptiveTagSelector } from "./components/descriptiveTags/DescriptiveTagSelector";
import { HealthScore } from "./components/HealthScore";
import { Score } from "./components/Score";
import { Tags } from "./components/Tags";
import { LimitationInput } from "./components/limitationSelect/LimitationInput";
import { LimitationTypeSelect } from "./components/limitationSelect/LimitationTypeSelect";
import { IsActiveCheckbox } from "./components/IsActive";
import { PromotionTypeSelect } from "./components/promotionTypeSelect";
import { t } from "common/localization/translate";
import { OutOfStockRuleSelect } from "./components/segmentComponents/outOfStockRuleSelect";
import { ClalitSmallImage } from "./components/segmentComponents/clalitSmallImage";
import { CostToSupplier } from "./components/segmentComponents/costToSupplier";
import { IsAdminOnly } from "./components/isAdminOnly";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    textAlign: "center",
    flex: "auto",
    marginTop: 30,
    marginBottom: 20,
    fontWeight: "normal",
    color: theme.palette.primary.main,
  },
  gridWidth: {
    width: "50%",
  },
}));

export const InternalInfo = observer(() => {
  const classes = useStyles();

  return (
    <Grid className={classes.gridWidth} container spacing={2}>
      <Typography className={classes.subtitle} component="h1" variant="h2">
        {t("addNewCouponType.AddCouponTypeInternalSubitle")}
      </Typography>
      <Grid>
        <ClalitSmallImage />
        <CostToSupplier />
        <LimitationTypeSelect />
        <LimitationInput />
        <DescriptiveTagSelector />
        <Tags />
        <Score />
        <HealthScore />
        <OutOfStockRuleSelect />
        <PromotionTypeSelect />
        <IsAdminOnly />
        <IsActiveCheckbox />
      </Grid>
    </Grid>
  );
});
