import React from "react";
import { ContactNameInput } from "./components/ContactNameInput";
import { CreateUserButton } from "./components/CreateUserButton";
import { EmailInput } from "./components/EmailInput";
import { PhoneInfo } from "./components/PhoneInfo";

export const Form = (props) => {
  return (
    <div>
      <EmailInput/>
      <ContactNameInput/>
      <PhoneInfo/>
      <CreateUserButton/>
    </div>
  );
};
