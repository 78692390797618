import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { KeyboardDatePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import { t } from "common/localization/translate";
import { convertDateToMoment } from "common/dateAndTime/moment";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 18,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

export const StartDateChallenge = observer(() => {
  const store = useAddNewCouponTypeStore();
  const classes = useStyles();
  const { startChallengeDate } = store;

  const handleDateChange = (date) => {
    if(!date) {
      store.startChallengeDateEmptyError = t("addNewCouponType.selectStartDay");
      return;
    }
    store.startChallengeDateEmptyError = null;
    store.startChallengeDate = convertDateToMoment(date).format("YYYY-MM-DD");
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <InputLabel className={classes.label} shrink>
        {t("addNewCouponType.selectStartDay") + " *"}
      </InputLabel>
      <KeyboardDatePicker
        minDate={new Date()}
        value={startChallengeDate}
        onChange={(date) => handleDateChange(date)}
        format="DD/MM/YYYY"
        invalidDateMessage={startChallengeDate ? true : false}
      />
    </Grid>
  );
});
