import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewAuctionStore } from "screens/controlPanel/addNewAuction/store";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
    marginTop: 10,
  },
}));

export const AuctionTitle = observer(() => {
  const classes = useStyles();
  const store = useAddNewAuctionStore();

  const handleInputChange = (event) => {
    if (!event.target.value) {
      store.auctionTitleEmptyError = t("addNewAuction.addNewAuctionTitle");
    }
    store.auctionTitleEmptyError = null;
    store.auctionTitle = event.target.value;
  };

  return (
    <TextField
      variant="outlined"
      required
      fullWidth
      label={t("addNewAuction.addNewAuctionTitle")}
      onChange={handleInputChange}
      className={classes.input}
      value={store.auctionTitle || ""}
    />
  );
});
