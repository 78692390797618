export const resources = {
  "screenHome.totalNumbersCubesRow.overview.title": "Overview",
  "screenHome.totalNumbersCubesRow.membersCount": "מספר חברי {{segmentName}} ",
  "screenHome.totalNumbersCubesRow.thisMonthNewMembersCount":
    "מספר חברי  {{segmentName}} שהצטרפו בחודש האחרון ",
  "screenHome.totalNumbersCubesRow.totalCoinsSum": "סך כל מטבעות חברי {{segmentName}} ",
  "screenHome.totalNumbersCubesRow.avgImprovement": "ממוצע שיפור {{segmentName}} החודש",
  "screenHome.totalNumbersCubesRow.avgImprovement.display": "%{{number}}",
  "screenHome.topReferrals.tableTitle": "Referral",
  "screenHome.topReferrals.tableSubTitle": "מובילים בצירוף חברים ",
  "screenHome.topReferrals.addedNumber": "Added {{count}} members",
  "screenHome.bonusesTotals.title":"פילוח בונוסים",
  "screenHome.bonusesTotals.articles":"Articles",
  "screenHome.bonusesTotals.referral":"Referral",
  "screenHome.bonusesTotals.dataText":"{{value}} users",

  "screenPurchases.table.title": "Purchases",
  "screenPurchases.table.col.couponTitle": "Coupon Title",
  "screenPurchases.table.col.businessName": "Business Name",
  "screenPurchases.table.col.couponCount": "Purchases Count",

  "topBar.logout.title": "Logout",

  'sideBar.home.title':"Home",
  'sideBar.coupons.title':"Coupons",

  "screenPurchaseUsersInfo.table.title": "פירוט רכישות",
  "screenPurchaseUsersInfo.table.col.couponTitle": "שם השובר",
  "screenPurchaseUsersInfo.table.col.couponCodeTitle": "קוד הטבה/מספר הזמנה",
  "screenPurchaseUsersInfo.table.col.identifier": "מספר תעודת זהות",
  "screenPurchaseUsersInfo.table.col.phone" : "טלפון",
  "screenPurchaseUsersInfo.table.col.userName": "שם המשתמש",
  "screenPurchaseUsersInfo.table.col.date": "תאריך",

  "screenCouponsInInventory.table.title": "Coupons In Inventory",
  "screenCouponsInInventory.table.col.couponTitle": "Coupon Title",
  "screenCouponsInInventory.table.col.businessName": "Business Name",
  "screenCouponsInInventory.table.col.couponCount": 'Amount Of Coupons In Inventory',
  "screenCouponsInInventory.button.allInInventory": 'כרגע במלאי',

  "business.home.impressionGraph.title":'Total views',
  "business.home.ClicksGraph.title":'Total clicks',
  "business.home.impressionCube.title":'Total views in last 30 days',
  "business.home.clicksCube.title":'Total clicks in last 30 days',
  "business.home.purchasesCube.title":'Coupons purchased in the last 30 days',
  "business.home.categoryPositionCube.title":'The position of your business in the category',
  "business.home.categoryPositionCube.value":'{{position}} out of {{businesses}}',
  "businessHome.ImpressionChart.dropDownOption.6Months": "In the last six months",
  "businessHome.ImpressionChart.dropDownOption.30days": "In the last 30 days",
  "businessHome.ImpressionChart.dropDownOption.3Months": "In the last 3 months"
};
