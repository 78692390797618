import React from "react";
import { decorate, action, observable } from "mobx";
import { getBusinessIds } from "../../addCouponType/store/server/getBusinessIds";
import { getCouponTypesByBusinessId } from "./server/getCouponTypesByBusinessId";
import { externalCouponGenerationTypes } from "./logic/externalCouponGenerationTypes";
import { timeTypes } from "screens/controlPanel/addCouponType/store/logic/timeTypes";
import { getSuppliers } from "screens/controlPanel/addCouponType/store/server/getSuppliers";
import { getCouponTypesGroupServer } from 'screens/controlPanel/addCouponType/store/server/getCouponTypesGroupServer';
import { limitationTypes } from "screens/controlPanel/addCouponType/store/logic/limitationTypes";
import { getDescriptiveTagsCategories } from "screens/controlPanel/addNewBuisness/store/server/getDescriptiveTagsCategories";
import { getDescriptiveTagsByCategory } from "screens/controlPanel/addNewBuisness/store/server/getDescriptiveTagsByCategory";
import { promotionTypes } from "./logic/promotionTypes";
import { editCouponType } from "./server/editCouponType";
import { calcActiveDurationTimeInMinutes } from "screens/controlPanel/addCouponType/store/logic/calcActiveDurationTime";
import { getBusinessById } from "../../addCouponType/store/server/getBusinessById";
import { codeGenerationStrategies } from "./components/form/generalInfo/components/codeGeneration/codeGenerationStrategies";
import { getCouponFixedCode } from "./server/getCouponFixedCode";
import { getPatches } from "screens/controlPanel/addCouponType/store/server/getPatches";
import { patch } from "./logic/patch";
import { t } from "common/localization/translate";
import { paymentMethods } from "screens/controlPanel/addCouponType/store/components/form/GeneralInfo/components/couponCreditPrice/logic/paymentMethods";
import { getChallengeByCriterionId } from "./server/getChallengeByCriterionId";

const EditCouponTypeStoreContext = React.createContext({});

export const EditCouponTypeStoreProvider =
  EditCouponTypeStoreContext.Provider;

export const useEditCouponTypeStore = () =>
  React.useContext(EditCouponTypeStoreContext);

const defaultHealthScore = 25;
const defaultScore = 10;

class EditCouponTypeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  };

  initProperties() {
    this.couponTypesGroup = [];
    this.couponData = {};
    this.limitationParams = {};
    this.codesFromExternalCouponCodesInputType = externalCouponGenerationTypes[0];
    this.timeType = timeTypes[0];
    this.limitationType = limitationTypes[2];
    this.descriptiveTagsCategoriesList = [];
    this.descriptiveTagsByCategoryList = [];
    this.descriptiveTags = [];
    this.segments = [];
    this.score = defaultScore;
    this.healthScore = defaultHealthScore;
    this.promotionType = promotionTypes[0].name;
    this.costToSupplier = null;
    this.patches = [];
    this.selectedPatch = '';
    this.getPatches();
    this.getBusinessIdsList();
    this.hasChallengeBeenEdited  = false;
    // this.getSuppliersList();
    this.getDescriptiveTagsCategoriesList();
  };
  async getBusinessIdsList() {
    this.businessesList = await getBusinessIds();
  };
  async getCouponTypesList() {
    this.couponTypesList = await getCouponTypesByBusinessId(this.selectedBusinessId);
  };
  async getSuppliersList() {
    this.suppliersList = await getSuppliers();
  };
  async getCouponTypesGroup() {
    const couponTypesGroup = await getCouponTypesGroupServer({supplierId: this.supplierId});
    this.couponTypesGroup = couponTypesGroup;
  };
  async getDescriptiveTagsCategoriesList() {
    const tagsCategories = await getDescriptiveTagsCategories();
    tagsCategories.forEach((item, i) => { item.id = i + 1 });
    this.descriptiveTagsCategoriesList = tagsCategories;
  };
  async getDescriptiveTagsByCategoryList() {
    const descriptiveTags = await getDescriptiveTagsByCategory({ categoryTags: this.descriptiveTagsCategory });
    this.descriptiveTagsByCategoryList = descriptiveTags[0].descriptive_tags;
  };
  
  async getPatches() {
    const res = await getPatches();
    const withoutPatch = { value: patch.WITHOUT_PATCH, title: `${t("editCouponType.choosePatch.withoutPatch")}`, patch_id: patch.WITHOUT_PATCH, parameters: { url: patch.WITHOUT_PATCH } };
    res.unshift(withoutPatch);
    this.patches = res;
  };
  async getBusinessData(){
    const businessData = await getBusinessById(this.selectedBusinessId);
    this.segments = businessData.segments;
  };
  
  async getChallengeData (){
    const challengeData = await getChallengeByCriterionId(this.criterionId);
    if(challengeData){
      this.challengeTypeSelected = challengeData.criterion_type;
      this.stepsAmountInput = challengeData.steps;
      this.startChallengeDate = challengeData.from_date;
      this.daysRangeInput = challengeData.days_range;
      this.daysSuccessionInput = challengeData.days_succession;
    }
  };
  
  async getCouponData() {
    const couponData = this.couponTypesList.find(element => element.coupon_type_id == this.selectedCouponTypeId);
    this.couponTitle = couponData.title;
    this.couponDescription = couponData.description;
    this.externalLink = couponData.external_link;
    this.currentCouponImageUrl = couponData.image_link;
    this.coins = couponData.coins;
    this.priceInCurrency = couponData.price_in_currency ? couponData.price_in_currency.ils : null;
    this.activationType = couponData.activation_type;
    this.instructions = couponData.instruction_text;
    this.isOnline = couponData.is_online;
    this.isOneTime = couponData.is_one_time;
    this.codeGenerationStrategy = couponData.code_generation_strategy;
    this.expiresAt = couponData.expire_at;
    this.orderByDate = couponData.order_by_date;
    this.activeDurationMinutes = couponData.active_duration_min;
    this.supplierId = couponData.supplier_id;
    this.limitationType = couponData.limitations ? couponData.limitations[0] : limitationTypes[2];
    this.limitationParams = couponData.limitations ? couponData.limitations[0].parameters : "";
    this.descriptiveTags = couponData.descriptive_tags;
    this.tags = couponData.tags;
    this.score = couponData.score;
    this.healthScore = couponData.health_score;
    this.promotionType = couponData.attributes ? couponData.attributes.promotionType : promotionTypes[0].name;
    this.costToSupplier = couponData.attributes? couponData.attributes.costToOvdim || couponData.attributes.costToMushlam : null;
    this.isActive = couponData.is_active;  
    this.isAdminOnly = couponData.attributes ? couponData.attributes.isShowAdminOnly : null;
    this.externalProductIdBySupplier = couponData.attributes ? couponData.attributes.externalProductIdBySupplier : "";
    this.isCreditRequired = Boolean(couponData.price_in_currency);  
    this.previousAttributes = couponData.attributes;
    this.previousDisplayAttributes = couponData.display_attributes;
    this.warningText = couponData.warnings ? couponData.warnings[0].text : null;
    this.underPurchaseButtonText = couponData.display_attributes ? couponData.display_attributes.freePurchaseButtonText : null;
    this.underTitleText = couponData.display_attributes ? couponData.display_attributes.warningCouponText : null;
    this.selectedPatch = couponData.display_attributes ? couponData.display_attributes.patch : '';
    this.getSelectedPaymentMethod({ couponData })
    this.setCurrentFixedCode({ couponData });
    this.criterionId = couponData.criterion_id ? Number(couponData.criterion_id) : null;
    this.isChallenge = couponData.criterion_id ? true : false;
    if (this.isChallenge){
      await this.getChallengeData();
    }
  };
  
  async submitEditCouponType() {
    this.isFetching = true;

    this.challengeParams = this.hasChallengeBeenEdited || this.isChallenge ? { stepsAmount: this.stepsAmountInput, daysRangeInput: this.daysRangeInput, startChallengeDate: this.startChallengeDate, challengeTypeSelected: this.challengeTypeSelected, daysSuccessionInput: this.daysSuccessionInput } : null;
   
    const res = await editCouponType({businessId: this.selectedBusinessId, couponTypeId: this.selectedCouponTypeId, couponTitle: this.couponTitle, couponDescription: this.couponDescription, externalLink: this.externalLink, 
      couponImage: this.couponImage, coins: this.coins, price: this.priceInCurrency, activationType: this.activationType, instructions: this.instructions, isOnline: this.isOnline, 
      codeGenerationStrategy: this.codeGenerationStrategy, expiresAt: this.expiresAt, activeDurationMinutes: Math.round(calcActiveDurationTimeInMinutes(this.activeDurationMinutes, this.timeType)), supplierId: this.supplierId, isSupplierDashboardCouponType: this.isSupplierDashboardCouponType,
      limitationParams: this.limitationParams, limitationType: this.limitationType.type, tags: this.tags, isOneTime: this.isOneTime, descriptiveTags: this.descriptiveTags, score: this.score, healthScore: this.healthScore, isActive: this.isActive,
      codeGenerationParameters: this.codeGenerationParameters, previousAttributes: this.previousAttributes, promotionType: this.promotionType, prevCouponImageUrl: this.currentCouponImageUrl, costToSupplier: this.costToSupplier, segments: this.segments,
      clalitSmallImage: this.clalitSmallImage, previousDisplayAttributes: this.previousDisplayAttributes, warningText: this.warningText, orderByDate: this.orderByDate, selectedPatch: this.selectedPatch, selectedPaymentMethod: this.selectedPaymentMethod, criterionId: this.criterionId , challengeParams: this.challengeParams});
      
    this.editCouponTypeStatus = res.isSuccess;
    this.isFetching = false;
    return res;
    }  

  async setCurrentFixedCode({ couponData }) {
    if (couponData.code_generation_strategy == codeGenerationStrategies.FIXED_CODE) {
      const res = await getCouponFixedCode({ selectedCouponTypeId: this.selectedCouponTypeId });
      this.currentFixedCode = res.extra.code;
    }
  }

  getSelectedPaymentMethod({ couponData }) {
    const { attributes } = couponData;
    if(attributes){
      this.selectedPaymentMethod = attributes.transactionProvider || paymentMethods.MOVEMENT_PROVIDER.value;
    }
  }
  };

decorate(EditCouponTypeStore, {
  businessesList: observable,
  couponTypesList: observable,
  selectedBusinessId: observable,
  selectedCouponTypeId: observable,
  couponData: observable,
  couponTitle: observable,
  couponDescription: observable,
  externalLink: observable,
  externalProductIdBySupplier: observable,
  currentCouponImageUrl: observable,
  couponImage: observable,
  coins: observable,
  priceInCurrency: observable,
  activationType: observable,
  instructions: observable,
  isOneTime: observable,
  isOnline: observable,
  codeGenerationStrategy: observable,
  codeGenerationParameters: observable,
  currentFixedCode: observable,
  warningText: observable,
  underPurchaseButtonText: observable,
  underTitleText: observable,
  codesFromExternalCouponCodesInputType: observable,
  expiresAt: observable,
  activeDurationMinutes: observable,
  timeType: observable,
  suppliersList: observable,
  supplierId: observable,
  isSupplierDashboardCouponType: observable,
  isSupportSupplierDashboard: observable,
  couponTypesGroup: observable,
  isExistingCouponTypeGroup: observable,
  limitationParams: observable,
  limitationType: observable,
  descriptiveTagsCategoriesList: observable,
  descriptiveTagsCategory: observable,
  descriptiveTagsByCategoryList: observable,
  descriptiveTags: observable,
  tags: observable,
  score: observable,
  healthScore: observable,
  promotionType: observable,
  isActive: observable,
  isAdminOnly: observable,
  isFetching: observable,
  isCreditRequired: observable,
  editCouponTypeStatus: observable,
  challengeEditStatus: observable,
  isErrorPopUpOpen: observable,
  previousAttributes: observable,
  segments: observable,
  costToSupplier: observable,
  clalitSmallImage: observable,
  previousDisplayAttributes: observable,
  orderByDate: observable,
  patches: observable,
  selectedPatch: observable,
  selectedPaymentMethod: observable,
  isChallenge: observable,
  challengeTypeSelected: observable,
  stepsAmountInput: observable,
  startChallengeDate: observable,
  daysRangeInput: observable,
  daysSuccessionInput: observable,
  criterionId: observable,
  hasChallengeBeenEdited: observable,
  initProperties: action.bound,
});

export function createEditCouponTypeStore(rootStore) {
  const store = new EditCouponTypeStore(rootStore);
  return store;
};