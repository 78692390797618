import React from 'react';
import { segmentsPaths } from '../../../../commonStores/analytics/userInfoStore/logic/getSegmentPathById';
import { formatNumber } from "common/formatNumber";
import { t } from "common/localization/translate";
import { clalitTableData } from './clalitTableData';

export const getTableData = (segmentId) => {

    const columns = (segmentId == segmentsPaths.CLALIT.id) ? clalitTableData() :
    [
        {
            name: "day",
            label: t("screenDailyStepsAndGoals.table.col.day"),
          },
          {
            name: "dailySteps",
            label: t("screenDailyStepsAndGoals.table.col.dailySteps"),
            options: {
              customBodyRender: (value) => {
                return <p>{formatNumber(value)}</p>;
              },
            },
          },
          {
            name: "goal",
            label: t("screenDailyStepsAndGoals.table.col.goal"),
            options: {
              customBodyRender: (value) => {
                return <p>{formatNumber(value)}</p>;
              },
            },
          },
          {
            name: "goalMet",
            label: t("screenDailyStepsAndGoals.table.col.goalMet"),
            options: {
              customBodyRender: (isGoalMet) => {
                const styles = { green: { color: "green" }, red: { color: "red" } };
                const text = isGoalMet ? t("screenDailyStepsAndGoals.table.userDidMetGoal") : t("screenDailyStepsAndGoals.table.userDidNotMetGoal");
                const style = isGoalMet ? styles.green : styles.red;
                return <p style={style}>{text}</p>;
            },
           },
          },
    ];
  
    const options = {
      download: false,
      filter: false,
      print: false,
      viewColumns: false,
      responsive: "scrollMaxHeight",
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: t("screenDailyStepsAndGoals.table.col.noMatch"),
        },
      },
    };
    return { options, columns };
  };
  