import React, { useState } from "react";
import moment from "moment";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button, ButtonGroup } from "@material-ui/core";
import {
  DatePicker as MaterialDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CalendarTodayIcon from "@material-ui/icons/CalendarTodayOutlined";
import {
  getNowInMoment,
  subtractFromDate,
  convertDateToMoment
} from "common/dateAndTime/moment";
import "moment/locale/he";

const useStyles = makeStyles(theme => ({
  root: {},
  dates: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 15,
    marginTop: 10
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  buttonGroup: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      width: "50%"
    }
  }
}));

const DatePicker = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const now = getNowInMoment();
  const seventDaysAgo = subtractFromDate(now, 7, "days");

  const [startDate, setStartDate] = useState(seventDaysAgo);
  const [endDate, setEndDate] = useState(now);
  const [selectEdge, setSelectEdge] = useState(null);
  const [calendarDate, setCalendarDate] = useState(now);

  const handleCalendarOpen = async (edge)=> {
    if (edge === "start") {
      await setCalendarDate(startDate);
    } else {
      await setCalendarDate(endDate);
    }
    setSelectEdge(edge);

  };

  const handleCalendarChange = date => {
    setCalendarDate(date);
  };

  const handleCalendarClose = () => {
    const now = getNowInMoment();
    setCalendarDate(now);

    setSelectEdge(null);
  };

  const handleCalendarAccept = date => {
    const { onChangeStartDate, onChangeEndDate } = props;
    const now = getNowInMoment();
    const dateInMoment = convertDateToMoment(date);
    setCalendarDate(now);

    if (selectEdge === "start") {
      setStartDate(date);
      onChangeStartDate(date);

      if (dateInMoment.isAfter(endDate)) {
        setEndDate(date);
        onChangeEndDate(date);
      }
    } else {
      setEndDate(date);
      onChangeEndDate(date);

      if (dateInMoment.isBefore(startDate)) {
        setStartDate(date);
        onChangeStartDate(date);
      }
    }

    setSelectEdge(null);
  };

  const open = Boolean(selectEdge);
  moment.locale("he");
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container justify="flex-end" spacing={3}>
        <Grid className={classes.dates} item lg={6} xs={12}>
          <ButtonGroup variant="contained" className={classes.buttonGroup}>
            <Button
              className={classes.button}
              onClick={() => handleCalendarOpen("end")}
            >
              <CalendarTodayIcon className={classes.calendarTodayIcon} />
              {endDate.format("DD/MM/YYYY")}
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleCalendarOpen("start")}
            >
              <CalendarTodayIcon className={classes.calendarTodayIcon} />
              {startDate.format("DD/MM/YYYY")}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale="he"
      >
        <MaterialDatePicker
          maxDate={now}
          onAccept={handleCalendarAccept}
          onChange={handleCalendarChange}
          onClose={handleCalendarClose}
          disableFuture={true}
          open={open}
          style={{ display: "none" }} // Temporal fix to hide the input element
          value={calendarDate}
          variant="dialog"
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePicker;
