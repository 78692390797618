import React from "react";
import { decorate, action, observable } from "mobx";
import { deleteUserById } from "./server/deleteUserById";
import { handleServerError } from "./logic/handleServerError";
import { t } from "common/localization/translate";

const DeleteUser = React.createContext({});
export const DeleteUserStoreProvider = DeleteUser.Provider;
export const useDeleteUserStoreStore = () => React.useContext(DeleteUser);

class DeleteUserStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }

  initProperties() {
    this.userIdForClean = null;
    this.serverPopUpErrorContent = null;
    this.isErrorPopUpOpen = null;
    this.userIdEmptyError = t("deleteUser.UserIdError");
    this.areYouSurePopUp = false;
  }

  async submitDeleteUser() {
    this.isFetching = true;
    const res = await deleteUserById({
      userIdForClean: this.userIdForClean,
    });
    if (res.isSuccess) {
      this.isSuccessPopUpOpen = true;
    } else {
      this.serverPopUpErrorContent = handleServerError(res.errorCode);
      this.isErrorPopUpOpen = true;
    }
    this.isFetching = false;
  }
}

decorate(DeleteUserStore, {
  userIdForClean: observable,
  userIdEmptyError: observable,
  isErrorPopUpOpen: observable,
  isSuccessPopUpOpen: observable,
  areYouSurePopUp: observable,
  serverPopUpErrorContent: observable,
  isFetching: observable,
  initProperties: action.bound,
});

export function createDeleteUserStore(rootStore) {
  const store = new DeleteUserStore(rootStore);
  return store;
}
