import React from "react";
import { decorate, action, observable } from "mobx";
import { getUserData as getScreenDataServer } from "./server/getScreenData";
import { editUserInfo } from "./server/editUserInfo";
import { SendResetPassword } from "./server/sendResetPassword";
import { OpenUniqueDeviceIdBlock } from "./server/openUniqueDeviceIdBlock";
import { t } from "common/localization/translate";
import { editUserInfoErrorCodes } from "./logic/editUserInfoErrorCodes";
import { validatePhoneNumber } from '../../editUserInfo/store/logic/validatePhoneNumber';
import { errorCodes } from '../../services/errorCodes';
import { validateIsraeliIdStructure } from "common/validateIsraeliIdStructure/validateIsraeliIdStructure";

const ScreenEditUserInfoStoreContext = React.createContext({});
export const ScreenEditUserInfoDataProvider = ScreenEditUserInfoStoreContext.Provider;
export const useScreenEditUserInfoStore = () => React.useContext(ScreenEditUserInfoStoreContext);

class ScreenEditUserInfoStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  setErrorCode(errorCode) {
    this.setSuccessText(null);
    this.errorCode = errorCode;
  }
  setUserIdentifier(userIdentifier) {
    this.userIdentifier = userIdentifier;
  }

  setServerError(errorNum) {
    this.serverError = errorNum;
  }

  setUserId(userId) {
    this.userId = userId;
  }

  setUserEmail(email) {
    this.userEmail = email;
    if(this.isRemovedSpecialCharacters){
      this.isRemovedSpecialCharacters = false;
    }
  }

  setUserName(userName) {
    this.userName = userName;
  }

  setIsUserEntitled(isEntitled) {
    this.isEntitled = isEntitled;
  }

  async submitResetPassword() {
    const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
    const res = await SendResetPassword(this.userId, segmentId);
    this.setErrorCode(res.errorCode);
    if(res.isSuccess){
      this.setSuccessText(t('screenEditUserInfo.resetPassword.success', { email: this.userEmail}))
    }
    return res;
  }

  async unblockUniqueDeviceId() {
    if(this.isFetching){
      return; 
    }
    this.setIsFetching(true);
    const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
    const res = await OpenUniqueDeviceIdBlock(this.userId, segmentId);
    this.setErrorCode(res.errorCode);
    if(res.isSuccess){
      this.setSuccessText(t('screenEditUserInfo.button.unblockUniqueDeviceId.success'))
    }
    this.setIsFetching(false);
  }

  async submitUserChanges() {
    if(this.isFetching){
      return; 
    }
    this.setErrorCode(null);
    await this.validatEditUserInfo();
    if(this.errorCode){
      return;
    }
    this.setIsFetching(true);
    const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
    const res = await editUserInfo(this.userId, this.userName, this.userEmail, this.phone, segmentId);
    this.setErrorCode(res.errorCode);
    if(res.isSuccess){
      this.setSuccessText(t('screenEditUserInfo.editInfo.sucessText'))
      this.userEmail = res.email;
    }
    if(res.extra && res.extra.isRemovedSpecialCharacters){
      this.isRemovedSpecialCharacters = res.extra.isRemovedSpecialCharacters;
    }
    this.setIsFetching(false);
  }

  submitIdentifier() {
    this.setUserName(null);
    const isValidId = validateIsraeliIdStructure(this.userIdentifier);
    const isValidPhone = validatePhoneNumber(this.userIdentifier, true);
    if(this.rootStore.userInfoStore.isWithSearchByPhoneOption){
      if(isValidId || isValidPhone){ 
        this.getUserData();
        return;
      }
      this.setErrorCode(errorCodes.ERROR_TYPE_INVALID_DATA);
      return;
    }
    if(!isValidId){
      this.setErrorCode(errorCodes.ERROR_TYPE_INVALID_DATA);
      return;
    }
    this.getUserData();

  }

  validatEditUserInfo(){
    if(!this.userName){
      this.setErrorCode(editUserInfoErrorCodes.ERROR_TYPE_EMPTY_USER_NAME);
    }
    if(!this.userEmail){
      this.setErrorCode(editUserInfoErrorCodes.ERROR_TYPE_EMPTY_EMAIL);
    }

    const isValidPhone = validatePhoneNumber(this.phone);
    if(!isValidPhone){
      this.setErrorCode(editUserInfoErrorCodes.ERROR_TYPE_INVALID_PHONE);
    }
  }

  async getUserData() {
    try{
    if(this.isFetching){
      return; 
    }
    this.setIsFetching(true);
    const segmentId = this.rootStore.userInfoStore.currentSegment
      ? this.rootStore.userInfoStore.currentSegment.segmentId
      : null;
    const res = await getScreenDataServer(this.userIdentifier, segmentId);
    this.setErrorCode(res.errorCode);
    this.setUserName(res.userName);
    this.setUserEmail(res.email);
    this.setUserId(res.userId);
    this.setIsUserEntitled(res.isEntitled);
    this.phone = res.phone;
    this.setIsFetching(false);
  }
  catch(e){
    this.setErrorCode(editUserInfoErrorCodes.ERROR_TYPE_USER_NOT_EXISTS);
    this.setIsFetching(false);
  }
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }

  setSuccessText(text) {
    this.successText = text;
  }
}

decorate(ScreenEditUserInfoStore, {
  userIdentifier: observable,
  userEmail: observable,
  userId: observable,
  userName: observable,
  phone: observable,
  serverError: observable,
  isFetching: observable,
  errorCode: observable,
  successText: observable,
  isRemovedSpecialCharacters: observable,
  isEntitled: observable,
  setUserIdentifier: action.bound,
  setServerError: action.bound,
  setUserEmail: action.bound,
  setUserName: action.bound,
  setErrorCode: action.bound,
  setUserId: action.bound,
  setIsFetching: action.bound,
  setSuccessText: action.bound,
  setIsUserEntitled: action.bound,
  validatEditUserInfo: action.bound
});

export function createScreenEditUserInfoStore(rootStore) {
  const store = new ScreenEditUserInfoStore(rootStore);
  return store;
}
