import { getMoment } from "screens/organization/appOpenByHours/store/logic/getMoment";

export function createTraineesInfoTableDataArray(traineesList, isPoliceSegment) {
    const moment = getMoment();
    const getTrainerNames = (item) => item.trainersNames ? item.trainersNames.join(', ') : '---';
    const getGroupNames = (item) => item.groupNames ? item.groupNames.join(', ') : '---';
    const getNutritionistNames = (item) => item.nutritionistNames ? item.nutritionistNames.join(', ') : '---';
    const getSignupStatusText = (item) => item.isSignUp ? 'מחובר' : 'לא מחובר';
    const getContentAmount = (item) => item.countReadAtricleInLast6Month ? Number(item.countReadAtricleInLast6Month) : '---';
    const getDate = (date) => date ? moment(date).format('DD/MM/YYYY HH:mm') : '---';

    const traineesListData = traineesList.map((item) => {
        return isPoliceSegment
            ? createPoliceDataArray({ item, getContentAmount, getDate })
            : createDefaultDataArray({ item, getTrainerNames, getGroupNames, getNutritionistNames, getSignupStatusText, getContentAmount, getDate });
    });

    return traineesListData;
}

const createPoliceDataArray = ({ item, getContentAmount, getDate }) => {
    if(!item){
        return [];
    }
    return ([
        item.userName,
        item.idNum || '---',
        item.phone || '---',
        getDate(item.createdAt),
        getDate(item.lastOpen),
        item.email,
        item.avgStepsLast30Days || 'לא התבצעה המרת צעדים בתקופה זו',
        getDate(item.lastDepositDate),
        item.countAppOpenLast30Days || 0,
        getDate(item.lastArticleReadDate),
        item.lastArticleReadName || '---',
        getContentAmount(item)
    ]);
}

const createDefaultDataArray = ({ item, getTrainerNames, getGroupNames, getNutritionistNames, getSignupStatusText, getContentAmount, getDate }) => {
    return ([
        item.segmentName,
        getGroupNames(item),
        getNutritionistNames(item),
        getTrainerNames(item),
        item.userName,
        item.idNum || '---',
        item.phone || '---',
        getSignupStatusText(item),
        getDate(item.createdAt),
        getDate(item.lastOpen),
        item.email,
        item.userName,
        item.avgStepsLast30Days || 'לא התבצעה המרת צעדים בתקופה זו',
        getDate(item.lastDepositDate),
        item.countAppOpenLast30Days || 0,
        item.countPurchaseRumbleAcoSystemCoupons || 0,
        getDate(item.lastPurchaseAtidCouponDate),
        item.lastPurchaseAtidCouponName || '---',
        getDate(item.lastArticleReadDate),
        item.lastArticleReadName || '---',
        getContentAmount(item)
    ]);
}