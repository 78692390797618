import { UserInfoStore } from '../userInfoStore/index';
import { StoreLogin } from 'screens/login/store';
import { StorePurchases as StorePurchasesBiz } from 'screens/biz/purchases/store';
import { StorePurchasesSummaryByBranch } from 'screens/biz/purchasesSummaryByBranch/store';
import { StoreLoadings } from 'screens/org/loadings/store';
import { StorePurchases as StorePurchasesOrg } from 'screens/org/purchases/store';
import { StoreMain } from 'screens/movment/main/store';
import { StoreSummaryOfLoadingsPerOrganization } from 'screens/movment/summaryOfLoadingsPerOrganization/store';
import { StoreSummaryOfPurchasesByBusiness } from 'screens/movment/summaryOfPurchasessByBusiness/store';

class RootStore {

    constructor() {
        this.biz = {
            storePurchases: new StorePurchasesBiz(this),
            storePurchasesSummaryByBranch: new StorePurchasesSummaryByBranch(this)
        }

        this.org = {
            storeLoadings: new StoreLoadings(this),
            storePurchases: new StorePurchasesOrg(this)
        }

        this.movement = {
            storeMain: new StoreMain(this),
        }

        //This is here because StoreSummaryOfLoadingsPerOrganization is using storeMain from movement
        this.movement.StoreSummaryOfLoadingsPerOrganization = new StoreSummaryOfLoadingsPerOrganization(this);
        this.movement.StoreSummaryOfPurchasesByBusiness = new StoreSummaryOfPurchasesByBusiness(this);

        this.userInfoStore = new UserInfoStore(this);
        this.loginStore = new StoreLogin(this);
    }
}

export function createStore(){
    const store = new RootStore();
    return store;
}
