import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { Page } from "components";
import { Grid } from "@material-ui/core";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { t } from "common/localization/translate";
import {
  createScreenConfirmationsUsersStore,
  ScreenConfirmationsUsersDataProvider,
} from "./store";
import ConfirmationTable from "./components/verticalTableConfirmations";
import { PageTitles } from "./components/titles";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import { Redirect } from "react-router-dom";
import { IdNumForm } from './components/idNumForm/index'


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flex: 1,
  },
  container: {
    "& > *": {
      height: "100%",
      padding: theme.spacing(0.5),
    },
  }
}));

export const ConfirmationsUserInfo = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() =>
    createScreenConfirmationsUsersStore(rootStore)
  );
  const classes = useStyles();
  const { confirmationsUserTableData, isFetching } = store;


  if (!rootStore.userInfoStore.isShowSubSegmentUsersScreen) {
    return <Redirect to={{ pathname: `/${dashboardPaths.ORGANIZATION}/home` }} />
  }
  return (
    <ScreenConfirmationsUsersDataProvider value={store}>
      <Page className={classes.root} title={t("screenConfirmationUser.title")}>
        <Grid className={classes.container} container item lg={12} xs={12} spacing={2}>
          <PageTitles />
          <IdNumForm />
        </Grid>
        <br></br>
        <ConfirmationTable
          isFetching={isFetching}
          errorType={store.errorCode}
          confirmationsData={confirmationsUserTableData}
        />
      </Page>
    </ScreenConfirmationsUsersDataProvider>
  );
});
