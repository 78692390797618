import React from "react";
import { decorate, action, observable, computed } from "mobx";
import { validationText } from '../store/logic/validationText';
import { getValidationCodeScreenData } from './server/getValidationCodeScreenData';
import { changeSupplierValidationServer } from '../store/server/changeSupplierValidationServer';
import { t } from "common/localization/translate";

const ScreenValidationCodeStoreContext = React.createContext({});
export const ScreenValidationCodeStoreProvider = ScreenValidationCodeStoreContext.Provider;
export const useScreenValidationCodeStore = () =>
  React.useContext(ScreenValidationCodeStoreContext);

class ScreenValidationCodeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }


  getScreenData = async () => {
    const data = await getValidationCodeScreenData();
    this.suppliersList = data.extra.suppliers;
    this.validationsList = data.extra.validations;
    this.chosenSupplier = data.extra.suppliers[0].supplier_id;
    this.supplierValidation = validationText[data.extra.suppliers[0].extra.validationMethod] || t("suppliers.validation.validationIsNotExist");
    this.chosenValidation = data.extra.validations[0].methodName;
    this.chosenListCodesToPerformValidation = data.extra.validations[0].listCodesToPerformValidation;
  }

  getSupplierValidation = async () =>{
    const validationMethod = this.suppliersList.find(supplier => supplier.supplier_id == this.chosenSupplier).extra.validationMethod;
    this.supplierValidation = validationText[validationMethod] || t("suppliers.validation.validationIsNotExist");
  }

  changeSupplierValidation = async() =>{
    const data = await changeSupplierValidationServer(this.chosenValidation, this.chosenSupplier, this.chosenListCodesToPerformValidation);
    if(data.isSuccess){
      this.isSuccessChangeValidationPopUp = data.isSuccess;
      this.supplierValidation = validationText[this.chosenValidation];
    }

  }
}

decorate(ScreenValidationCodeStore, {
  chosenSupplier: observable,
  chosenValidation: observable,
  chosenListCodesToPerformValidation : observable,
  supplierValidation: observable,
  suppliersList : observable,
  validationsList : observable,
  isSuccessChangeValidationPopUp: observable
});

export function createScreenValidationCodeStore(rootStore) {
  const store = new ScreenValidationCodeStore(rootStore);
  return store;
}
