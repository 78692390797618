import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { observer } from "mobx-react-lite";
import { useEditBusinessStore } from "../../../../index";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.white,
      fontSize: 16,
      fontFamily: "Assistant",
      fontWeight: "bold",
    },
  },
}));

export const EmptyFieldErrorPopUp = observer(() => {
  const store = useEditBusinessStore();
  const classes = useStyles();
  const {contactPhoneEmptyError, businessNameEmptyError, businessLogoEmptyError, businessLocationEmptyError, businessDescriptionEmptyError, scoreEmptyError, tagsEmptyError, segmentsEmptyError, primaryCategoryEmptyError, descriptiveTagsEmptyError, contactEmailEmptyError, contactNameEmptyError} = store;
  const requiredFields = [businessNameEmptyError, businessDescriptionEmptyError, businessLogoEmptyError, primaryCategoryEmptyError, businessLocationEmptyError, segmentsEmptyError, descriptiveTagsEmptyError, tagsEmptyError, contactNameEmptyError, contactEmailEmptyError, contactPhoneEmptyError, scoreEmptyError];
  
  const emptyFields = requiredFields.filter(fieldEmptyError => fieldEmptyError !== null);
  const emptyFieldErrorReport = emptyFields.map((field)=><p key={field}>{field}</p>);

  const handleClose = () => {
    store.isEmptyFieldErrorPopUpOpen = false;
  };

  return (
    <div>
      <Dialog open={store.isEmptyFieldErrorPopUpOpen}>
        <DialogTitle className={classes.title}>
          {t("screenAddNewBusiness.ErrorPopUpTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <ul>
              {emptyFieldErrorReport}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t("suppliers.supplierHome.verifyCodesOkPopUp")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});