import React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { CodeGenerationStrategyDropdown } from "../CodeGenerationStrategyDropdown";
import { ChooseExternalInputType } from "./ChooseExternalInputType";

export const ExternalCode = observer(() => {
  return (
    <Grid>
      <CodeGenerationStrategyDropdown />
      <ChooseExternalInputType />
    </Grid>
  );
});
