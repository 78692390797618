import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { Page } from "components";
import { Grid } from "@material-ui/core";
import { t } from "common/localization/translate";
import { TitlesSendNotificationToUser } from "./components/titlesSendNotificationToUser";
import {createScreenSendNotificationToUserStore, ScreenSendNotificationToUserProvider} from "./store";
import { IdNumForm } from "./components/idNumForm";
import { NotificationCard } from './components/notificationCard';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flex: 1,
  },
  container: {
    "& > *": {
      height: "100%",
      padding: theme.spacing(0.5),
    },
  }
}));

export const SendNotificationToUser = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenSendNotificationToUserStore(rootStore));
  const classes = useStyles();

  return (
    <ScreenSendNotificationToUserProvider value={store}>
      <Page className={classes.root} title={t("screenCouponsUser.title")}>
        <Grid className={classes.container} container item lg={12} xs={12} spacing={2}>
          <TitlesSendNotificationToUser />
          <IdNumForm />
        </Grid>
        <br />
        <NotificationCard/>
      </Page>
    </ScreenSendNotificationToUserProvider>
  );
});
