import { decorate, action, flow, observable } from "mobx";
import { login } from "./server/login";
import { getRedirectTo } from "../getRedirectTo";
import { getDashboardMode } from "common/getDashboardMode";

export class StoreLogin {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }

  initProperties() {
    this.email = "";
    this.password = "";
    this.errorCode = null;
  }

  preformLogin = flow(function*(pathname, history) {
    const { isSuccess, errorCode, extra } = yield login(
      this.email,
      this.password,
      pathname
    );

    if (isSuccess) {
      const { token } = extra;
      const { userInfoStore } = this.rootStore;
      userInfoStore.login(token, extra, pathname);
      const dashboardMode = getDashboardMode(pathname);
      const newPath = getRedirectTo(dashboardMode);
      history.push(newPath);
    } else {
      this.errorCode = errorCode;
    }
  });

  setEmail(email) {
    this.email = email;
  }

  setPassword(password) {
    this.password = password;
  }
}

decorate(StoreLogin, {
  email: observable,
  password: observable,
  errorCode: observable,
  setEmail: action.bound,
  setPassword: action.bound,
  preformLogin: action.bound,
  initProperties: action.bound
});
