import React from "react";
import { InputLabel, Select, makeStyles, MenuItem, Grid} from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from 'screens/controlPanel/editCouponType/store';
import { promotionTypes } from "../../../logic/promotionTypes";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  category: {
    margin: theme.spacing(1),
    minWidth: 300,
    border: 1,
    borderColor: "black",
  },
  selectEmpty: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
}));

export const PromotionTypeSelect = observer(() => {
  const store = useEditCouponTypeStore();
  const classes = useStyles();

  const promotionTypeOptions = promotionTypes.map(promotionType => <MenuItem key={promotionType.name} value={promotionType}>{promotionType.label}</MenuItem>);
  const currentPromotionType = promotionTypes.filter(promotionType => promotionType.name == store.promotionType);

  const handleChange = (event) => {
    store.promotionType = event.target.value.name;
  };

  return (
    <Grid className={classes.selectEmpty} item xs={9}>
      <InputLabel >
        {t("addNewCouponType.promotionType")}
      </InputLabel>
      <Select
        onChange={handleChange}
        className={classes.category}
        value={currentPromotionType[0] || ""}
        renderValue={selected=>selected.label}
      >
        {promotionTypeOptions}
      </Select>
    </Grid>
  );
});
