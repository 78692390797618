import React from "react";
import { observer } from "mobx-react";
import { useEditAuctionStore } from "screens/controlPanel/editAuction/store";
import { t } from "common/localization/translate";
import Select from "react-select";
import { Grid, makeStyles} from "@material-ui/core";
import { customStyles } from "../../../techOpsReportes/store/components/form/components/selectStyles";

const useStyles = makeStyles(() => ({
    input: {
      marginBottom: 10,
    },
  }));

export const AuctionSelectEdit = observer(() => {
  const store = useEditAuctionStore();
  const classes = useStyles();

  const handleChange = (event) => {
    if(!event.name || !event.auction_id){
      store.auctionIsEmptyError = t("editAuction.editAuctionAuctionSelect");
    }
    store.auctionIsEmptyError = null;
    store.auctionId = Number(event.auction_id);
    store.getAuctionNameById(store.auctionId);
    store.getAuctionDataById();
  };

  return (
    <Grid className={classes.input}>
      <Select
        options={store.auctionList}
        getOptionLabel={(auction) => `${auction.name} - ${auction.auction_id}`}
        onChange={handleChange}
        styles={customStyles}
        placeholder={t("editAuction.editAuctionAuctionSelect")}
        />
    </Grid>
  );
});