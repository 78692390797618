import React from 'react';
import { stableSort } from './stableSort';
import { getSorting } from './getSorting';
import { TableRowComponent } from '../components/tableRowComponent';

export function getTableRow(items, order, orderBy, rowsPerPage, page, itemKey, renderRow) {

    return stableSort(items, getSorting(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => <TableRowComponent item={item} key={index} renderRow={renderRow} />)
}