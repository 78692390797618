import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    textAlign: "center",
    flex: "auto",
    marginTop: 30,
    marginBottom: 10,
    marginLeft: 90,
    fontWeight: "normal",
    color: theme.palette.primary.main,
  }
}));

export const GeneralInfoSubTitle = observer(() => {
  const classes = useStyles();

  return (
    <Grid>
      <Typography className={classes.subtitle} component="h1" variant="h2">
        {t("addNewCouponType.AddCouponTypeExternalSubtitle")}
      </Typography>
    </Grid>
  );
});
