import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { t } from "common/localization/translate";
import Select from '@material-ui/core/Select';
import { useScreenValidationCodeStore } from "../store";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold'
  }
}));

export const ValidationsList = observer(() => {
  const store = useScreenValidationCodeStore();
  const classes = useStyles();

  const handleChange = (event, item) => {
    store.chosenValidation = event.target.value;
    store.chosenListCodesToPerformValidation = item.props.name;
  };

  if (!store.validationsList) {
    return null;
  }
  
  const menuItems = store.validationsList.map(item => <MenuItem value={item.methodName} name={item.listCodesToPerformValidation}>{item.explanationText}</MenuItem>);
  return (
    <Grid item xs={12} spacing={3}>
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.label} shrink>
          {t("suppliers.projectManager.validationsList")}
        </InputLabel>
        <Select value={store.chosenValidation} onChange={handleChange} displayEmpty className={classes.selectEmpty} >
          {menuItems}
        </Select>
      </FormControl>
    </Grid>
  );
})