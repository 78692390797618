import React from "react";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { Grid } from "@material-ui/core";
import { StepsAmountInput } from "./stepsAmountInput";
import { DaysRangeInput } from "./daysRangeInput";
import { DaysSuccessionInput } from "./daysSuccessionInput";
import { StartDateChallenge } from "./startDateChallenge";
import { challengeTypes } from "../../../../../../../../addCouponType/store/components/form/GeneralInfo/components/challengeCoupon/components/logic/challengeTypes";

export const ChallengeCouponGenerationInputs = observer(() => {
  const store = useEditCouponTypeStore();
  const goalBasedChallengeTypes = challengeTypes.filter((challengeType) => challengeType.isGoalBased).map((challengeType) => challengeType.challengeTypeId);
  const isGoalBasedChallenge = goalBasedChallengeTypes.includes(store.challengeTypeSelected);
  
  if (!store.challengeTypeSelected || !store.isChallenge) {
    return null;
  }
    return (
      <Grid>
        <StepsAmountInput isGoalBasedChallenge={isGoalBasedChallenge} />
        <DaysRangeInput />
        <DaysSuccessionInput />
        <StartDateChallenge />
      </Grid>
    );
  });