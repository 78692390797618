import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

export const IsActiveCheckbox = observer(() => {
  const store = useEditBusinessStore();
  const { isActive } = store;

  const handleChange = (event) => {
    store.isActive = event.target.checked;
  };

  return (
    <Grid>
      <FormControlLabel
        control={
          <Checkbox checked={isActive ? true : false} onChange={handleChange} />
        }
        label={t("screenAddNewBusiness.isActive")}
      />
    </Grid>
  );
});