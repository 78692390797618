import React from "react";
import {
  InputLabel,
  TextField,
  makeStyles,
  createStyles
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      marginBottom:10
    },
    label: {
      marginBottom: 5
  },
  })
);

export const SubCategorySelect = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();
  const { categoriesList } = store;

  const handleChange = (event, values) => {
    store.subCategoriesToAdd = values.map(value => value.category_id);
  };

  return (
    <div className={classes.root}>
      <InputLabel className={classes.label}>{t("editBusiness.addSubCategories")}</InputLabel>
      <Autocomplete
        multiple
        options={categoriesList}
        getOptionLabel={option => option.name}
        filterSelectedOptions
        onChange={handleChange}
        className={classes.input}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    </div>
  );
});