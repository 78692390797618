import React from 'react';
import { formatNumber } from "common/formatNumber";
import { t } from "common/localization/translate";

export function clalitTableData() {
    const columns = [
        {
          name: "day",
          label: t("screenDailyStepsAndGoals.table.col.day"),
        },
        {
          name: "dailySteps",
          label: t("screenDailyStepsAndGoals.table.col.dailySteps"),
          options: {
            customBodyRender: (value) => {
              return <p>{formatNumber(value)}</p>;
            },
          },
        },
        {
          name: "goal",
          label: t("screenDailyStepsAndGoals.table.col.goal"),
          options: {
            customBodyRender: (value) => {
              return <p>{formatNumber(value)}</p>;
            },
          },
        },
        {
          name: "goalMet",
          label: t("screenDailyStepsAndGoals.table.col.goalMet"),
          options: {
            customBodyRender: (isGoalMet) => {
              const styles = { green: { color: "green" }, red: { color: "red" } };
              const text = isGoalMet ? t("screenDailyStepsAndGoals.table.userDidMetGoal") : t("screenDailyStepsAndGoals.table.userDidNotMetGoal");
              const style = isGoalMet ? styles.green : styles.red;
              return <p style={style}>{text}</p>;
          },
         },
        },
        {
          name: "WeeklySteps",
          label: t("screenDailyStepsAndGoals.table.col.weeklySteps"),
          options: {
            customBodyRender: (value) => {
              return <p>{formatNumber(value)}</p>;
            },
          },
        },
        {
          name: "weeklyGoal",
          label: t("screenDailyStepsAndGoals.table.col.weeklyGoal"),
          options: {
            customBodyRender: (value) => {
              return <p>{formatNumber(value)}</p>;
            },
          },
        },
        {
          name: "goalMet",
          label: t("screenDailyStepsAndGoals.table.col.goalMet"),
          options: {
            customBodyRender: (isweeklyGoalMet) => {
              const styles = { green: { color: "green" }, red: { color: "red" } };
              const text = isweeklyGoalMet ? t("screenDailyStepsAndGoals.table.userDidMetGoal") : t("screenDailyStepsAndGoals.table.userDidNotMetGoal");
              const style = isweeklyGoalMet ? styles.green : styles.red;
              return <p style={style}>{text}</p>;
          },
         },
        }
    ];
    return columns;
}