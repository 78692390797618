import { COLORS } from 'common/ui/colors';

export const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: COLORS.BACKGROUND_COLOR,
      borderColor: COLORS.OUTLINE_GRAY,
      boxShadow: COLORS.OUTLINE_GRAY,
      "&:hover": {
        borderColor: 'black'
      },
      height: 50,
    }),
    placeholder: () => ({
      color: 'black',
      }),
      menu: base => ({
        ...base,
        zIndex: 100
      }),
      option: provided => ({
        ...provided,
        backgroundColor: COLORS.BACKGROUND_COLOR,
        color: 'black',
        "&:hover": {
          backgroundColor: COLORS.OUTLINE_GRAY
        },
      })
  };
