import React from "react";
import { decorate, action, observable, computed } from "mobx";
import { getScreenData as getScreenDataServer } from "./server/getScreenData";
import { getMultiSegmentUsersTableData as getMultiSegmentUsersTableDataLogic } from "./logic/getMultiSegmentUsersTableData";

const ScreenMultiSegmentUsersStoreContext = React.createContext({});
export const ScreenMultiSegmentUserssStoreProvider =
  ScreenMultiSegmentUsersStoreContext.Provider;
export const useScreenMultiSegmentUsersStore = () =>
  React.useContext(ScreenMultiSegmentUsersStoreContext);

class ScreenMultiSegmentUsersStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }

  initProperties() {
    this.isFetching = true;
  }

  getScreenData = async () => {
    this.setIsFetching(true);
    const segmentId = this.rootStore.userInfoStore.currentSegment
      ? this.rootStore.userInfoStore.currentSegment.segmentId
      : null;
    const multiSegmentUsersList = await getScreenDataServer(segmentId);
    this.setMultiSegmentUsersList(multiSegmentUsersList);
    this.setIsFetching(false);
  };

  setMultiSegmentUsersList(multiSegmentUsersList) {
    this.multiSegmentUsersList = multiSegmentUsersList;
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }

  get multiSegmentUsersTableData() {
    return getMultiSegmentUsersTableDataLogic(this);
  }
}

decorate(ScreenMultiSegmentUsersStore, {
  allowToAccessToMultiSegmentUsers:observable,
  isFetching: observable,
  multiSegmentUsersList: observable,
  multiSegmentUsersTableData: computed,
  initProperties: action.bound,
  getScreenData: action.bound,
  setMultiSegmentUsersList: action.bound,
  setIsFetching: action.bound,
});

export function createScreenMultiSegmentUsersStore(rootStore) {
  const store = new ScreenMultiSegmentUsersStore(rootStore);
  return store;
}
