import React from "react";
import {InputLabel, Select, makeStyles, MenuItem, Grid} from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  category: {
    margin: theme.spacing(1),
    minWidth: 300,
    border: 1,
    borderColor: "black",
  },
  selectEmpty: {
    marginBottom: 10,
    marginTop: 10
  }
}));

export const PatchSelect = observer(() => {
  const store = useAddNewCouponTypeStore();
  const classes = useStyles();

  const patchOptions = store.patches.map((patch) => {return <MenuItem key={patch.patch_id} value={patch}>{patch.title}</MenuItem>});

  const handleChange = (event) => {
    store.selectedPatch = event.target.value;
  };

  return (
    <Grid className={classes.selectEmpty} item xs={9}>
      <InputLabel>{t("addNewCouponType.choosePatch")}</InputLabel>
      <Select
        onChange={handleChange}
        className={classes.category}
        value={store.selectedPatch || ""}
      >
        {patchOptions}
      </Select>
    </Grid>
  );
});
