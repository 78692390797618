import React from "react";
import {
  InputLabel,
  Select,
  makeStyles,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../../index";
import { limitationTypes } from "../../../../../../../../addNewBuisness/store/components/form/components/AccountInformation/components/limitationSelect/limitationTypes";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  category: {
    margin: theme.spacing(1),
    minWidth: 300,
    border: 1,
    borderColor: "black",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const LimitationTypeSelect = observer(() => {
  const store = useEditBusinessStore();
  const classes = useStyles();

  let selectedLimitationType = limitationTypes.filter(limitationType => limitationType.type === store.limitationType);
  const limitationTypeOptions = limitationTypes.map((limitationType) => {return (<MenuItem key={limitationType.type} value={limitationType}>{limitationType.name}</MenuItem>);})

  const handleChange = (event) => {
    store.limitationType = event.target.value.type;
    selectedLimitationType = limitationTypes.filter(limitationType => limitationType.type === store.limitationType);
    store.limitationParams = null;
    if (event.target.value.type === limitationTypes[2].type) {
      store.limitationParams = { count: 1 };
    }
  };

  return (
    <Grid item sm={4}>
      <InputLabel>
        {t("screenAddNewBusiness.ChooseLimitationType")}
      </InputLabel>
      <Select
        onChange={handleChange}
        className={classes.category}
        value={selectedLimitationType[0] ? selectedLimitationType[0] : ""}
        renderValue={selected=>selected.name}
      >
        {limitationTypeOptions}
      </Select>
    </Grid>
  );
});
