import React from "react";
import { decorate, action, observable } from "mobx";
import { getSegments } from "../../addNewBuisness/store/server/getSegments";
import { getArticlesList } from "./server/getArticlesList";
import { getArticleData } from "./server/getArticleData";
import { getArticleQuestions } from "./server/getArticleQuestions";
import { editArticleById } from "./server/editArticleById";
import { getTranslationTextsAllLanguages, getTranslationTextsArticle } from "./logic/getTranslationTexts";
import { languages } from "./logic/languages";

const EditArticle = React.createContext({});

export const EditArticleStoreProvider = EditArticle.Provider;

export const useEditArticleStore = () => React.useContext(EditArticle);

class EditArticleStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }

  initProperties() {
    this.segmentsOptionsList = [];
    this.isAdmin = false;
    this.isTranslate = false;
    this.questionArray = [];
    this.answerArray = [];
    this.getArticlesList();
    this.getSegmentsList();
    this.selectedLanguage = languages.ARABIC.languageCode;
    this.isCorrectAnswer = false;
    this.isLead = false;
  }

  async getSegmentsList() {
    this.segmentsOptionsList = await getSegments();
  }

  async getArticlesList() {
    this.articlesList = await getArticlesList();
  }

  async getArticleData() {
    this.articleData = await getArticleData(this.selectedArticle);
    this.questionArray = await getArticleQuestions(this.selectedArticle);
    this.articleName = this.articleData.title;
    this.translatedArticleName = getTranslationTextsArticle(this.articleData, this.selectedLanguage, 'title');
    this.translatedSubTitle = getTranslationTextsArticle(this.articleData, this.selectedLanguage, 'subTitle');
    this.translatedArticleHtml = getTranslationTextsArticle(this.articleData, this.selectedLanguage, 'htmlString');
    this.existingTranslationTexts = getTranslationTextsAllLanguages(this.articleData);
    this.articleSubTitle = this.articleData.sub_title;
    this.articleHtml = this.articleData.html_string;
    this.isAdmin = this.articleData.is_admin_only;
    this.segments = this.articleData.segments;
    this.articleImageSmallUrl = this.articleData.image_small;
    this.articleImageLargeUrl = this.articleData.image_big;
    this.leadContactEmail = this.articleData.external_entity_ids.default.leadContactEmail;
  }

  async submitEditArticle() {
    this.isFetching = true;

    const res = await editArticleById({articleId: this.selectedArticle, language: this.selectedLanguage, isTranslate: this.isTranslate, translatedArticleName: this.translatedArticleName, translatedSubTitle: this.translatedSubTitle, translatedArticleHtml: this.translatedArticleHtml, articleName: this.articleName, articleSubTitle: this.articleSubTitle, segments: this.segments, articleImageSmall: this.articleImageSmall, articleImageLarge: this.articleImageLarge, articleHtml: this.articleHtml, leadContactEmail: this.leadContactEmail, isAdmin: this.isAdmin, articleImageSmallUrl: this.articleImageSmallUrl, articleImageLargeUrl: this.articleImageLargeUrl, existingTranslationTexts: this.existingTranslationTexts});

    this.errorCode = res.errorCode;
    this.editBusinessStatus = res.isSuccess;
    this.isFetching = false;
    window.location.reload();
  }
}

decorate(EditArticleStore, {
  articleName: observable,
  articleSubTitle: observable,
  segments: observable,
  segmentsOptionsList: observable,
  articleImageSmall: observable,
  articleImageLarge: observable,
  articleImageSmallUrl: observable,
  articleImageLargeUrl: observable,
  articleHtml: observable,
  leadContactEmail: observable,
  isAdmin: observable,
  articlesList: observable,
  selectedArticle: observable,
  articleData: observable,
  isTranslate: observable,
  translatedArticleName: observable,
  translatedSubTitle: observable,
  translatedArticleHtml: observable,
  selectedQuestion: observable,
  questionArray: observable,
  answerArray: observable,
  quizAction: observable,
  selectedLanguage: observable,
  questionToAdd: observable,
  answerToAdd: observable,
  selectedAnswer: observable,
  isCorrectAnswer: observable,
  correctAnswerId: observable,
  isFetching: observable,
  existingTranslationTexts: observable,
  isLead: observable,
  initProperties: action.bound,
});

export function createEditArticleStore(rootStore) {
  const store = new EditArticleStore(rootStore);
  return store;
}
