import React from "react";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/";
import { Grid } from "@material-ui/core";
import { segmentIds } from "../../../../../../../addCouponType/store/logic/segmentIds";

const useStyles = makeStyles(() => ({
  inputRoot: {
    width: 310
  },
}));
export const SupplierSelect = observer(() => {
  const store = useScreenCreateAddNewBusinessStore();
  const classes = useStyles();

  if(!store.segments.includes(segmentIds.CLALIT_SEGMENT_ID)){
    return null;
  }
  const handleChange = (event, value) => {
    if (value) {
      store.supplierId = value.supplier_id;
      return;
    }
    store.supplierId = null;
  };

  const currentSupplier = store.suppliersList ? store.suppliersList.find(supplier => supplier.supplier_id == store.supplierId) : "";
  
  return (
    <Grid>
      <Autocomplete
        options={store.suppliersList}
        getOptionLabel={supplier => `${supplier.supplier_id} - ${supplier.name}`}
        onChange={handleChange}
        value={currentSupplier}
        classes={classes}
        required
        renderInput={(params) => (
        <TextField {...params} label={t("addNewCouponType.SupplierIdSelectLabel") + ' *'} variant="outlined" />)} />
    </Grid>
  );
});
