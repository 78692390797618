import React from "react";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    "&::placeholder": {
      fontSize: 13,
    },
  },
  textbox: {
    marginBottom: 10
  }
}));

export const BusinessHours = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();

  const handleInputChange = (event) => {
    store.businessHours = event.target.value;
  };

  return (
    <TextField
      InputProps={{ classes: { input: classes.input } }}
      className={classes.textbox}
      label={t("screenAddNewBusiness.BusinessHours")}
      multiline
      rows={6}
      value={store.businessHours ? store.businessHours : ""}
      placeholder={t("screenAddNewBusiness.BusinessHoursPlaceHolder")}
      variant="outlined"
      onChange={handleInputChange}
    />
  );
});