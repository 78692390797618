import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { observer } from "mobx-react-lite";
import { useAddNewCouponTypeStore } from 'screens/controlPanel/addCouponType/store';
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.white,
      fontSize: 16,
      fontFamily: "Assistant",
      fontWeight: "bold",
    },
  },
}));

export const EmptyFieldErrorPopUp = observer(() => {
  const store = useAddNewCouponTypeStore();
  const classes = useStyles();
  const { codeGenerationStrategyEmptyError, couponTitleEmptyError, businessIdEmptyError, coinsEmptyError, activationTypeEmptyError, activeDurationMinutesEmptyError, groupTitleErrorText, groupPriceErrorText, chosenCouponTypeErrorText, supplierErrorText, couponImageEmptyError, costToSupplierEmptyError, selectedPaymentMethodEmptyError, isCreditRequired, isChallenge, challengeTypeSelectError, challengeTypeSelected, daysRangeInputEmptyError, daysSuccessionInputEmptyError, startChallengeDateEmptyError } = store;
  const requiredFields = [codeGenerationStrategyEmptyError, couponTitleEmptyError, businessIdEmptyError, coinsEmptyError, activationTypeEmptyError, activeDurationMinutesEmptyError, groupTitleErrorText, groupPriceErrorText, chosenCouponTypeErrorText, supplierErrorText, couponImageEmptyError, costToSupplierEmptyError];
  if (isCreditRequired) {
    requiredFields.push(selectedPaymentMethodEmptyError);
  }
  if(isChallenge) {
    requiredFields.push(challengeTypeSelectError);
  }
  if (challengeTypeSelected){
    requiredFields.push(daysRangeInputEmptyError, daysSuccessionInputEmptyError, startChallengeDateEmptyError);
  }
  const emptyFields = requiredFields.filter(fieldEmptyError => fieldEmptyError !== null && fieldEmptyError !== undefined );
  const emptyFieldErrorReport = emptyFields.map((field)=><li key={field}>{field}</li>);

  const handleClose = () => {
    store.isEmptyFieldErrorPopUpOpen = false;
  };

  return (
    <div>
      <Dialog open={store.isEmptyFieldErrorPopUpOpen}>
        <DialogTitle className={classes.title}>
        {t("screenAddNewBusiness.EmptyFieldError")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {emptyFieldErrorReport}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t("suppliers.supplierHome.verifyCodesOkPopUp")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});