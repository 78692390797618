import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../../store";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
errorText:{
    fontSize:10,
    color:'red'
}
}));

export const LoadingCodesInputErrors = observer(() => {
    const store = useScreenHomeStore();
    const classes = useStyles();
    
    return (
 
            <Grid item xs={12}>
                <Typography className = {classes.errorText} >{store.verifyCodesErrorText}</Typography>
            </Grid>

    )
});

