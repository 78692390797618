import { editUserInfoErrorCodes } from "./editUserInfoErrorCodes";
import { t } from "common/localization/translate";
import { useRootStore } from "commonStores/analytics/rootStoreContext";

export function getErrorTextByErrorCode(errorCode) {
  const rootStore = useRootStore();
  if (!errorCode) {
    return;
  }
  switch (errorCode) {
    case editUserInfoErrorCodes.ERROR_TYPE_USER_NOT_EXISTS:
      return rootStore.userInfoStore.isWithSearchByPhoneOption ?  t("screenEditUserInfo.errors.userNotExsist.idOrPhone") : t("screenEditUserInfo.errors.userNotExsist");
    case editUserInfoErrorCodes.ERROR_TYPE_INVALID_ID_NUM:
      return t("screenEditUserInfo.errors.invalidInput");
    case editUserInfoErrorCodes.ERROR_TYPE_EMPTY_EMAIL:
      return t("screenEditUserInfo.errors.invalidEmail");
    case editUserInfoErrorCodes.ERROR_TYPE_INVALID_EMAIL:
      return t("screenEditUserInfo.errors.invalidEmail");
    case editUserInfoErrorCodes.ERROR_TYPE_ALREADY_USED_EMAIL:
      return t("screenEditUserInfo.errors.usedEmail");
    case editUserInfoErrorCodes.ERROR_TYPE_ID_TOO_SHORT:
      return t("screenEditUserInfo.errors.idNumberTooShort");
    case editUserInfoErrorCodes.ERROR_TYPE_NOT_ONLY_NUMBERS:
      return t("screenEditUserInfo.errors.onlyNumbers");
    case editUserInfoErrorCodes.ERROR_TYPE_EMPTY_USER_NAME:
        return t("screenEditUserInfo.errors.emptyUserName");
    case editUserInfoErrorCodes.ERROR_TYPE_RATE_LIMITER_IS_EXCEEDED:
        return t("screenEditUserInfo.errors.limitReached");
    case editUserInfoErrorCodes.ERROR_TYPE_INVALID_PHONE:
        return t("screenEditUserInfo.errors.invalidPhone");
    default:
      return t("screenEditUserInfo.errors.general");
  }
}
