import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { CommonTable } from '../../../common/ui/commonTable.js';
import { DAY_TIME_FORMAT, parseISOString } from '../../../common/dateAndTime';
import { format } from 'date-fns/esm';
import { Root } from '../../../common/ui/root.js';
import { DatesPicker } from '../../../common/ui/datePicker/index.js';
import { InfoCard } from 'componentsRumble/infoCard/index.js';

class LoadingsComponent extends Component {

    componentDidMount() {
        this.props.rootStore.org.storeLoadings.getFitCreditLoadings();
    }

    render() {
        const { fitCreditLoadings, startAt, endAt, onChangeRange, countOfLoads, totalBillForOrganization } = this.props.rootStore.org.storeLoadings;
        const countOfLoadsText = `מספר ההטענות : ${countOfLoads}`;
        const totalBillText = `סה"כ לתשלום : ₪${totalBillForOrganization}`;
        const headerColumns = [
            { id: 'createdAt', label: 'תאריך ושעה' },
            { id: 'actualUserCosts', label: 'עלות לעובד' },
            { id: 'creditsDelta', label: 'סכום הטענה' },
            { id: 'creditsTotal', label: 'קרדיט לאחר הטענה' }
        ];
        const renderRow = item =>
            <TableRow hover>
                <TableCell>{format(parseISOString(item.createdAt), DAY_TIME_FORMAT)}</TableCell>
                <TableCell>{item.actualUserCosts}</TableCell>
                <TableCell>{item.creditsDelta}</TableCell>
                <TableCell>{item.creditsTotal}</TableCell>
            </TableRow>

        return <Root>
            <DatesPicker


                startAt={startAt}
                endAt={endAt}
                onChangeRange={onChangeRange} />
            <InfoCard info={countOfLoadsText} />
            <InfoCard info={totalBillText} />
            <CommonTable
                tableTitle='טעינות אחרונות'
                defaultSortByColumn='title'
                renderRow={renderRow}
                items={fitCreditLoadings}
                headerColumns={headerColumns} />
        </Root>
    }
}

export const Loadings = inject("rootStore")(observer(LoadingsComponent));