import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const CouponTitle = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();

  const handleInputChange = (event) => {
    if(!event.target.value) {
      store.couponTitleEmptyError = t("addNewCouponType.CouponTitle");
      return;
    }
    store.couponTitleEmptyError = null;
    store.couponTitle = event.target.value;
  };

  return (
    <TextField
      required
      multiline
      variant="outlined"
      fullWidth
      label={t("addNewCouponType.CouponTitle")}
      onChange={handleInputChange}
      className={classes.input}
    />
  );
});
