import React from "react";
import {
  InputLabel,
  Select,
  makeStyles,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";

export const PrimaryCategorySelect = observer(() => {
  const store = useScreenCreateAddNewBusinessStore();
  const { primaryCategory, categoriesList } = store;

  const useStyles = makeStyles((theme) => ({
    category: {
      margin: theme.spacing(1),
      minWidth: 300,
      border: 1,
      borderColor: "black",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    input: {
      marginTop: 15,
      marginBottom: 15
    }
  }));

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    if(event.target.value == null) {
      store.setPrimaryCategoryEmptyError(t("screenAddNewBusiness.PrimaryCategory"))
      return;
    }
    store.setPrimaryCategoryEmptyError(null)
    newValue = event.target.value;
    store.setPrimaryCategory(newValue);
  };

  return (
    <Grid item sm={4} className={classes.input}>
      <InputLabel id="demo-simple-select-outlined-label">
        {t("screenAddNewBusiness.PrimaryCategory") + " *"}
      </InputLabel>
      <Select
        id="demo-simple-select-outlined"
        value={primaryCategory}
        onChange={handleChange}
        className={classes.category}
      >
        {categoriesList.map(category=> {return(<MenuItem value={category.category_id} key={category.category_id}>{category.name}</MenuItem>)})}
      </Select>
    </Grid>
  );
});