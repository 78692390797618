import React from "react";
import { TextField, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@material-ui/styles";
import { useScreenCreateBusinessDashboardUserStore } from "../../..";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "200px",
    marginTop: "20px"
  },
}));

export const PhoneInfo = observer((props) => {
  const store = useScreenCreateBusinessDashboardUserStore();
  const classes = useStyles();
  const { businessPhone } = store;

  return (
    <Grid item sm={4} lg={2} spacing={2}>
      <TextField
        disabled={true}
        className={classes.input}
        fullWidth
        type="string"
        placeholder={t("screenBusinessDashboardUser.businessPhone")}
        value={businessPhone}
      />
    </Grid>
  );
});
