import React from "react";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import { createEncodedLogo } from "../../../../../../../addNewBuisness/store/components/form/components/GeneralInfo/createEncodedLogo";
import { segmentIds } from "screens/controlPanel/addCouponType/store/logic/segmentIds";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
  },
  grid: {
    marginBottom: 20,
  },
}));

export const ClalitSmallImage = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();

  const handleInputChange = async (event) => {
    store.clalitSmallImage = await createEncodedLogo(event.target.files[0]);
  };
  
  if(!store.segments.includes(segmentIds.CLALIT_SEGMENT_ID) && !store.segments.includes(segmentIds.TEST_SEGMENT_ID)) {
    return null;
  }

  return (
    <Grid item xs={12} className={classes.grid}>
      <label className={classes.label}>{t("addNewCouponType.clalitSmallImage")}</label>
      <br />
      <input
        className={classes.input}
        type="file"
        onChange={handleInputChange}
      />
    </Grid>
  );
});
