import {t} from "common/localization/translate";

export const emptyFieldsError = {
 ADD_AUCTION_EMPTY_FIELD_ERROR_BUSINESS: t("addNewCouponType.BusinessIdSelect"),
 ADD_AUCTION_EMPTY_FIELD_ERROR_TITLE: t("addNewAuction.addNewAuctionTitle"),
 ADD_AUCTION_EMPTY_FIELD_ERROR_START_DATE: t("addNewAuction.addNewAuctionStartDate"),
 ADD_AUCTION_EMPTY_FIELD_ERROR_END_DATE: t("addNewAuction.addNewAuctionEndDate"),
 ADD_AUCTION_EMPTY_FIELD_ERROR_COST_TO_BUSINESS: t("addNewAuction.addNewAuctionCostToBusiness"),
 ADD_AUCTION_EMPTY_FIELD_ERROR_DESCRIPTION: t("addNewAuction.addNewAuctionDescription"),
 ADD_AUCTION_EMPTY_FIELD_ERROR_START_SPRINT_DATE: t("addNewAuction.addNewAuctionStartSprintDate"),
 ADD_AUCTION_EMPTY_FIELD_ERROR_SEGMENT_CHOICE: t("screenAddNewBusiness.SegmentChoice"),
 ADD_AUCTION_EMPTY_FIELD_ERROR_IMAGE: t("AddNewAuction.AddNewAuctionImage"),
}