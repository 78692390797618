import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { CommonButton } from "common/ui/commonButton";
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from "screens/organization/healthAndFitnessData/store/storeHealthAndFitnessDataStoreContext";
import { ErrorMessage } from "./errorMessage";

const useStyles = makeStyles(() => ({
    text: {
        color: "#fff",
        marginRight: 5,
    },
    root: {
        marginBottom: 10,
        alignItems: 'center'
    },
    flexGrow: {
        flexGrow: 1
    },
}));

export const SubmitButtonAndErrorText = observer((props) => {
    const classes = useStyles();
    const store = useHealthAndFitnessDataStore();
    const { isSavingData } = store;

    if (!props.isVisible) {
        return null;
    }

    return (
        <Grid container xs={12} sm={8} spacing={5} className={classes.root}>
            <Grid className={classes.flexGrow} />
            <ErrorMessage text={props.errorText} isError={props.isError} />
            <CommonButton disabled={isSavingData}>
                <Typography className={classes.text}>{t("screenHealthAndFitnessData.submit")} {props.text}</Typography>
            </CommonButton>
        </Grid>
    );
});