import amplitude from "amplitude-js";

export const AMPLITUDE_KEY = process.env.AMPLITUDE_KEY;
const amplitudeInstance = amplitude.getInstance();
amplitudeInstance.init(AMPLITUDE_KEY);

export function sendAnalyticsEvent(eventName, parameters) {
    amplitudeInstance.logEvent(eventName, parameters);
}

export function setUserId(userId) {
    amplitudeInstance.setUserId(userId);
}