import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Container } from "@material-ui/core";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(6),
    width: "100%",
    height: "100%",
    textAlign: "center",
  },
  title: {
    flex: "auto",
    fontSize: 42,
  },
}));
export const Home = observer(() => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md" className={classes.container}>
      <Typography className={classes.title} component="h1" variant="h1">
        ברוך הבא!
      </Typography>
    </Container>
  );
});
