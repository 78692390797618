import React from "react";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "screens/controlPanel/editBusiness/store";
import { t } from "common/localization/translate";
import Select from "react-select";
import { COLORS } from 'common/ui/colors';

export const BusinessSelect = observer(() => {
  const store = useEditBusinessStore();
  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: COLORS.BACKGROUND_COLOR,
      borderColor: COLORS.OUTLINE_GRAY,
      boxShadow: COLORS.OUTLINE_GRAY,
      "&:hover": {
        borderColor: 'black'
      },
      height: 50,
    }),
    placeholder: () => ({
      color: 'black',
      }),
      menu: base => ({
        ...base,
        zIndex: 100
      }),
      option: provided => ({
        ...provided,
        backgroundColor: "white",
        color: 'black',
        "&:hover": {
          backgroundColor: COLORS.OUTLINE_GRAY
        },
      })
  };

  const handleChange = (event) => {
    if (!event.business_id) {
      store.businessIdEmptyError = t("addNewCouponType.BusinessIdSelect");
      return;
    }
    store.businessIdEmptyError = null;
    store.businessId = Number(event.business_id);
    store.getBusinessById(store.businessId);
  };

  return (
    <div>
      <Select
        options={store.businessIdsList}
        getOptionLabel={(business) => `${business.name} - ${business.business_id}`}
        onChange={handleChange}
        styles={customStyles}
        placeholder={t("addNewCouponType.BusinessIdSelect")}
        />
    </div>
  );
});
