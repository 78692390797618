import React from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import SimpleCube from "common/ui/simpleCube";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../../store";
import { formatNumber } from "common/formatNumber";
import PhoneIcon from '@material-ui/icons/Phone';


const useStyles = makeStyles(() => ({
    row: {
        width: "100%"
    }
}));

export const PhoneCube = observer(() => {
    const { isFetching, clicksOnPhoneBusiness } = useScreenHomeStore();


    const clicksOnPhoneBusinessFormatted = formatNumber(clicksOnPhoneBusiness);

    const classes = useStyles();

    return (
        <Grid item sm={12} lg={3} className={classes.row}>
            <div>
                <SimpleCube
                    isWithoutMargin={true}
                    isFetching={isFetching}
                    label={t("business.home.cubes.phone")}
                    isWithConstatHeight={true}
                    icon={<PhoneIcon />}
                    value={clicksOnPhoneBusinessFormatted}
                    withoutMargin={true}
                />
            </div>
        </Grid>

    );
});
