import React from "react";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const BusinessDescription = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();

  const handleInputChange = (event) => {
    if(!event.target.value) {
      store.businessDescriptionEmptyError = t("screenAddNewBusiness.BusinessDescription");
      store.businessDescription = event.target.value;
      return;
    }
    store.businessDescriptionEmptyError = null;
    store.businessDescription = event.target.value;
  };

  return (
      <TextField
        fullWidth
        required
        variant="outlined"
        multiline
        rows={7}
        rowsMax={100}
        value={store.businessDescription ? store.businessDescription : ""}
        onChange={handleInputChange}
        label={t("screenAddNewBusiness.BusinessDescription")}
        className={classes.input}
      />
  );
});