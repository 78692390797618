import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import { getDashboardMode } from 'common/getDashboardMode';
import { dashBoardModes } from 'common/dashDashBoardModes';
import { styles } from './style';
import { RumbleLogoWhite, GymPassLogo, CustomLogo } from 'common/ui/logos';
import { isShowGymPassLogo } from './isShowGymPassLogo';
import { LogoContainer } from './components/LogoContainer';

class RumbleAppBarComponent extends Component {
    render() {
        const { classes, rootStore, location } = this.props;
        const { userInfoStore } = rootStore;
        const { pathname } = location;
        const { isLoggedIn, businessImage, segmentLogo, isSupportFitCredit } = userInfoStore;
        const shouldShowBar = isLoggedIn && !pathname.includes("login")
        const showGymPassLogo = isShowGymPassLogo(isSupportFitCredit);
        const isInBizMode = getDashboardMode(location.pathname) === dashBoardModes.MODE_BIZ;
        const isInOrgMode = getDashboardMode(location.pathname) === dashBoardModes.MODE_ORG;
        return shouldShowBar ? <AppBar position="fixed" color="primary" className={classes.appBar} >
            <Toolbar className={classes.container}>
                <RumbleLogoWhite />
                {isInBizMode ? <LogoContainer> <CustomLogo src={businessImage} /> </LogoContainer> : null}
                {isInOrgMode ? <LogoContainer> <CustomLogo src={segmentLogo} /> </LogoContainer> : null}
                {isInBizMode ? showGymPassLogo ? <GymPassLogo /> : null : <GymPassLogo />}
            </Toolbar>
        </AppBar > : null;
    }
}
export const RumbleAppBar = withRouter(withStyles(styles)(inject("rootStore")(observer(RumbleAppBarComponent))));
