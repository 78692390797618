import React from "react";
import { Grid } from "@material-ui/core";
import { CouponTypeCreationSuccessPopUp } from "./components/CouponTypeCreationSuccessPopUp";
import { CouponTypeCreationFailedPopUp } from "./components/CouponTypeCreationFailedPopUp";
import { EmptyFieldErrorPopUp } from "./components/EmptyFieldErrorPopUp";
import { NegNumberErrorPopUp } from "./components/NegNumberErrorPopUp";

export const PopUps = () => {
  return (
    <div>
      <Grid item xs={12}>
        <CouponTypeCreationSuccessPopUp />
      </Grid>
      <Grid item xs={12}>
        <CouponTypeCreationFailedPopUp />
      </Grid>
      <Grid item xs={12}>
        <EmptyFieldErrorPopUp />
      </Grid>
      <Grid item xs={12}>
        <NegNumberErrorPopUp />
      </Grid>
    </div>
  );
};
