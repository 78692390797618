import { createMuiTheme } from "@material-ui/core";

import { getPaletteForTheme } from "./palette";
import typography from "./typography";
import overrides from "./overrides";

const theme = mainColor => {
  return createMuiTheme({
    palette: getPaletteForTheme(mainColor),
    typography,
    overrides,
    direction: "rtl"
  });
};

export default theme;
