import React from "react";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";

export const InsertCodeInput = observer(() => {
  const store = useAddNewCouponTypeStore();

  const handleChange = (event) => {
  if(!event.target.value){
    store.codeGenerationStrategyEmptyError = t("addNewCouponType.CodeGenerationStrategyLabel");
    return;
  }
  store.codeGenerationStrategyEmptyError = null;
  store.codeGenerationParameters = {[event.target.name]: event.target.value};
  };

  return (
      <TextField
        fullWidth
        variant="outlined"
        name="CouponCodesFromInput"
        multiline
        rows={3}
        onChange={handleChange}
        label={t("addNewCouponType.ExternalCouponInputLabel")}
        placeholder={t("addNewCouponType.ExternalCouponCodeInputPlaceholder")}
      />
  );
});
