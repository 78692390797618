import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { observer } from 'mobx-react-lite';
import { useScreenHideCouponTypesStore } from "../store";
import { makeStyles } from "@material-ui/styles";
import { t } from 'common/localization/translate';

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 16,
            fontFamily: 'Assistant',
            fontWeight: 'bold'
        }
    },
    buttons: {
        fontSize: 14,
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          color: theme.palette.white,
          backgroundColor: theme.palette.primary.secondGraphColor
        }
      },
}));

export const SuccessHideCouponTypePopUp = observer(() => {
    const store = useScreenHideCouponTypesStore();
    const classes = useStyles();
    const handleClose = () => {
        store.isSuccessHideCouponType = false;
    };

    return (
        <div>
            <Dialog open={store.isSuccessHideCouponType} >
                <DialogTitle className={classes.title} >{t("organizationDashboard.hideCouponType.AreYouSurePopUpTitle")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("organizationDashboard.hideCouponType.successHideCoupn", { couponTypeTitle: store.couponTypeTitle })}
                    <br></br>
                    {t("organizationDashboard.hideCouponType.successHideCouponNote")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className={classes.buttons} autoFocus >{t("general.great")}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
})