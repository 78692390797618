import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useScreenUserBalanceStore } from "../store/index";
import MUIDataTable from "mui-datatables";
import { formatNumber } from "common/formatNumber";

export const UserBalanceTable = observer(() => {
  const store = useScreenUserBalanceStore();
  const { userBalanceTableData, isFetching, errorCode, userName } = store;
  const { options, columns } = getTableData(isFetching);

  if (errorCode || (userBalanceTableData.length == 0 && !userName)) {
    return null;
  }

  return (
    <MUIDataTable
      title={t("screenUserBalance.table.title", { userName })}
      data={userBalanceTableData}
      columns={columns}
      options={options}
    />
  );
});

const getTableData = () => {
  const columns = [
    {
      name: "date",
      label: t("screenUserBalance.table.col.date"),
    },
    {
      name: "action",
      label: t("screenUserBalance.table.col.business"),
    },
    {
      name: "cost",
      label: t("screenUserBalance.table.col.cost"),
      options: {
        customBodyRender: (value) => {
          const styles = { green: { color: "green" }, red: { color: "red" } };
          const text = value >= 0 ? formatNumber(value) : formatNumber(Math.abs(Number(value))) + " -";
          const style = value > 0 ? styles.green : styles.red;
          return <p style={style}>{text}</p>;
        },
      },
    },
    {
      name: "total",
      label: t("screenUserBalance.table.col.total"),
      options: {
        customBodyRender: (value) => {
          const text = value >= 0 ? formatNumber(value) : formatNumber(Math.abs(Number(value))) + " -";
          return <p>{text}</p>;
        },
      },
    },
  ];

  const options = {
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    responsive: "scrollMaxHeight",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: t("screenUserBalanceUser.table.col.noMatch"),
      },
    },
  };
  return { options, columns };
};
