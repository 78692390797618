import React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { Typography } from "@material-ui/core";
import { useScreenValidationCodeStore } from "../store";
import { t } from "common/localization/translate";


export const CurrentValidation = observer(() => {
    const store = useScreenValidationCodeStore();
    if (!store.supplierValidation) {
        return null;
    }

    return (

        <Grid item xs={12} spacing={3}>
            <Typography variant="h4" component="h2"> {t("suppliers.validation.currentValidation")}  {store.supplierValidation}  </Typography>
        </Grid>

    )
}
);

