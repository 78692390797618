import React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../store";
import { CouponTypeDropDown } from './couponTypeDropDown';
import { SelectDate } from './selectDate';
import { OtpPolicyPopUp } from './otpScreen/otpPolicyPopUp';
import { RefundSupplier } from './refundSupplier';
import { LoadingCodesButton } from '../supplier/loadingCodesSupplier/loadingCodesButtons';
import { LoadingCodesInput } from "./loadingCodesSupplier/loadingCodesInput";



export const EnterCodesPreviousMonth = observer(() => {
    const store = useScreenHomeStore();

    if (!store.changeMonthDecision) {
        return null;
    }
    if(!store.isValidOtpCode){
        return null;
    }


    return (
        <Grid container spacing={3} >
            <OtpPolicyPopUp /> 
            <SelectDate store={store}/>
            <CouponTypeDropDown store={store}/>
            <LoadingCodesInput store={store}/>
            <RefundSupplier />
            <LoadingCodesButton store={store}/>
        </Grid>
    )

});

