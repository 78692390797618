import { decorate, action, observable, computed } from "mobx";
import { setHttpHeaders } from "common/setHttpHeaders";
import { rumbleColors } from "theme/palette";
import { getStorageKeyByDashobardType } from "../localStorage/getStorageKeyByDashobardType";
import { getDashboardByPathName } from "screens/loginV2/store/logic/getDashboardByPathName";
import {
  getLocalData,
  setLocalData,
  createLocalDataObject,
  clearLocalData,
} from "../localStorage/localPersistence";
import { dashboardTypes } from "screens/loginV2/store/logic/dashboardTypes";
import { updateStoreByTypeOrganization } from "./logic/updateStoreByTypeOrganization";
import { getUserInfo } from "./server/getUserInfo";
import { updateStoreByTypeBusiness } from "./logic/updateStoreByTypeBusiness";
import { updateStoreByTypeSupport } from "./logic/updateStoreByTypeSupport";
import { updateStoreByTypeSuppliers } from "./logic/updateStoreByTypeSuppliers";

export class UserInfoStore {
  constructor(rootStore, path) {
    const dashboardType = getDashboardByPathName(path);
    const key = getStorageKeyByDashobardType(dashboardType);
    this.rootStore = rootStore;
    const localInfoObject = getLocalData(key);
    this.initProperties({ ...localInfoObject, dashboardType });
    const { token } = localInfoObject;
    if (token) {
      setHttpHeaders(token);
    }
  }

  initProperties({ token, segmentId, businessId, isSupplier, isProjectManager, dashboardType, isAllowedHideCouponTypes, isAllowedToSendNotification, isShowSwitchBusinessScreen }) {
    this.token = token;
    this.dashboardType = dashboardType;
    this.currentSegmentId = segmentId;
    this.currentBusinessId = businessId;
    this.currentSupplierId = isSupplier;
    this.isProjectManager = isProjectManager;
    this.isAllowedHideCouponTypes = isAllowedHideCouponTypes;
    this.isShowSwitchBusinessScreen = isShowSwitchBusinessScreen;
    this.isAllowedToSendNotification = isAllowedToSendNotification;
  }

  setToken(token) {
    this.token = token;
    setLocalData(this.keyForStorage, this.getLocalDataToSave());
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }

  setCurrentSegment(segment) {
    this.currentSegment = segment;
    this.currentSegmentId = segment.segmentId;
    setLocalData(this.keyForStorage, this.getLocalDataToSave());
  }

  setCurrentBusiness(business) {
    this.currentBusiness = business;
  }

  setCurrentSupplier(supplier) {
    this.currentSupplierId = supplier;
  }

  setCurrentSegmentId(segment) {
    this.currentSegmentId = segment.segmentId;
  }

  setAllowedSegments(allowedSegments) {
    this.allowedSegments = allowedSegments;
  }

  setUserName(name) {
    this.userName = name;
  }

  setDashboardFeatures(dashboardFeatures) {
    this.dashboardFeatures = dashboardFeatures;
  }

  setIsShowSwitchBusinessScreen(isShowSwitchBusinessScreen) {
    this.isShowSwitchBusinessScreen = isShowSwitchBusinessScreen;
  }

  getLocalDataToSave() {
    return createLocalDataObject(
      this.token,
      this.currentSegmentId,
      this.currentBusinessId,
      this.currentSupplierId,
      this.isProjectManager,
      this.isAllowedHideCouponTypes,
      this.isShowSwitchBusinessScreen,
      this.isAllowedToSendNotification
    );
  }

  get keyForStorage() {
    return getStorageKeyByDashobardType(this.dashboardType);
  }

  get isLoggedIn() {
    return this.token ? true : false;
  }

  get isShowTopBarDropDown() {
    return this.dashboardType === dashboardTypes.ORGANIZATION;
  }

  get mainColor() {
    return this.currentSegment && this.currentSegment.theme
      ? this.currentSegment.theme.mainColor
      : rumbleColors.mainColor;
  }

  get segmentThemeColor() {
    const { mainColor, lightColor, darkColor, secondGraphColor } =
      this.currentSegment &&
        this.currentSegment.theme &&
        this.currentSegment.theme.dashboardTheme
        ? this.currentSegment.theme.dashboardTheme
        : rumbleColors;
    return { mainColor, lightColor, darkColor, secondGraphColor };
  }
  get navBarLogo() {
    if (this.currentSegment && this.currentSegment.theme) {
      return this.currentSegment.theme.circleGroupsSegmentSelected;
    }
    if (this.currentBusiness) {
      return this.currentBusiness.businessImage;
    }
    return null;
  }

  get customTopBarLogo() {
    if (this.currentSegment && this.currentSegment.theme) {
      return this.currentSegment.theme.dashboardCustomTopBarLogo;
    }
    return null;
  }

  get segmentCoinImage() {
    const { homeScreenCoinImage } =
      this.currentSegment &&
        this.currentSegment.theme &&
        this.currentSegment.theme.dashboardTheme
        ? this.currentSegment.theme.dashboardTheme
        : {};
    return homeScreenCoinImage;
  }
  get isShowSubSegmentUsersScreen() {
    return this.dashboardFeatures && this.dashboardFeatures.isShowSubSegmentUsersScreen;
  }
  get isShowAppOpenByHours() {
    return this.dashboardFeatures && this.dashboardFeatures.isShowAppOpenByHours;
  }
  get isShowHealthAndFitnessData() {
    return this.dashboardFeatures && this.dashboardFeatures.isShowHealthAndFitnessData;
  }
  get isHideCoachesInfoScreen() {
    return this.dashboardFeatures && this.dashboardFeatures.isHideCoachesInfoScreen;
  }
  get isShowMultiSegmentUsersScreen() {
    return this.dashboardFeatures && this.dashboardFeatures.isShowMultiSegmentUsersScreen;
  }

  get isShowTrainesInfoScreen() {
    return this.dashboardFeatures && this.dashboardFeatures.isShowTrainesInfoScreen;
  }
  get isShowOrderDetailsScreen() {
    return this.dashboardFeatures && this.dashboardFeatures.isShowOrderDetailsScreen;
  }
  get isShowStepsAndGoalsScreen() {
    return this.dashboardFeatures && this.dashboardFeatures.isShowStepsAndGoalsScreen;
  }
  get isWithSearchByPhoneOption() {
    return this.dashboardFeatures && this.dashboardFeatures.isWithSearchByPhoneOption;
  }
  get isWithValidationScreen() {
    return this.dashboardFeatures && this.dashboardFeatures.isWithValidationScreen;
  }
  get isShowHideCouponTypesScreen() {
    return this.dashboardFeatures && this.dashboardFeatures.isShowHideCouponTypesScreen;
  }

  get isShowSendNotificationScreen() {
    return this.dashboardFeatures && this.dashboardFeatures.isShowSendNotificationScreen;
  }

  get isShowCustomTopBarLogo() {
    return this.dashboardFeatures && this.dashboardFeatures.isShowCustomTopBarLogo;
  }
  
  get isShowCenterUvtalLogo() {
    return this.dashboardFeatures && this.dashboardFeatures.isShowCenterUvtalLogo;
  }

  getData = async (pathname, history) => {
    this.setIsFetching(true);
    const userInfoData = await getUserInfo(this.dashboardType);
    this.userName = userInfoData.userName;
    this.isAdmin = userInfoData.isAdmin;
    switch (this.dashboardType) {
      case dashboardTypes.SUPPLIERS:
        await updateStoreByTypeSuppliers(this, userInfoData);
        break;
      case dashboardTypes.BUSINESS:
        await updateStoreByTypeBusiness(this, userInfoData);
        break;
      case dashboardTypes.ORGANIZATION:
        await updateStoreByTypeOrganization(this, userInfoData, pathname, history);
        break;
      case dashboardTypes.SUPPORT:
        await updateStoreByTypeSupport(this, userInfoData);
        break;
      case dashboardTypes.CONTROL_PANEL:
        break;
      default:
        await updateStoreByTypeOrganization(this, userInfoData);
        break;
    }
    this.setIsFetching(false);
  };

  clearUserInfoStore() {
    clearLocalData(this.keyForStorage);
    this.currentSegment = null;
    this.currentSegmentId = null;
    this.currentBusinessId = null;
  }
}

decorate(UserInfoStore, {
  dashboardFeatures: observable,
  isFetching: observable,
  dashboardType: observable,
  token: observable,
  currentSegment: observable,
  currentSegmentId: observable,
  currentBusinessId: observable,
  currentBusiness: observable,
  allowedSegments: observable,
  userName: observable,
  isAdmin: observable,
  isAllowedToSendNotification: observable,
  isShowSwitchBusinessScreen: observable,
  isLoggedIn: computed,
  isShowTopBarDropDown: computed,
  mainColor: computed,
  navBarLogo: computed,
  customTopBarLogo: computed,
  segmentThemeColor: computed,
  segmentCoinImage: computed,
  keyForStorage: computed,
  isShowSubSegmentUsersScreen: computed,
  isShowMultiSegmentUsersScreen: computed,
  isShowAppOpenByHours: computed,
  isShowHealthAndFitnessData: computed,
  isShowHideCouponTypesScreen: computed,
  isShowSendNotificationScreen: computed,
  isShowTrainesInfoScreen: computed,
  isShowOrderDetailsScreen: computed,
  isShowCustomTopBarLogo: computed,
  isShowCenterUvtalLogo: computed,
  initProperties: action.bound,
  getData: action.bound,
  setToken: action.bound,
  setIsFetching: action.bound,
  setCurrentSegmentId: action.bound,
  setCurrentSegment: action.bound,
  setCurrentBusiness: action.bound,
  setCurrentSupplier: action.bound,
  setAllowedSegments: action.bound,
  setUserName: action.bound,
  clearUserInfoStore: action.bound,
  setDashboardFeatures: action.bound
});
