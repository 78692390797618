import React from "react";
import {
  InputLabel,
  Select,
  makeStyles,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { codeGenStrategies } from "../../../../../../logic/codeGenStrategies";
import { t } from "common/localization/translate";

export const CodeGenerationStrategyDropdown = observer(() => {
  const store = useAddNewCouponTypeStore();
  const { codeGenerationStrategy } = store;

  const useStyles = makeStyles((theme) => ({
    category: {
      margin: theme.spacing(1),
      minWidth: 300,
      border: 1,
      borderColor: "black",
    },
    input: {
      marginBottom: 10,
    },
  }));

  const classes = useStyles();
  const codeGenStrategyOptions = codeGenStrategies.map((strategy) => {return <MenuItem key={strategy.strategyId} value={strategy.strategyId}>{strategy.name}</MenuItem>});

  const handleChange = (event) => { 
    store.codeGenerationStrategyEmptyError = t("addNewCouponType.CodeGenerationStrategyLabel");
    store.codeGenerationStrategy = event.target.value;
    store.codeGenerationParameters = null;
  };

  return (
    <Grid className={classes.input}>
      <InputLabel>
        {t("addNewCouponType.CodeGenerationStrategyLabel") + " *"}
      </InputLabel>
      <Select
        value={codeGenerationStrategy ? codeGenerationStrategy : ""}
        onChange={handleChange}
        className={classes.category}
      >
        {codeGenStrategyOptions}
      </Select>
    </Grid>
  );
});
