import React from "react";
import { t } from "common/localization/translate";
import { Typography, Grid } from "@material-ui/core";
import { useRootStore } from "commonStores/analytics/rootStoreContext";

export const PageTitles = props => {
    const {  classes } = props;
    const rootStore = useRootStore();
    const subTitle = rootStore.userInfoStore.isWithSearchByPhoneOption ? t("support.common.withPhone.subtitle") : t("support.common.subtitle");

    return (
        <Grid className={classes.container}>
            <Typography component="h2" gutterBottom variant="overline">
                {t("screenEditUserInfo.title")}
            </Typography>
            <Typography variant="subtitle2">
                {subTitle}
            </Typography>
        </Grid>
    );
}