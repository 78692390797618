import { t } from "common/localization/translate";
import { clalitColors } from '../../../../../common/ui/colors';
import { formatNumber } from "common/formatNumber";

export const weeklyMeasurementsTypes = {
    DRINK_WATER : "drink_water",
    READ_ARTICLE : "read_article",
    HOURS_OF_SLEEP: "hours_of_sleep",
    INTENSE_ACTIVITY: "intense_activity",
    UPDATE_BMI : "bmi",
    NURITION: "satisfaction_feedback"
}


export function getMeausermentsForChart(store) {
  const { segmentUsersUsingBonusesTotals, segmentUsersUsingMeasurements } = store;
  if(segmentUsersUsingBonusesTotals){
    return;
  }
  if (!segmentUsersUsingMeasurements) {
    return [];
  }


  let arrayOfMeasurementsForChart = [];
  arrayOfMeasurementsForChart.push({
    id: weeklyMeasurementsTypes.READ_ARTICLE,
    label: t("screenHome.meausermentsTotal.articles"),
    value: segmentUsersUsingMeasurements[weeklyMeasurementsTypes.READ_ARTICLE]|| 0,
    valueTitle : formatNumber(segmentUsersUsingMeasurements[weeklyMeasurementsTypes.READ_ARTICLE]) || 0,
    color: clalitColors.CLALIT_TEXT_DARK_BLUE_3 
  });

  arrayOfMeasurementsForChart.push({
    id: weeklyMeasurementsTypes.DRINK_WATER,
    label: t("screenHome.meausermentsTotal.water"),
    value: segmentUsersUsingMeasurements[weeklyMeasurementsTypes.DRINK_WATER] || 0,
    valueTitle : formatNumber(segmentUsersUsingMeasurements[weeklyMeasurementsTypes.DRINK_WATER]) || 0,
    color:clalitColors.CLALIT_MAIN
    
  });
  
  arrayOfMeasurementsForChart.push({
    id: weeklyMeasurementsTypes.HOURS_OF_SLEEP,
    label: t("screenHome.meausermentsTotal.sleep"),
    value: segmentUsersUsingMeasurements[weeklyMeasurementsTypes.HOURS_OF_SLEEP] || 0,
    valueTitle : formatNumber(segmentUsersUsingMeasurements[weeklyMeasurementsTypes.HOURS_OF_SLEEP]) || 0,
    color:clalitColors.CLALIT_PURPLE_2 
  });

  arrayOfMeasurementsForChart.push({
    id: weeklyMeasurementsTypes.INTENSE_ACTIVITY,
    label: t("screenHome.meausermentsTotal.intenseActivity"),
    value: segmentUsersUsingMeasurements[weeklyMeasurementsTypes.INTENSE_ACTIVITY]|| 0,
    valueTitle : formatNumber(segmentUsersUsingMeasurements[weeklyMeasurementsTypes.INTENSE_ACTIVITY]) || 0,
    color:clalitColors.CLALIT_LIGHT_BLUE_8
  });

  arrayOfMeasurementsForChart.push({
    id: weeklyMeasurementsTypes.UPDATE_BMI,
    label: t("screenHome.meausermentsTotal.bmi"),
    value: segmentUsersUsingMeasurements[weeklyMeasurementsTypes.UPDATE_BMI] || 0,
    valueTitle : formatNumber(segmentUsersUsingMeasurements[weeklyMeasurementsTypes.UPDATE_BMI]) || 0,
    color:clalitColors.CLALIT_GREEN_3
  });

  arrayOfMeasurementsForChart.push({
    id: weeklyMeasurementsTypes.NURITION,
    label: t("screenHome.meausermentsTotal.nutrition"),
    value: segmentUsersUsingMeasurements[weeklyMeasurementsTypes.NURITION] || 0,
    valueTitle : formatNumber(segmentUsersUsingMeasurements[weeklyMeasurementsTypes.NURITION]) || 0,
    color:clalitColors.CLALIT_LIGHT_BLUE_8
  });
  return arrayOfMeasurementsForChart;
}
