import React from "react";
import { InputLabel, Select, makeStyles, MenuItem, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { paymentMethods } from "screens/controlPanel/addCouponType/store/components/form/GeneralInfo/components/couponCreditPrice/logic/paymentMethods";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";

const useStyles = makeStyles((theme) => ({
    category: { margin: theme.spacing(1), minWidth: 300, border: 1, borderColor: "black" },
    selectEmpty: { marginBottom: 10, marginTop: 10 }
}));

export const PaymentOptionSelect = observer(() => {
    const store = useEditCouponTypeStore();
    const classes = useStyles();
    const { selectedPaymentMethod, isCreditRequired } = store;

    const paymentOptions = Object.values(paymentMethods).map(m => <MenuItem key={m.label} value={m.value}>{m.label}</MenuItem>);

    const handleChange = ({ target }) => {
        store.selectedPaymentMethod = target.value;
    };

    if (!isCreditRequired) {
        return null;
    }

    return (
        <Grid className={classes.selectEmpty} item xs={9}>
            <InputLabel>{t("addNewCouponType.choosePaymentMethod")}</InputLabel>
            <Select onChange={handleChange} className={classes.category} value={selectedPaymentMethod || ""}>
                {paymentOptions}
            </Select>
        </Grid>
    );
});
