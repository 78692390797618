import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import WaveChart from "common/ui/waveChart";
import { useScreenHomeStore } from "../../../store";
import { LoadingSpinner } from "common/ui/loadingSpinner";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    height: 500
  },
  header: { flexDirection: "row", display: "flex" },
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    height: 375,
    minWidth: 500
  },
  chart: {
    height: "100%"
  }
}));

export const TotalClicksWaveChart = observer(() => {
  const classes = useStyles();
  const {
    amountOfClicksForBusinessGraphData,
    isFetchingClicks
  } = useScreenHomeStore();

  const counts = amountOfClicksForBusinessGraphData.map(item => item.count);
  const labels = amountOfClicksForBusinessGraphData.map(item => item.date);
  const data = { data: counts, labels };

  return (
    <PerfectScrollbar>
      <div className={classes.inner}>
        <LoadingSpinner isFetching={isFetchingClicks} className={classes.chart}>
          <WaveChart
            className={classes.chart}
            data={data.data}
            labels={data.labels}
            linesData={[{ dataArray: data.data, textLabel: t("business.home.ClicksGraph.linesData") }]}
          />
        </LoadingSpinner>
      </div>
    </PerfectScrollbar>
  );
});

