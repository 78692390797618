export function handleErrorCheck(store){
    const { codeGenerationStrategyEmptyError, couponTitleEmptyError, businessIdEmptyError,
      coinsEmptyError, activationTypeEmptyError, activeDurationMinutesEmptyError,
      coinsError, scoreError, healthScoreError, priceError, activeDurationMinutesError,
      chosenCouponTypeErrorText, groupPriceErrorText, groupTitleErrorText, supplierErrorText, couponImageEmptyError, costToSupplierEmptyError, selectedPaymentMethodEmptyError, isCreditRequired, challengeTypeSelectError, daysRangeInputEmptyError, daysSuccessionInputEmptyError, startChallengeDateEmptyError, isChallenge } = store;

    if (chosenCouponTypeErrorText || groupPriceErrorText || groupTitleErrorText || supplierErrorText) {
      store.isEmptyFieldErrorPopUpOpen = true;
      return true;
    }
    if (codeGenerationStrategyEmptyError || couponTitleEmptyError || businessIdEmptyError || coinsEmptyError || activationTypeEmptyError || activeDurationMinutesEmptyError || couponImageEmptyError || costToSupplierEmptyError || (isCreditRequired && selectedPaymentMethodEmptyError)) {
      store.isEmptyFieldErrorPopUpOpen = true;
      return true;
    }
    if (isChallenge && (challengeTypeSelectError || daysRangeInputEmptyError || daysSuccessionInputEmptyError || startChallengeDateEmptyError)) {
      store.isEmptyFieldErrorPopUpOpen = true;
      return true;
    }
    if (coinsError || scoreError || healthScoreError || priceError || activeDurationMinutesError) {
      store.isNegNumberErrorPopUpOpen = true;
      return true;
    }
    return false;
  };
