import React from "react";
import { TextField, makeStyles, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBalanceStore } from "../../../index";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 40,
    maxWidth: 450,
  },
}));

export const CoinsNumberUpdate = observer(() => {
  const classes = useStyles();
  const store = useEditBalanceStore();
  const handleInputChange = (event) => {
    if (!event.target.value) {
      store.coinsBalanceEmptyError = t("editBalance.CoinsError");
      store.amountCoinsForChange = null;
      return;
    }
    store.coinsBalanceEmptyError = null;
    store.amountCoinsForChange = event.target.value;
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <TextField
        variant="outlined"
        type="number"
        onWheel={handleBlockScroll}
        fullWidth
        value={store.amountCoinsForChange}
        label={t("editBalance.amountCoinsForChange")}
        onChange={handleInputChange}
      />
    </Grid>
  );
});
