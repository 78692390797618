import React from "react";
import { TextField, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@material-ui/styles";
import { useScreenCreateBusinessDashboardUserStore } from "../../..";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "200px",
    marginTop: "20px"
  },
}));

export const EmailInput = observer((props) => {
  const store = useScreenCreateBusinessDashboardUserStore();
  const classes = useStyles();
  const { businessEmail } = store;

  const handleInputChange = (event) => {
    store.errorCode = null;
    store.successText = null;
    store.setBusinessEmail(event.target.value);
  };
  
  return (
    <Grid item sm={4} lg={2} spacing={2}>
      <TextField
        className={classes.input}
        fullWidth
        type="string"
        placeholder={t("screenBusinessDashboardUser.inputEmail")}
        value={businessEmail}
        onChange={handleInputChange}
      />
    </Grid>
  );
});
