import React from 'react';
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { Page } from "components";

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: 20
    }
}));

export const DataPage = observer(({ children }) => {
    const classes = useStyles();

    return (
        <Page className={classes.root}>
            {children}
        </Page>
    );
});