import Excel from "exceljs";
import { setSheetProps } from "../../../../home/store/logic/createExcel/setSheetProps";
import { downloadWorksheet } from "../../../../home/store/logic/createExcel/downloadWorksheet";
import { createSheetOpenAppByHours } from "./createSheetOpenAppByHours";
import { t } from "common/localization/translate";
export async function getAppOpenByHoursExcel(storeAppOpenByHours) {
    const { appOpenByHoursCountGraphData } = storeAppOpenByHours;
    const workbook = new Excel.Workbook();
    if (appOpenByHoursCountGraphData) {
        const sheetAppOpenByHours = workbook.addWorksheet(t("screenAppOpenByHours.excelTitle"));
        setSheetProps([sheetAppOpenByHours]);
        createSheetOpenAppByHours(sheetAppOpenByHours, storeAppOpenByHours)
    }

    downloadWorksheet(workbook);
}
