import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { EditQuestionInput } from "./editQuestionInput";
import { EditQuestionButton } from "./editQuestionButton";
import { EditQuestionSelect } from "./editQuestionSelect";
import { useEditArticleStore } from "../../../../..";
import { quizActions } from "screens/controlPanel/editArticle/store/logic/quizActions";

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
  },
}));

export const EditQuestionsComponents = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();

  if (store.quizAction !== quizActions.EDIT) {
    return null;
  }
  return (
    <span className={classes.input}>
      <EditQuestionInput />
      <EditQuestionButton />
      <EditQuestionSelect />
    </span>
  );
});
