import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Score } from "./components/Score";
import { HealthScore } from "./components/HealthScore";
import { IsKosherCheckbox } from "../CompanyInfo/components/IsKosherCheckbox";
import { IsUnhealthyCheckbox } from "./components/IsUnhealthyCheckbox";
import { IsLocalCheckbox } from "../CompanyInfo/components/IsLocalCheckbox";
import { IsOnlineCheckbox } from "./components/IsOnlineCheckbox";
import { IsVegCheckbox } from "../CompanyInfo/components/IsVegCheckbox";
import { IsActiveCheckbox } from "../CompanyInfo/components/isActiveCheckbox"
import { StartWorkingWithBusinessDate } from "./components/StartWorkingWithBusinessDate";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 10,
    textAlign: "center",
    flex: "auto",
    color: theme.palette.primary.main,
  },
  input: {
    marginBottom: 50,
  }
}));

export const CompanyInfo = () => {
  const classes = useStyles();
  return (
    <div className={classes.input}>
      <Typography className={classes.title} component="h1" variant="h2">
        {t("screenAddNewBusiness.CompanyInfo")}
      </Typography>
      <Grid item xs={12}>
        <StartWorkingWithBusinessDate />
      </Grid>
      <Grid item xs={12}>
        <Score />
      </Grid>
      <Grid item xs={12}>
        <HealthScore />
      </Grid>
      <Grid item xs={12}>
        <IsKosherCheckbox />
      </Grid>
      <Grid item xs={12}>
        <IsUnhealthyCheckbox />
      </Grid>
      <Grid item xs={12}>
        <IsLocalCheckbox />
      </Grid>
      <Grid item xs={12}>
        <IsOnlineCheckbox />
      </Grid>
      <Grid item xs={12}>
        <IsVegCheckbox />
      </Grid>
      <Grid item xs={12}>
        <IsActiveCheckbox />
      </Grid>
    </div>
  );
};