import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button, ButtonGroup } from "@material-ui/core";
import {
  DatePicker as MaterialDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CalendarTodayIcon from "@material-ui/icons/CalendarTodayOutlined";
import { getMoment } from "../../../screens/organization/appOpenByHours/store/logic/getMoment"
import Tooltip from '@material-ui/core/Tooltip';
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
  root: {},
  dates: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10, 

  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  buttonGroup: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

export const SingleDatePicker = props => {
  const { className, initialDate, ...rest} = props;
  const classes = useStyles();
  const moment = getMoment('he');
  const now = moment();
  const initialDateToDisplay = initialDate || now ;
  const [startDate, setStartDate] = useState(initialDateToDisplay);
  const [selectEdge, setSelectEdge] = useState(null);
  const [calendarDate, setCalendarDate] = useState(now);
  const handleCalendarOpen = async ()=> {
    await setCalendarDate(startDate);
    setSelectEdge(true);
  };

  const handleCalendarChange = date => {
    setCalendarDate(date);
  };

  const handleCalendarClose = () => {
    const now = moment();
    setCalendarDate(now);
    setSelectEdge(null);
  };

  const handleCalendarAccept = date => {
    const { onChangeStartDate} = props;
    const now = moment();
    setCalendarDate(now);
    setStartDate(date);
    onChangeStartDate(date);
  };

  const open = Boolean(selectEdge);
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container justify="flex-end" spacing={3}>
        <Grid className={classes.dates} item lg={6} xs={12}>
          <ButtonGroup variant="contained" className={classes.buttonGroup}>
            <Tooltip title={t("singelDatePicker.buttonToolTip.fromStartDate")} arrow>
            <Button
              className={classes.button}
              onClick={() => handleCalendarOpen()}
            >
              <CalendarTodayIcon className={classes.calendarTodayIcon} />
              {startDate.format("DD/MM/YYYY")}
            </Button>
            </Tooltip>
          </ButtonGroup>
        </Grid>
      </Grid>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale="he"
      >
        <MaterialDatePicker
          maxDate={now}
          onAccept={handleCalendarAccept}
          onChange={handleCalendarChange}
          onClose={handleCalendarClose}
          disableFuture={true}
          open={open}
          style={{ display: "none" }} // Temporal fix to hide the input element
          value={calendarDate}
          variant="dialog"
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

