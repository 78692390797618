import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: 10,
  },
}));

export const CouponDescription = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();

  const handleInputChange = (event) => {
    store.couponDescription = event.target.value;
  };

  return (
      <TextField
        fullWidth
        variant="outlined"
        multiline
        rows={8}
        rowsMax={100}
        value={store.couponDescription ? store.couponDescription : ""}
        onChange={handleInputChange}
        label={t("addNewCouponType.CouponDescription")}
        className={classes.input}
      />
  );
});
