import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

export const IsEmailAtPurchaseCheckbox = observer(() => {
  const store = useEditBusinessStore();

  const handleChange = (event) => {
    store.isEmailAtPurchase = event.target.checked;
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            checked={store.isEmailAtPurchase ? true : false}
            name="isEmailAtPurchase"
          />
        }
        label={t("screenAddNewBusiness.isEmailAtPurchase")}
      />
    </div>
  );
});