import React from "react";
import { TextField, makeStyles, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { userUpdateSegmentStore } from "../../../index";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 40,
    maxWidth: 450,
  },
}));

export const UpdateSegmentReason = observer(() => {
  const classes = useStyles();
  const store = userUpdateSegmentStore();
  const handleInputChange = (event) => {
    if (!event.target.value) {
      store.reasonEmptyError = t("updateUserSegment.ReasonForChangeEmptyError");
      store.textReasonForChange = null;
      return;
    }
    store.reasonEmptyError = null;
    store.textReasonForChange = event.target.value;
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <TextField
        variant="outlined"
        multiline
        rows={8}
        rowsMax={100}
        fullWidth
        value={store.textReasonForChange}
        label={t("updateUserSegment.textReasonForChange")}
        onChange={handleInputChange}
      />
    </Grid>
  );
});
