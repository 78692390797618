import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const Score = observer(() => {
  const classes = useStyles();
  const store = useEditCouponTypeStore();
  const minValue = 0;

  const handleInputChange = (event) => {
    if(event.target.value < minValue){
      store.scoreError = true;
      return;
    }
    store.scoreError = false;
    store.score = event.target.value;
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      label={t("screenAddNewBusiness.Score") + " (Score)"}
      type="number"
      value={store.score || ""}
      onChange={handleInputChange}
      onWheel={handleBlockScroll}
      error={store.scoreError}
      helperText={store.scoreError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
      className={classes.input}
    />
  );
});
