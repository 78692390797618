import { httpCall } from "common/httpCall";

export async function getSegmentUsersUsingBonusesTotals(segmentId) {
  const data = await httpCall(
    "organization/home/getSegmentUsersUsingBonusesTotals",
    {
      segmentId
    }
  );
  return data;
}
