import React from "react";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import { handleBlockScroll} from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const AutoGenInputCount = observer(() => {
  const store = useAddNewCouponTypeStore();
  const { codeGenerationParameters } = store;
  const classes = useStyles();

  const handleChange = (event) => {
    if(!event.target.value){
        store.codeGenerationStrategyEmptyError = t("addNewCouponType.CodeGenerationStrategyLabel");
        return;
    }
    store.codeGenerationStrategyEmptyError = null;
    store.codeGenerationParameters = {...codeGenerationParameters, [event.target.name]: Number(event.target.value),
    };
  };

  return (
      <Grid className={classes.input}>
        <TextField
          type="number"
          name="count"
        onChange={handleChange}
        onWheel={handleBlockScroll}
          label={t("addNewCouponType.ExternalCouponAmountInput")}
        />
      </Grid>
  );
});
