import React from "react";
import { makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const BusinessLogoUrl = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();
  const linkContent = store.currentBusinessLogoUrl ? <Link href={store.currentBusinessLogoUrl} target="_blank">{t("editBusiness.currentImage")}</Link> : null

  return (
    <div className={classes.input}>
      {linkContent}
    </div>
  );
});