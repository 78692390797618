import React from "react";
import { DescriptiveCategorySelect } from "./components/DescriptiveCategorySelect";
import { DescriptiveTagSelect } from "./components/DescriptiveTagSelect";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  grid: {
    marginBottom: 20,
  }
}));

export const DescriptiveTagSelector = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.grid}>
      <Grid item xs={12}>
        <DescriptiveCategorySelect />
        <DescriptiveTagSelect />
      </Grid>
    </Grid>
  );
};