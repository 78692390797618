import React, { useState } from "react";
import { Form } from "./store/components/index";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { observer } from "mobx-react";
import { createAddNewAuctionStore, AddNewAuctionStoreProvider } from "./store/index";

export const CreateAddNewAuction = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createAddNewAuctionStore(rootStore));
  const { userInfoStore } = useRootStore();
  const { isLoggedIn } = userInfoStore;

  if (isLoggedIn) {
    return (
      <AddNewAuctionStoreProvider value={store}>
        <Form store={store} />
      </AddNewAuctionStoreProvider>
    );
  }
});