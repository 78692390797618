import React, { useState } from "react";
import { Form } from "./store/components/form/index";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { observer } from "mobx-react";
import {EditCouponTypeStoreProvider, createEditCouponTypeStore} from "./store/index";

export const EditCouponType = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createEditCouponTypeStore(rootStore));

    return (
      <EditCouponTypeStoreProvider value={store}>
         <Form store={store} />
       </EditCouponTypeStoreProvider>
    );
  
});