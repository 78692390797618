import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import { Divider } from "@material-ui/core";
import { Navigation } from "components";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { getNavigationConfigByDashboardType } from "../navigationConfig";
import { TopNavBar } from "./topNavBar";
import { addSegmentRouteParam } from "./logic/addSegmentRouteParam";
import MobileLogoutButton from './mobileLogoutButton';
import { SupplierSupportButton } from "./supplierSupportButton";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflowY: "auto",
  },
  content: {
    padding: theme.spacing(2),
    height: "90vh"
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  }
}));

export const NavBarContent = observer((props) => {
  const { isMobile, className, ...rest } = props;
  const { userInfoStore } = useRootStore();
  const classes = useStyles();
  const { dashboardType, currentSegmentId } = userInfoStore;
  const [navigationConfig, setNavigationConfig] = useState([]);
  const { isShowSubSegmentUsersScreen, isShowMultiSegmentUsersScreen, isShowAppOpenByHours, isShowHideCouponTypesScreen, isShowTrainesInfoScreen, isShowOrderDetailsScreen, isShowStepsAndGoalsScreen, isShowSendNotificationScreen, isProjectManager, isAllowedHideCouponTypes, isAllowedToSendNotification, isShowSwitchBusinessScreen, isShowHealthAndFitnessData, isHideCoachesInfoScreen, currentSupplierId: isSupplier, userName } = userInfoStore;
  useEffect(() => {
    const navigationConfigWithoutParams = getNavigationConfigByDashboardType(dashboardType);
    const segmentFeatures = { isShowSubSegmentUsersScreen, isShowMultiSegmentUsersScreen, isShowAppOpenByHours, isShowHideCouponTypesScreen, isShowTrainesInfoScreen, isShowOrderDetailsScreen, isShowStepsAndGoalsScreen, isShowSendNotificationScreen, isShowHealthAndFitnessData, isHideCoachesInfoScreen };
    const navigationConfigWithParams = addSegmentRouteParam({ dashboardType, navigationConfig: navigationConfigWithoutParams, currentSegmentId, segmentFeatures, isProjectManager, isSupplier, isAllowedHideCouponTypes, isAllowedToSendNotification, isShowSwitchBusinessScreen });
    setNavigationConfig(navigationConfigWithParams);
  }, []);
  const mobileLogout = (isMobile ? <MobileLogoutButton /> : "")

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.content}>
        <TopNavBar />
        {mobileLogout}
        <Divider className={classes.divider} />
        <nav className={classes.navigation}>
          {navigationConfig.map((list) => (
            <Navigation
              component="div"
              key={list.title}
              pages={list.pages}
              title={list.title}
            />
          ))}
        </nav>
        {isSupplier && <SupplierSupportButton userName={userName}/>}
      </div>
    </div>
  );
});
