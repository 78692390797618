import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import { Actions } from "./actions";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 2000,
  },
  media: {
    height: 2300,
    width: 1000,
  }
}));

export const PricingImage = () => {
  const classes = useStyles();
  const linkToImage = "https://rumbleapp.s3.eu-west-1.amazonaws.com/dashboardBusiness/businessOffers.png";

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={linkToImage}
        />
      </CardActionArea>
      <Actions />
    </Card>
  );
};
