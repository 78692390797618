import React, { useState } from "react";
import { Form } from "./store/components/form/index";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { observer } from "mobx-react";
import {createEditBusinessStore, EditBusinessStoreProvider} from "./store/index";

export const EditBusinessForm = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createEditBusinessStore(rootStore));
  const { userInfoStore } = useRootStore();
  const { isLoggedIn } = userInfoStore;

  if (isLoggedIn) {
    return (
      <EditBusinessStoreProvider value={store}>
        <Form store={store} />
      </EditBusinessStoreProvider>
    );
  }
});