import { t } from "common/localization/translate";
import { changeUserBalanceErrorCodes } from "./userBalanceChangeErrorCodes ";

export function handleServerError(serverErrorCode) {
  switch (serverErrorCode) {
    case changeUserBalanceErrorCodes.USER_WITHOUT_PERMISSION:
      return t("editBalance.UserUnauthorized");
    case changeUserBalanceErrorCodes.USER_IS_NOT_EXIST:
      return t("editBalance.UserIdNotExist");
    case changeUserBalanceErrorCodes.INVALID_INPUT_NUMBER:
      return t("editBalance.InvalidInputNumber");
    case changeUserBalanceErrorCodes.INVALID_INPUT_REASON_TEXT:
      return t("editBalance.InvalidReason");
    default:
      return t("editBalance.generalServerError");
  }
}
