import { dashBoardModes } from "./dashDashBoardModes";

export function getDashboardMode(pathname) {

    if (pathname.includes('biz')) {
        return dashBoardModes.MODE_BIZ;
    } else if (pathname.includes('org')) {
        return dashBoardModes.MODE_ORG;
    } else if (pathname.includes('mov')) {
        return dashBoardModes.MODE_MOV;
    }

    return null;
}