import React from "react";
import { makeStyles, Grid, Select, MenuItem } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { InputLabel } from "@material-ui/core";
import { t } from "common/localization/translate";
import { languages } from "../../../../../../../editArticle/store/logic/languages";

const useStyles = makeStyles(() => ({
  input: {
    marginLeft: 20,
    marginTop: 10,
    maxWidth: 100,
  },
}));

export const LanguageSelect = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();
  const languagesArray = Object.values(languages);
  
  const handleChange = (event) => {
    store.selectedLanguage = event.target.value;
  };
  
  if (!store.isTranslate) {
    return;
  }
  const languageContent = languagesArray.map((language) => (<MenuItem value={language.languageCode}>{language.label}</MenuItem>));

  return (
    <Grid item xs={12} className={classes.input}>
      <InputLabel>{t("editArticle.languageSelect")}</InputLabel>
      <Select onChange={handleChange} value={store.selectedLanguage}>
        {languageContent}
      </Select>
    </Grid>
  );
});
