export function setSelectedSegments(selectedSegments, segmentsList){    
    let selectedSegmentsArray = [];
    for (let selectedSegment of selectedSegments){
        if(selectedSegment == null){
            selectedSegment = 0;
        }
        selectedSegmentsArray.push(segmentsList.find(segment => segment.segment_id == selectedSegment))
    }
    return selectedSegmentsArray;
}
