import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { BusinessDescription } from "./components/BusinessDescription";
import { BusinessHours } from "./components/BusinessHours";
import { BusinessLocation } from "./components/BusinessLocation";
import { BusinessName } from "./components/BusinessName";
import { BusinessLogo } from "./components/BusinessLogo";
import { PrimaryCategorySelect } from "./components/PrimaryCategorySelect";
import { SegmentSelect } from "./components/SegmentChoice";
import { SubCategorySelect } from "./components/SubCategorySelect";
import { SubtitleInput } from "./components/SubtitleInput";
import { t } from "common/localization/translate";
import { SupplierSelect } from "./components/supplierSelect";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 10,
    textAlign: "center",
    flex: "auto",
    color: theme.palette.primary.main,
  },
  input: {
    marginBottom: 50,
  }
}));

export const General = () => {
  const classes = useStyles();
  return (
    <div className={classes.input}>
      <Typography className={classes.title} component="h1" variant="h2">
        {t("screenAddNewBusiness.GeneralInfo")}
      </Typography>
      <Grid item xs={12}>
        <BusinessName />
      </Grid>
      <Grid item xs={12}>
        <SubtitleInput />
      </Grid>
      <Grid item xs={12}>
        <BusinessDescription />
      </Grid>
      <Grid item xs={12}>
        <BusinessLogo />
      </Grid>
      <Grid item xs={12}>
        <PrimaryCategorySelect />
      </Grid>
      <Grid item xs={12}>
        <SubCategorySelect />
      </Grid>
      <Grid item xs={12}>
        <BusinessHours />
      </Grid>
      <Grid item xs={12}>
        <BusinessLocation />
      </Grid>
      <Grid item xs={12}>
        <SegmentSelect />
      </Grid>
      <Grid item xs={12}>
        <SupplierSelect />
      </Grid>
    </div>
  );
};