import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { observer } from "mobx-react-lite";
import { useEditBalanceStore } from "../../index";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.white,
      fontSize: 16,
    },
  },
}));

export const EditUserBalanceErrorPopUp = observer(() => {
  const store = useEditBalanceStore();
  const classes = useStyles();
  const {coinsBalanceEmptyError, userIdEmptyError, reasonEmptyError, popUpErrorContent} = store;
  const requiredFields = [ coinsBalanceEmptyError, userIdEmptyError, reasonEmptyError];
  const serverErrorContent = popUpErrorContent ? <li>{popUpErrorContent}</li> : null;
  
  const emptyFields = requiredFields.filter(fieldEmptyError => fieldEmptyError !== null && fieldEmptyError !== undefined );
  const emptyFieldErrorReport = emptyFields.map((field)=><li key={field}>{field}</li>);

  const handleClose = () => {
    store.isErrorPopUpOpen = false;
  };

  return (
    <Dialog open={store.isErrorPopUpOpen}>
      <DialogTitle className={classes.title} >
        {t("editBalance.ErrorPopUpTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("editBalance.editBalanceFailure")}
        </DialogContentText>
        {emptyFieldErrorReport}
        {serverErrorContent}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {t("suppliers.supplierHome.verifyCodesOkPopUp")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
