import React from "react";
import { Grid, CssBaseline, Typography, Container } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";
import { BusinessSelect } from "./businessSelect";
import { General } from "./components/GeneralInfo/General";
import { MediaForm } from "./components/Media/MediaForm";
import { ContactInfo } from "./components/ContactInfo/ContactInfo";
import { AccountInfo } from "./components/AccountInformation/AccountInfo";
import { CompanyInfo } from "./components/CompanyInfo/CompanyInfo";
import { DescriptiveTags } from "./components/DescriptiveTags/DescriptiveTags";
import { EditBusinessButton } from "./submitEditBusinessButton";
import { EditBusinessSuccessPopUp } from "./components/PopUps/EditBusinessSuccessPopUp";
import { FailedLocationsPopUp } from "./components/PopUps/FailedLocationsPopUp";
import { EditBusinessFailedPopUp } from "./components/PopUps/EditBusinessFailedPopUp";
import { EmptyFieldErrorPopUp } from "./components/PopUps/EmptyFieldErrorPopUp";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 30,
    flex: "auto",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    marginBottom: 10,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  gridWidth: {
    width: "50%",
  },
}));

export const Form = observer(() => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography className={classes.title} component="h1" variant="h1">
          {t("editBusiness.editBusinessFormTitle")}
        </Typography>
        <form className={classes.form} noValidate>
          <Grid className={classes.gridWidth}>
            <BusinessSelect />
            <General />
            <DescriptiveTags />
            <MediaForm />
            <ContactInfo />
            <AccountInfo />
            <CompanyInfo />
            <EditBusinessButton />
            <EditBusinessSuccessPopUp />
            <EditBusinessFailedPopUp />
            <FailedLocationsPopUp />
            <EmptyFieldErrorPopUp />
          </Grid>
        </form>
      </div>
    </Container>
  );
});
