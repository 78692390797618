export function handleErrorCheck(store) {
  const { userIdForChange, newSegmentId, textReasonForChange } = store;

  if (!userIdForChange || !textReasonForChange) {
    return true;
  }
  if (newSegmentId === null || undefined) {
    return true;
  }
  return false;
}
