import React from "react";
import { observer } from "mobx-react";
import { useDeleteUserStoreStore } from "../../../index";
import { t } from "common/localization/translate";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@material-ui/core";
import { handleErrorCheck } from "../../../logic/handleErrorCheck";

export const DeleteUserButton = observer(() => {
  const store = useDeleteUserStoreStore();
  const confirmDeleteUser = async () => {
    const isError = handleErrorCheck(store);
    if (isError) {
      store.isErrorPopUpOpen = true;
      store.serverPopUpErrorContent = null;
      return;
    } else {
      await store.submitDeleteUser();
      return;
    }
  };

  const deleteUserButtonContent = store.isFetching ? (<CircularProgress color="#fffff" size={20} />) : (t("deleteUser.deleteUserBtn"));
  
  return <Grid onClick={confirmDeleteUser}>{deleteUserButtonContent}</Grid>;
});
