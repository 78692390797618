import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const ExternalLink = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();

  const handleInputChange = (event) => {
    store.externalLink = event.target.value;
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      label={t("addNewCouponType.ExternalLink")}
      onChange={handleInputChange}
      className={classes.input}
    />
  );
});
