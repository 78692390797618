export const screenTypes = {


    SCREEN_BIZ_PURCHASES: '/biz/purchases',
    SCREEN_BIZ_BRANCHES_INFO: '/biz/branchesInfo',
    SCREEN_BIZ_LOGIN: '/biz/login',

    SCREEN_ORG_LOADINGS: '/org/loadings',
    SCREEN_ORG_LOGIN: '/org/login',
    SCREEN_ORG_PURCHASES: '/org/purchases',

    SCREEN_MOV_LOGIN: '/mov/login',
    SCREEN_MOV_MAIN: '/mov/main',
    SCREEN_MOV_SUM_OF_LOADINGS_PER_ORG: '/mov/SummaryOfLoadingsPerOrganization',
    SCREEN_MOV_SUM_OF_PURCHASES_BY_BIZ: '/mov/SummaryOfPurchasesByBusiness'
}