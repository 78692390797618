import { isSameWithoutTime } from "common/dateAndTime";
import { getYearAndMonth, getStartEndOfMonth } from "common/dateAndTime";

export function updateSelectedYearAndMonth(component) {
    const { year: startAtYear, month: startAtMonth } = getYearAndMonth(component.startAt);
    const { start: startOfMonth, end: endOfMonth } = getStartEndOfMonth(startAtYear, startAtMonth);
    const sameStart = isSameWithoutTime(startOfMonth, component.startAt);
    const sameEnd = isSameWithoutTime(endOfMonth, component.endAt);

    if (sameStart && sameEnd) {
        component.selectedKeyYear = startAtYear;
        component.selectedKeyMonth = startAtMonth;
        return;
    }
    component.resetSelectedYearAndMonth();
}

