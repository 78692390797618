import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { CommonButton } from "common/ui/commonButton";

const useStyles = makeStyles(() => ({
    text: {
        color: '#fff',
        marginRight: 5,
        width: '90%',
    },
    button: {
        width: '130px',
    }
}));

export const StageWithDropdown = observer((props) => {
    const { isShowingData, title, onClick } = props;
    const classes = useStyles();
    const icon = isShowingData ? <ArrowDropUpIcon fontSize={'large'} /> : <ArrowDropDownIcon fontSize={'large'} />;

    return (
        <Grid item sm={4} lg={3} spacing={2}>
            <CommonButton className={classes.button} onClick={onClick}>
                <Typography className={classes.text}>{title}</Typography>
                {icon}
            </CommonButton>
        </Grid>
    );
});