import React, { useState } from "react";
import { Form } from "./store/components/form/index";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { observer } from "mobx-react";
import { createUpdateSegmentStore, UpdateSegmentStoreProvider } from "./store/index";

export const UpdateUserSegment = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createUpdateSegmentStore(rootStore));

  return (
    <UpdateSegmentStoreProvider value={store}>
      <Form store={store} />
    </UpdateSegmentStoreProvider>
  );
});