import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import Select from "react-select";
import { observer } from "mobx-react";
import { userUpdateSegmentStore } from "../../../index";
import { t } from "common/localization/translate";
import { customStyles } from './segmentsSelectStyles';

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
    width: 420,
  },
}));

export const SelectSegment = observer(() => {
  const classes = useStyles();
  const store = userUpdateSegmentStore();
  const { segmentList } = store;
    const handleInputChange = (event,  value) => {
        if (!value) {
          store.selectSegmentEmptyError = t("updateUserSegment.selectSegmentEmptyError");;
          store.newSegmentId = null;
          return;
        }
        store.selectSegmentEmptyError = null;
        store.newSegmentId = Number(event.segment_id)
      };

  return (
    <Grid item xs={12} className={classes.input}>
        <Select
          options={segmentList}
          getOptionLabel={(segment) => `${segment.segment_id} - ${segment.name}`}
          onChange={handleInputChange}
          styles={customStyles}
          classes={classes}
          placeholder={t("updateUserSegment.selectSegmentLabel")}
        />
    </Grid>
  );
});