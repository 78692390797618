import React, { Fragment } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Drawer, Paper } from "@material-ui/core";
import { Hidden } from "@material-ui/core";
import { NavBarContent } from "./components/navBarContent";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 600,
    overflowY: "auto"
  },
  content: {
    padding: theme.spacing(2),
    height: "90vh",
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
}));

const NavBar = observer((props) => {
  const { openMobile, onMobileClose, className, ...rest } = props;
  const classes = useStyles();
  const currentUrl = window.location.href

  const isUrlChange = () => {
    if (currentUrl != window.location.href) {
      onMobileClose()
    }
  };

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClick={isUrlChange}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <NavBarContent {...rest} className={clsx(classes.root, className)} isMobile={true} />
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
           <NavBarContent {...rest} className={clsx(classes.root, className)} />
        </Paper>
      </Hidden>
    </Fragment>
  );
});

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
