import React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from "@material-ui/styles";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    toolTipIcon: {
        color: theme.palette.primary.main
    },
}));

export const SummaryMonthlyReportSuppliersCouponsTitle = observer(() => {
    const classes = useStyles();


    return (
        <Grid item container xs={12} >
            <Grid item >
                <InputLabel className={classes.label} shrink >{t("suppliers.ProjectManagerHome.MonthlyCouponsSummaryReportTitle")}</InputLabel>
            </Grid>
            <Grid item >
                <Tooltip title={t("suppliers.home.projectManagerCouponsReportSummaryToolTip")} >
                    <HelpIcon className={classes.toolTipIcon} />
                </Tooltip>
            </Grid>
        </Grid>
    )
});

