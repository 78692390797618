import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const Commission = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();
  const { commissionError } = store;
  const commission = store.commission ? store.commission : "";

  const handleInputChange = (event) => {
    if (event.target.value < 0) {
      store.commissionError = true;
      return;
    }
    store.commissionError = null;
    store.commission = event.target.value;
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      label={t("screenAddNewBusiness.Commission")}
      type="number"
      name="Commission"
      onChange={handleInputChange}
      onWheel={handleBlockScroll}
      className={classes.input}
      value={commission}
      error={commissionError}
      helperText={commissionError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
    />
  );
});