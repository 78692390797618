import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 18,
  },
  input: {
    marginBottom: 15,
  }
}));

export const StartWorkingWithBusinessDate = observer(() => {
  const store = useEditBusinessStore();
  const classes = useStyles();

  const handleDateChange = (date) => {
    store.startWorkingWithBusinessDate = date;
  };

  return (
    <Grid item xs={12}>
      <InputLabel className={classes.label} shrink>
        {t("screenAddNewBusiness.StartWorkingWithBusiness")}
      </InputLabel>
      <KeyboardDateTimePicker
        className={classes.input}
        ampm={false}
        onChange={handleDateChange}
        value={store.startWorkingWithBusinessDate ? store.startWorkingWithBusinessDate : null}
        openTo="year"
        format="HH:mm DD/MM/YYYY"
        invalidDateMessage={store.startWorkingWithBusinessDate ? true : false}
      />
    </Grid>
  );
});
