import React from "react";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";
import { CardActions, Grid } from "@material-ui/core";
import { useScreenEditUserInfoStore } from "screens/support/editUserInfo/store";
import { CommonButton } from "common/ui/commonButton";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    alignSelf: "center",
    height: "100%",
    alignItems: "center",
  },
}));

export const SaveButton = (props) => {
  const classes = useStyles();
  const store = useScreenEditUserInfoStore();

  const handleSubmit = async (event) => {
    event.preventDefault();
    store.submitUserChanges();
  };

  return (
    <Grid item md={4} xs={12}>
      <CardActions className={classes.container}>
        <CommonButton onClick={handleSubmit}>
          {t("screenEditUserInfo.button.save")}
        </CommonButton>
      </CardActions>
    </Grid>
  );
};
