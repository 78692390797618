
import { MAX_YEAR, MAX_MONTH } from "../consts";

export function incSelectedKeyMonth(component) {
    if (component.selectedKeyMonth === MAX_MONTH) {
        if (component.selectedKeyYear === MAX_YEAR) {
            return;
        }
        component.selectedKeyYear++;
        component.selectedKeyMonth = 0;
    } else {
        component.selectedKeyMonth++;
    }

    if (component.isYearOrMonthNull) {
        return;
    }

    component.updateStartEndDate();
}