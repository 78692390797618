import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 18,
  },
  input: {
    marginBottom: 15,
  }
}));

export const StartWorkingWithBusinessDate = observer(() => {
  const store = useScreenCreateAddNewBusinessStore();
  const classes = useStyles();
  const { startWorkingWithBusinessDate } = store;

  const handleDateChange = (date) => {
    store.setStartWorkingWithBusinessDate(date);
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <InputLabel className={classes.label} shrink>
        {t("screenAddNewBusiness.StartWorkingWithBusiness")}
      </InputLabel>
      <KeyboardDateTimePicker
        ampm={false}
        onChange={handleDateChange}
        value={startWorkingWithBusinessDate}
        openTo="year"
        format="HH:mm DD/MM/YYYY"
        invalidDateMessage={startWorkingWithBusinessDate ? true : false}
      />
    </Grid>
  );
});
