import React from "react";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import { Grid, makeStyles, TextField } from "@material-ui/core";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  grid: {
    marginBottom: 20,
  }
}));

export const HealthScore = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();
  const defaultValue = 25;
  const minValue = 0;

  const handleInputChange = (event) => {
    if (!event.target.value){
      store.healthScore = defaultValue;
      return;
    }
    if(event.target.value < minValue){
      store.healthScoreError = true;
      return;
    }
    store.healthScoreError = false;
    store.healthScore = event.target.value;
  };

  return (
    <Grid item xs={12} className={classes.grid}>
      <TextField
        variant="outlined"
        fullWidth
        name="Health Score"
        label={t("screenAddNewBusiness.HealthScore") + " (Health Score)"}
        type="number"
        onChange={handleInputChange}
        onWheel={handleBlockScroll}
        error={store.healthScoreError}
        helperText={store.healthScoreError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
      />
    </Grid>
  );
});
