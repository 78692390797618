import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const DaysRangeInput = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();

  const handleInputChange = (event) => {
    if(!event.target.value) {
      store.daysRangeInputEmptyError = t("addNewCouponType.daysRangeInput");
      return;
    }
    store.daysRangeInputEmptyError = null;
    store.daysRangeInput = event.target.value;
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      label={t("addNewCouponType.daysRangeInput") + " *"}
      onChange={handleInputChange}
      type="number"
      onWheel={handleBlockScroll}
      className={classes.input}
    />
  );
});