import React from "react";
import { observer } from "mobx-react";
import { CountInput } from "./CountInput";
import { MonthInput } from "./MonthInput";
import { Grid } from "@material-ui/core";

export const PurchaseCountInCalendarMonthLimitation = observer(() => {
  return (
    <Grid>
      <CountInput />
      <MonthInput />
    </Grid>
  );
});
