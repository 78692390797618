import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../..";
import { t } from "common/localization/translate";

export const IsTranslateCheckbox = observer(() => {
  const store = useEditArticleStore();

  const handleChange = (event) => {
    store.isTranslate = event.target.checked;
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox checked={store.isTranslate} onChange={handleChange} />
        }
        label={t("editArticle.isTranslate")}
      />
    </div>
  );
});