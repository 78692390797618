import React from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import PersonIcon from "@material-ui/icons/Person";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { Grid } from "@material-ui/core";
import SimpleCube from "common/ui/simpleCube";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../store";
import { formatNumber } from "common/formatNumber";
import { TodaysGainedCoinsLabel } from "./todaysGainedCoinsLabel";
import { SegmentImprovementPercentageCube } from "./segmentImprovementPercentageCube";
import { TotalUsersToDemandPayment } from './totalUsersToDemandPayment';

const useStyles = makeStyles(theme => ({
  row: {
    width: "100%"
  },
  reversedIcon: {
    color: theme.palette.primary.main
  },
  img: {
    height: 35
  }
}));

export const  GeneralTotalNumbersCubesRow = observer(() => {
  const rootStore = useRootStore();
  const {
    totalCurrentSegmentMembers,
    sumOfCoinsOfCurrentSegmentUsers,
    lastWeekNewUsers
  } = useScreenHomeStore();
  const totalCurrentSegmentMembersFormatted = formatNumber(
    totalCurrentSegmentMembers
  );
  const sumOfCoinsOfCurrentSegmentUsersFormatted = formatNumber(
    sumOfCoinsOfCurrentSegmentUsers
  );
  const lastWeekNewUsersFormatted = formatNumber(
    lastWeekNewUsers
  );

  
  const classes = useStyles();
  const store = useScreenHomeStore();
  const { selectedSegmentName } = store;
  const { segmentCoinImage } = rootStore.userInfoStore;
  const coinIconToShow = segmentCoinImage ? (
    <img src={segmentCoinImage} alt="coin" className={classes.img} />
  ) : (
    <AttachMoneyIcon />
  );
  return (
    <Grid container spacing={2}>
      <SegmentImprovementPercentageCube />
      <Grid item  xs={12} md className={classes.row}>
        <SimpleCube
          label={t("screenHome.totalNumbersCubesRow.membersCount", {
            selectedSegmentName
          })}
          value={totalCurrentSegmentMembersFormatted}
          icon={<PersonIcon />}
          withoutMargin={true}
        />
      </Grid>
      <Grid item  xs={12} md className={classes.row}>
        <SimpleCube
          label={t("screenHome.totalNumbersCubesRow.thisWeekNewMembersCount", {
            selectedSegmentName
          })}
          value={lastWeekNewUsersFormatted}
          icon={<GroupAddIcon />}
          withoutMargin={true}
        />
      </Grid>
      <TotalUsersToDemandPayment/>
      <Grid item  xs={12} md className={classes.row}>
        <SimpleCube
          isWithoutMargin={true}
          label={t("screenHome.totalNumbersCubesRow.totalCoinsSum", {
            selectedSegmentName
          })}
          sideComponent={<TodaysGainedCoinsLabel />}
          value={sumOfCoinsOfCurrentSegmentUsersFormatted}
          icon={coinIconToShow}
          withoutMargin={true}
        />
      </Grid>
    </Grid>
  );
});
