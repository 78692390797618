import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 18,
  },
  input: {
    marginTop:10,
    marginBottom: 10
  }
}));

export const ExpiresAt = observer(() => {
  const store = useEditCouponTypeStore();
  const classes = useStyles();
  const { expiresAt } = store;

  const handleDateChange = (date) => {
    store.expiresAt = date;
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <InputLabel className={classes.label} shrink>
        {t("addNewCouponType.ExpiresAtLabel")}
      </InputLabel>
      <KeyboardDateTimePicker
        disablePast
        ampm={false}
        value={expiresAt}
        onChange={handleDateChange}
        openTo="year"
        format="HH:mm DD/MM/YYYY"
        invalidDateMessage={expiresAt ? true : false}
      />
    </Grid>
  );
});
