import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { ArticleHtmlInput } from "../hebrewComponents/articleHtmlInput";
import { TranslatedArticleHtml } from "./translatedArticleHtml";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
  },
}));

export const ArticleHtmlTranslate = observer(() => {
  const classes = useStyles();

  return (
    <Grid className={classes.paper}>
      <ArticleHtmlInput />
      <TranslatedArticleHtml />
    </Grid>
  );
});