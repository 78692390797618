import React from "react";
import { TextField, makeStyles, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
    textAlign: "center",
    flex: "auto",
  },
}));

export const BusinessUuid = observer(() => {
  const store = useEditBusinessStore();
  const classes = useStyles();

  return (
        <TextField
          disabled
          variant="outlined"
          label="UUID"
          fullWidth
          value={store.businessUuid ? store.businessUuid : ""}
          className={classes.input}
        />
  );
});
