import React from "react";
import {
  InputLabel,
  Select,
  makeStyles,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  category: {
    margin: theme.spacing(1),
    minWidth: 300,
    border: 1,
    borderColor: "black",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
}));

export const DescriptiveCategorySelect = observer(() => {
  const store = useEditBusinessStore();
  const { descriptiveTagsCategory, descriptiveTagsCategoriesList } = store;

  const handleChange = (event, newValue) => {
    newValue = event.target.value;
    store.descriptiveTagsCategory = newValue;
    store.getDescriptiveTagsByCategoryList();
  };

  const classes = useStyles();

  return (
    <Grid className={classes.selectEmpty} item xs={9}>
      <InputLabel>
        {t("screenAddNewBusiness.ChooseDescriptiveTagCategory") +  " *"}
      </InputLabel>
      <Select
        onChange={handleChange}
        className={classes.category}
        value={descriptiveTagsCategory ? descriptiveTagsCategory : ""}
      >
        {descriptiveTagsCategoriesList.map((tagsCategory) => {return <MenuItem key={tagsCategory.id} value={tagsCategory.category_tags}>{tagsCategory.category_tags}</MenuItem>})}
      </Select>
    </Grid>
  );
});