import React from "react";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { ChooseExternalInputType } from "./components/ChooseExternalInputType";
import { FixedCodeInput } from "./components/FixedCodeInput";
import { RandomCodeMessage } from "./components/randomCodeMessage";

export const CodeGenerationInput = observer(() => {
  const store = useAddNewCouponTypeStore();

  switch (store.codeGenerationStrategy) {
    case 1:
      store.codeGenerationStrategyEmptyError = null;
      return <RandomCodeMessage />;
    case 5:
      return <FixedCodeInput />;
    case 2:
      return <ChooseExternalInputType />;
    default:
      return null;
  }
});
