import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../index";
import { t } from "common/localization/translate";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    buttons: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 15,
        width: 200,
        height: 40,
        color: theme.palette.white,
        marginRight: 5,
        marginTop: 5,
        "&:hover": {
            backgroundColor: theme.palette.primary.white,
        }
    },
    title: {
        marginBottom: 30,
        flex: "auto",
      }
}));

export const EditBusinessButton = observer(() => {
    const store = useEditBusinessStore();
    const classes = useStyles();
    const { contactPhoneEmptyError, businessNameEmptyError, businessLogoEmptyError, businessLocationEmptyError, businessDescriptionEmptyError, scoreEmptyError, 
        tagsEmptyError, segmentsEmptyError, primaryCategoryEmptyError, descriptiveTagsEmptyError, contactEmailEmptyError, 
        contactNameEmptyError} = store;

    const handleCheck = async () => {
        if(contactNameEmptyError || contactEmailEmptyError || descriptiveTagsEmptyError || primaryCategoryEmptyError || segmentsEmptyError || tagsEmptyError || scoreEmptyError || businessDescriptionEmptyError || businessLocationEmptyError || businessLogoEmptyError || businessNameEmptyError || contactPhoneEmptyError || contactNameEmptyError){
            store.isEmptyFieldErrorPopUpOpen = true;
            return;
          }  

        await store.submitEditBusiness();

        if (store.editBusinessStatus) {
            store.isEditBusinessSuccessPopUpOpen = true;
            return;
          }

        if (store.failedLocations.length > 0) {
          store.isLocationFailedErrorPopUpOpen = true;
          return;
        }
        store.isEditBusinessFailedPopUpOpen = true;
        return;
      };

    const getCreateBusinessButtonContent = store.isFetching ? <CircularProgress color='#fffff' size={20} /> : t("editBusiness.submitEditCoupon");
    return (
        <Grid className={classes.title} item xs={12}>
        <Button onClick={handleCheck} variant="contained" className={classes.buttons}>
        {getCreateBusinessButtonContent}
        </Button>
      </Grid>
    )
});