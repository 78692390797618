import AuthLayout from "../layouts/Auth";
import { Login as LoginV2 } from "screens/loginV2";
import { Home } from "screens/controlPanel/home";
import Dashboard from "layouts/Dashboard";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import { CreateBusinessDashboardUser } from "screens/controlPanel/businessDashboard";
import { CreateAddNewBusiness } from "screens/controlPanel/addNewBuisness";
import { CreateAddNewCouponType } from "screens/controlPanel/addCouponType";
import { EditBusinessForm } from "screens/controlPanel/editBusiness";
import { EditArticle } from "screens/controlPanel/editArticle";
import { EditCouponType } from "screens/controlPanel/editCouponType";
import { ManualEditUserBalance } from "screens/controlPanel/manualEditUserBlance";
import { DeleteUser } from "screens/controlPanel/deleteUser";
import { UpdateUserSegment } from "screens/controlPanel/updateUserSegment";
// import { TechOpsReports } from "screens/controlPanel/techOpsReportes";
import { CreateAddNewAuction } from "screens/controlPanel/addNewAuction";
import { EditAuction } from "screens/controlPanel/editAuction";

export const controlPanelRoutes = [
  {
    path: `/${dashboardPaths.CONTROL_PANEL}/login`,
    component: AuthLayout,
    routes: [
      {
        path: `/${dashboardPaths.CONTROL_PANEL}/login`,
        exact: true,
        component: LoginV2,
      },
    ],
  },
  {
    path: `/${dashboardPaths.CONTROL_PANEL}`,
    component: Dashboard,
    routes: [
      {
        path: `/${dashboardPaths.CONTROL_PANEL}/home`,
        exact: true,
        component: Home,
      },
      {
        path: `/${dashboardPaths.CONTROL_PANEL}/businessDashboardUser`,
        exact: true,
        component: CreateBusinessDashboardUser,
      },
      {
        path: `/${dashboardPaths.CONTROL_PANEL}/addNewBusiness`,
        exact: true,
        component: CreateAddNewBusiness,
      },
      {
        path: `/${dashboardPaths.CONTROL_PANEL}/addCouponType`,
        exact: true,
        component: CreateAddNewCouponType,
      },
      {
        path: `/${dashboardPaths.CONTROL_PANEL}/editBusiness`,
        exact: true,
        component: EditBusinessForm,
      },
      {
        path: `/${dashboardPaths.CONTROL_PANEL}/editCouponType`,
        exact: true,
        component: EditCouponType,
      },
      {
        path: `/${dashboardPaths.CONTROL_PANEL}/editArticle`,
        exact: true,
        component: EditArticle,
      },
      {
        path: `/${dashboardPaths.CONTROL_PANEL}/editBalance`,
        exact: true,
        component: ManualEditUserBalance,
      },
      {
        path: `/${dashboardPaths.CONTROL_PANEL}/deleteUser`,
        exact: true,
        component: DeleteUser,
      },
      {
        path: `/${dashboardPaths.CONTROL_PANEL}/updateUserSegment`,
        exact: true,
        component: UpdateUserSegment,
      },
      // {
      //   path: `/${dashboardPaths.CONTROL_PANEL}/techOpsReports`,
      //   exact: true,
      //   component: TechOpsReports,
      // },
      {
        path: `/${dashboardPaths.CONTROL_PANEL}/addNewAuction`,
        exact: true,
        component: CreateAddNewAuction,
      },
      {
        path: `/${dashboardPaths.CONTROL_PANEL}/editAuction`,
        exact: true,
        component: EditAuction,
      }
    ],
  },
];
