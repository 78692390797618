import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useScreenSendNotificationStore } from "../store/index";
import { Grid } from '@material-ui/core';
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
    title:{
        fontWeight:'bold'
    }
}));

export const UserDetails = () => {
    const classes = useStyles();
    const store = useScreenSendNotificationStore();
    const { userName, userIdNum } = store;
    return (
        <Grid>
            <Typography className={classes.title} gutterBottom> {t("support.sendNotificationScreen.userName", { userName })}</Typography>
            <Typography className={classes.title} gutterBottom> {t("support.sendNotificationScreen.idNum", { idNum :userIdNum })}</Typography>
        </Grid>
    );
}