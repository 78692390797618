import { loginErrorCodes } from "./loginErrorCodes";

export function getErrorMsg(errorCode) {

    switch (errorCode) {
        case loginErrorCodes.NO_PERMISSION:
            return 'אין לך הרשאה מתאימה';
        case loginErrorCodes.USER_NOT_FOUND:
            return 'אימייל או סיסמה לא נכונים';
        case loginErrorCodes.NO_BUSINESSES:
            return 'לא הוגדרו עסקים עבור המשתמש';
        default:
            return 'שגיאה';
    }
}