import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from 'screens/controlPanel/editCouponType/store';
import { t } from "common/localization/translate";
import { Grid } from "@material-ui/core";

export const IsOneTime = observer(() => {
  const store = useEditCouponTypeStore();
  const { isOneTime } = store;

  const handleChange = event => {
    store.isOneTime = event.target.checked;
  };

  return (
    <Grid>
      <FormControlLabel control={
          <Checkbox checked={isOneTime ? true: false} onChange={handleChange} />
        }
        label={t("addNewCouponType.IsOneTime")}
      />
    </Grid>
  );
});
