import React from "react";
import clsx from "clsx";
import { Line } from "react-chartjs-2";
import { makeStyles, useTheme } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { formatNumber } from "common/formatNumber";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative"
  }
}));

const WaveChart = props => {
  const { linesData, className, labels, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();

  const data = canvas => {
    const ctx = canvas.getContext("2d");
    const ctx1 = canvas.getContext("2d");

    const topGradient = ctx1.createLinearGradient(0, 0, 0, 450);
    topGradient.addColorStop(0, fade(theme.palette.primary.main, 0));
    topGradient.addColorStop(0.6, fade(theme.palette.primary.main, 0));
    topGradient.addColorStop(0, theme.palette.primary.main);
    
    const bottomGradient = ctx.createLinearGradient(0, 0, 0,310);
    bottomGradient.addColorStop(1, fade(theme.palette.white, 0));
    bottomGradient.addColorStop(0.8, fade(theme.palette.primary.secondGraphColor, 0.9));
    bottomGradient.addColorStop(0, theme.palette.white);

    const dataSets = linesData ? linesData.map(({ dataArray, textLabel }, index) => {
      return {
        label: textLabel,
        data: dataArray,
        backgroundColor: index == 0 ? topGradient : bottomGradient,
        borderColor: index == 0 ? theme.palette.primary.main : theme.palette.primary.secondGraphColor ,
        pointBorderColor: theme.palette.white,
        pointBorderWidth: 2,
        pointRadius: 6,
        pointBackgroundColor: index == 0 ?  theme.palette.primary.main : theme.palette.primary.secondGraphColor
      }
    }) : [];

    return {
      datasets: dataSets,
      labels
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      steps: 100,
      scale: false,
    },
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 7,
            callback: value => {
              return value;
            }
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      mode: "index",
      intersect: false,
      caretSize: 20,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        title: () => { },
        label: tooltipItem => {
          const index = tooltipItem.datasetIndex;
          const textLabel = linesData[index].textLabel;
          const label = `${tooltipItem.xLabel}: ${formatNumber(tooltipItem.yLabel)} ${textLabel}`;
          return label;
        }
      }
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Line
        data={data}
        options={options}
      />
    </div>
  );
};

export default WaveChart;
