import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: 10,
  },
}));

export const BusinessFacebook = observer((props) => {
  const classes = useStyles();
  const store = useScreenCreateAddNewBusinessStore();

  const handleInputChange = (event) => {
    store.setBusinessFacebook(event.target.value);
  };

  return (
    <TextField
      autoComplete="fname"
      variant="outlined"
      fullWidth
      id="businesslogo"
      label={t("screenAddNewBusiness.FacebookUrl")}
      onChange={handleInputChange}
      className={classes.input}
    />
  );
});