import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useScreenEditUserInfoStore } from "../../../store/index";
import { Grid, TextField } from "@material-ui/core";

export const UserNameInput = observer((props) => {
  const store = useScreenEditUserInfoStore();

  const handleUserNameChange = (event) => {
    const { value } = event.target;
    store.setUserName(value);
  };

  const isError = !store.userName;
  const errorText = !store.userName ? t("screenEditUserInfo.errors.emptyUserName") : null;

  return (
    <Grid item md={3} xs={12}>
      <TextField
        fullWidth
        label={t("screenEditUserInfo.username")}
        name="userName"
        onChange={handleUserNameChange}
        required
        value={store.userName}
        error={isError}
        helperText={errorText}
        variant="outlined"
      />
    </Grid>
  );
});
