import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const BusinessName = observer(() => {
  const classes = useStyles();
  const store = useScreenCreateAddNewBusinessStore();

  const handleInputChange = (event) => {
    if(!event.target.value){
      store.setBusinessNameEmptyError(t("screenAddNewBusiness.BusinessName"))
      return;
    }
    store.setBusinessNameEmptyError(null);
    store.setBusinessName(event.target.value);
  };

  return (
    <TextField
      variant="outlined"
      required
      fullWidth
      id="businessname"
      label={t("screenAddNewBusiness.BusinessName")}
      onChange={handleInputChange}
      className={classes.input}
    />
  );
});