import React from "react";
import { ErrorText } from "./components/errorText";
import { SuccessText } from "./components/successText";

export const HelperTexts = (props) => {
  return (
    <div>
      <ErrorText />
      <SuccessText />
    </div>
  );
};
