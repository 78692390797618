import EditIcon from '@material-ui/icons/Edit';
import React from "react";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../../..";
import IconButton from '@material-ui/core/IconButton';
import { editQuestionById } from 'screens/controlPanel/editArticle/store/server/editQuestionById';


export const EditQuestionButton = observer(() => {
    const store = useEditArticleStore();
    const editQuestion = () => {
        if (!store.selectedQuestion.body || !store.selectedQuestion.question_id) {
            return;
        }
        editQuestionById({questionId: store.selectedQuestion.question_id, questionBody: store.selectedQuestion.body});
    }

    return(
        <IconButton color="primary" component="span">
          <EditIcon onClick={editQuestion}/>
        </IconButton>
    )
});