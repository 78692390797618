import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { observer } from 'mobx-react-lite';
import { useScreenSendNotificationStore } from "../store";
import { makeStyles } from "@material-ui/styles";
import { t } from 'common/localization/translate';


const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 16,
            fontFamily: 'Assistant',
            fontWeight: 'bold'
        }
    },
    content: {
        fontFamily: 'Assistant',
        fontSize: 14
    }, 
     button: {
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.secondGraphColor,
        },
      }
}));


export const SuccessSendNotificationPopUp = observer(() => {
    const store = useScreenSendNotificationStore();
    const classes = useStyles();
    
    const handleClose = () => {
        store.isSuccessSendNotification = false;
    };


    return (
        <div>
            <Dialog open={store.isSuccessSendNotification}>
                <DialogTitle className={classes.title}>{t("support.sendNotificationScreen.successSendNotificationTitlie")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("support.sendNotificationScreen.successSendNotificationContent")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className={classes.button} autoFocus >{t("support.sendNotificationScreen.successSendNotificationButton")}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
})