import React from "react";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../..";
import { t } from "common/localization/translate";
import Select from "react-select";
import { customStylesSelect } from "../../../logic/customStylesSelect";

export const ArticleSelect = observer(() => {
  const store = useEditArticleStore();
  const customStyles = customStylesSelect;

  const optionLabels = (article) => `${article.title} - ${article.article_id}`;
  const handleChange = (event) => {
    store.selectedArticle = event.article_id;
    store.getArticleData(store.selectedArticle);
    store.selectedQuestion = "";
    store.selectedAnswer = "";
  };

  return (
    <div>
      <Select
        options={store.articlesList}
        getOptionLabel={optionLabels}
        onChange={handleChange}
        styles={customStyles}
        placeholder={t("editArticle.chooseArticle")}
        />
    </div>
  );
});
