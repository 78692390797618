import React from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { Card, CardHeader, CardContent, Divider } from "@material-ui/core";
import { t } from "common/localization/translate";
import ChangePeriodDropDown from "./components/changePeriodDropDown";
import { TotalClicksWaveChart } from './components/totalClicksWaveChart'
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    height: 500
  },
  header: { flexDirection: "row", display: "flex" },
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    height: 375,
    minWidth: 500
  },
  chart: {
    height: "100%"
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 48,
    width: 48,
    display: "flex",
    justifyContent: "center"
  },
  row: {
    height: "100%",
  },
}));

export const TotalClicksChart = observer(() => {
  const classes = useStyles();

  return (
    <Grid item lg={12} xl={9} xs={12} className={classes.row}>
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        title={t("business.home.ClicksGraph.title")}
        action={<ChangePeriodDropDown />}
      />
      <Divider />
      <CardContent>
        <TotalClicksWaveChart />
      </CardContent>
    </Card>
    </Grid>
  );
});

