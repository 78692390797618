import React, { useState } from "react";
import { Form } from "./store/components/form/index";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { observer } from "mobx-react";
import { createDeleteUserStore, DeleteUserStoreProvider } from "./store/index";

export const DeleteUser = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createDeleteUserStore(rootStore));

  return (
    <DeleteUserStoreProvider value={store}>
      <Form store={store} />
    </DeleteUserStoreProvider>
  );
});
