import { writeToRow } from "./writeToRow";

export function createSheetBonuses(sheet, storeHome) {

    const { arrayOfBonusesForChart, arrayOfMeausermentsForChart  } = storeHome;
    const chosenArray = arrayOfBonusesForChart || arrayOfMeausermentsForChart;
    const col1 = 'סוג';
    const col2 = 'כמות';
    writeToRow(sheet, [col1, col2], 1);
    let rowNumber = 2;
    chosenArray.forEach(({ label, value }) => {
        writeToRow(sheet, [label, value], rowNumber);
        rowNumber++;
    });

}