import React from "react";
import {InputLabel, Select, makeStyles, MenuItem, Grid} from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { activationTypes } from "../../../../logic/activationTypes";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  category: {
    margin: theme.spacing(1),
    minWidth: 300,
    border: 1,
    borderColor: "black",
  },
  selectEmpty: {
    marginBottom: 10,
  }
}));

export const ActivationTypeSelect = observer(() => {
  const store = useAddNewCouponTypeStore();
  const { activationType } = store;
  const classes = useStyles();

  const activationTypeOptions = activationTypes.map((type) => {return <MenuItem key={type.activationTypeId} value={type.activationTypeId}>{type.name} - {type.activationTypeId}</MenuItem>});

  const handleChange = (event) => {
    if (!event.target.value) {
      store.activationTypeEmptyError = t("addNewCouponType.ActivationTypeLabel");
      return;
    }
    store.supplierErrorText =null;
    store.activationTypeEmptyError = null;
    store.activationType = event.target.value;
    store.supplierId = null;
  };

  return (
    <Grid className={classes.selectEmpty} item xs={9}>
      <InputLabel>{t("addNewCouponType.ActivationTypeLabel") + " *"}</InputLabel>
      <Select
        onChange={handleChange}
        className={classes.category}
        value={activationType ? activationType : ""}
      >
        {activationTypeOptions}
      </Select>
    </Grid>
  );
});
