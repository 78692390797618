import React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import SearchIcon from "@material-ui/icons/Search";
import { CommonButton } from "common/ui/commonButton";
import { useScreenOrderDetailsStore } from "../../../store/index";

export const SubmitButton = observer(() => {
  const store = useScreenOrderDetailsStore();
  const { isFetching } = store;
  return (
    <Grid item sm={4} lg={3} spacing={2}>
      <CommonButton disabled={isFetching}>
        <SearchIcon />
      </CommonButton>
    </Grid>
  );
});
