import React from "react";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { Grid } from "@material-ui/core";
import { StepsAmountInput } from "./stepsAmountInput";
import { DaysRangeInput } from "./daysRangeInput";
import { DaysSuccessionInput } from "./daysSuccessionInput";
import { StartDateChallenge } from "./startDateChallenge";
import { challengeTypes } from "./logic/challengeTypes";

export const ChallengeCouponGenerationInputs = observer(() => {
  const store = useAddNewCouponTypeStore();
  const goalBasedChallengeTypes = challengeTypes.filter((challengeType) => challengeType.isGoalBased).map((challengeType) => challengeType.challengeTypeId);
  const isGoalBasedChallenge = goalBasedChallengeTypes.includes(store.challengeTypeSelected);
  
  if (!store.challengeTypeSelected) {
    return null;
  }
  if (store.challengeTypeSelected) {
    return (
      <Grid>
        <StepsAmountInput isGoalBasedChallenge={isGoalBasedChallenge} />
        <DaysRangeInput />
        <DaysSuccessionInput />
        <StartDateChallenge />
      </Grid>
    );
  }
});
