import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const BusinessContactPhone = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();

  const handleInputChange = (event) => {
    if(!event.target.value) {
      store.contactPhoneEmptyError = t("screenAddNewBusiness.ContactPhone");
      store.contactPhone = event.target.value;
      return;
    }
    store.contactPhoneEmptyError = null;
    store.contactPhone = event.target.value;
  };

  return (
    <TextField
      required
      value={store.contactPhone ? store.contactPhone : ""}
      variant="outlined"
      fullWidth
      type="tel"
      label={t("screenAddNewBusiness.ContactPhone")}
      onChange={handleInputChange}
      className={classes.input}
    />
  );
});