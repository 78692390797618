import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { segmentIds } from "screens/controlPanel/addCouponType/store/logic/segmentIds";
import { Grid } from "@material-ui/core";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  grid: {
    marginBottom: 20,
  }
}));

export const CostToSupplier = observer(() => {
  const classes = useStyles();
  const store = useEditCouponTypeStore()
  const label = store.segments.includes(segmentIds.CLALIT_SEGMENT_ID) ? t("addNewCouponType.costToMushlam") : t("addNewCouponType.costToOvdim");

  const handleInputChange = (event) => {
    store.costToSupplier = Number(event.target.value);
  };

  if(!store.segments.includes(segmentIds.CLALIT_SEGMENT_ID) && !store.segments.includes(segmentIds.TEST_SEGMENT_ID) && !store.segments.includes(segmentIds.OVDIM_BARI_SEGMENT_ID)) {
    return null;
  }
  return (
    <Grid item xs={12} className={classes.grid}>
      <TextField
        variant="outlined"
        fullWidth
        label={label}
        type="number"
        onChange={handleInputChange}
        onWheel={handleBlockScroll}
        className={classes.input}
        value={store.costToSupplier}
      />
    </Grid>
  );
});

