import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import find from "lodash/find";
import { Button, Menu, MenuItem, ListItemText } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useRootStore } from "commonStores/analytics/rootStoreContext";

const useStyles = makeStyles(theme => ({
  header: {
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center"
  },
  changeSegmentButton: {
    color: "white"
  }
}));

const ChangeSegmentDropDown = observer(props => {
  const rootStore = useRootStore();
  const { currentSegment: currentSegmentStore , isShowTopBarDropDown } =  rootStore.userInfoStore;
  if(!isShowTopBarDropDown){ 
    return null;
  }
  const currentSegmentName = currentSegmentStore ? currentSegmentStore.segmentName : '';
  const [openChangeSegment, setOpenChangeSegment] = useState(false);
  const [currentSegment, setCurrentSegment] = useState(currentSegmentName);
  const options = rootStore.userInfoStore.allowedSegments
    ? rootStore.userInfoStore.allowedSegments.map(item => item.segmentName)
    : null;
  const classes = useStyles();
  const changeSegmentRef = useRef(null);

  if (!options || options.length <= 1) {
    return null;
  }
  const optionsComponents = options.map(option => (
    <MenuItem
      className={classes.menuItem}
      key={option}
      onClick={() => handleChangeSegmentSelect(option)}
    >
      <ListItemText primary={option} />
    </MenuItem>
  ));

  const handleChangeSegmentOpen = () => {
    setOpenChangeSegment(true);
  };

  const handleChangeSegmentClose = () => {
    setOpenChangeSegment(false);
  };

  const handleChangeSegmentSelect = value => {
    setCurrentSegment(value);
    const segment = find(rootStore.userInfoStore.allowedSegments, [
      "segmentName",
      value
    ]);
    rootStore.userInfoStore.setCurrentSegment(segment);
    setOpenChangeSegment(false);
    window.location.reload();
  };

  return (
    <div>
      <div className={classes.header}>
        <Button
          className={classes.changeSegmentButton}
          onClick={handleChangeSegmentOpen}
          ref={changeSegmentRef}
        >
          {currentSegment}
          <ArrowDropDownIcon />
        </Button>
      </div>
      <Menu
        anchorEl={changeSegmentRef.current}
        className={classes.menu}
        onClose={handleChangeSegmentClose}
        open={openChangeSegment}
      >
        {optionsComponents}
      </Menu>
    </div>
  );
});

export default ChangeSegmentDropDown;
