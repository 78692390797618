import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { AddQuestionInput } from "./addQuestionInput";
import { AddQuestionButton } from "./addQuestionButton";
import { useEditArticleStore } from "../../../../..";
import { QuestionSelect } from "./questionSelect";
import { quizActions } from "screens/controlPanel/editArticle/store/logic/quizActions";

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
  },
}));

export const AddQuestionsComponents = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();

  if (store.quizAction !== quizActions.ADD) {
    return null;
  }
  return (
    <span className={classes.input}>
      <AddQuestionInput />
      <AddQuestionButton />
      <QuestionSelect />
    </span>
  );
});
