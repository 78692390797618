import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button, TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import useRouter from "utils/useRouter";
import { useScreenLoginStore } from "../../store";
import { ResetPassword } from '../resetPassword';
import { EmailSentSuccessfullyPopUp } from '../mailSentSuccessfullyPopUp';

const useStyles = makeStyles(theme => ({
  root: {},
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  }
}));

const LoginForm = observer(props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const router = useRouter();
  const {
    email,
    password,
    setEmail,
    setPassword,
    login } = useScreenLoginStore();

  const onEmailChange = event => {
    const { value } = event.target;
    setEmail(value);
  };

  const onPasswordChange = event => {
    const { value } = event.target;
    setPassword(value);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await login(router.history, router.location.pathname);
  };

  return (
    <form
      {...rest}
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
    >
      <div className={classes.fields}>
        <TextField
          error={false}
          fullWidth
          helperText={""}
          label="אימייל"
          name="email"
          onChange={onEmailChange}
          value={email || ""}
          variant="outlined"
        />
        <TextField
          error={false}
          fullWidth
          helperText={""}
          label="סיסמה"
          name="password"
          onChange={onPasswordChange}
          type="password"
          value={password || ""}
          variant="outlined"
        />
      </div>
      <Button
        className={classes.submitButton}
        disabled={false}
        size="large"
        type="submit"
        variant="contained"
      >
        התחבר
      </Button>
      <ResetPassword />
      <EmailSentSuccessfullyPopUp />
    </form>
  );
});

LoginForm.propTypes = {
  className: PropTypes.string
};

export default LoginForm;
