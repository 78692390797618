import React from "react";
import { decorate, action, observable, computed } from "mobx";
import { getUserBalanceDataServer } from "./server/getUserBalanceDataServer"
import { getUserBalanceTableData } from "./logic/getUserBalanceTableData";
import { validatePhoneNumber } from '../../editUserInfo/store/logic/validatePhoneNumber';
import { errorCodes } from '../../services/errorCodes';
import { validateIsraeliIdStructure } from "common/validateIsraeliIdStructure/validateIsraeliIdStructure";


const ScreenUserBalanceStoreContext = React.createContext({});
export const ScreenUserBalanceDataProvider = ScreenUserBalanceStoreContext.Provider;
export const useScreenUserBalanceStore = () =>
  React.useContext(ScreenUserBalanceStoreContext);


class ScreenUserBalanceStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  setUserIdentifier(userIdentifier) {
    this.userIdentifier = userIdentifier;
  }

  setUserBalance(value) {
    this.userBalance = value;
  }

  setUserName(userName) {
    this.userName = userName;
  }


  setErrorCodes(errorCode) {
    this.errorCode = errorCode;
  }


  getScreenData = async () => {
    try{
      this.setUserBalance(null);
      this.setIsFetching(true);
      const segmentId = this.rootStore.userInfoStore.currentSegment
        ? this.rootStore.userInfoStore.currentSegment.segmentId
        : null;
      const response = await getUserBalanceDataServer(this.userIdentifier, segmentId);
      const userName = response.userName;
      this.setUserName(userName);
      this.setErrorCodes(response.errorCode);
      this.setUserBalance(response.userBalanceWithDateFormat);
      this.setIsFetching(false);
    }catch(e){
      this.setErrorCodes(errorCodes.ERROR_TYPE_USER_NOT_EXISTS);
      this.setIsFetching(false);
    }

  }

  submitIdentifier() {
    this.setUserName(null);
    const isValidId = validateIsraeliIdStructure(this.userIdentifier);
    const isValidPhone = validatePhoneNumber(this.userIdentifier,  true);
    if(this.rootStore.userInfoStore.isWithSearchByPhoneOption){
      if(isValidId || isValidPhone){ 
        this.getScreenData();
        return;
      }
      this.setErrorCodes(errorCodes.ERROR_TYPE_INVALID_DATA);
      return;
    }
    if(!isValidId){
      this.setErrorCodes(errorCodes.ERROR_TYPE_INVALID_DATA);
      return;
    }
    this.getScreenData();
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }

  get userBalanceTableData() {
   return getUserBalanceTableData(this);
  }
}


decorate(ScreenUserBalanceStore, {
  isFetching: observable,
  userIdentifier: observable,
  userName: observable,
  userBalance: observable,
  errorCode: observable,
  userBalanceTableData: computed,
  setUserName: action.bound,
  submitIdentifier: action.bound,
  setUserBalance: action.bound,
  setUserIdentifier: action.bound,
  setErrorCodes: action.bound,
  setIsFetching: action.bound,
  getScreenData: action.bound,
});


export function createScreenUserBalanceUsersStore(rootStore) {
  const store = new ScreenUserBalanceStore(rootStore);
  return store;
}