import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  statsItem: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(5, 2),
    "&:not(:last-of-type)": {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  }
}));

const NumberCube = props => {
  const classes = useStyles();
  const { label, valueExplaind, value, id } = props.item;
  if(!Number(value) && id == "BMI"){
    return null;
  }
  return (
    <div className={classes.statsItem}>
      <Typography align="center" component="h6" gutterBottom variant="overline">
        {label}
      </Typography>
      <Typography align="center" variant="h4">
        {valueExplaind}
      </Typography>
    </div>
  );
};

export default NumberCube;
