import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { Page } from "components";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenStaffInfoStore, ScreenStaffInfosStoreProvider } from "./store";
import { t } from "common/localization/translate";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import { Redirect } from "react-router-dom";
import { StaffTable } from "./components/staffTable";
import { Header } from "./components/header";
import { SendExcelMailPopup } from "./components/sendExcelMailPopup";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flex: 1,
  },
}));

export const StaffInfo = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenStaffInfoStore(rootStore));

  const classes = useStyles();
  useEffect(() => { store.getScreenData(); }, []);

  if (!rootStore.userInfoStore.isShowTrainesInfoScreen) {
    return <Redirect to={{ pathname: `/${dashboardPaths.ORGANIZATION}/login` }} />
  }
  return (
    <ScreenStaffInfosStoreProvider value={store}>
      <Page className={classes.root} title={t("screenStaffInfo.table.title")}>
        <Header />
        <div style={{height: 10}}/>
        <StaffTable />
        <SendExcelMailPopup />
      </Page>
    </ScreenStaffInfosStoreProvider>
  );
});
