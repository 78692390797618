import { decorate, computed, action, observable } from "mobx";
import {
  getLocalInfoObject,
  clearLocalInfoObject,
  setLocalInfoObject,
  createLocalInfoObjectBiz,
  createLocalInfoObjectOrg,
  createLocalInfoObjectMov,
} from "../../common/localStorageLogic";
import { screenTypes } from "./screenTypes";
import { getDashboardMode } from "../../common/getDashboardMode";
import { dashBoardModes } from "../../common/dashDashBoardModes";
import { setHttpHeaders } from "common/setHttpHeaders";
import { sendAnalyticsEvent, setUserId } from "common/analyticsEvent";
import { analyticsEvents } from "common/analyticsEvent/events";

export class UserInfoStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    const localInfoObject = getLocalInfoObject();
    this.initProperties(localInfoObject);
    if (this.token) {
      setHttpHeaders(this.token);
    }
  }

  initProperties(localInfoObject) {
    this.currentScreen = null;
    if (localInfoObject) {
      const { token, businessId, businessName, businessImage, segmentName, segmentLogo, isSupportFitCredit, isSupportHealthCoins } = localInfoObject;
      this.token = token;
      this.businessId = businessId;
      this.businessName = businessName;
      this.businessImage = businessImage;
      this.segmentName = segmentName;
      this.segmentLogo = segmentLogo;
      this.isSupportFitCredit = isSupportFitCredit;
      this.isSupportHealthCoins = isSupportHealthCoins;
    } else {
      this.token = null;
      this.businessId = null;
      this.businessName = null;
      this.businessImage = null;
      this.segmentName = null;
      this.segmentLogo = null;
      this.isSupportFitCredit = null;
      this.isSupportHealthCoins = null;
    }
  }

  setCurrentScreen(screenName) {
    this.currentScreen = screenName;
  }

  login(token, info, pathname) {
    this.token = token;
    setHttpHeaders(token);
    const { user_id } = info;
    setUserId(user_id);
    const dashboardMode = getDashboardMode(pathname);
    let localInfoObject;
    if (dashboardMode === dashBoardModes.MODE_BIZ) {
      const { businesses, isSupportFitCredit, isSupportHealthCoins } = info;
      const { businessId, businessName, businessImage } = businesses[0];
      localInfoObject = createLocalInfoObjectBiz(businessName, businessId, businessImage, token, isSupportFitCredit, isSupportHealthCoins);
      this.businessId = businessId;
      this.businessName = businessName;
      this.businessImage = businessImage;
      this.currentScreen = screenTypes.SCREEN_BIZ_PURCHASES;
      this.isSupportFitCredit = isSupportFitCredit;
      this.isSupportHealthCoins = isSupportHealthCoins;
      info.businessId = businessId;
      sendAnalyticsEvent(analyticsEvents.EVENT_LOGIN_DASHBOARD_BIZ);
    } else if (dashboardMode === dashBoardModes.MODE_ORG) {
      const { segmentName, segmentLogo } = info;
      localInfoObject = createLocalInfoObjectOrg(segmentName, token, segmentLogo);
      this.segmentName = segmentName;
      this.segmentLogo = segmentLogo;
      this.currentScreen = screenTypes.SCREEN_ORG_LOADINGS;
      sendAnalyticsEvent(analyticsEvents.EVENT_LOGIN_DASHBOARD_ORG);
    } else if (dashboardMode === dashBoardModes.MODE_MOV) {
      this.currentScreen = screenTypes.SCREEN_MOV_MAIN;
      localInfoObject = createLocalInfoObjectMov(token);
      sendAnalyticsEvent(analyticsEvents.EVENT_LOGIN_DASHBOARD_MOV);
    }
    setLocalInfoObject(localInfoObject);
  }

  logout() {
    clearLocalInfoObject();
    window.location.reload();
  }

  get isLoggedIn() {
    return this.token ? true : false;
  }
}

decorate(UserInfoStore, {
  token: observable,
  currentScreen: observable,
  businessId: observable,
  businessName: observable,
  businessImage: observable,
  isSupportFitCredit: observable,
  isSupportHealthCoins: observable,
  isLoggedIn: computed,
  setCurrentScreen: action.bound,
  login: action.bound,
  logout: action.bound,
  initProperties: action.bound,
});
