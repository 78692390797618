import React from "react";
import { TextField, makeStyles, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
    input: {
        marginBottom: 10,
    },
}));

export const UnderTitleText = observer(() => {
    const classes = useStyles();
    const store = useEditCouponTypeStore();

    const handleInputChange = ({ target }) => {
        store.underTitleText = target.value;
        store.previousDisplayAttributes = { ...store.previousDisplayAttributes, warningCouponText: target.value };
    };

    return (
        <Grid>
            <TextField
                variant="outlined"
                fullWidth
                label={t("addNewCouponType.underTitleText")}
                onChange={handleInputChange}
                className={classes.input}
                value={store.underTitleText || ""}
            />
        </Grid>
    );
});
