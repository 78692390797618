import React from "react";
import { decorate, action, observable, computed } from "mobx";
import { getScreenData } from "./server/getScreenData";
import { getSegmentUsersUsingBonusesTotals } from "./server/getSegmentUsersUsingBonusesTotals";
import { getSegmentUsersUsingMeasurementTotalsServer } from './server/getSegmentUsersUsingMeasurementTotalsServer';
import { getPeriodsForNewUsersChart, specialPeriodTypes } from "./logic/getPeriodsForNewUsersChart";
import { getBonusesForChart } from "./logic/getBonusesForChart";
import find from "lodash/find";
import { segmentsPaths } from '../../../../commonStores/analytics/userInfoStore/logic/getSegmentPathById';
import { getMeausermentsForChart } from './logic/getMeausermentsForChart';

const ScreenHomeStoreContext = React.createContext({});
export const ScreenHomeStoreProvider = ScreenHomeStoreContext.Provider;
export const useScreenHomeStore = () => React.useContext(ScreenHomeStoreContext);

class ScreenHomeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }

  initProperties() {
    this.isFetching = true;
    this.periodOptions = getPeriodsForNewUsersChart();
    this.currentPeriodIndexNewUsers = 2;
    this.currentPeriodIndexAppOpen = 2;
    this.currentPeriodIndexActiveUsers = 4;
  }

  getScreenData = async (isWithSmallSpinner) => {
    this.setIsFetching(isWithSmallSpinner ? false : true);
    this.setIsFetchingForSmallSpinner(true);
    const segmentId = this.rootStore.userInfoStore.currentSegmentId;
    const periodNewUsers = this.periodOptions[this.currentPeriodIndexNewUsers].type;
    const periodAppOpen = this.periodOptions[this.currentPeriodIndexAppOpen].type;
    const periodActiveUsers = specialPeriodTypes.LAST_NINETY_DAYS;
    const {
      countNewUsersInSegment,
      totalCurrentSegmentMembers,
      sumOfCoinsOfCurrentSegmentUsers,
      referralLeaders,
      lastWeekNewUsers,
      activeUsersCountGraphData,
      numberOfUsersToDemandPayment,
      date,
      formattedAppOpenData,
      yesterdayGainedCoins,
      segmentImprovement
    } = await getScreenData(segmentId, periodNewUsers, periodAppOpen, periodActiveUsers);
    this.totalUsersToDemandPaymentClalit = numberOfUsersToDemandPayment;
    this.date = date;
    this.setCountNewUsersInSegment(countNewUsersInSegment);
    this.setActiveUsersCountGraphData(activeUsersCountGraphData)
    this.setTotalCurrentSegmentMembers(totalCurrentSegmentMembers);
    this.setSumOfCoinsOfCurrentSegmentUsers(sumOfCoinsOfCurrentSegmentUsers);
    this.setReferralLeaders(referralLeaders);
    this.setLastWeekNewUsers(lastWeekNewUsers);
    this.formattedAppOpenData = formattedAppOpenData;
    this.todayGainedCoins = yesterdayGainedCoins;
    this.segmentImprovement = segmentImprovement;
    this.setIsFetching(false);
    this.setIsFetchingForSmallSpinner(false);
  }

  getSegmentUsersUsingBonusesTotals = async () => {
    try {
      this.setIsFetchingBonusesTotals(true);
      const segmentId = this.selectedSegmentIdToShow || this.rootStore.userInfoStore.currentSegmentId;
      const segmentUsersUsingBonusesTotals = await getSegmentUsersUsingBonusesTotals(segmentId);
      this.segmentUsersUsingBonusesTotals = segmentUsersUsingBonusesTotals;
      this.setIsFetchingBonusesTotals(false);
    } catch (e) {
      this.setIsFetchingBonusesTotals(false);
    }
  }
  getSegmentUsersUsingMeasurementTotals = async () => {
    try {
      this.isFetchingMeasurementTotals = true;
      const segmentId = this.selectedSegmentIdToShow || this.rootStore.userInfoStore.currentSegmentId;
      const response = await getSegmentUsersUsingMeasurementTotalsServer(segmentId);
      this.segmentUsersUsingMeasurements = response && response.extra && response.extra.segmentUsers;
      this.isFetchingMeasurementTotals = false;
    } catch (e) {
      this.isFetchingMeasurementTotals = false;
    }
  }

  getAllScreenData = async () => {
    this.getScreenData();
    const segmentId = this.selectedSegmentIdToShow || this.rootStore.userInfoStore.currentSegmentId;
    const homePieChartFunction = segmentId != segmentsPaths.CLALIT.id ? this.getSegmentUsersUsingBonusesTotals : this.getSegmentUsersUsingMeasurementTotals;
    homePieChartFunction();
  }

  setActiveUsersCountGraphData(activeUsersCountGraphData) {
    this.activeUsersCountGraphData = activeUsersCountGraphData;
  }

  setCurrentPeriodIndexNewUsers(index) {
    this.currentPeriodIndexNewUsers = index;
    this.getScreenData(true);
  }

  setCurrentPeriodIndexAppOpen(index) {
    this.currentPeriodIndexAppOpen = index;
    this.getScreenData(true);
  }

  setcurrentPeriodIndexActiveUsers(index) {
    this.currentPeriodIndexActiveUsers = index;
    this.getScreenData(true);
  }

  setCountNewUsersInSegment(countNewUsersInSegment) {
    this.countNewUsersInSegment = countNewUsersInSegment;
  }

  setTotalCurrentSegmentMembers(totalCurrentSegmentMembers) {
    this.totalCurrentSegmentMembers = totalCurrentSegmentMembers;
  }

  setSumOfCoinsOfCurrentSegmentUsers(sumOfCoinsOfCurrentSegmentUsers) {
    this.sumOfCoinsOfCurrentSegmentUsers = sumOfCoinsOfCurrentSegmentUsers;
  }

  setReferralLeaders(referralLeaders) {
    this.referralLeaders = referralLeaders;
  }

  setLastWeekNewUsers(lastWeekNewUsers) {
    this.lastWeekNewUsers = lastWeekNewUsers;
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }


  setIsFetchingForSmallSpinner(isFetching) {
    this.isFetchingForSmallSpinner = isFetching;
  }

  setIsFetchingBonusesTotals(isFetching) {
    this.isFetchingBonusesTotals = isFetching;
  }

  setSelectedSegmentIdToShow(segmentId) {
    const currentSegment = this.rootStore.userInfoStore.currentSegmentId;
    currentSegment.segmentIds = segmentId ? [segmentId] : this.rootStore.userInfoStore.currentSegment.multiSegmentOptions.map(segment => segment.id);
    this.selectedSegmentIdToShow = segmentId;
  }

  get arrayOfBonusesForChart() {
    return getBonusesForChart(this);
  }

  get arrayOfMeausermentsForChart() {
    return getMeausermentsForChart(this);
  }

  get selectedSegmentName() {
    const { segmentName } = this.rootStore.userInfoStore.currentSegment;
    if (!this.selectedSegmentIdToShow) {
      return segmentName;
    }
    const { userInfoStore } = this.rootStore;
    const { currentSegment: currentSegmentStore } = userInfoStore;
    const segment = find(currentSegmentStore.multiSegmentOptions, item => item.id === this.selectedSegmentIdToShow);
    return segment.name;
  }
}

decorate(ScreenHomeStore, {
  isFetching: observable,
  isFetchingForSmallSpinner: observable,
  isFetchingSegmentImprovement: observable,
  isFetchingBonusesTotals: observable,
  isFetchingTodayGainedCoins: observable,
  isFetchingMeasurementTotals: observable,
  countNewUsersInSegment: observable,
  activeUsersCountGraphData: observable,
  totalCurrentSegmentMembers: observable,
  sumOfCoinsOfCurrentSegmentUsers: observable,
  segmentUsersUsingBonusesTotals: observable,
  todayGainedCoins: observable,
  segmentImprovement: observable,
  referralLeaders: observable,
  periodOptions: observable,
  currentPeriodIndexNewUsers: observable,
  currentPeriodIndexAppOpen: observable,
  currentPeriodIndexActiveUsers: observable,
  lastWeekNewUsers: observable,
  selectedSegmentIdToShow: observable,
  segmentUsersUsingMeasurements: observable,
  totalUsersToDemandPaymentClalit: observable,
  formattedAppOpenData: observable,
  date: observable,
  arrayOfBonusesForChart: computed,
  selectedSegmentName: computed,
  getScreenData: action.bound,
  getSegmentUsersUsingMeasurementTotals: action.bound,
  getSegmentUsersUsingBonusesTotals: action.bound,
  initProperties: action.bound,
  setCurrentPeriodIndexNewUsers: action.bound,
  setCurrentPeriodIndexAppOpen: action.bound,
  setcurrentPeriodIndexActiveUsers: action.bound,
  setCountNewUsersInSegment: action.bound,
  setActiveUsersCountGraphData: action.bound,
  setTotalCurrentSegmentMembers: action.bound,
  setIsFetchingForSmallSpinner: action.bound,
  setIsFetching: action.bound,
  setLastWeekNewUsers: action.bound,
  setReferralLeaders: action.bound,
  setSumOfCoinsOfCurrentSegmentUsers: action.bound,
  setSelectedSegmentIdToShow: action.bound,
  setIsFetchingBonusesTotals: action.bound,
  getAllScreenData: action.bound
});

export function createScreenHomeStore(rootStore) {
  const store = new ScreenHomeStore(rootStore);
  return store;
}
