import React from "react";
import {
  InputLabel,
  Select,
  makeStyles,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  category: {
    margin: theme.spacing(1),
    minWidth: 300,
    border: 1,
    borderColor: "black",
    marginBottom: 10,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const PrimaryCategorySelect = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();
  const { primaryCategory, categoriesList } = store;
  const categoriesListOptions = categoriesList.map(category=> {return(<MenuItem value={category} key={category.category_id}>{category.name}</MenuItem>)});

  let selectedCategory = categoriesList.filter(option => option.category_id === primaryCategory);
  
  const handleChange = (event) => {
    store.primaryCategory = event.target.value.category_id;
    selectedCategory = categoriesList.filter(option => option.category_id === primaryCategory);
    };

  return (
    <Grid item sm={4}>
      <InputLabel>
        {t("screenAddNewBusiness.PrimaryCategory") + " *"}
      </InputLabel>
      <Select
        value={selectedCategory[0] ? selectedCategory[0] : ""}
        onChange={handleChange}
        className={classes.category}
      >
      {categoriesListOptions}
      </Select>
    </Grid>
  );
});