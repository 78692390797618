import React, { Component } from "react";
import { inject } from "mobx-react";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router";
import { COLORS } from "common/ui/colors";

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit
  },
  button: {
    width: "100%",
    marginTop: "20px",
    backgroundColor: COLORS.OUTLINE_GRAY
  }
});

class LoginButtonComponent extends Component {
  onClick = () => {
    const { rootStore, location, history } = this.props;
    const { pathname } = location;
    rootStore.loginStore.preformLogin(pathname, history);
  };

  render() {
    const { classes } = this.props;
    return (
      <Button
        onClick={this.onClick}
        className={classes.button}
        variant="contained"
        color="primary"
      >
        התחבר
      </Button>
    );
  }
}

export const LoginButton = withRouter(
  withStyles(styles)(inject("rootStore")(LoginButtonComponent))
);
