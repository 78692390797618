import React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { FixedCodeInput } from "./FixedCodeInput";
import { CodeGenerationStrategyDropdown } from "../CodeGenerationStrategyDropdown";

export const FixedCode = observer(() => {
  return (
    <Grid>
      <CodeGenerationStrategyDropdown />
      <FixedCodeInput />
    </Grid>
  );
});
