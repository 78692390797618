import React from "react";
import { TableRow, TableCell } from "@material-ui/core";

export const TermsConfirmations = (props) => {
  const { columns, confirmationsData } = props;

  return (
    <TableRow>
      <TableCell>{columns.approvalPolicy}</TableCell>
      <TableCell>{confirmationsData.termsConfirmationDate}</TableCell>
    </TableRow>
  );
};
