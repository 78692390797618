import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Card } from './components/card';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router";
import { LoginButton } from './components/loginButton';
import { ErrorBox } from './components/errorBox';
import { RumbleLogo } from 'common/ui/logos';

class LoginComponent extends Component {

    onEmailChanged = (e) => {
        this.props.rootStore.loginStore.setEmail(e.target.value);
    }

    onPasswordChanged = (e) => {
        this.props.rootStore.loginStore.setPassword(e.target.value);
    }

    render() {
        const { rootStore } = this.props;
        const { email, password } = rootStore.loginStore;
        return <div style={{ textAlign: "center" }}>
                <RumbleLogo />
                <form action="" method="get">
                    <Card>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Typography>התחברות</Typography>
                            <TextField
                                id="outlined-email-input"
                                label="אימייל"
                                type="email"
                                name="email"
                                autoComplete="email"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                value={email}
                                onChange={this.onEmailChanged}
                            />

                            <TextField
                                fullWidth
                                id="outlined-password-input"
                                label="סיסמה"
                                type="password"
                                autoComplete="current-password"
                                margin="normal"
                                variant="outlined"
                                value={password}
                                onChange={this.onPasswordChanged}
                            />
                            <ErrorBox />
                            <LoginButton />
                        </Grid>
                    </Card>
                </form>
            </div>
    }
}

export const Login = withRouter(inject("rootStore")(observer(LoginComponent)));