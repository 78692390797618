import React, { PureComponent } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { CommonTable } from 'common/ui/commonTable.js';
import { parseISOString, format, DAY_TIME_FORMAT } from 'common/dateAndTime';

export class PurchasedCouponTable extends PureComponent {

    render() {
        const { purchasedCoupons } = this.props;

        const headerColumns = [
            { id: 'businessName', label: 'שם העסק' },
            { id: 'title', label: 'סוג ההטבה' },
            { id: 'createdAt', label: 'תאריך ושעה' },
            { id: 'branchName', label: 'סניף' }
        ];

        const renderRow = item => <TableRow hover>
            <TableCell>{item.businessName}</TableCell>
            <TableCell>{item.title}</TableCell>
            <TableCell>{format(parseISOString(item.createdAt), DAY_TIME_FORMAT)}</TableCell>
            <TableCell>{item.branchName}</TableCell>
        </TableRow>

        return <CommonTable
            defaultSortByColumn='createdAt'
            renderRow={renderRow}
            itemKey='couponId'
            items={purchasedCoupons}
            headerColumns={headerColumns} />
    }
}