import { NO_OPTION_SELECTED, MIN_YEAR } from "../consts";

export function decSelectedKeyYear(component) {
    if (component.selectedKeyYear === MIN_YEAR || component.selectedKeyYear === NO_OPTION_SELECTED) {
        return;
    }
    component.selectedKeyYear--;
    if (component.isYearOrMonthNull) {
        return;
    }
    component.updateStartEndDate();
}