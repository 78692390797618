

import React from "react";
import { observer } from "mobx-react";
import { ChangePeriodDropDown } from "./changePeriodDropDown";
import { DatePicker } from "./datePicker"
import { Grid } from "@material-ui/core";

export const PeriodButtons = observer(() => {
    return (
        <Grid  justify='flex-end'container spacing={2} >
            <Grid item>
                <DatePicker />
            </Grid>
            <Grid item>
                <ChangePeriodDropDown />
            </Grid>
        </Grid>
    );
});



