import React from 'react';
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from '../store/storeHealthAndFitnessDataStoreContext';

const useStyles = makeStyles(() => ({
    header: {
        fontSize: "18px",
        lineHeight: "18px",
        marginTop: 20,
        fontWeight: 'bold',
    },
}));

export const IdNumSearchHeader = observer(() => {
    const classes = useStyles();
    const store = useHealthAndFitnessDataStore();
    if (store.isFetching) {
        return null;
    }
    return (
        <Typography className={classes.header} component="h2" variant="overline" gutterBottom>
            {t("screenHealthAndFitnessData.search.label")}
        </Typography>
    );
});