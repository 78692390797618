import React, { PureComponent } from 'react';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import CaretRight from '@material-ui/icons/ChevronRight';
import CaretLeft from '@material-ui/icons/ChevronLeft';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { styles } from './styles';

class DropdownPrevNextComponent extends PureComponent {

    getMenuItem = () => {
        const { items } = this.props;
        return items.map(i => <MenuItem value={i.key} key={i.key}>{i.value}</MenuItem>)
    }

    render() {
        const { classes, onChange, selectedKey, onPressPrev, onPressNext } = this.props;
        return (
            <div>
                <IconButton onClick={onPressPrev} >
                    <CaretRight />
                </IconButton>
                <FormControl className={classes.formControl}>
                    <Select
                        value={selectedKey}
                        onChange={onChange}
                        input={<Input name="age" id="age-helper" />}
                    >
                        {this.getMenuItem()}
                    </Select>
                </FormControl>
                <IconButton onClick={onPressNext} aria-label="Next">
                    <CaretLeft />
                </IconButton>
            </div>
        );
    }
}

export const DropdownPrevNext = withStyles(styles)(DropdownPrevNextComponent);
