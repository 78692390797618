import React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import MUIDataTable from "mui-datatables";
import { isShowPickUpInfo } from "../store/logic/isShowPickUpInfo";
import { t } from "common/localization/translate";
import { useScreenPurchaseUsersInfosStore } from "../store";

export const PurchaseUsersInfoTable = observer(() => {
  const store = useScreenPurchaseUsersInfosStore();
  const { currentSegment } =  store.rootStore.userInfoStore || {};
  const { segmentId } = toJS(currentSegment) || {};
  const { purchasesUsersInfoTableData: data, isFetching, segmentCouponPurchasersInfo } = store;
  const isWithSegmentName = segmentCouponPurchasersInfo && segmentCouponPurchasersInfo.length > 0 ? segmentCouponPurchasersInfo[0].segmentName : null;
  const isShowCodeOrOrderIdCoupon = segmentCouponPurchasersInfo && segmentCouponPurchasersInfo.length > 0 ? segmentCouponPurchasersInfo[0].couponCode :null;
  const { title, options, columns } = getTableData(isFetching, isWithSegmentName, isShowCodeOrOrderIdCoupon, segmentId);

  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
    />
  );
});

const getTableData = (isFetching, isWithSegmentName, isShowCodeOrOrderIdCoupon, segmentId) => {
  const policeSegmentName = t("police.segment.name");
  const columns = [
    {
      name: "couponTitle",
      label: t("screenPurchaseUsersInfo.table.col.couponTitle"),
    },
    {
      name: "identifier",
      label: t("screenPurchaseUsersInfo.table.col.identifier"),
    },
    {
      name: "phone",
      label: t("screenPurchaseUsersInfo.table.col.phone"),
    },
    {
      name: "userName",
      label: t("screenPurchaseUsersInfo.table.col.userName"),
    },
    { name: "date", label: t("screenPurchaseUsersInfo.table.col.date") },
  ];
  if(isWithSegmentName || (segmentId && segmentId.name === policeSegmentName)){
      columns.push({ name: "segmentName", label: t("screenPurchaseUsersInfo.table.col.segmentName")});
  }

  if(isShowCodeOrOrderIdCoupon){
    columns.splice(1, 0, { name: "couponCodeTitle", label: t("screenPurchaseUsersInfo.table.col.couponCodeTitle")});
  }

  if(isShowPickUpInfo(segmentId)){
    columns.splice(1, 0, { name: "pickUpPoint", label: t("screenPurchaseUsersInfo.table.col.pickUpPoint")});
  }

  const options = {
    filter: false,
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    downloadOptions: { filename: "usersPurchases.csv" },
    print: false,
    viewColumns: false,
    responsive: "scrollMaxHeight",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: isFetching ? "...Loading" : "Sorry, no matching records found",
      },
    },
  };
  const title = t("screenPurchaseUsersInfo.table.title");
  return { title, options, columns };
};
