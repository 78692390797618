import React from "react";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { CategoryPositionCube } from "./categoryPositionCube";
import { ImpressionCube } from "./totalNumbersPreformence/impressionCube";
import { ClicksCube } from "./totalNumbersPreformence/clicksCube";
import { PurchasesCube } from "./totalNumbersPreformence/purchasesCube";


export const TotalNumbersPerformence = observer(() => {
    
    return (
        <Grid container spacing={3}>
            <CategoryPositionCube/>
            <ImpressionCube />
            <ClicksCube />
            <PurchasesCube />
        </Grid>

    );
});
