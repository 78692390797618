import { t } from "common/localization/translate";
import { deleteUserErrorCodes } from "./deleteUserServerErrorCodes ";

export function handleServerError(serverErrorCode) {
  switch (serverErrorCode) {
    case deleteUserErrorCodes.USER_NOT_FOUND:
      return t("deleteUser.UserIdNotExist");
    case deleteUserErrorCodes.USER_NOT_ALLOWED_TO_CHANGE:
      return t("deleteUser.UserUnauthorized");
    default:
      return t("deleteUser.generalServerError");
  }
};
