import AddIcon from '@material-ui/icons/Add';
import React from "react";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../../..";
import IconButton from '@material-ui/core/IconButton';
import { translateAnswerById } from 'screens/controlPanel/editArticle/store/server/translateAnswerById';

export const TranslateAnswerButton = observer(() => {
    const store = useEditArticleStore();
    const translateAnswer = () => {
        if(!store.selectedAnswer.translation_texts) {
            return;
        }
        translateAnswerById({answerId: store.selectedAnswer.answer_id, answerTranslation: store.selectedAnswer.translation_texts, language: store.selectedLanguage});
        store.selectedAnswer = "";
    }

    return(
        <IconButton color="primary" component="span">
          <AddIcon onClick={translateAnswer}/>
        </IconButton>
    )
});