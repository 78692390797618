import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditAuctionStore } from "screens/controlPanel/editAuction/store";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 18,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

export const StartAuctionDateEdit = observer(() => {
  const store = useEditAuctionStore();
  const classes = useStyles();

  const handleDateChange = (date) => {
    if(!date) {
      store.startAuctionDateEmptyError = t("addNewAuction.addNewAuctionStartDate");
      store.startAuctionDate = null;
      return;
    }
    store.startAuctionDateEmptyError = null;
    store.startAuctionDate = date;
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <InputLabel className={classes.label} shrink>
        {t("addNewAuction.addNewAuctionStartDate")+ " *"}
      </InputLabel>
      <KeyboardDateTimePicker
        ampm={false}
        value={store.startAuctionDate ? store.startAuctionDate : ""}
        onChange={(date) => handleDateChange(date)}
        format="HH:mm DD/MM/YYYY"
        invalidDateMessage={store.startAuctionDate ? true : false}
      />
    </Grid>
  );
});