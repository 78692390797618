import React from "react";
import { observer } from "mobx-react";
import { ThemeProvider as MaterialThemePovider } from "@material-ui/styles";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import getTheme from "./index";

const ThemeProvider = observer(props => {
  const rootStore = useRootStore();
  const { segmentThemeColor } = rootStore.userInfoStore;
  const theme = getTheme(segmentThemeColor);
  return (
    <MaterialThemePovider theme={theme}>{props.children}</MaterialThemePovider>
  );
});
export default ThemeProvider;
