import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { BusinessContactName } from "./components/BusinessContactName";
import { BusinessContactEmail } from "./components/BusinessContactEmail";
import { CustomerServicePhone } from "./components/CustomerServicePhone";
import { CustomerServiceEmail } from "./components/CustomerServiceEmail";
import { BusinessContactPhone } from "./components/BusinessContactPhone";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 10,
    textAlign: "center",
    flex: "auto",
    color: theme.palette.primary.main,
  },
  subtitle: {
    marginBottom: 10,
  },
  input: {
    marginBottom: 50,
  }
}));
export const ContactInfo = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.input}>
      <Typography className={classes.title} component="h1" variant="h2">
        {t("screenAddNewBusiness.ContactInformation")}
      </Typography>
      <Grid item xs={12}>
        <Typography className={classes.subtitle} component="h4" variant="h4">
          {t("screenAddNewBusiness.InternalContactInfo")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <BusinessContactName />
      </Grid>
      <Grid item xs={12}>
        <BusinessContactEmail />
      </Grid>
      <Grid item xs={12}>
        <BusinessContactPhone />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.subtitle} component="h4" variant="h4">
          {t("screenAddNewBusiness.ExternalContactInfo")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomerServicePhone />
      </Grid>
      <Grid item xs={12}>
        <CustomerServiceEmail />
      </Grid>
    </Grid>
  );
};