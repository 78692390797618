import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { observer } from "mobx-react-lite";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.white,
      fontSize: 16,
      fontFamily: "Assistant",
      fontWeight: "bold",
    },
  },
}));

export const EditCouponTypeErrorPopUp = observer(() => {
  const store = useEditCouponTypeStore();
  const classes = useStyles();

  const handleClose = () => {
    store.isErrorPopUpOpen = false;
  };

  return (
      <Dialog open={store.isErrorPopUpOpen}>
        <DialogTitle className={classes.title}>
          {t("screenAddNewBusiness.ErrorPopUpTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("editBusiness.editBusinessFailure")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t("suppliers.supplierHome.verifyCodesOkPopUp")}
          </Button>
        </DialogActions>
      </Dialog>
  );
});