import React from "react";
import { decorate, action, observable } from "mobx";
import { editBalanceById } from "./server/editBalanceById";
import { handleServerError } from "./logic/handleServerError";
import { t } from "common/localization/translate";

const EditBalance = React.createContext({});
export const EditBalanceStoreProvider = EditBalance.Provider;
export const useEditBalanceStore = () => React.useContext(EditBalance);

class EditBalanceStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }

  initProperties() {
    this.userIdForChange = null;
    this.amountCoinsForChange = null;
    this.textReasonForChange = null;
    this.popUpErrorContent = null;
    this.coinsBalanceEmptyError = t("editBalance.CoinsError");
    this.userIdEmptyError = t("editBalance.UserIdError");
    this.reasonEmptyError = t("editBalance.ReasonForChange");
  }

  async submitEditBalance() {
    this.isFetching = true;
    const res = await editBalanceById({
      userIdForChange: this.userIdForChange,
      amountCoinsForChange: this.amountCoinsForChange,
      textReasonForChange: this.textReasonForChange,
    });
    if (res.isSuccess) {
      this.isSuccessEditBalance = true;
    } else {
      this.popUpErrorContent = handleServerError(res.errorCode);
      this.isErrorPopUpOpen = true;
    }
    this.isFetching = false;
  }
}

decorate(EditBalanceStore, {
  userIdForChange: observable,
  amountCoinsForChange: observable,
  textReasonForChange: observable,
  isErrorPopUpOpen: observable,
  isSuccessEditBalance: observable,
  coinsBalanceEmptyError: observable,
  userIdEmptyError: observable,
  reasonEmptyError: observable,
  popUpErrorContent: observable,
  isFetching: observable,
  initProperties: action.bound,
});

export function createEditBalanceStore(rootStore) {
  const store = new EditBalanceStore(rootStore);
  return store;
}
