import React from "react";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/";
import { segmentIds } from "../../../../../../logic/segmentIds";

const useStyles = makeStyles(() => ({
  inputRoot: {
    width: 310,
    marginBottom: 10
  },
}));
export const SupplierIdSelect = observer(() => {
  const store = useAddNewCouponTypeStore();
  const classes = useStyles();

  if(!store.segments.includes(segmentIds.OVDIM_BARI_SEGMENT_ID)) {
    return null;
  }
  const handleChange = (event, value) => {
    store.isExistingCouponTypeGroup = null;
    store.couponTypesGroup = [];
    store.notSupprtSupplierDashboardErrorText = null;
    store.isSupplierDashboardCouponType = false;
    if (value) {
      store.supplierErrorText = null;
      store.supplierId = value.supplier_id;
      store.isSupplierDashboardCouponType = true;
      store.getCouponTypesGroup();
      return;
    }
    store.supplierId = null;
  };

  return (
    <div>
      <Autocomplete
        options={store.suppliersList}
        getOptionLabel={supplier => `${supplier.supplier_id} - ${supplier.name}`}
        onChange={handleChange}
        classes={classes}
        renderInput={(params) => (
        <TextField {...params} label={t("addNewCouponType.SupplierIdSelectLabel")} variant="outlined" />)} />
    </div>
  );
});
