import React from 'react';
import { Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
    divider: { background: '#00000025', height: 1.5 },
}));

export const InputsDivider = () => {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <Divider className={classes.divider} />
        </Grid>
    );
};