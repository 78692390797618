import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@material-ui/core";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
  avatar: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.dark
  },
  listItem: {
    flex: 1
  }
}));

const SingleRow = props => {
  const { place, isWithDivider, name, count } = props;
  const classes = useStyles();
  return (
    <ListItem divider={isWithDivider} key={name}  className={classes.listItem}>
      <ListItemAvatar>
        <Avatar className={classes.avatar} size="small">
          {place}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={name} />
      <Typography variant="subtitle2">
        {t("screenHome.topReferrals.addedNumber", { count })}
      </Typography>
    </ListItem>
  );
};

export default SingleRow;
