import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditAuctionStore } from "screens/controlPanel/editAuction/store";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const ExternalLinkUrlEdit = observer(() => {
  const classes = useStyles();
  const store = useEditAuctionStore();

  const handleInputChange = (event) => {
    store.externalLinkUrl = event.target.value;
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      label={t("addNewAuction.addNewAuctionExternalLinkUrl")}
      onChange={handleInputChange}
      className={classes.input}
      value={store.externalLinkUrl? store.externalLinkUrl : ""}
    />
  );
});