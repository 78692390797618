import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Page } from "components";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenSwitchBusinessStore, ScreenSwitchBusinessStoreProvider } from "./store/index";
import { t } from "common/localization/translate";
import { SwitchToBusinessButton } from "./store/components/switchToBusinessButton";
import { BusinessIdSelector } from "./store/components/businessIdSelect";
import { EmptyBusinessIdErrorPopUp } from "./store/components/EmptyBusinessIdErrorPopUp";
import { FailedToChangeBusinessErrorPopUp } from "./store/components/FailedToChangeBusinessErrorPopUp";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import { LoadingSpinner } from "common/ui/loadingSpinner";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    width: "100%",
    height: "100%",
  }
  
}));

export const SwitchBusiness = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenSwitchBusinessStore(rootStore));
  const classes = useStyles();
  const { isFetchingBusinesses } = store;

  if (!rootStore.userInfoStore.isShowSwitchBusinessScreen) {
    return (
      <Redirect to={{ pathname: `/${dashboardPaths.BUSINESS}/home` }} />
    );
  }
  
  return (
    <ScreenSwitchBusinessStoreProvider value={store}>
      <LoadingSpinner isFetching={isFetchingBusinesses} >
        <Page className={classes.root} title={t("switchBusiness.home.title")}>
            <BusinessIdSelector />
            <SwitchToBusinessButton />
            <EmptyBusinessIdErrorPopUp />
            <FailedToChangeBusinessErrorPopUp />
        </Page>
      </LoadingSpinner>
    </ScreenSwitchBusinessStoreProvider>
  )

});

