import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { DatePicker } from '@material-ui/pickers';
import { ChangeMonthPopUp } from './changeMonthPopUp';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme) => ({
    tooltip: {
        fontSize: 14,
    },
    toolTipIcon: {
        color: theme.palette.primary.main
    },
    label: {
        fontSize: 18,
        fontWeight: 'bold'
    },
}));

export const SelectDate = observer((props) => {
    const { store, isProjectManager } = props;
    const classes = useStyles();
    const defalutDate = moment().subtract(1, 'months').toDate();
    const maxDate = new Date(moment().subtract(1, 'month').endOf('month').toDate());
    const minDate = new Date(moment().subtract(1, 'years').startOf('month').toDate());


    const handleDateChange = (selectedDate) => {
        store.isValidOtpCode = false;
        if (selectedDate > maxDate || selectedDate < minDate) {
            store.month = defalutDate;
            store.changeMonthDecision = false;
            return;
        }
        if (moment(store.month).isSame(selectedDate, 'month')) {
            return;
        }
        if (!moment(defalutDate).isSame(selectedDate, 'month') && !moment(store.month).isSame(selectedDate, 'month') && !isProjectManager) {
            store.changeMonthPopUp = true;
            store.selectedDate = selectedDate.toDate();
            return;
        }
        store.changeMonthDecision = false;
        store.month = selectedDate.toDate();
    };

    return (
        <Grid item xs={12}>
            <ChangeMonthPopUp store={store} />
            <InputLabel className={classes.label} shrink>{t("suppliers.supplierHome.selectMonthAndYear")} </InputLabel>
            <DatePicker
                defaultValue={defalutDate}
                minDate={minDate}
                maxDate={maxDate}
                views={["year", "month"]}
                value={store.month || defalutDate}
                onChange={handleDateChange}
            />
        </Grid>
    )

});

