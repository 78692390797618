import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { useScreenCouponsStore } from "../../store/index";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";
import { getPopUpText } from '../../store/logic/getPopUpText';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.white,
      fontSize: 14,
    },
  },
  buttons: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
    color: theme.palette.white,
    marginLeft: 21,
    marginTop: 18,
    "&:hover": {
      backgroundColor: theme.palette.primary.secondGraphColor,
      color: theme.palette.white,
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

export const UnSuccessCancelCoupun = observer(() => {
  const store = useScreenCouponsStore();
  const {
    isExposeCouponPopUp,
    couponName,
    isSuccessCancelCoupon,
    deleteCouponErrorCode,
    setDeleteCouponErrorCode,
  } = store;
  const classes = useStyles();

  const handleClose = () => {
    setDeleteCouponErrorCode(null);
  };

  if (isSuccessCancelCoupon) {
    return;
  }

  const errorCode = store.deleteCouponErrorCode;
  const text = getPopUpText({couponName, errorCode});
  return (
    <div>
      <Dialog
        className={classes.dialog}
        open={deleteCouponErrorCode}
        onClose={isExposeCouponPopUp}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ width: 500, height: 180 }}>
          <DialogTitle className={classes.root} id="responsive-dialog-title">
            {t("screenCouponsUser.cancelCoupon")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {text}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttonsContainer}>
            <Button
              className={classes.buttons}
              size="small"
              type="submit"
              variant="contained"
              onClick={handleClose}
            >
              {t("screenCouponsUser.popUp.closeButton")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
});
