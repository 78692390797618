import React from "react";
import { observer } from "mobx-react";
import { AutoGenInput } from "./AutoGenInput";
import { InsertCodeInput } from "./InsertCodeInput";
import { externalCouponGenerationTypes } from "../../../../../../logic/externalCouponGenTypes";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";

export const ExternalCouponGenerationTypeSelect = observer(() => {
  const store = useAddNewCouponTypeStore();
  store.codeGenerationStrategyEmptyError=t("addNewCouponType.CodeGenerationStrategyLabel");
  switch (store.codesFromExternalCouponCodesInputType.value) {
    case externalCouponGenerationTypes[0].value:
      return <AutoGenInput />;
    case externalCouponGenerationTypes[1].value:
      return <InsertCodeInput />;
    case externalCouponGenerationTypes[2].value:
      store.codeGenerationStrategyEmptyError = null;
      return null;
    default:
      return null;
  }
});
