import React from "react";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { makeStyles } from "@material-ui/styles";
import { TextField } from "@material-ui/core";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  input: {
    "&::placeholder": {
      fontSize: 12,
    },
  },
}));

export const Tags = observer(() => {
  const classes = useStyles();
  const store = useScreenCreateAddNewBusinessStore();

  const handleInputChange = (event) => {
    if(!event.target.value){
      store.setTagsEmptyError(t("screenAddNewBusiness.TagsLabel"));
      return;
    }
    store.setTagsEmptyError(null);
    store.setBusinessTags(event.target.value);
  };

  return (
    <>
      <TextField
        required
        InputProps={{ classes: { input: classes.input } }}
        fullWidth
        variant="outlined"
        id="outlined-multiline-static"
        multiline
        rows={5}
        onChange={handleInputChange}
        label={t("screenAddNewBusiness.TagsLabel")}
        placeholder={t("screenAddNewBusiness.TagsPlaceholder")}
        className={classes.input}
      />
    </>
  );
});