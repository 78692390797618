import React from "react";
import { httpCall } from "common/httpCall";
import { decorate, action, observable } from "mobx";

const ScreenStaffInfoStoreContext = React.createContext({});
export const ScreenStaffInfosStoreProvider = ScreenStaffInfoStoreContext.Provider;
export const useScreenStaffInfoStore = () => React.useContext(ScreenStaffInfoStoreContext);

class ScreenStaffInfoStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  getScreenData = async () => {
    try {
      this.isFetching = true;
      this.staffList = null;
      const segmentId  = this.rootStore.userInfoStore.currentSegmentId;
      const res = await httpCall("organization/atidBariStaffInfo/getData", { segmentId });
      const { staffInfo: staffList , email }  = res;
      this.staffList = staffList;
      this.email = email;
      this.isFetching = false;
    } catch (e) {
      this.isFetching = false;
    }
  }

  sendExcelMail = async () => {
      const segmentId  = this.selectedSegmentIdToShow || this.rootStore.userInfoStore.currentSegmentId;
      httpCall("organization/atidBariStaffInfo/sendExcelDataByEmail", { segmentId });
      this.isSendExcelMailPopUpVisible = true;
  }
}

decorate(ScreenStaffInfoStore, {
  isFetching: observable,
  staffList: observable,
  isSendExcelMailPopUpVisible: observable,
  email: observable,
  initProperties: action.bound,
  getScreenData: action.bound
});

export function createScreenStaffInfoStore(rootStore) {
  const store = new ScreenStaffInfoStore(rootStore);
  return store;
}
