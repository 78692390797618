import React from "react";
import { decorate, action, observable } from "mobx";
import { sendEmailToSalesAboutPurchasingApackage as sendEmailToSalesAboutPurchasingApackageServer  } from './server/sendEmailToSalesAboutPurchasingApackage'

const ScreenPricingStoreContext = React.createContext({});
export const ScreenPricingStoreProvider = ScreenPricingStoreContext.Provider;
export const useScreenPricingStore = () =>
  React.useContext(ScreenPricingStoreContext);

class ScreenPricingStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  async sendEmailToSalesAboutPurchasingApackage(){
    this.setIsSendEmailToSales(false);
    const businessName = this.rootStore.userInfoStore.currentBusinessId.businessName;
    const businessId = this.rootStore.userInfoStore.currentBusinessId.businessId;
    const isSendEmailToSales = await sendEmailToSalesAboutPurchasingApackageServer({ businessName, businessId});
    this.setIsSendEmailToSales(isSendEmailToSales.isSuccess);
  }
  setIsSendEmailToSales(isSendEmailToSales){
    this.isSendEmailToSales = isSendEmailToSales; 
  }

}
decorate(ScreenPricingStore, {
  isSendEmailToSales: observable,
  sendEmailToSalesAboutPurchasingApackage: action.bound,
  setIsSendEmailToSales: action.bound
});

export function createScreenPricingStore(rootStore) {
  const store = new ScreenPricingStore(rootStore);
  return store;
}
