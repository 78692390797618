import React from "react";
import { TextField, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@material-ui/styles";
import { useScreenSendNotificationStore } from "../../../store/index";
import { getErrorTextByErrorCode } from "../../../store/logic/getErrorTextByErrorCode";

const useStyles = makeStyles(() => ({
  input: {
    width: "155px",
  },
}));

export const IdNumInput = observer(() => {
  const store = useScreenSendNotificationStore();
  const placeHolder = t("support.common.placeHolder");
  const inputProps = { maxLength: 10 };
  const classes = useStyles();
  const errorText = getErrorTextByErrorCode(store.errorCode);

  const handleInputChange = (event) => {
    const { value } = event.target;
    store.userIdNum = value;
  };
  const isError = store.errorCode ? true : false;

  return (
    <Grid item sm={4} lg={2} spacing={2}>
      <TextField
        className={classes.input}
        id="standard-basic"
        type="string"
        error={isError}
        placeholder={placeHolder}
        helperText={errorText}
        inputProps={inputProps}
        onChange={handleInputChange}
      />
    </Grid>
  );
});
