import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import { Grid, makeStyles, Checkbox } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  grid: {
    marginBottom: 20,
  }
}));

export const IsActiveCheckbox = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();

  const handleChange = (event) => {
    store.isActive = event.target.checked;
  };

  return (
    <Grid item xs={12} className={classes.grid}>
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            name="isActive"
          />
        }
        label={t("addNewCouponType.IsActive")}
      />
    </Grid>
  );
});
