import React from "react";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import Select from "react-select";
import { customStyles } from "./businessSelectStyles";
import { Grid, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const BusinessIdSelector = observer(() => {
  const store = useAddNewCouponTypeStore();
  const classes = useStyles();

  const handleChange = (event) => {
    if (!event.business_id) {
      store.businessIdEmptyError = t("addNewCouponType.BusinessIdSelect");
      return;
    }
    store.businessIdEmptyError = null;
    store.businessId = Number(event.business_id);
    store.getBusinessData();
  };

  return (
    <Grid className={classes.input}>
      <Select
        options={store.businessIdsList}
        getOptionLabel={(business) =>`${business.name} - ${business.business_id}`}
        onChange={handleChange}
        styles={customStyles}
        placeholder={t("addNewCouponType.BusinessIdSelect")}
      />
    </Grid>
  );
});
