import { decorate, action, flow, observable } from 'mobx';
import { getPurchases as getPurchasesServer } from './server/getPurchases';

export class StorePurchases {

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }

  initProperties() {
    this.purchasedCoupons = [];
  }

  getDataPurchases = flow(function* () {
    const data = yield getPurchasesServer();
    this.purchasedCoupons = data.purchasedCoupons;
  });
}

decorate(StorePurchases, {
  purchasedCoupons: observable,
  getDataPurchases: action.bound,
  initProperties: action.bound
});