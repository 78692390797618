import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditAuctionStore } from "screens/controlPanel/editAuction/store";
import { t } from "common/localization/translate";

export const IsSprintCheckboxEdit = observer(() => {
  const store = useEditAuctionStore();

  const handleChange = (event) => {
    store.isSprintAuction = event.target.checked;
    if(store.isSprintAuction === false){
      store.sprintStartDate = null;
    }
  };

  return (
    <FormControlLabel
      control={
      <Checkbox
      checked={store.isSprintAuction}
      onChange={handleChange} />}
      label={t("addNewAuction.addNewAuctionIsSprintCheckbox")}
    />
  );
});