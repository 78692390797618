import React from 'react';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/';
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from '../../../../..';
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom:10,
  }
}));

export const CommissionPercentage = observer(() => {
  const classes = useStyles();
  const store = useScreenCreateAddNewBusinessStore();
  const { commissionPercentError } = store;
  
  const handleInputChange = (event) => {
    if(event.target.value < 0 || event.target.value > 100) {
      store.setCommissionPercentError(true);
      return;
    };
    store.setCommissionPercentError(null);
    store.setCommissionPercent(Number(event.target.value));
  };

  return (
      <TextField
        variant="outlined"
        fullWidth
        label={t("screenAddNewBusiness.CommissionPercent")}
        type="number"
        name="Commission Percent"
        onChange={handleInputChange}
        onWheel={handleBlockScroll}
        className={classes.input}
        error = {commissionPercentError}
        helperText = {commissionPercentError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
      />
  );
});