import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenCreateBusinessDashboardUserStore, ScreenCreateBusinessDashboardUserStoreProvider } from "./store";
import { Page } from "components";
import { t } from "common/localization/translate";
import { BusinessesComboBox } from "./store/components/BusinessesComboBox";
import { HelperTexts } from "./store/components/helperTexts";
import { LoadingSpinner } from "common/ui/loadingSpinner";
import { Redirect } from "react-router-dom";
import { Form } from "./store/components/form";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
    height: "100%",
  }
}));

export const CreateBusinessDashboardUser = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenCreateBusinessDashboardUserStore(rootStore));
  const classes = useStyles();
  useEffect(() => { store.getScreenData();}, []);
  const { businessesData, isFetching } = store;
  const { userInfoStore } = useRootStore();
  const { isLoggedIn } = userInfoStore;

  if(isLoggedIn){
    return (
      <ScreenCreateBusinessDashboardUserStoreProvider value={store}>
        <Page className = {classes.root} title={t("screenBusinessDashboardUser.title")}>
        <BusinessesComboBox options = {businessesData} label= {t("screenBusinessDashboardUser.dropDownTitle")}/>
        <Form/>
        <LoadingSpinner isFetching={isFetching}>
        <HelperTexts/>
        </LoadingSpinner>
        </Page>
      </ScreenCreateBusinessDashboardUserStoreProvider>
    )
  } 
  
  return  (<Redirect to={{ pathname: "login" }} />);
});
