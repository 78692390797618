import React from "react";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const AutoGenInputCount = observer(() => {
  const store = useEditCouponTypeStore();
  const { codeGenerationParameters } = store;
  const classes = useStyles();

  const handleChange = (event) => {
    store.codeGenerationParameters = {...codeGenerationParameters, [event.target.name]: Number(event.target.value),
    };
  };

  return (
      <Grid item xs={12}>
        <TextField
          type="number"
          name="count"
        onChange={handleChange}
        onWheel={handleBlockScroll}
          label={t("addNewCouponType.ExternalCouponAmountInput")}
          className={classes.input}
        />
      </Grid>
  );
});
