import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../../store";
import { t } from "common/localization/translate";


const useStyles = makeStyles((theme) => ({
    buttons: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 14,
        color: theme.palette.white,
        marginRight: 5,
        "&:hover": {
          backgroundColor: theme.palette.primary.white,
        }
      },
}));

export const ValidateOtpButton = observer(() => {
    const store = useScreenHomeStore();
    const classes = useStyles();

    const validateOtpCode = () => {
        store.validateOtpCode();
    }

    return (
            <Grid  item  xs={12}  spacing={3}>
                    <Button className={classes.buttons} size="small" variant="contained" onClick={validateOtpCode} >{t("suppliers.supplierHome.verifyOtpCode")}</Button>
            </Grid>
    )

});

