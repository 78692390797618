import React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { IsChallengeCheckbox } from "./components/isChallengeCheckbox.js";
import { ChallengeTypeSelect } from "./components/challengeTypeSelect.js";

export const IsChallengeCoupon = observer(() => {
  const store = useEditCouponTypeStore();
  const { isChallenge } = store;

  if (isChallenge) {
    return (
      <Grid>
        <IsChallengeCheckbox value={isChallenge} />
        <ChallengeTypeSelect />
      </Grid>
    );
  }
  return <IsChallengeCheckbox value={isChallenge} />;
});