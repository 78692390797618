import React from "react";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../../index";
import { PurchaseCountLimitation } from "./PurchaseCountLimitation";
import { PurchaseCountInCalendarMonthLimitation } from "./PurchaseCountInCalendarMonthLimitation";
import { limitationTypes } from "screens/controlPanel/addNewBuisness/store/components/form/components/AccountInformation/components/limitationSelect/limitationTypes";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const LimitationInput = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();

  switch (store.limitationType) {
    case limitationTypes[0].type:
      return (
      <PurchaseCountLimitation className={classes.input}/>
      );

    case limitationTypes[1].type:
      return (
      <PurchaseCountInCalendarMonthLimitation className={classes.input}/>
      );

    default:
      return null;
  }
});