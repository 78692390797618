import React from "react";
import { decorate, action, observable, computed } from "mobx";
import { getScreenData as getScreenDataServer } from "./server/getScreenData";
import { getSubSegmentUsersTableData as getSubSegmentUsersTableDataLogic } from "./logic/getSubSegmentUsersTableData";

const ScreenSubSegmentUsersStoreContext = React.createContext({});
export const ScreenSubSegmentUserssStoreProvider =
  ScreenSubSegmentUsersStoreContext.Provider;
export const useScreenSubSegmentUsersStore = () =>
  React.useContext(ScreenSubSegmentUsersStoreContext);

class ScreenSubSegmentUsersStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }

  initProperties() {
    this.isFetching = true;
  }

  getScreenData = async () => {
    this.setIsFetching(true);
    const segmentId = this.rootStore.userInfoStore.currentSegment
      ? this.rootStore.userInfoStore.currentSegment.segmentId
      : null;
    const subSegmentUsersList = await getScreenDataServer(segmentId);
    this.setSubSegmentUsersList(subSegmentUsersList);
    this.setIsFetching(false);
  };

  setSubSegmentUsersList(subSegmentUsersList) {
    this.subSegmentUsersList = subSegmentUsersList;
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }

  get subSegmentUsersTableData() {
    return getSubSegmentUsersTableDataLogic(this);
  }
}

decorate(ScreenSubSegmentUsersStore, {
  allowToAccessTosubSegmentUsers:observable,
  isFetching: observable,
  subSegmentUsersList: observable,
  subSegmentUsersTableData: computed,
  initProperties: action.bound,
  getScreenData: action.bound,
  setSubSegmentUsersList: action.bound,
  setIsFetching: action.bound,
});

export function createScreenSubSegmentUsersStore(rootStore) {
  const store = new ScreenSubSegmentUsersStore(rootStore);
  return store;
}
