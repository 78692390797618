import React from "react";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { Sizes } from "./sizes";
import { Colors } from "./colors";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";

const ACTIVATION_TYPE_DELIVERY = 5;
const ACTIVATION_TYPE_PICK_UP = 6;

export const SizeAndColor = observer(() => {
  const store = useAddNewCouponTypeStore();

  if (store.activationType != ACTIVATION_TYPE_DELIVERY && store.activationType != ACTIVATION_TYPE_PICK_UP) {
    return null;
  }
  return (
    <Grid item xs={12}>
      <Sizes />
      <Colors />
    </Grid>
  );
});
