import React from "react";
import { decorate, action, observable } from "mobx";
import { t } from "common/localization/translate";
import { limitationTypes } from "../../addNewBuisness/store/components/form/components/AccountInformation/components/limitationSelect/limitationTypes";
import { getBusinessIdsList } from "./server/getBusinessIdsList";
import { getDescriptiveTagsCategories } from "../../addNewBuisness/store/server/getDescriptiveTagsCategories";
import { getDescriptiveTagsByCategory } from "../../addNewBuisness/store/server/getDescriptiveTagsByCategory";
import { getBusinessById } from "./server/getBusinessById";
import { getCategories } from "../../addNewBuisness/store/server/getCategories";
import { getSegments } from "../../addNewBuisness/store/server/getSegments";
import { editBusiness } from "./server/editBusiness";
import { getSubCategories } from "./server/getSubcategories";
import { getBranchesByBusinessId } from "./server/getBranchesByBusinessId";
import { editBusinessErrorCodes } from "./server/editBusinessErrorCodes";

const EditBusiness = React.createContext({});

export const EditBusinessStoreProvider = EditBusiness.Provider;

export const useEditBusinessStore = () => React.useContext(EditBusiness);

class EditBusinessStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  };

  initProperties() {
    this.primaryCategory = [];
    this.categoriesList = [];
    this.descriptiveTagsCategoriesList = [];
    this.descriptiveTagsByCategoryList = [];
    this.businessIdEmptyError = t("addNewCouponType.BusinessIdSelect");
    this.isFetching = false;
    this.isNegNumberErrorPopUpOpen = false;
    this.isEmptyFieldErrorPopUpOpen = false;
    this.isLocationFailedErrorPopUpOpen = false;
    this.isEditBusinessSuccessPopUpOpen = false;
    this.isEditBusinessFailedPopUpOpen = false;
    this.editBusinessStatus = false;
    this.failedLocations = [];
    this.getBusinessIdsList();
    this.getSegmentsList();
    this.getCategoriesList();
    this.getDescriptiveTagsCategoriesList();
  };

  async getBusinessIdsList() {
    this.businessIdsList = await getBusinessIdsList();
  };

  async getSegmentsList() {
    this.segmentsList = await getSegments();
  };

  async getBusinessById() {
    this.businessData = await getBusinessById(this.businessId);
    this.subCategories = await getSubCategories(this.businessId);
    this.branches = await getBranchesByBusinessId({businessId: this.businessId})
    this.commission = this.businessData.commission;
    this.commissionPercent = this.businessData.commission_percent;
    this.contactEmail = this.businessData.contact_email;
    this.contactName = this.businessData.contact_name;
    this.contactPhone = this.businessData.contact_phone;
    this.customerServiceEmail = this.businessData.customer_service_email;
    this.customerServicePhone = this.businessData.customer_service_phone;
    this.businessDescription = this.businessData.desc_long;
    this.subtitle = this.businessData.description;
    this.businessFacebook = this.businessData.facebook_url;
    this.businessInstagram = this.businessData.instagram_url;
    this.healthScore = this.businessData.health_score;
    this.businessHours = this.businessData.hours;
    this.isLocal = this.businessData.attributes.isLocal;
    this.isUnhealthy = this.businessData.attributes.isUnhealthy;
    this.startWorkingWithBusinessDate = this.businessData.attributes.startWorkingWithBusinessDate;
    this.isKosher = this.businessData.is_kosher;
    this.isActive = this.businessData.is_active;
    this.isOnline = this.businessData.is_online;
    this.isEmailAtLike = this.businessData.is_send_email_at_like;
    this.isEmailAtNotification = this.businessData.is_send_email_at_notification;
    this.isEmailAtPurchase = this.businessData.is_send_email_at_purchase;
    this.isVeg = this.businessData.is_vegetarian;
    this.businessName = this.businessData.name;
    this.businessPhone = this.businessData.phone;
    this.retainer = this.businessData.retainer ? this.businessData.retainer.toString() : "0";
    this.score = this.businessData.score;
    this.businessWebsite = this.businessData.site_url;
    this.businessTags = this.businessData.tags;
    this.businessSegments = this.businessData.segments;
    this.currentBusinessLogoUrl = this.businessData.image_url;
    this.businessCreatedAt = this.businessData.created_at;
    this.businessUuid = this.businessData.uuid;
    this.primaryCategory = this.businessData.primary_category_id;
    this.limitationType = this.businessData.limitations ? this.businessData.limitations[0].type : limitationTypes[3].type;
    this.limitationParams = this.businessData.limitations ? this.businessData.limitations[0].parameters : null;
    this.descriptiveTags = this.businessData.descriptive_tags;
  };

  async getCategoriesList() {
    this.categoriesList = await getCategories();;
  };

  async getSubcategories() {
    this.subCategories = await getSubCategories(this.businessId);
  };

  async getDescriptiveTagsCategoriesList() {
    const tagsCategories = await getDescriptiveTagsCategories();
    tagsCategories.forEach((item, i) => { item.id = i + 1 });
    this.descriptiveTagsCategoriesList = tagsCategories;
  };
  
  async getDescriptiveTagsByCategoryList() {
    const descriptiveTags = await getDescriptiveTagsByCategory({ categoryTags: this.descriptiveTagsCategory });
    this.descriptiveTagsByCategoryList = descriptiveTags[0].descriptive_tags;
  };
  
  checkAndResetRequiredFields() {
    if(!this.businessName || !this.businessDescription || !this.primaryCategory || !this.businessSegments || !this.businessTags || !this.descriptiveTags || !this.contactEmail || !this.contactName || !this.contactPhone ) {
      this.businessName = this.businessData.name;
      this.businessDescription = this.businessData.desc_long;
      this.primaryCategory = this.businessData.primary_category_id;
      this.businessSegments = this.businessData.segments;
      this.businessTags = this.businessData.tags;
      this.descriptiveTags = this.businessData.descriptive_tags;
      this.contactEmail = this.businessData.contact_email;
      this.contactName = this.businessData.contact_name;
      this.contactPhone = this.businessData.contact_phone;
    }
  }
  
  async submitEditBusiness() {
    this.isFetching = true;

    const res = await editBusiness({ businessId: this.businessId, businessName: this.businessName, subtitle: this.subtitle, description: this.businessDescription, 
      primaryCategory: this.primaryCategory, businessHours: this.businessHours, businessSegments: this.businessSegments, 
      businessTags: this.businessTags, descriptiveTags: this.descriptiveTags, businessFacebook: this.businessFacebook, 
      businessInstagram:this.businessInstagram, businessWebsite: this.businessWebsite, contactName: this.contactName, 
      contactEmail: this.contactEmail, contactPhone: this.contactPhone, customerServiceEmail: this.customerServiceEmail, businessPhone: this.businessPhone, retainer: this.retainer, 
      score: this.score, healthScore: this.healthScore, commission: this.commission, commissionPercent: this.commissionPercent,
      isEmailAtLike: this.isEmailAtLike, isEmailAtNotification: this.isEmailAtNotification, isEmailAtPurchase: this.isEmailAtPurchase, isOnline: this.isOnline, isVeg: this.isVeg,
      isLocal: this.isLocal, isKosher: this.isKosher, isActive: this.isActive, isUnhealthy: this.isUnhealthy, subCategories: this.subCategories, subCategoriesToDelete: this.subCategoriesToDelete, subCategoriesToAdd: this.subCategoriesToAdd,
      startWorkingWithBusinessDate: this.startWorkingWithBusinessDate, branchesToDelete: this.branchesToDelete, branchesToAdd: this.branchesToAdd, currentBusinessLogoUrl: this.currentBusinessLogoUrl, newBusinessLogo: this.newBusinessLogo, limitationType: this.limitationType, limitationParams: this.limitationParams
    });
    this.errorCode = res.errorCode;
    this.editBusinessStatus = res.isSuccess;
    this.isFetching = false;

    !this.editBusinessStatus && this.errorCode === editBusinessErrorCodes.ERROR_TYPE_LOCATION_FAILED ? 
    this.failedLocations = res.extra.map((location => location.locationText)) : this.failedLocations = [];
  };
};


decorate(EditBusinessStore, {
  editBusinessStatus: observable,
  failedLocations: observable,
  isEditBusinessSuccessPopUpOpen: observable,
  isEditBusinessFailedPopUpOpen: observable,
  isLocationFailedErrorPopUpOpen: observable,
  isEmptyFieldErrorPopUpOpen: observable,
  segmentsList: observable,
  branches: observable,
  branchesToDelete: observable,
  branchesToAdd: observable,
  primaryCategory: observable,
  categoriesList: observable,
  businessId: observable,
  commission: observable,
  commissionError: observable,
  commissionPercent: observable,
  contactEmail: observable,
  contactName: observable,
  contactPhone: observable,
  customerServiceEmail: observable,
  customerServicePhone: observable,
  businessDescription: observable,
  subtitle: observable,
  businessFacebook: observable,
  businessInstagram: observable,
  businessHours: observable,
  isLocal: observable,
  isUnhealthy: observable,
  startWorkingWithBusinessDate: observable,
  isKosher: observable,
  isActive: observable,
  isEmailAtLike: observable,
  isEmailAtPurchase: observable,
  isEmailAtNotification: observable,
  isVeg: observable,
  businessLocation: observable,
  businessName: observable,
  businessPhone: observable,
  businessWebsite: observable,
  businessTags: observable,
  businessSegments: observable,
  retainer: observable,
  retainerError: observable,
  newBusinessLogo: observable,
  currentBusinessLogoUrl: observable,
  businessCreatedAt: observable,
  businessUuid: observable,
  healthScore: observable,
  score: observable,
  isOnline: observable,
  healthScoreError: observable,
  scoreError: observable,
  businessIdEmptyError: observable,
  limitationType: observable,
  limitationParams: observable,
  limitationTimeError: observable,
  limitationCountError: observable,
  descriptiveTagsCategory: observable,
  descriptiveTags: observable,
  descriptiveTagsCategoriesList: observable,
  descriptiveTagsByCategoryList: observable,
  coinsError: observable,
  priceError: observable,
  errorCode: observable,
  isNegNumberErrorPopUpOpen: observable,
  isSupplierDashboardCouponType : observable,
  couponCreationStatus: observable,
  businessIdsList: observable,
  couponTypesList: observable,
  isFetching: observable,
  couponTypeId: observable,
  businessData: observable,
  subCategories: observable,
  subCategoriesToDelete: observable,
  subCategoriesToAdd: observable,
  contactNameEmptyError: observable,
  tagsEmptyError: observable,
  segmentsEmptyError: observable,
  primaryCategoryEmptyError: observable,
  descriptiveTagsEmptyError: observable,
  contactEmailEmptyError: observable,
  scoreEmptyError: observable,
  businessDescriptionEmptyError: observable,
  businessLocationEmptyError: observable,
  businessLogoEmptyError: observable,
  businessNameEmptyError: observable,
  contactPhoneEmptyError: observable,
  initProperties: action.bound,
});

export function createEditBusinessStore(rootStore) {
  const store = new EditBusinessStore(rootStore);
  return store;
};