import React, { useState } from "react";
import { Form } from "./store/components/form";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { observer } from "mobx-react";
import {createScreenAddNewBusinessStore, ScreenCreateAddNewBusinessStoreProvider} from "./store/index";

export const CreateAddNewBusiness = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenAddNewBusinessStore(rootStore));
  const { userInfoStore } = useRootStore();
  const { isLoggedIn } = userInfoStore;

  if (isLoggedIn) {
    return (
      <ScreenCreateAddNewBusinessStoreProvider value={store}>
        <Form store={store} />
      </ScreenCreateAddNewBusinessStoreProvider>
    );
  }
});