import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
    textAlign: "center",
    flex: "auto",
  },

}));

export const BusinessId = observer(() => {
  const store = useEditBusinessStore();
  const classes = useStyles();

  return (
      <TextField
        disabled
        variant="outlined"
        label={t("editBusiness.businessId")}
        fullWidth
        value={store.businessId ? store.businessId : ""}
        className={classes.input}
      />
  );
});
