import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Page } from "components";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenLoginStore, ScreenLoginStoreProvider } from "./store";
import { Card, CardContent, Typography } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { LoginForm } from "./components";
import ErrorText from "./components/errorText";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(6, 2),
    backgroundColor: theme.palette.white
  },
  card: {
    width: 400,
    maxWidth: "100%",
    overflow: "unset",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%"
    }
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4)
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32
  },
  loginForm: {
    marginTop: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex"
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

export const Login = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenLoginStore(rootStore));
  const classes = useStyles();
  return (
    <ScreenLoginStoreProvider value={store}>
      <Page className={classes.root} title="Login">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <LockIcon className={classes.icon} />
            <Typography gutterBottom variant="h3">
              התחברות
            </Typography>
            <Typography variant="subtitle2">
              הזן אימייל וסיסמה כדי להתחבר
            </Typography>
            <LoginForm className={classes.loginForm} />
            <ErrorText />
          </CardContent>
        </Card>
      </Page>
    </ScreenLoginStoreProvider>
  );
});
