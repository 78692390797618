import React from "react";
import { InputLabel, Select, makeStyles, MenuItem, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import { paymentMethods } from "../logic/paymentMethods";

const useStyles = makeStyles((theme) => ({
    category: { margin: theme.spacing(1), minWidth: 300, border: 1, borderColor: "black" },
    selectEmpty: { marginBottom: 10, marginTop: 10 }
}));

export const PaymentOptionSelect = observer(() => {
    const store = useAddNewCouponTypeStore();
    const classes = useStyles();
    const { selectedPaymentMethod } = store;

    const paymentOptions = Object.values(paymentMethods).map(m => <MenuItem key={m.label} value={m.value}>{m.label}</MenuItem>);

    const handleChange = ({ target }) => {
        if(!target.value) {
            store.selectedPaymentMethodEmptyError = t("addNewCouponType.paymentOptionSelect.emptyError");
            return;
          }
        store.selectedPaymentMethodEmptyError = null;
        store.selectedPaymentMethod = target.value;
    };

    return (
        <Grid className={classes.selectEmpty} item xs={9}>
            <InputLabel>{t("addNewCouponType.choosePaymentMethod")}</InputLabel>
            <Select
                onChange={handleChange}
                className={classes.category}
                value={selectedPaymentMethod || ""}
            >
                {paymentOptions}
            </Select>
        </Grid>
    );
});
