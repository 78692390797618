import 'date-fns';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate, action, observable, computed } from 'mobx';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import heLocale from 'date-fns/locale/he';
import { withStyles } from '@material-ui/core';
import { styles } from 'common/styles';
import { datePickerStyles } from './styles/styles';
import { DividerLine } from './components/dividerLine';
import { Root } from './components/root';
import { DropdownPrevNext } from './components/dropdownPrevNext';
import { YEARS, MONTHS, NO_OPTION_SELECTED } from './consts';
import { setSelectedKeyYear } from "./logic/setSelectedKeyYear";
import { setSelectedKeyMonth } from "./logic/setSelectedKeyMonth";
import { initPickerStartEndAt } from "./logic/initPickerStartEndAt";
import { resetSelectedYearAndMonth } from './logic/resetSelectedYearAndMonth';
import { updateSelectedYearAndMonth } from './logic/updateSelectedYearAndMonth';
import { onChangeStartAt } from './logic/onChangeStartAt';
import { onChangeEndAt } from './logic/onChangeEndAt';
import { onChangeSelectedKeyYear } from './logic/onChangeSelectedKeyYear';
import { onChangeSelectedKeyMonth } from './logic/onChangeSelectedKeyMonth';
import { incSelectedKeyYear } from './logic/incSelectedKeyYear';
import { decSelectedKeyYear } from './logic/doSelectedKeyYear';
import { incSelectedKeyMonth } from './logic/incSelectedKeyMonth';
import { decSelectedKeyMonth } from './logic/decSelectedKeyMonth';
import { updateStartEndDate } from './logic/updateStartEndDate';
import { isYearOrMonthNull } from './logic/isYearOrMonthNull';


class DatesPickerComponent extends Component {

    startAt;
    endAt;
    selectedKeyYear;
    selectedKeyMonth;

    constructor(props) {
        const { startAt, endAt } = props;
        super();
        this.initPickerStartEndAt(startAt, endAt);
        this.setSelectedKeyYear(NO_OPTION_SELECTED);
        this.setSelectedKeyMonth(NO_OPTION_SELECTED);
    }
    setSelectedKeyYear(year) {
        setSelectedKeyYear(year, this);
    }

    setSelectedKeyMonth(month) {
        setSelectedKeyMonth(month, this);
    }

    onChangeStartAt(startDate) {
        onChangeStartAt(startDate, this);
    }

    onChangeEndAt(endDate) {
        onChangeEndAt(endDate, this);
    }

    onChangeSelectedKeyYear(event) {
        onChangeSelectedKeyYear(event, this);
    }

    onChangeSelectedKeyMonth(event) {
        onChangeSelectedKeyMonth(event, this);
    }

    incSelectedKeyYear() {
        incSelectedKeyYear(this);
    }

    decSelectedKeyYear() {
        decSelectedKeyYear(this);
    }

    incSelectedKeyMonth() {
        incSelectedKeyMonth(this);
    }

    decSelectedKeyMonth() {
        decSelectedKeyMonth(this);
    }

    get isYearOrMonthNull() {
        return isYearOrMonthNull(this);
    }

    updateStartEndDate() {
        updateStartEndDate(this);
    }

    updateSelectedYearAndMonth() {
        updateSelectedYearAndMonth(this);
    }

    resetSelectedYearAndMonth() {
        resetSelectedYearAndMonth(this);

    }

    initPickerStartEndAt(startAt, endAt) {
        initPickerStartEndAt(startAt, endAt, this);
    }

    render() {
        const { classes } = this.props;

        return (
            <Paper className={classes.paper}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={heLocale}>
                    <Grid container className={classes.grid}>
                        <DatePicker
                            autoOk
                            margin="normal"
                            label="תאריך התחלה"
                            value={this.startAt}
                            onChange={this.onChangeStartAt}
                        />
                        <DatePicker
                            autoOk
                            margin="normal"
                            label="תאריך סיום"
                            value={this.endAt}
                            onChange={this.onChangeEndAt}
                            className={classes.endDatePicker}
                        />
                        <DividerLine />
                        <Root>
                            <DropdownPrevNext items={YEARS} onChange={this.onChangeSelectedKeyYear} selectedKey={this.selectedKeyYear} onPressPrev={this.decSelectedKeyYear} onPressNext={this.incSelectedKeyYear} />
                            <DropdownPrevNext items={MONTHS} onChange={this.onChangeSelectedKeyMonth} selectedKey={this.selectedKeyMonth} onPressPrev={this.decSelectedKeyMonth} onPressNext={this.incSelectedKeyMonth} />
                        </Root>
                    </Grid>
                </MuiPickersUtilsProvider>
            </Paper>
        );
    }
}

decorate(DatesPickerComponent, {
    startAt: observable,
    endAt: observable,
    selectedKeyYear: observable,
    selectedKeyMonth: observable,
    onChangeSelectedKeyYear: action.bound,
    onChangeSelectedKeyMonth: action.bound,
    incSelectedKeyMonth: action.bound,
    decSelectedKeyMonth: action.bound,
    incSelectedKeyYear: action.bound,
    decSelectedKeyYear: action.bound,
    resetSelectedYearAndMonth: action.bound,
    updateSelectedYearAndMonth: action.bound,
    updateStartEndDate: action.bound,
    onChangeStartAt: action.bound,
    onChangeEndAt: action.bound,
    setSelectedKeyYear: action.bound,
    setSelectedKeyMonth: action.bound,
    initPickerStartEndAt: action.bound,
    isYearOrMonthNull: computed
});

export const DatesPicker = withStyles({ ...styles, ...datePickerStyles })(observer(DatesPickerComponent));

