import React from "react";
import { observer } from "mobx-react";
import { Grid, TextField } from "@material-ui/core";
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from "screens/organization/healthAndFitnessData/store/storeHealthAndFitnessDataStoreContext";

export const UserName = observer(() => {
    const store = useHealthAndFitnessDataStore();

    return (
        <Grid item md={6} xs={12}>
            <TextField
                fullWidth
                label={t("screenHealthAndFitnessData.personalDetails.name")}
                name={"userName"}
                value={store.userName}
                variant="outlined"
                disabled
            />
        </Grid>
    );
});