import React from "react";
import { observer } from "mobx-react";
import { AutoGenInputLength } from "./AutGenInputLength";
import { AutoGenInputCount } from "./AutoGenInputCount";
import { Grid } from "@material-ui/core";

export const AutoGenInput = observer(() => {
  return (
    <Grid>
      <AutoGenInputLength />
      <AutoGenInputCount />
    </Grid>
  );
});
