import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";

export const IsCreditCheckbox = observer((props) => {
  const store = useAddNewCouponTypeStore();

  const handleChange = (event) => {
    store.isCreditRequired = event.target.checked;
    store.priceInCurrency = null;
  };

  return (
      <FormControlLabel
        control={
          <Checkbox
            checked={props.value}
            onChange={handleChange}
            name="isCreditRequired"
          />
        }
        label={t("addNewCouponType.isCreditRequired")}
      />
  );
});
