import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { CouponDescription } from "../CouponDescription";
import { TranslateDescription } from "./translateDescription";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    width: "100%"
  },
}));

export const CouponDescriptionWithTranslate = observer(() => {
  const classes = useStyles();

  return (
    <Grid className={classes.paper}>
      <CouponDescription />
      <TranslateDescription />
    </Grid>
  );
});