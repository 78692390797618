import React from "react";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from 'screens/controlPanel/addCouponType/store';
import { makeStyles } from "@material-ui/styles";
import { TextField } from "@material-ui/core";
import { t } from "common/localization/translate";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  input: {
    "&::placeholder": {
      fontSize: 12,
    },
  },
  grid: {
    marginBottom: 20,
  }
  
}));

export const Sizes = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();

  const handleInputChange = (event) => {
    store.sizes = event.target.value;
  };

  return (
    <Grid item xs={12} className={classes.grid}>
      <TextField
        InputProps={{classes: {input: classes.input}}}    
        fullWidth
        variant="outlined"
        multiline
        rows={5}
        value={store.sizes || ""}
        onChange={handleInputChange}
        label={t("screenAddNewBusiness.sizes")}
        placeholder={t("screenAddNewBusiness.SizesPlaceholder")}
        className={classes.input}
      />
    </Grid>
  );
});
