import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { ImageTooLargeMessage } from "./ImageTooLargeMessage";
import { t } from "common/localization/translate";
import { createEncodedLogo } from '../createEncodedLogo';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
  },
}));

export const BusinessLogo = observer(() =>{
  const classes = useStyles();
  const store = useScreenCreateAddNewBusinessStore();
  const maxImageSize = 45000;
  const [isImageTooLarge, setIsImageTooLarge] = useState(false);

  const handleInputChange = async (event) => {
    if (!event.target.files[0]) {
      store.setBusinessLogoEmptyError(t("screenAddNewBusiness.BusinessLogo"));
      return;
    } else if (event.target.files[0].size > maxImageSize) {
      setIsImageTooLarge(true);
      return;
    }
    setIsImageTooLarge(false);
    store.setBusinessLogoEmptyError(null)
    const businessLogo = await createEncodedLogo(event.target.files[0]);
    store.setBusinessLogo(businessLogo);
}

  return (
    <div>
      <label className={classes.label}>
        {t("screenAddNewBusiness.BusinessLogo") + " *"}
      </label>
      <br />
      <input type="file" id="businesslogo" onChange={handleInputChange} />
      <ImageTooLargeMessage isImageTooLarge={isImageTooLarge} />
    </div>
  );
});
