import React from "react";
import { InputLabel, Select, makeStyles, MenuItem, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { limitationTypes } from "screens/controlPanel/addCouponType/store/logic/limitationTypes";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  category: {
    minWidth: 300,
    border: 1,
    borderColor: "black",
  },
  input: {
    marginBottom: 10
  }
}));

export const LimitationTypeSelect = observer(() => {
  const store = useEditCouponTypeStore();
  const classes = useStyles();

  let selectedLimitationType = limitationTypes.filter(limitationType => limitationType.type === store.limitationType.type);
  const limitationTypeOptions = limitationTypes.map((limitationType) => {return (<MenuItem key={limitationType.type} value={limitationType}>{limitationType.name}</MenuItem>)});

  const handleChange = (event) => {
    store.limitationType = event.target.value;
    store.limitationParams = {};
  };

  return (
    <Grid item sm={4} className={classes.input}>
      <InputLabel>
        {t("screenAddNewBusiness.ChooseLimitationType")}
      </InputLabel>
      <Select
        onChange={handleChange}
        className={classes.category}
        value={selectedLimitationType[0] || ""}
        renderValue={selected=>selected.name}
      >
       {limitationTypeOptions}
      </Select>
    </Grid>
  );
});
