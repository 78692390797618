import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { ArticleSubTitle } from "../hebrewComponents/articleSubtitle";
import { TranslatedSubTitle } from "./translatedSubtitle";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
  },
}));

export const ArticleSubTitleTranslate = observer(() => {
  const classes = useStyles();

  return (
    <Grid className={classes.paper}>
      <ArticleSubTitle />
      <TranslatedSubTitle />
    </Grid>
  );
});