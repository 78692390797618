import AuthLayout from "../layouts/Auth";
import { Home } from "screens/organization/home";
import { Login } from "screens/organization/login";
import { Purchases } from "screens/organization/purchases";
import { PurchaseUsersInfo } from "screens/organization/purchaseUsersInfo";
import { CouponsInInventory } from "screens/organization/couponsInInventory";
import { SubSegmentUsers } from "screens/organization/subSegmentUsers";
import Dashboard from "layouts/Dashboard";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import { ConfirmationsUserInfo} from "screens/organization/confirmations";
import { MultiSegmentUsers } from "screens/organization/multiSegmentUsers"
import { AppOpenByHoursCountsChart }  from "screens/organization/appOpenByHours"
import { TraineesInfo } from "screens/organization/traineesInfo"
import { StaffInfo } from "screens/organization/staffInfo";
import { HideCouponTypes } from '../screens/organization/hideCouponTypes/index';
import { HealthAndFitnessData } from '../screens/organization/healthAndFitnessData';

export const organizationRoutes = [
  {
    path: `/${dashboardPaths.ORGANIZATION}/login`,
    component: AuthLayout,
    routes: [
      {
        path: [`/${dashboardPaths.ORGANIZATION}/login/:segmentPath`,`/${dashboardPaths.ORGANIZATION}/login`],
        exact: true,
        component: Login
      }
    ]
  },
  {
    path: `/${dashboardPaths.ORGANIZATION}`,
    component: Dashboard,
    routes: [
      {
        path: [`/${dashboardPaths.ORGANIZATION}/home/:segmentPath`,`/${dashboardPaths.ORGANIZATION}/home`],
        exact: true,
        component: Home
      },
      {
        path: [`/${dashboardPaths.ORGANIZATION}/purchases/:segmentPath`,`/${dashboardPaths.ORGANIZATION}/purchases`],
        exact: true,
        component: Purchases
      },
      {
        path: [`/${dashboardPaths.ORGANIZATION}/purchaseUsersInfo/:segmentPath`,`/${dashboardPaths.ORGANIZATION}/purchaseUsersInfo`],
        exact: true,
        component: PurchaseUsersInfo
      },
      {
        path: [`/${dashboardPaths.ORGANIZATION}/couponsInInventory/:segmentPath`,`/${dashboardPaths.ORGANIZATION}/couponsInInventory`],
        exact: true,
        component: CouponsInInventory
      },
      {
        path: [`/${dashboardPaths.ORGANIZATION}/subSegmentUsers/:segmentPath`,`/${dashboardPaths.ORGANIZATION}/subSegmentUsers`],
        exact: true,
        component: SubSegmentUsers
      },
      {
        path: [`/${dashboardPaths.ORGANIZATION}/confirmations/:segmentPath`,`/${dashboardPaths.ORGANIZATION}/confirmations`],
        exact: true,
        component: ConfirmationsUserInfo
      },
      {
        path: [`/${dashboardPaths.ORGANIZATION}/multiSegmentUsers/:segmentPath`,`/${dashboardPaths.ORGANIZATION}/confirmations`],
        exact: true,
        component: MultiSegmentUsers
      },
      {
        path: [`/${dashboardPaths.ORGANIZATION}/appOpenByHours/:segmentPath`],
        exact: true,
        component: AppOpenByHoursCountsChart
      },
      {
        path: [`/${dashboardPaths.ORGANIZATION}/traineesInfo/:segmentPath`],
        exact: true,
        component: TraineesInfo
      },
      {
        path: [`/${dashboardPaths.ORGANIZATION}/staffInfo/:segmentPath`],
        exact: true,
        component: StaffInfo
      },
      {
        path: [`/${dashboardPaths.ORGANIZATION}/hideCouponTypes/:segmentPath`],
        exact: true,
        component: HideCouponTypes
      },
      {
        path: [`/${dashboardPaths.ORGANIZATION}/healthAndFitnessData/:segmentPath`],
        exact: true,
        component: HealthAndFitnessData
      },
      
    ]
  }
];

