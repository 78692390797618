export const analyticsEvents = {
    EVENT_LOGIN_DASHBOARD_BIZ: 'EVENT_LOGIN_DASHBOARD_BIZ',
    EVENT_LOGIN_DASHBOARD_ORG: 'EVENT_LOGIN_DASHBOARD_ORG',
    EVENT_LOGIN_DASHBOARD_MOV: 'EVENT_LOGIN_DASHBOARD_MOV',
    EVENT_BIZ_SIDEBAR_PRESSED_PURCHASES: 'EVENT_BIZ_SIDEBAR_PRESSED_PURCHASES',
    EVENT_BIZ_SIDEBAR_PRESSED_PURCHASES_BY_BRANCH: 'EVENT_BIZ_SIDEBAR_PRESSED_PURCHASES_BY_BRANCH',
    EVENT_ORG_SIDEBAR_PRESSED_PURCHASES: 'EVENT_ORG_SIDEBAR_PRESSED_PURCHASES',
    EVENT_ORG_SIDEBAR_PRESSED_LOADINGS: 'EVENT_ORG_SIDEBAR_PRESSED_LOADINGS',
    EVENT_MOV_SIDEBAR_PRESSED_MAIN: 'EVENT_MOV_SIDEBAR_PRESSED_MAIN',
    EVENT_MOV_SIDEBAR_PRESSED_SUMMARY_OF_LOADINGS_PER_ORGANIZATION: 'EVENT_MOV_SIDEBAR_PRESSED_SUMMARY_OF_LOADINGS_PER_ORGANIZATION',
    EVENT_MOV_SIDEBAR_PRESSED_SUMMERY_OF_PURCHASES_BY_BUSINESS: 'EVENT_MOV_SIDEBAR_PRESSED_SUMMERY_OF_PURCHASES_BY_BUSINESS'
};
