import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";
import { handleBlockScroll} from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const CountInput = observer(() => {
  const classes = useStyles();
  const store = useEditCouponTypeStore();
  const { limitationCountError, limitationParams } = store;

  const handleInputChange = (event) => {
    if (event.target.value < 0) {
      store.limitationCountError = true;
      return;
    }
    store.limitationCountError = false;
    store.limitationParams = {...limitationParams, "count": Number(event.target.value)};
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      label={t("screenAddNewBusiness.NumberOfCouponsLimitation")}
      type="number"
      onChange={handleInputChange}
      onWheel={handleBlockScroll}
      className={classes.input}
      error={limitationCountError}
      value={store.limitationParams.count || ""}
      helperText={limitationCountError ? t("screenAddNewBusiness.NegativeNumberError"): ""}
    />
  );
});