import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from 'screens/controlPanel/editCouponType/store';
import { t } from "common/localization/translate";
import { Grid } from "@material-ui/core";

export const IsOnline = observer(() => {
  const store = useEditCouponTypeStore();
  const { isOnline } = store;
  
  const handleChange = event => {
    store.isOnline = event.target.checked;
  };

  return (
    <Grid>
      <FormControlLabel control={
          <Checkbox checked={isOnline ? true : false} onChange={handleChange} />
        }
        label={t("screenAddNewBusiness.isOnline")}
      />
    </Grid>
  );
});
