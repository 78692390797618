import React from "react";
import {
  Container,
  CssBaseline,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { GeneralInfo } from "./GeneralInfo/GeneralInfo";
import { InternalInfo } from "./InternalInfo/InternalInfo";
import { PopUps } from "./PopUps/PopUps";
import { t } from "common/localization/translate";
import { SubmitCouponTypeButton } from "./SubmitCouponTypeButton";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    flex: "auto",
  },
  subtitle: {
    textAlign: "center",
    flex: "auto",
    marginTop: 30,
    marginBottom: 10,
    fontWeight: "normal",
    color: theme.palette.primary.main,
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    marginBottom: 10,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  gridWidth: {
    width: "50%",
  },
}));

export const Form = observer(() => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Grid className={classes.paper}>
        <Typography className={classes.title} component="h1" variant="h1">
          {t("addNewCouponType.AddCouponTypeFormTitle")}
        </Typography>
        <form className={classes.form} noValidate>
          <GeneralInfo />
          <InternalInfo />
          <PopUps />
          <SubmitCouponTypeButton />
        </form>
      </Grid>
    </Container>
  );
});
