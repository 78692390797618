import React from "react";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { IsCreditCheckbox } from "./components/IsCreditCheckbox";
import { Price } from "./components/Price";
import { Grid } from "@material-ui/core";
import { PaymentOptionSelect } from "./components/paymentOptionSelect";

export const CreditRequired = observer(() => {
  const store = useAddNewCouponTypeStore();
  const { isCreditRequired } = store;

  if (isCreditRequired) {
    return (
      <Grid>
        <IsCreditCheckbox value={isCreditRequired}/>
        <PaymentOptionSelect />
        <Price />
      </Grid>
    );
  }
  return <IsCreditCheckbox value={isCreditRequired}/>;
});
