import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { AddAnswerInput } from "./addAnswerInput";
import { AddAnswerButton } from "./addAnswerButton";
import { useEditArticleStore } from "../../../../..";
import { IsCorrectCheckbox } from "./isCorrectCheckbox";
import { AnswerSelect } from "../editAnswer/answerSelect";
import { quizActions } from "screens/controlPanel/editArticle/store/logic/quizActions";

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
  },
}));

export const AddAnswersComponents = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();

  if (store.quizAction !== quizActions.ADD) {
    return null;
  }
  return (
    <span className={classes.input}>
      <AddAnswerInput />
      <IsCorrectCheckbox />
      <AddAnswerButton />
      <AnswerSelect />
    </span>
  );
});
