import React from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import SimpleCube from "common/ui/simpleCube";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../store";
import { formatNumber } from "common/formatNumber";
import PaymentIcon from '@material-ui/icons/Payment';

const useStyles = makeStyles(() => ({
    row: {
        width: "100%"
    }
}));

export const TotalUsersToDemandPayment = observer(() => {
    const store = useScreenHomeStore();
    const { date, totalUsersToDemandPaymentClalit, selectedSegmentName } = store;
    const totalUsersToDemandPaymentClalitFormatted = formatNumber(totalUsersToDemandPaymentClalit);
    if(!totalUsersToDemandPaymentClalit){
        return null;
    }
    const classes = useStyles();
    return (
        <Grid item xs={12} md className={classes.row}>
            <SimpleCube
                label={t("screenHome.totalNumbersCubesRow.totalMembersToDemandPayment", { selectedSegmentName, date })}
                value={totalUsersToDemandPaymentClalitFormatted}
                icon={<PaymentIcon />}
                withoutMargin={true}
            />
        </Grid>
    );
});
