import React from "react";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../..";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
    marginLeft: 15,
    width: 450
  },
}));

export const TranslatedArticleHtml = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();
  const value = store.translatedArticleHtml ? store.translatedArticleHtml : "";

  const handleInputChange = (event) => {
    store.translatedArticleHtml = event.target.value;
  };

  if (!store.isTranslate) {
    return;
  }
  return (
      <TextField
        fullWidth
        variant="outlined"
        multiline
        rows={7}
        value={value}
        onChange={handleInputChange}
        label={t("editArticle.translatedArticleHtml")}
        className={classes.input}
      />
  );
});