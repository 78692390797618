import React from "react";
import { Container, CssBaseline, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { t } from "common/localization/translate";
import { BusinessSelect } from "./businessSelect";
import { AuctionTitle } from "./auctionTitle";
import { StartAuctionDate } from "./startAuctionDate";
import { EndAuctionDate } from "./endAuctionDate";
import { CostToBusiness } from "./costToBusiness";
import { ExternalLinkUrl } from "./externalLink";
import { ExternalLinkButtonText } from "./externalLinkButtonText";
import { BccEmail } from "./bccEmail";
import { StepsBid } from "./stepsBid";
import { AuctionDescription } from "./auctionDescription";
import { IsSprintCheckbox } from "./sprint/isSprintCheckbox";
import { StartSprintDate } from "./sprint/sprintStartDate";
import { SubmitAddAuctionButton } from "./submitAddAuctionButton"
import { SegmentSelect } from "./segmentSelect";
// import { AuctionCreationFailedPopUp } from "../popUps/auctionCreationFailedPopUp";
import { AuctionCreationSuccessPopUp } from "../popUps/auctionCreationSuccessPopUp";
import { EmptyFieldErrorPopUp } from "../popUps/emptyFieldErrorPopUp";
import { AuctionImage } from "./auctionImage";


const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 30,
    flex: "auto",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    marginBottom: 10,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  gridWidth: {
    width: "50%",
  },
}));

export const Form = observer(() => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Grid className={classes.paper}>
        <Typography className={classes.title} component="h1" variant="h1">
          {t("addNewAuction.addNewAuctionFormTitle")}
        </Typography>
        <form className={classes.form} noValidate>
        <Grid className={classes.gridWidth} container spacing={2}>
         <BusinessSelect />
         <AuctionTitle />
         <AuctionDescription />
         <StartAuctionDate />
         <EndAuctionDate />
         <AuctionImage />
         <BccEmail />
         <CostToBusiness />
         <ExternalLinkUrl />
         <ExternalLinkButtonText />
         <StepsBid />
         <IsSprintCheckbox />
         <StartSprintDate />
         <SegmentSelect />
         </Grid>
         <Grid className={classes.form}>
           {/* <AuctionCreationFailedPopUp /> */}
           <AuctionCreationSuccessPopUp />
           <EmptyFieldErrorPopUp />
          <SubmitAddAuctionButton />
         </Grid>
        </form>
      </Grid>
    </Container>
  );
});