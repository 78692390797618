import React from "react";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { timeTypes } from "screens/controlPanel/addCouponType/store/logic/timeTypes";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  inputRoot: {
    width: 195,
  },
}));

export const SelectTimeType = observer(() => {
  const store = useEditCouponTypeStore();
  const classes = useStyles();

  const handleChange = (event, value) => {
    store.timeType = value;
  };
  
  return (
    <Autocomplete
      options={timeTypes}
      getOptionLabel={(type) => type.name}
      onChange={handleChange}
      classes={classes}
      value={store.timeType}
      renderInput={(params) => (
        <TextField {...params} placeholder={t("addNewCouponType.TimeTypePlaceholder")} variant="outlined" />
      )}
    />
  );
});
