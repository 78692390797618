import React from "react";
import { CssBaseline, Typography, Container } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";
import { SegmentSelect } from "./components/segmentSelect";
import { ArticleImageSmall } from "./components/articleImageSmall";
import { ArticleImageLarge } from "./components/articleImageLarge";
import { ArticleImageSmallUrl } from "./components/articleImageSmallUrl";
import { ArticleImageLargeUrl } from "./components/articleImageLargeUrl";
import { LeadContactEmail } from "./components/leadContactEmail";
import { IsAdminCheckbox } from "./components/isAdminCheckbox";
import { ArticleSelect } from "./components/articleSelect";
import { IsTranslateCheckbox } from "./components/isTranslateCheckbox";
import { LanguageSelect } from "./components/translateArticleComponents/languageSelect";
import { EditArticleButton } from "./components/editArticleButton";
import { ArticleNameTranslate } from "./components/translateArticleComponents/articleNameTranslate";
import { ArticleSubTitleTranslate } from "./components/translateArticleComponents/articleSubTitleTranslate";
import { ArticleHtmlTranslate } from "./components/translateArticleComponents/articleHtmlTranslate";
import { QuizComponents } from "./components/quizComponents/quizComponents";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 30,
    flex: "auto",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  translate: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: 10,
  },
}));

export const Form = observer(() => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography className={classes.title} component="h1" variant="h1">
          {t("editArticle.editArticleTitle")}
        </Typography>
        <form className={classes.form} noValidate>
          <ArticleSelect />
          <IsTranslateCheckbox />
          <LanguageSelect />
          <ArticleNameTranslate />
          <ArticleSubTitleTranslate />
          <SegmentSelect />
          <ArticleImageSmall />
          <ArticleImageSmallUrl />
          <ArticleImageLarge />
          <ArticleImageLargeUrl />
          <ArticleHtmlTranslate />
          <LeadContactEmail />
          <IsAdminCheckbox />
          <EditArticleButton />
          <QuizComponents />
        </form>
      </div>
    </Container>
  );
});
