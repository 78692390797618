import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const FixedCodeInput = observer(() => {
  const store = useEditCouponTypeStore();
  const { codeGenerationParameters } = store;
  const classes = useStyles();

  const handleChange = ({ target }) => {
    store.codeGenerationParameters = {...codeGenerationParameters, [target.name]: target.value};
    store.currentFixedCode = target.value;
  };
  
  return (
    <TextField
      name="fixedCode"
      onChange={handleChange}
      label={t("addNewCouponType.InputFixedCouponLabel")}
      className={classes.input}
      value={store.currentFixedCode || ''}
    />
  );
});
