import { decorate, action, flow, observable } from 'mobx';
import { getDataPurchasesSummaryByBranch as doGetDataPurchasesSummaryByBranch } from './server/getDataPurchasesSummaryByBranch';
import { toISOString, getNow, getStartOfMonth } from 'common/dateAndTime';

export class StorePurchasesSummaryByBranch {

  startAt;
  endAt;
  purchasesCountTotal;
  purchasesSummaryByBranch;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }

  initProperties() {
    this.purchasesSummaryByBranch = [];
    this.startAt = getStartOfMonth();
    this.endAt = getNow();
    this.purchasesCountTotal = null;
  }

  getDataPurchasesSummaryByBranch = flow(function* () {
    const { businessId } = this.rootStore.userInfoStore;
    const startAtISO = toISOString(this.startAt);
    const endAtISO = toISOString(this.endAt);
    const data = yield doGetDataPurchasesSummaryByBranch(businessId, startAtISO, endAtISO);
    this.purchasesSummaryByBranch = data.purchasesSummaryByBranch;
    this.purchasesCountTotal = data.purchasesCountTotal;
  });

  setStartAt(date) {
    this.startAt = date;
  }

  setEndAt(date) {
    this.endAt = date;
  };

  onChangeRange(startDate, endDate) {
    this.startAt = startDate;
    this.endAt = endDate;
    this.getDataPurchasesSummaryByBranch();
  }

}

decorate(StorePurchasesSummaryByBranch, {
  startAt: observable,
  endAt: observable,
  purchasesSummaryByBranch: observable,
  purchasesCountTotal: observable,
  setStartAt: action.bound,
  setEndAt: action.bound,
  getDataPurchasesSummaryByBranch: action.bound,
  onChangeRange: action.bound,
  initProperties: action.bound
});