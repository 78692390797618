import React from "react";
import { observer } from "mobx-react";
import { useEditAuctionStore } from "screens/controlPanel/editAuction/store";
import { t } from "common/localization/translate";
import Select from "react-select";
import { Grid, makeStyles} from "@material-ui/core";
import { customStyles } from "../../../techOpsReportes/store/components/form/components/selectStyles";

const useStyles = makeStyles(() => ({
    input: {
      marginBottom: 10,
    },
  }));

export const BusinessSelectEdit = observer(() => {
  const store = useEditAuctionStore();
  const classes = useStyles();

  const handleChange = (event) => {
    store.businessId = Number(event.business_id);
    store.businessName = (event.name);
  };

  return (
    <Grid className={classes.input}>
      <Select
        options={store.businessList}
        getOptionLabel={(business) => `${business.name} - ${business.business_id}`}
        onChange={handleChange}
        styles={customStyles}
        placeholder={`${store.businessName} - ${store.businessId}` || t("addNewCouponType.BusinessIdSelect")}
        />
    </Grid>
  );
});