import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import { handleBlockScroll} from "common/ui/handleBlockScroll";

export const AutoGenInputLength = observer(() => {
  const store = useAddNewCouponTypeStore();
  const { codeGenerationParameters } = store;

  const handleChange = (event) => {
      if(!event.target.value){
        store.codeGenerationStrategyEmptyError=t("addNewCouponType.CodeGenerationStrategyLabel");
        return;
      }
      store.codeGenerationStrategyEmptyError = null;
      store.codeGenerationParameters = {...codeGenerationParameters, [event.target.name]: Number(event.target.value),
    };
  };

  return (
      <Grid>
        <TextField
          type="number"
          name="length"
          onChange={handleChange}
          onWheel={handleBlockScroll}
          label={t("addNewCouponType.ExternalCouponLength")}
        />
      </Grid>
  );
});
