import React from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { useScreenLoginStore } from "../store";
import { t } from "common/localization/translate";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    submitButton: {
        marginTop: theme.spacing(2),
        textDecorationLine: 'underline',
        "&:hover": {
            cursor: 'pointer',
            fontWeight: 'bold'
        }
    }
}));


export const ResetPassword = observer(() => {
    const store = useScreenLoginStore();
    const classes = useStyles();

    const resetPasswordSendEmail = (event) => {
        event.preventDefault();
        store.resetPassword();
    }
    
    return (
        <Grid className={classes.submitButton} onClick={resetPasswordSendEmail} type="submit" >
            <Typography variant="subtitle2">{t("login.mailSuccessResetPassword.title")}</Typography>
        </Grid>
    );
});


