import { decorate, action, flow, observable } from 'mobx';
import { getSummaryOfPurchasesByBusiness as doGetSummaryOfPurchasesByBusiness } from './server/getSummaryOfPurchasesByBusiness';

export class StoreSummaryOfPurchasesByBusiness {

  constructor(rootStore) {
    this.rootStore = rootStore
    this.initProperties();
  }

  initProperties() {
    this.startAt = this.rootStore.movement.storeMain.startAt;
    this.endAt = this.rootStore.movement.storeMain.endAt;
    this.summaryByBusiness = [];
    this.countOfPurchases = 0; // Default value
    this.totalBillForMovement = 0; // Default value
  }

  getSummaryOfPurchasesByBusiness = flow(function* () {
    const data = yield doGetSummaryOfPurchasesByBusiness(this.startAt, this.endAt);
    this.summaryByBusiness = data.summaryByBusiness;
    this.countOfPurchases = data.countOfPurchases;
    this.totalBillForMovement = data.totalBillForMovement;
  });

  setStartAt(date) {
    this.startAt = date;
  }

  setEndAt(date) {
    this.endAt = date;
  };

  onChangeRange(startDate, endDate) {
    this.startAt = startDate;
    this.endAt = endDate;
    this.getSummaryOfPurchasesByBusiness();
  }
}

decorate(StoreSummaryOfPurchasesByBusiness, {
  summaryByBusiness: observable,
  countOfPurchases: observable,
  totalBillForMovement: observable,
  getDataPurchases: action.bound,
  startAt: observable,
  endAt: observable,
  setStartAt: action.bound,
  setEndAt: action.bound,
  onChangeRange: action.bound,
  initProperties: action.bound
});