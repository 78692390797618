import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../index";
import { t } from "common/localization/translate";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  buttons: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 15,
    width: 200,
    height: 40,
    color: theme.palette.white,
    marginRight: 5,
    marginTop: 5,
    "&:hover": {
      backgroundColor: theme.palette.primary.white,
    }
  },
  title: {
    marginBottom: 30,
    flex: "auto",
  }
}));

export const CreateBusinessButton = observer(() => {
  const store = useScreenCreateAddNewBusinessStore();
  const classes = useStyles();
  const { commissionPercentError, healthScoreError, retainerError, commissionError, scoreError, contactPhoneEmptyError,
    businessNameEmptyError, businessLogoEmptyError, businessLocationEmptyError, businessDescriptionEmptyError, scoreEmptyError,
    tagsEmptyError, segmentsEmptyError, primaryCategoryEmptyError, descriptiveTagsEmptyError, contactEmailEmptyError,
    contactNameEmptyError } = store;
  const [isDisabled, setIsDisabled] = useState(false);

  const handleCheck = async () => {
    if (scoreError || commissionError || retainerError || healthScoreError || commissionPercentError) {
      store.setIsNegNumberErrorPopUpOpen(true);
      return;
    }

    if (contactNameEmptyError || contactEmailEmptyError || descriptiveTagsEmptyError || primaryCategoryEmptyError || segmentsEmptyError || tagsEmptyError || scoreEmptyError || businessDescriptionEmptyError || businessLocationEmptyError || businessLogoEmptyError || businessNameEmptyError || contactPhoneEmptyError || contactNameEmptyError) {
      store.setIsEmptyFieldErrorPopUpOpen(true);
      return;
    }
    setIsDisabled(true);
    await store.submitCreateBusiness();
    setIsDisabled(false);

    if (store.businessCreationStatus) {
      store.setIsBusinessCreatedSuccessPopUpOpen(true);
      return;
    }

    if (store.failedLocations.length > 0) {
      store.setIsLocationFailedErrorPopUpOpen(true);
      return;
    }

    store.setIsBusinessFailedErrorPopUpOpen(true);
    return;
  };

  const getCreateBusinessButtonContent = store.isFetching ? <CircularProgress color='#fffff' size={20} /> : t("screenAddNewBusiness.CreateBusinessButton");
  return (
    <Grid className={classes.title} item xs={12}>
      <Button onClick={handleCheck} disabled={isDisabled} variant="contained" className={classes.buttons}>
        {getCreateBusinessButtonContent}
      </Button>
    </Grid>
  )
});