import React from "react";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import Checkbox from '@material-ui/core/Checkbox';
import { Grid } from "@material-ui/core/";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { t } from "common/localization/translate";
import { segmentIds } from "../../../../../../logic/segmentIds";

export const AddToExistingCouponTypeGroupCheckBox = observer(() => {
    const store = useAddNewCouponTypeStore();

    const handleChange = (event) => {
        store.isExistingCouponTypeGroup = event.target.checked;
    };

    if (!store.isSupplierDashboardCouponType || store.couponTypesGroup.length == 0 || store.segments.includes(segmentIds.CLALIT_SEGMENT_ID)) {
        return null;
    }

    return (
        <Grid>
            <FormControlLabel control={<Checkbox
                checked={store.isExistingCouponTypeGroup}
                onChange={handleChange}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }} />} label={t("addNewCouponType.addToExsistGroup")} />
        </Grid>
    );
});
