import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { PurchasedCouponTable } from "componentsRumble/purchasedCouponTable";

export class PurchasesComponent extends Component {
  componentDidMount() {
    this.props.rootStore.biz.storePurchases.getDataPurchases();
  }

  render() {
    const { purchasedCoupons } = this.props.rootStore.biz.storePurchases;
    return purchasedCoupons ? (
      <PurchasedCouponTable purchasedCoupons={purchasedCoupons} />
    ) : null;
  }
}

export const Purchases = inject("rootStore")(observer(PurchasesComponent));
