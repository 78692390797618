import React from "react";
import { makeStyles, Grid, Select, MenuItem } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../..";
import { InputLabel } from "@material-ui/core";
import { quizActions } from "../../../../logic/quizActions";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    minWidth: 300
  },
  label: {
    marginTop: 20,
    marginBottom: 10
  },
}));

export const ActionSelect = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();

  const handleChange = (event) => {
    store.quizAction = event.target.value;
  };

  const actionContent = Object.values(quizActions).map((action) => (
    <MenuItem value={action}>{action}</MenuItem>
  ));

  return (
    <Grid item xs={12}>
        <InputLabel className={classes.label}>
        {t("editArticle.actionSelect")}
        </InputLabel>
      <Select onChange={handleChange} value={store.quizAction} className={classes.input}>
        {actionContent}
      </Select>
    </Grid>
  );
});
