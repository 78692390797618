import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { styles } from 'common/styles';

export class infoCardComponent extends Component {

    onClick = () => {
        if (this.props.history !== undefined) {
            this.props.history.push(this.props.redirectTo)
        }
    }

    render() {
        const { classes, info } = this.props;
        return <div onClick={this.onClick}>
            <Paper className={classes.paper}>
                <Typography variant="title">
                    {info}
                </Typography>
            </Paper>
        </div>
    }
}

export const InfoCard = withStyles(styles)((infoCardComponent));