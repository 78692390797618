import React from "react";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../..";
import { t } from "common/localization/translate";
import { createEncodedLogo } from '../../../../../addNewBuisness/store/components/form/components/GeneralInfo/createEncodedLogo';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
  },
  input: {
    marginBottom:10,
  }
}));

export const ArticleImageSmall = observer(() =>{
  const classes = useStyles();
  const store = useEditArticleStore();

  const handleInputChange = async (event) => {
    const articleImageSmall = await createEncodedLogo(event.target.files[0]);
    store.articleImageSmall = articleImageSmall;
}

  return (
    <div>
      <label className={classes.label}>
        {t("editArticle.smallImage")}
      </label>
      <br />
      <input type="file" onChange={handleInputChange} className={classes.input} />
    </div>
  );
});
