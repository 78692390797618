import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import { Grid, makeStyles, Checkbox } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  grid: {
    marginBottom: 20,
  },
}));

export const IsAdminOnly = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();

  const handleChange = (event) => {
    store.isAdminOnly = event.target.checked;
  };

  return (
    <Grid item xs={12} className={classes.Grid}>
      <FormControlLabel
        control={<Checkbox onChange={handleChange} />}
        label={t("editArticle.isAdmin")}
      />
    </Grid>
  );
});
