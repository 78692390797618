import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditAuctionStore } from "screens/controlPanel/editAuction/store";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
    marginTop: 10,
  },
}));

export const AuctionDescriptionEdit = observer(() => {
  const classes = useStyles();
  const store = useEditAuctionStore();

  const handleInputChange = (event) => {
    if (!event.target.value) {
      store.auctionDescriptionEmptyError = t("addNewAuction.addNewAuctionDescription");
      store.auctionDescription = null;
      return;
    }
    store.auctionDescriptionEmptyError = null;
    store.auctionDescription = event.target.value;
  };

  return (
    <TextField
      required
      variant="outlined"
      fullWidth
      multiline
      rows={8}
      rowsMax={100}
      label={t("addNewAuction.addNewAuctionDescription")}
      onChange={handleInputChange}
      className={classes.input}
      value={store.auctionDescription || ""}
    />
  );
});