import React from "react";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { useScreenCreateBusinessDashboardUserStore } from "..";
import { ComboBox } from "common/ui/comboBox";

export const BusinessesComboBox = observer(({label}) => {
  const store = useScreenCreateBusinessDashboardUserStore();
  const { businessesData } = store;

  const onChange = (event, newValue) => {
    store.errorCode = null;
    store.successText = null;
    store.businessId = newValue.business_id;
    store.businessName = newValue.title;
    store.businessEmail = newValue.contact_email;
    store.businessContactName = newValue.contact_name;
    store.businessPhone = newValue.phone;
  };

  return (
      <Grid item sm={4} lg={2} spacing={2}>
      <ComboBox options = {businessesData} label= {label} onChange={onChange} />
      </Grid>
  );
});
