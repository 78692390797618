import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const Coins = observer(() => {
  const classes = useStyles();
  const store = useEditCouponTypeStore();
  const minValue = 0;

  const handleInputChange = (event) => {
    if(event.target.value >= minValue){
      store.coins = event.target.value;
    };
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      label={t("addNewCouponType.Coins")}
      type="number"
      value={store.coins || ""}
      onChange={handleInputChange}
      onWheel={handleBlockScroll}
      className={classes.input}
      error={store.coinsError}
      helperText={store.priceError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
    />
  );
});
