import HomeIcon from "@material-ui/icons/Home";
import { t } from "common/localization/translate";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LabelOffIcon from '@material-ui/icons/LabelOff';
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";

export default [
  {
    title: "תפריט",
    pages: [
      {
        title: t("sideBar.home.title"),
        href: `/${dashboardPaths.SUPPLIERS}/home`,
        icon: HomeIcon,
      },
      {
        title: t("suppliers.supplierAndCouponReports.title"),
        href: `/${dashboardPaths.SUPPLIERS}/supplierReports`,
        icon: ConfirmationNumberIcon
      },
      {
        title: t("suppliers.sideBar.validations"),
        href: `/${dashboardPaths.SUPPLIERS}/validationCodes`,
        icon: EmojiSymbolsIcon,
      },
      {
        title: t("suppliers.projectManagerHome.title"),
        href: `/${dashboardPaths.SUPPLIERS}/billingReportSystem`,
        icon: AccountTreeIcon,
      },
      {
        title: t("suppliers.billingReportSystem.projectManagerSupplier.title"),
        href: `/${dashboardPaths.SUPPLIERS}/projectManagerSupplier`,
        icon: LabelOffIcon,
      },
    ],
  },
];
