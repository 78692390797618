import React from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    "&::placeholder": {
      fontSize: 13,
    },
  },
  root: {
    marginBottom: 15,
  }
}));

export const BusinessLocation = observer(() => {
  const classes = useStyles();

  const store = useScreenCreateAddNewBusinessStore();

  const handleInputChange = (event) => {
    if (!event.target.value) {
      store.setBusinessLocationEmptyError(t("screenAddNewBusiness.BusinessLocation"));
      return;
    }
    store.setBusinessLocationEmptyError(null);
    store.setBusinessLocation(event.target.value);
  };

  return (
    <div className={classes.root}>
      <TextField
        InputProps={{ classes: { input: classes.input } }}
        required
        id="outlined-multiline-static"
        label={t("screenAddNewBusiness.BusinessLocation")}
        multiline
        rows={6}
        placeholder={t("screenAddNewBusiness.BusinessLocationPlaceholder")}
        variant="outlined"
        onChange={handleInputChange}
      />
    </div>
  );
});