import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { userUpdateSegmentStore } from "../../../index";
import { t } from "common/localization/translate";
import CircularProgress from "@material-ui/core/CircularProgress";
import { handleErrorCheck } from "../../../logic/handleErrorCheck";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 15,
    width: 200,
    height: 40,
    color: theme.palette.white,
    marginRight: 5,
    marginTop: 5,
    "&:hover": {
      backgroundColor: theme.palette.primary.white,
    },
  },
  title: {
    marginBottom: 30,
    flex: "auto",
  },
}));

export const UpdateUserSegmentButton = observer(() => {
  const store = userUpdateSegmentStore();
  const classes = useStyles();
  const handleSubmitBalance = async () => {
    const isError = handleErrorCheck(store);
    if (isError) {
      store.isErrorPopUpOpen = true;
      store.popUpErrorContent = null;
      return;
    } else {
      await store.submitUpdateUserSegment();
      return;
    }
  };

  const updateSegmentButtonContent = store.isFetching ? (
    <CircularProgress color="#fffff" size={20} />) : (t("updateUserSegment.submitChangeButton"));
  return (
    <Grid className={classes.title} item xs={12}>
      <Button
        onClick={handleSubmitBalance}
        variant="contained"
        className={classes.button}
      >
        {updateSegmentButtonContent}
      </Button>
    </Grid>
  );
});
