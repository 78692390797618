import { decorate, action, observable } from "mobx";
import { exportAllPoliceDataToExcel } from './logic/createExcel/exportAllPoliceDataToExcel/exportAllPoliceDataToExcel';
import { getAllPoliceHealthAndFitnessData } from './server/getAllPoliceHealthAndFitnessData';
import { getPoliceHealthAndFitnessHomeData } from './server/getPoliceHealthAndFitnessHomeData';
import { getSinglePoliceData } from './server/getSinglePoliceData';
import { savePoliceData } from './server/savePoliceData';
import { isWithData } from './logic/isWithData';
import { policeErrorCodes, sectionErrorCodeStr } from './logic/policeErrorCodes';
import { exportSinglePoliceDataToExcel } from './logic/createExcel/exportSinglePoliceDataToExcel/exportSinglePoliceDataToExcel';
import { sectionCountKeys, sectionEmptyDataKeys } from "./logic/sectionKeys";
import { setEmptySectionDataFlag } from "./logic/setEmptySectionDataFlag";
import { validateIdNum } from "screens/organization/confirmations/store/logic/validateIdNumber";
import { isMultiSegment } from "./logic/isMultiSegment";

class ScreenHealthAndFitnessDataStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.initProperties();
    }

    initProperties() {
        this.isFetching = null;
        this.isFetchingCopData = null;
        this.isExporting = null;
        this.isSavingData = null;
        this.startMeasurements = {};
        this.finishMeasurements = {};
        this.errorCode = null;
        this.errorCodeId = null;
        this.errorCodeSaveStart = null;
        this.errorCodeSaveFinish = null;
        this.userName = null;
        this.totalCount = null;
        this.currentCountStart = null;
        this.currentCountFinish = null;
        this.isSuccessSaveData = null;
        this.isStartSectionDataEmpty = null;
        this.isFinishSectionDataEmpty = null;
    }

    async getScreenData() {
        this.isFetching = true;
        const segmentId  = this.selectedSegmentIdToShow || this.rootStore.userInfoStore.currentSegmentId;
        const data = await getPoliceHealthAndFitnessHomeData(segmentId);
        this.totalCount = data.totalUsersCount;
        this.currentCountStart = data.usersWithStartDataCount;
        this.currentCountFinish = data.usersWithFinishDataCount;
        this.isFetching = false;
    }

    setSelectedSegmentIdToShow(segmentId) {
        const currentSegment = this.rootStore.userInfoStore.currentSegmentId;
        currentSegment.segmentIds = segmentId ? [segmentId] : this.rootStore.userInfoStore.currentSegment.multiSegmentOptions.map(segment => segment.id);
        this.selectedSegmentIdToShow = segmentId;
      }

    setUserIdNum(userIdNum) {
        this.errorCodeId = null;
        this.userIdNumInput = userIdNum;
    }

    async submitIdNum() {
        this.isFetchingCopData = true;
        this.userIdNum = null;
        this.userName = null;
        const error = validateIdNum(this.userIdNumInput);

        if (error) {
            this.errorCodeId = error;
            this.isFetchingCopData = false;
            return;
        }
        const segmentId = this.selectedSegmentIdToShow || this.rootStore.userInfoStore.currentSegmentId;
        if(isMultiSegment(segmentId)){
            this.errorCodeId = policeErrorCodes.NO_SEGMENT_SELECTED;
            this.isFetchingCopData = false;
            return;
        }
    
        const data = await getSinglePoliceData(this.userIdNumInput, segmentId);

        if (data && !data.isSuccess) {
            this.errorCodeId = data.errorCode;
            this.isFetchingCopData = false;
            return;
        }

        const { start, finish } = data.extra || {};
        setEmptySectionDataFlag({ store: this, start, finish });
        this.userIdNum = data.extra.idNum;
        this.userName = data.extra.name;
        this.startMeasurements = data.extra.start || {};
        this.finishMeasurements = data.extra.finish || {};
        this.isFetchingCopData = false;
    }

    async submitAllData() {
        this.isSavingData = true;
        const segmentId = this.rootStore.userInfoStore.currentSegmentId;
        const start = this.startMeasurements;
        const finish = this.finishMeasurements;

        if (!isWithData({ start, finish })) {
            this.errorCode = policeErrorCodes.ERROR_TYPE_NO_INPUT_DATA;
            this.isSavingData = false;
            return;
        }

        const saveResult = await savePoliceData({ idNum: this.userIdNum, segmentId, fitnessData: { start, finish } });

        if (!saveResult.isSuccess) {
            this.errorCode = saveResult.errorCode;
        }

        this.isSuccessSaveData = saveResult.isSuccess;
        this.isSavingData = false;
    }

    async submitSectionData(section) {
        this.isSavingData = true;
        const errorCodeStr = sectionErrorCodeStr[section];
        this[errorCodeStr] = null;
        const segmentId = this.selectedSegmentIdToShow;
        const sectionDataObjName = `${section}Measurements`;
        const fitnessData = this[sectionDataObjName];
        const isWithData = Object.values(fitnessData).length != 0;

        if (!isWithData) {
            this[errorCodeStr] = policeErrorCodes.ERROR_TYPE_NO_INPUT_DATA;
            this.isSavingData = false;
            return;
        }

        const saveResult = await savePoliceData({ idNum: this.userIdNum, segmentId, fitnessData, section });
        const { isSuccess } = saveResult || {};

        if (!isSuccess) {
            this[errorCodeStr] = saveResult.errorCode;
        }

        const isSectionDataEmptyOnScreenLoad = this[sectionEmptyDataKeys[section]];
        if (isSectionDataEmptyOnScreenLoad) {
            const counterKey = sectionCountKeys[section];
            const newCount = this[counterKey] + 1;
            this[counterKey] = newCount;
            this[sectionEmptyDataKeys[section]] = false;
        }

        this.isSuccessSaveData = isSuccess;
        this.isSavingData = false;
    }

    async getAllDataAndExportToExcel() {
        this.isExporting = true;
        const res = await getAllPoliceHealthAndFitnessData(this.selectedSegmentIdToShow || this.rootStore.userInfoStore.currentSegmentId);

        if (res.isSuccess) {
            exportAllPoliceDataToExcel(res.extra.allCopsFitnessDataArr);
        }
        this.isExporting = false;
    }

    exportSinglePoliceDataToExcel() {
        this.isExporting = true;
        exportSinglePoliceDataToExcel(this);
        this.isExporting = false;
    }

    setBloodPressureSystolic(systolic, stage) {
        this.resetStageErrorCodes();
        this[stage].systolic = Number(systolic);
    }

    setBloodPressureDiastolic(diastolic, stage) {
        this.resetStageErrorCodes();
        this[stage].diastolic = Number(diastolic);
    }

    setWeight(weight, stage) {
        this.resetStageErrorCodes();
        this[stage].weight = Number(weight);
    }

    setWaistCircumference(waist, stage) {
        this.resetStageErrorCodes();
        this[stage].waistCircumference = Number(waist);
    }

    setFatPercentage(fatPercentage, stage) {
        this.resetStageErrorCodes();
        this[stage].fatPercentage = Number(fatPercentage);
    }

    setPushups(pushups, stage) {
        this.resetStageErrorCodes();
        this[stage].pushups = Number(pushups);
    }

    setStar(star, stage) {
        this.resetStageErrorCodes();
        this[stage].star = Number(star);
    }

    setSquat(squat, stage) {
        this.resetStageErrorCodes();
        this[stage].squat = Number(squat);
    }

    setRowing(rowing, stage) {
        this.resetStageErrorCodes();
        this[stage].rowing = Number(rowing);
    }

    resetStageErrorCodes = () => {
        this.errorCodeSaveStart = null;
        this.errorCodeSaveFinish = null;
    }
}


decorate(ScreenHealthAndFitnessDataStore, {
    isFetching: observable,
    isFetchingCopData: observable,
    isExporting: observable,
    isSavingData: observable,
    testValue: observable,
    errorCode: observable,
    errorCodeId: observable,
    errorCodeSaveStart: observable,
    errorCodeSaveFinish: observable,
    userIdNum: observable,
    userIdNumInput: observable,
    userName: observable,
    startMeasurements: observable,
    finishMeasurements: observable,
    totalCount: observable,
    currentCountStart: observable,
    currentCountFinish: observable,
    isSuccessSaveData: observable,
    selectedSegmentIdToShow: observable,
    getScreenData: action.bound,
    setUserIdNum: action.bound,
    submitIdNum: action.bound,
    submitAllData: action.bound,
    submitSectionData: action.bound,
    getAllDataAndExportToExcel: action.bound,
    exportSinglePoliceDataToExcel: action.bound,
    setUserName: action.bound,
    setBloodPressure: action.bound,
    setWeight: action.bound,
    setWaistCircumference: action.bound,
    setFatPercentage: action.bound,
    setPushups: action.bound,
    setStar: action.bound,
    setSquat: action.bound,
    setRowing: action.bound,
    resetStageErrorCodes: action.bound,
});

export function createScreenHealthAndFitnessDataStore(rootStore) {
    const store = new ScreenHealthAndFitnessDataStore(rootStore);
    return store;
}