import React, {useState} from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleErrorCheck } from '../../logic/handleErrorCheck';

const useStyles = makeStyles((theme) => ({
  buttons: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 15,
    width: 200,
    height: 40,
    color: theme.palette.white,
    marginRight: 5,
    marginTop: 5,
    "&:hover": {
      backgroundColor: theme.palette.primary.white,
    }
  },
  title: {
    marginBottom: 30,
    flex: "auto",
  }
}));

export const SubmitCouponTypeButton = observer(() => {
  const store = useAddNewCouponTypeStore();
  const classes = useStyles();
  const { isFetching} = store;
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = async () => {
    const isError = handleErrorCheck(store);
    setIsDisabled(true);
    if(!isError){
      await store.submitCreateCouponType();
      return;
    }
    setIsDisabled(false);
  };

  const buttonContent = isFetching ? <CircularProgress color='#fffff' size={20} /> : t("addNewCouponType.CreateCouponTypeButton");
  return (
    <Grid className={classes.title} item xs={12}>
      <Button onClick={handleSubmit} disabled={isDisabled} variant="contained" className={classes.buttons}>
        {buttonContent}
      </Button>
    </Grid>
  )
});