import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../../..";
import IconButton from "@material-ui/core/IconButton";
import { addQuestion as addQuestionToDb } from "screens/controlPanel/editArticle/store/server/addQuestion";
import { getArticleQuestions } from "screens/controlPanel/editArticle/store/server/getArticleQuestions";

export const AddQuestionButton = observer(() => {
  const store = useEditArticleStore();

  const addQuestion = async () => {
    if (store.questionToAdd) {
      await addQuestionToDb({question: store.questionToAdd, articleId: store.selectedArticle});
      store.questionToAdd = "";
      store.questionArray = await getArticleQuestions(store.selectedArticle);
    }
  };

  return (
    <IconButton color="primary" component="span">
      <AddIcon onClick={addQuestion} />
    </IconButton>
  );
});