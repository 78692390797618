import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";

export const IsEmailAtPurchaseCheckbox = observer(() => {
  const store = useScreenCreateAddNewBusinessStore();

  const handleChange = (event) => {
    store.setIsEmailAtPurchase(event.target.checked);
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            name="isEmailAtPurchase"
            defaultChecked={true}
          />
        }
        label={t("screenAddNewBusiness.isEmailAtPurchase")}
      />
    </div>
  );
});