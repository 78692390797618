import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { RootCenter } from "./components/rootCenter";
import { RootRegular } from "./components/rootRegular";

class RootComponent extends Component {
  render() {
    const { children, location } = this.props;
    const { isLoggedIn } = this.props.rootStore.userInfoStore;
    const { pathname } = location;
    const shouldShowRoot = isLoggedIn && !pathname.includes("login");
    return shouldShowRoot ? (
      <RootRegular>{children}</RootRegular>
    ) : (
      <RootCenter>{children}</RootCenter>
    );
  }
}

export const Root = inject("rootStore")(withRouter(observer(RootComponent)));
