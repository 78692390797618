import React from "react";
import { DescriptiveCategorySelect } from "./components/DescriptiveCategorySelect";
import { DescriptiveTagSelect } from "./components/DescriptiveTagSelect";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
    title: {  
      marginBottom: 10,
      textAlign: "center",
      flex: "auto",
      color: theme.palette.primary.main,
    },
    input: {
      marginBottom: 50,
    }
  }));

export const DescriptiveTags = () => {
    const classes = useStyles();
  return (
    <div className={classes.input}>
      <Typography className={classes.title} component="h1" variant="h2">
        {t("screenAddNewBusiness.DescriptiveTagsTitle")}
      </Typography>
      <Grid item xs={12}>
        <DescriptiveCategorySelect />
        <DescriptiveTagSelect />
      </Grid>
    </div>
  );
};