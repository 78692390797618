import {
    format as dateFnsFormat,
    addDays as dateFnsAddDays,
    subDays as dateFnsSubDays,
    addMinutes as dateFnsAddMinutes,
    isAfter as dateFnsIsAfter,
    parseISO
} from 'date-fns';

const moment = require("moment-timezone");

export const DAY_TIME_FORMAT = 'dd/MM/yyyy HH:mm:ss';

export function format(date, format) {

    return dateFnsFormat(date, format, { awareOfUnicodeTokens: true });
}

export function toISOString(date) {

    return date.toISOString();
}

export function parseISOString(dateStr) {
    return parseISO(dateStr);
}

export function addDays(date, days) {

    return dateFnsAddDays(date, days);
}

export function subDays(date, days) {

    return dateFnsSubDays(date, days);
}

export function addMinutes(date, minutes) {

    return dateFnsAddMinutes(date, minutes);
}

export function isDate1AfterDate2(date1, date2) {

    return dateFnsIsAfter(date1, date2);
}

export function getNow() {
    return new Date();
}

export function getStartOfMonth() {
    return moment.tz('Asia/Jerusalem').startOf('month').toDate();

}

export function convertToMomentTz(date) {
    return moment(date).tz('Asia/Jerusalem');
}

export function getStartEndOfMonth(year, month) {
    const date = moment.tz('Asia/Jerusalem');
    date.year(year);
    date.month(month);
    const start = date.startOf('month').toDate();
    const end = date.endOf('month').toDate();
    return { start, end };
}

export function getYearAndMonth(date) {
    const year = moment(date).tz('Asia/Jerusalem').get('year');
    const month = moment(date).tz('Asia/Jerusalem').get('month');
    return { year, month };
}

export function isSameWithoutTime(date1, date2) {
    const moment1 = convertToMomentTz(date1);
    const moment2 = convertToMomentTz(date2);
    const sameYear = moment1.isSame(moment2, 'year');
    const sameMonth = moment1.isSame(moment2, 'month');
    const sameDay = moment1.isSame(moment2, 'day');
    return sameYear && sameMonth && sameDay;
}
