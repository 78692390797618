import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const HealthScore = observer(() => {
  const classes = useStyles();
  const store = useScreenCreateAddNewBusinessStore();
  const { healthScoreError } = store;

  const handleInputChange = (event) => {
    if (event.target.value < 0) {
      store.setHealthScoreError(true);
      return;
    }
    store.setHealthScoreError(null);
    store.setHealthScore(Number(event.target.value));
  };

  return (
    <TextField
      variant="outlined"
      name="Health Score"
      fullWidth
      label={t("screenAddNewBusiness.HealthScore") + " (Health Score)"}
      placeholder="25"
      type="number"
      onChange={handleInputChange}
      onWheel={handleBlockScroll}
      className={classes.input}
      error={healthScoreError}
      helperText={healthScoreError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
    />
  );
});