import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const Score = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();
  const { scoreError } = store;

  const handleInputChange = (event) => {
    if(!event.target.value) {
      store.scoreEmptyError = t("screenAddNewBusiness.Score");
      store.score = event.target.value;
      return;
    }
    if (event.target.value < 0) {
      store.scoreError = true;
      return;
    } 
    store.scoreEmptyError = null;
    store.scoreError = null;
    store.score = event.target.value;
  };

  return (
    <TextField
      required
      variant="outlined"
      fullWidth
      name="Score"
      value={store.score ? store.score : ""}
      label={t("screenAddNewBusiness.Score") + " (Score)"}
      type="number"
      onChange={handleInputChange}
      onWheel={handleBlockScroll}
      className={classes.input}
      error={scoreError}
      helperText={scoreError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
    />
  );
});