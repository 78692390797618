import React from "react";
import {InputLabel, Select, makeStyles, MenuItem, Grid} from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { challengeTypes } from "../../../../../../../../addCouponType/store/components/form/GeneralInfo/components/challengeCoupon/components/logic/challengeTypes";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  category: { margin: theme.spacing(1), minWidth: 300, border: 1, borderColor: "black" },
  selectEmpty: { marginBottom: 10, marginTop: 10 }
}));

export const ChallengeTypeSelect = observer(() => {
  const store = useEditCouponTypeStore();
  const { challengeTypeSelected } = store;
  const classes = useStyles();

  const challengeTypesOptions = challengeTypes.map((type) => {return <MenuItem key={type.challengeTypeId} value={type.challengeTypeId}>{type.challengeTypeName} - {type.challengeTypeId}</MenuItem>});

  const handleChange = (event) => {
    store.challengeTypeSelected = event.target.value;
    store.hasChallengeBeenEdited = true;
  };

  return (
    <Grid className={classes.selectEmpty} item xs={9}>
      <InputLabel>{t("addNewCouponType.challengeTypeSelectLabel") + " *"}</InputLabel>
      <Select
        onChange={handleChange}
        className={classes.category}
        value={challengeTypeSelected ? challengeTypeSelected : ""}
      >
        {challengeTypesOptions}
      </Select>
    </Grid>
  );
});