import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { observer } from 'mobx-react-lite';
import { useScreenHomeStore } from "../../store";
import { makeStyles } from "@material-ui/styles";
import { t } from 'common/localization/translate';

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 16,
            fontFamily: 'Assistant',
            fontWeight: 'bold'
        }
    }
}));

export const ChangeMonthPopUp = observer(() => {
    const store = useScreenHomeStore();
    const classes = useStyles();

    const handleCloseClickYes = () => {
        store.changeMonthDecision = true;
        store.month = store.selectedDate;
        store.changeMonthPopUp = false;
    };

    const handleCloseClickNo = () => {
        store.changeMonthDecision = false;
        store.changeMonthPopUp = false;
    };

    return (
        <div>
            <Dialog open={store.changeMonthPopUp}>
            <DialogTitle className={classes.title} >{t("suppliers.supplierHome.loadOnPreviousMonthPopUpTitle")}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t("suppliers.supplierHome.insertCodesOnPreviousMonthQuestion")}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseClickNo} color="primary">
                {t("suppliers.supplierHome.insertCodesOnPreviousMonthAnswerNo")}
          </Button>
                <Button onClick={handleCloseClickYes} color="primary" autoFocus >
                {t("suppliers.supplierHome.insertCodesOnPreviousMonthAnswerYes")}
          </Button>
            </DialogActions>
            </Dialog>
        </div >
    );
})