import React from "react";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { makeStyles } from "@material-ui/styles";
import { TextField } from "@material-ui/core";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    "&::placeholder": {
      fontSize: 12,
    },
  },
}));

export const Tags = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();

  const handleInputChange = (event) => {
    if(!event.target.value){
      store.tagsEmptyError = t("screenAddNewBusiness.TagsLabel");
      store.businessTags = event.target.value;
      return;
    }
    store.tagsEmptyError = null;
    store.businessTags = event.target.value;
  };

  return (
      <TextField
        required
        InputProps={{ classes: { input: classes.input } }}
        fullWidth
        variant="outlined"
        multiline
        rows={5}
        onChange={handleInputChange}
        value={store.businessTags ? store.businessTags : ""}
        label={t("screenAddNewBusiness.TagsLabel")}
        placeholder={t("screenAddNewBusiness.TagsPlaceholder")}
        className={classes.input}
      />
  );
});