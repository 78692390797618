import React from "react";
import { makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditAuctionStore } from "screens/controlPanel/editAuction/store";
import { t } from "common/localization/translate";
import Link from '@material-ui/core/Link';
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 20,
  },
}));

export const AuctionImagePreviousUrl = observer(() => {
  const classes = useStyles();
  const store = useEditAuctionStore();
  const linkContent = store.imageURL ? <Link href={store.imageURL} target="_blank">{t("editBusiness.currentImage")}</Link> : null

  return (
    <Grid className={classes.input}>
      {linkContent}
    </Grid>
  );
});