import React from "react";
import { TextField, makeStyles, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../..";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 40,
    maxWidth: 450
  }
}));

export const ArticleSubTitle = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();
  const articleSubtitle = store.articleSubTitle || "";
  
  const handleInputChange = (event) => {
      store.articleSubTitle = event.target.value;
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <TextField
        variant="outlined"
        value={articleSubtitle}
        fullWidth
        label={t("editArticle.articleSubTitle")}
        onChange={handleInputChange}
      />
    </Grid>
  );
});