import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import { segmentIds } from "screens/controlPanel/addCouponType/store/logic/segmentIds";
import { Grid } from "@material-ui/core";
import { isSupplierSegment } from "../../../../../logic/isSupplierSegment";
import { handleBlockScroll} from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  grid: {
    marginBottom: 20,
  }
}));

export const CostToSupplier = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();
  const costToSupplierLabel = store.segments.includes(segmentIds.CLALIT_SEGMENT_ID) ? t("addNewCouponType.costToMushlam") : t("addNewCouponType.costToOvdim");

  const handleInputChange = (event) => {
    if(!event.target.value) {
      return store.costToSupplierEmptyError = costToSupplierLabel;
    }
    store.costToSupplierEmptyError = null;
    store.costToSupplier = event.target.value;
  };

  if(!isSupplierSegment(store.segments)) {
    return null;
  }
  return (
    <Grid item xs={12} className={classes.grid}>
      <TextField
        variant="outlined"
        fullWidth
        label={costToSupplierLabel}
        type="number"
        onChange={handleInputChange}
        onWheel={handleBlockScroll}
        className={classes.input}
      />
    </Grid>
  );
});

