import React from "react";
import { decorate, action, observable } from "mobx";
import { t } from "common/localization/translate";
import { createBusiness } from "../store/server/createBusiness";
import { limitationTypes } from "../store/components/form/components/AccountInformation/components/limitationSelect/limitationTypes";
import { getCategories } from "./server/getCategories";
import { getSegments } from "./server/getSegments";
import { getDescriptiveTagsCategories } from "./server/getDescriptiveTagsCategories";
import { getDescriptiveTagsByCategory } from "./server/getDescriptiveTagsByCategory";
import { addNewBusinessErrorCodes } from './addNewBusinessErrorCodes';
import { segmentIds } from "../../addCouponType/store/logic/segmentIds";
import { getSuppliers } from "../../addCouponType/store/server/getSuppliers";

const ScreenCreateAddNewBusinessStoreContext = React.createContext({});

export const ScreenCreateAddNewBusinessStoreProvider =
  ScreenCreateAddNewBusinessStoreContext.Provider;

export const useScreenCreateAddNewBusinessStore = () =>
  React.useContext(ScreenCreateAddNewBusinessStoreContext);

class ScreenCreateAddNewBusinessStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  };
  
  initProperties() {
    this.primaryCategory = [];
    this.failedLocations = [];
    this.categoriesList = [];
    this.segmentsList = [];
    this.descriptiveTagsCategoriesList = [];
    this.descriptiveTagsByCategoryList = [];
    this.segments = [];
    this.subtitle = null;
    this.businessHours = null;
    this.businessFacebook = null;
    this.businessWebsite = null;
    this.businessInstagram = null;
    this.customerServiceEmail = null;
    this.customerServicePhone = null;
    this.startWorkingDate = null;
    this.commission = null;
    this.commissionPercent = null;
    this.isEmailAtPurchase = true;
    this.isEmailAtNotification = true;
    this.isEmailAtLike = false;
    this.isKosher = false;
    this.isActive = false;
    this.isUnhealthy = false;
    this.isLocal = false;
    this.isOnline = false;
    this.isVeg = false;
    this.isNegNumberErrorPopUpOpen = false;
    this.isEmptyFieldErrorPopUpOpen = false;
    this.isLocationFailedErrorPopUpOpen = false;
    this.isBusinessCreatedSuccessPopUpOpen = false;
    this.isBusinessFailedErrorPopUpOpen = false;
    this.isFetching = false;
    this.healthScore=25;
    this.limitationType=limitationTypes[3];
    this.contactNameEmptyError = t("screenAddNewBusiness.ContactName");
    this.contactEmailEmptyError = t("screenAddNewBusiness.ContactEmail");
    this.contactPhoneEmptyError = t("screenAddNewBusiness.ContactPhone");
    this.businessNameEmptyError = t("screenAddNewBusiness.BusinessName");
    this.businessLogoEmptyError = t("screenAddNewBusiness.BusinessLogo");
    this.businessLocationEmptyError = t("screenAddNewBusiness.BusinessLocation");
    this.businessDescriptionEmptyError = t("screenAddNewBusiness.BusinessDescription");
    this.scoreEmptyError = t("screenAddNewBusiness.Score");
    this.tagsEmptyError = t("screenAddNewBusiness.TagsLabel");
    this.segmentsEmptyError = t("screenAddNewBusiness.SegmentChoice");
    this.primaryCategoryEmptyError = t("screenAddNewBusiness.PrimaryCategory");
    this.descriptiveTagsEmptyError = t("screenAddNewBusiness.DescriptiveTagSelect");
    this.getSegmentsList();
    this.getCategoriesList();
    this.getDescriptiveTagsCategoriesList();
  };

  async getSuppliersList() {
    this.suppliersList = await getSuppliers(segmentIds.CLALIT_SEGMENT_ID);
  };

  setBusinessName(businessName) {
    this.businessName = businessName;
  };

  setSubtitle(subtitle) {
    this.subtitle = subtitle;
  };

  setBusinessTags(businessTags) {
    this.businessTags = businessTags;
  };

  setBusinessLogo(businessLogo) {
    this.businessLogo = businessLogo;
  };

  async setSegments(segments) {
    this.segments = segments;
    if(this.segments.includes(segmentIds.CLALIT_SEGMENT_ID)){
      await this.getSuppliersList();
    }
  };

  setPrimaryCategory(primaryCategory) {
    this.primaryCategory = primaryCategory;
  };

  setSubCategories(subCategories) {
    this.subCategories = subCategories;
  };

  setBusinessDescription(businessDescription) {
    this.businessDescription = businessDescription;
  };

  setIsKosher(isKosher) {
    this.isKosher = isKosher;
  };

  setIsActive(isActive) {
    this.isActive = isActive;
  };

  setIsUnhealthy(isUnhealthy) {
    this.isUnhealthy = isUnhealthy;
  };

  setIsLocal(isLocal) {
    this.isLocal = isLocal;
  };

  setIsOnline(isOnline) {
    this.isOnline = isOnline;
  };

  setIsVeg(isVeg) {
    this.isVeg = isVeg;
  };

  setBusinessHours(businessHours) {
    this.businessHours = businessHours;
  };

  setBusinessLocation(businessLocation) {
    this.businessLocation = businessLocation;
  };

  setCustomerServicePhone(customerServicePhone) {
    this.customerServicePhone = customerServicePhone;
  };

  setContactPhone(contactPhone) {
    this.contactPhone = contactPhone;
  };

  setCommission(commission) {
    this.commission = commission;
  };

  setCommissionError(commissionError) {
    this.commissionError = commissionError
  };

  setCommissionPercent(commissionPercent) {
    this.commissionPercent = commissionPercent;
  };

  setCommissionPercentError(commissionPercentError) {
    this.commissionPercentError = commissionPercentError;
  };

  setRetainer(retainer) {
    this.retainer = retainer;
  };

  setRetainerError(retainerError) {
    this.retainerError = retainerError;
  };

  setHealthScore(healthScore) {
    this.healthScore = healthScore;
  };

  setHealthScoreError(healthScoreError) {
    this.healthScoreError = healthScoreError;
  };

  setScore(score) {
    this.score = score;
  };

  setScoreError(scoreError) {
    this.scoreError = scoreError;
  };

  setContactEmail(contactEmail) {
    this.contactEmail = contactEmail;
  };

  setContactEmailEmptyError(contactEmailEmptyError) {
    this.contactEmailEmptyError = contactEmailEmptyError;
  };

  setContactName(contactName) {
    this.contactName = contactName;
  };

  setContactNameEmptyError(contactNameEmptyError) {
    this.contactNameEmptyError = contactNameEmptyError;
  };

  setCustomerServiceEmail(customerServiceEmail) {
    this.customerServiceEmail = customerServiceEmail;
  };

  setBusinessFacebook(businessFacebook) {
    this.businessFacebook = businessFacebook;
  };

  setBusinessInstagram(businessInstagram) {
    this.businessInstagram = businessInstagram;
  };

  setBusinessWebsite(businessWebsite) {
    this.businessWebsite = businessWebsite;
  };

  setIsEmailAtLike(isEmailAtLike) {
    this.isEmailAtLike = isEmailAtLike;
  };

  setIsEmailAtPurchase(isEmailAtPurchase) {
    this.isEmailAtPurchase = isEmailAtPurchase;
  };

  setIsEmailAtNotification(isEmailAtNotification) {
    this.isEmailAtNotification = isEmailAtNotification;
  };

  setDescriptiveTagsCategory(descriptiveTagsCategory) {
    this.descriptiveTagsCategory = descriptiveTagsCategory;
  };

  setDescriptiveTags(descriptiveTags) {
    this.descriptiveTags = descriptiveTags;
  };

  setLimitationType(limitationType) {
    this.limitationType = limitationType;
  };

  setLimitationCountError(limitationCountError) {
    this.limitationCountError = limitationCountError;
  };

  setLimitationTimeError(limitationTimeError) {
    this.limitationTimeError = limitationTimeError;
  };

  setContactPhoneEmptyError(contactPhoneEmptyError) {
    this.contactPhoneEmptyError = contactPhoneEmptyError;
  };

  setBusinessNameEmptyError(businessNameEmptyError) {
    this.businessNameEmptyError = businessNameEmptyError;
  };

  setBusinessLogoEmptyError(businessLogoEmptyError) {
    this.businessLogoEmptyError = businessLogoEmptyError;
  };

  setBusinessLocationEmptyError(businessLocationEmptyError) {
    this.businessLocationEmptyError = businessLocationEmptyError;
  };

  setBusinessDescriptionEmptyError(businessDescriptionEmptyError) {
    this.businessDescriptionEmptyError = businessDescriptionEmptyError;
  };

  setScoreEmptyError(scoreEmptyError) {
    this.scoreEmptyError = scoreEmptyError;
  };

  setTagsEmptyError(tagsEmptyError) {
    this.tagsEmptyError = tagsEmptyError;
  };

  setSegmentsEmptyError(segmentsEmptyError) {
    this.segmentsEmptyError = segmentsEmptyError;
  };

  setPrimaryCategoryEmptyError(primaryCategoryEmptyError) {
    this.primaryCategoryEmptyError = primaryCategoryEmptyError;
  };

  setDescriptiveTagsEmptyError(descriptiveTagsEmptyError) {
    this.descriptiveTagsEmptyError = descriptiveTagsEmptyError;
  };

  setLimitationParams(limitationParams) {
    this.limitationParams = limitationParams;
  };
  
  setStartWorkingWithBusinessDate(startWorkingWithBusinessDate) {
    this.startWorkingWithBusinessDate = startWorkingWithBusinessDate;
    this.startWorkingDate = this.startWorkingWithBusinessDate ? this.startWorkingWithBusinessDate.toDate() : null;

  };
  
  setIsNegNumberErrorPopUpOpen(isNegNumberErrorPopUpOpen) {
    this.isNegNumberErrorPopUpOpen= isNegNumberErrorPopUpOpen;
  };

  setIsEmptyFieldErrorPopUpOpen(isEmptyFieldErrorPopUpOpen) {
    this.isEmptyFieldErrorPopUpOpen= isEmptyFieldErrorPopUpOpen;
  };

  setIsLocationFailedErrorPopUpOpen(isLocationFailedErrorPopUpOpen) {
    this.isLocationFailedErrorPopUpOpen = isLocationFailedErrorPopUpOpen;
  };

  setIsBusinessCreatedSuccessPopUpOpen(isBusinessCreatedSuccessPopUpOpen) {
    this.isBusinessCreatedSuccessPopUpOpen = isBusinessCreatedSuccessPopUpOpen;
  };

  setIsBusinessFailedErrorPopUpOpen(isBusinessFailedErrorPopUpOpen) {
    this.isBusinessFailedErrorPopUpOpen = isBusinessFailedErrorPopUpOpen;
  };

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  };

  setErrorCode(errorCode) {
    this.errorCode = errorCode;
  };

  setFailedLocations(failedLocations) {
    this.failedLocations = failedLocations;
  };

  setBusinessCreationStatus(businessCreationStatus) {
    this.businessCreationStatus = businessCreationStatus;
  };

  setCategoriesList(categoriesList) {
    this.categoriesList = categoriesList;
  };

  setSegmentsList(segmentsList) {
    this.segmentsList = segmentsList;
  };

  setDescriptiveTagsCategoriesList(descriptiveTagsCategoriesList) {
    this.descriptiveTagsCategoriesList = descriptiveTagsCategoriesList;
  };

  setDescriptiveTagsByCategoryList(descriptiveTagsByCategoryList) {
    this.descriptiveTagsByCategoryList = descriptiveTagsByCategoryList;
  };

  async getCategoriesList() {
    const categories = await getCategories();
    this.setCategoriesList(categories);
  };

  async getSegmentsList() {
    const segments = await getSegments();
    this.setSegmentsList(segments);
  };

  async getDescriptiveTagsCategoriesList() {
    const tagsCategories = await getDescriptiveTagsCategories();
    tagsCategories.forEach((item, i) => { item.id = i + 1 });
    this.setDescriptiveTagsCategoriesList(tagsCategories);
  };

  async getDescriptiveTagsByCategoryList() {
    const descriptiveTags = await getDescriptiveTagsByCategory({ categoryTags: this.descriptiveTagsCategory });
    this.setDescriptiveTagsByCategoryList(descriptiveTags[0].descriptive_tags);
  };

  async submitCreateBusiness() {

    this.setIsFetching(true);

    const res = await createBusiness({ businessName: this.businessName, businessHours: this.businessHours, 
      businessLogo: this.businessLogo, businessLocation: this.businessLocation, subtitle: this.subtitle, 
      businessWebsite: this.businessWebsite, businessFacebook: this.businessFacebook, businessInstagram: this.businessInstagram, 
      businessDescription: this.businessDescription, score: this.score, isOnline: this.isOnline, businessTags: this.businessTags, 
      healthScore: this.healthScore, contactName: this.contactName, contactEmail: this.contactEmail, 
      contactPhone: this.contactPhone, customerServicePhone: this.customerServicePhone, isKosher: this.isKosher, isActive: this.isActive,
      isVeg: this.isVeg, isLocal: this.isLocal, isUnhealthy: this.isUnhealthy, customerServiceEmail: this.customerServiceEmail, 
      segments: this.segments, primaryCategory: this.primaryCategory, subCategories: this.subCategories,
       limitationType: this.limitationType.type, limitationParams: this.limitationParams, descriptiveTags: this.descriptiveTags, 
       isEmailAtPurchase: this.isEmailAtPurchase, isEmailAtLike: this.isEmailAtLike, 
       isEmailAtNotification: this.isEmailAtNotification, retainer: this.retainer, commission: this.commission, 
       commissionPercent: this.commissionPercent, startWorkingWithBusinessDate: this.startWorkingDate, supplierId: this.supplierId });
    
    this.setErrorCode(res.errorCode);
    this.setBusinessCreationStatus(res.isSuccess);
    this.setIsFetching(false);

    !this.businessCreationStatus && this.errorCode === addNewBusinessErrorCodes.ERROR_TYPE_LOCATION_FAILED ? 
    this.setFailedLocations(res.extra.map((location => location.locationText))) : 
    this.setFailedLocations([]);
  }
};

decorate(ScreenCreateAddNewBusinessStore, {
  customerServicePhone: observable,
  businessCreationStatus: observable,
  businessLocation: observable,
  businessHours: observable,
  businessDescription: observable,
  businessName: observable,
  subtitle: observable,
  businessTags: observable,
  primaryCategory: observable,
  categoriesList: observable,
  descriptiveTagsCategoriesList: observable,
  descriptiveTagsByCategoryList: observable,
  segmentsList: observable,
  failedLocations: observable,
  subCategories: observable,
  segments: observable,
  commission: observable,
  commissionPercent: observable,
  retainer: observable,
  healthScore: observable,
  score: observable,
  contactEmail: observable,
  contactName: observable,
  contactPhone: observable,
  customerServiceEmail: observable,
  businessFacebook: observable,
  businessInstagram: observable,
  businessWebsite: observable,
  isKosher: observable,
  isActive: observable,
  isUnhealthy: observable,
  isLocal: observable,
  isOnline: observable,
  isVeg: observable,
  isEmailAtNotification: observable,
  isEmailAtLike: observable,
  isEmailAtPurchase: observable,
  isFetching: observable,
  limitationParams: observable,
  startWorkingWithBusinessDate: observable,
  descriptiveTagsCategory: observable,
  descriptiveTags: observable,
  limitationType: observable,
  limitationCountError: observable,
  limitationTimeError: observable,
  commissionError: observable,
  commissionPercentError: observable,
  retainerError: observable,
  healthScoreError: observable,
  scoreError: observable,
  errorCode: observable,
  contactNameEmptyError: observable,
  contactEmailEmptyError: observable,
  contactPhoneEmptyError: observable,
  businessNameEmptyError: observable,
  businessLogoEmptyError: observable,
  businessLocationEmptyError: observable,
  businessDescriptionEmptyError: observable,
  scoreEmptyError: observable,
  tagsEmptyError: observable,
  segmentsEmptyError: observable,
  primaryCategoryEmptyError: observable,
  descriptiveTagsEmptyError: observable,
  isNegNumberErrorPopUpOpen: observable,
  isEmptyFieldErrorPopUpOpen: observable,
  isLocationFailedErrorPopUpOpen: observable,
  isBusinessCreatedSuccessPopUpOpen: observable,
  isBusinessFailedErrorPopUpOpen: observable,
  suppliersList: observable,
  supplierId: observable,
  initProperties: action.bound,
});

export function createScreenAddNewBusinessStore(rootStore) {
  const store = new ScreenCreateAddNewBusinessStore(rootStore);
  return store;
};