import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { observer } from "mobx-react-lite";
import { useDeleteUserStoreStore } from "../../index";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";
import { DeleteUserButton } from "../form/components/deleteUserButton";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.white,
      fontSize: 16,
      fontWeight: "bold",
    },
  },
  action: {
    justifyContent: "space-between",
  },
}));

export const AreYouSurePopUp = observer(() => {
  const store = useDeleteUserStoreStore();
  const classes = useStyles();
  const { userIdForClean } = store;

  const handleClose = () => {
    store.areYouSurePopUp = false;
  };

  return (
    <Dialog open={store.areYouSurePopUp}>
      <DialogTitle className={classes.title}>
        {t("deleteUser.message")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("deleteUser.areYouSureDelete")}
          {userIdForClean}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button onClick={handleClose} color="primary" autoFocus>
          <DeleteUserButton />
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          {t("deleteUser.cancelDelete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
