import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import { getErrorCreateUserTextByErrorCode } from "../../../logic/getErrorCreateUserTextByErrorCode";
import { useScreenCreateBusinessDashboardUserStore } from "../../..";

const useStyles = makeStyles((theme) => ({
  errorText: {
    position: "absolute",
    marginTop: "60px",
    fontSize: "17px",
    fontFamily: "sans-serif",
    color: "red",
  },
}));

export const ErrorText = observer((props) => {
  const store = useScreenCreateBusinessDashboardUserStore();
  const classes = useStyles();
  const text = getErrorCreateUserTextByErrorCode(store.errorCode);
  return <p className={classes.errorText}>{text}</p>;
});
