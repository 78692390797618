import React from "react";
import { observer } from "mobx-react";
import { IsCreditCheckbox } from "./components/IsCreditCheckbox";
import { Price } from "./components/Price";
import { Grid } from "@material-ui/core";
import { PaymentOptionSelect } from "./components/paymentOptionSelect";
import { ExternalProductIdBySupplierInput } from './components/externalProductIdBySupplierInput';

export const CouponCreditPrice = observer(() => {
    return (
      <Grid>
        <IsCreditCheckbox />
        <PaymentOptionSelect />
        <Price />
        <ExternalProductIdBySupplierInput />
      </Grid>
    );
});
