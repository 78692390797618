import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { CommonTable } from '../../../common/ui/commonTable.js';
import { Root } from '../../../common/ui/root.js';
import { DatesPicker } from '../../../common/ui/datePicker/index.js';
import { InfoCard } from 'componentsRumble/infoCard/index.js';


export class SummaryOfPurchasesByBusinessComponent extends Component {

    constructor(props) {
        const { startAt, endAt } = props.rootStore.movement.storeMain;
        super();
        props.rootStore.movement.StoreSummaryOfPurchasesByBusiness.setStartAt(startAt);
        props.rootStore.movement.StoreSummaryOfPurchasesByBusiness.setEndAt(endAt);
    }

    componentDidMount() {
        this.props.rootStore.movement.StoreSummaryOfPurchasesByBusiness.getSummaryOfPurchasesByBusiness();
        document.title = "Purchases summary";
    }


    render() {
        const { startAt, endAt, onChangeRange, summaryByBusiness, countOfPurchases, totalBillForMovement } = this.props.rootStore.movement.StoreSummaryOfPurchasesByBusiness;
        const countOfLoadsText = `מספר הכניסות : ${countOfPurchases}`;
        const totalBillText = `סה"כ לתשלום : ₪${totalBillForMovement}`;
        const headerColumns = [
            { id: 'business name', label: 'שם העסק' },
            { id: 'total bill', label: 'סה"כ לתשלום' },
            { id: 'coupon title', label: 'ההטבה' },
            { id: 'number of purchases', label: 'מספר הכניסות' },
            { id: 'cost to movement', label: 'עלות לכניסה' },
        ];

        const renderRow = (item) =>
            <TableRow hover>
                <TableCell >{item['business name']}</TableCell>
                <TableCell >{item['total bill']}</TableCell>
                <TableCell >{item['coupon title']}</TableCell>
                <TableCell >{item['number of purchases']}</TableCell>
                <TableCell >{item['cost to movement']}</TableCell>
            </TableRow>

        return <Root>
            <DatesPicker startAt={startAt} endAt={endAt} onChangeRange={onChangeRange} />
            <InfoCard info={countOfLoadsText} />
            <InfoCard info={totalBillText} />
            <CommonTable
                defaultSortByColumn='title'
                renderRow={renderRow}
                items={summaryByBusiness}
                headerColumns={headerColumns} />
        </Root>
    }
}
export const SummaryOfPurchasesByBusiness = inject("rootStore")(observer(SummaryOfPurchasesByBusinessComponent));