import React from "react";
import { TextField, makeStyles, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../..";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
    marginTop: 10,
    marginLeft: 10,
    width: 450
  }
}));

export const TranslatedArticleName = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();

  const handleInputChange = (event) => {
      store.translatedArticleName = event.target.value;
  };

  if(!store.isTranslate) {
      return;
  }

  return (
    <Grid item xs={12} className={classes.input}>
      <TextField
        variant="outlined"
        value={store.translatedArticleName ? store.translatedArticleName : ""}
        fullWidth
        label={t("editArticle.translatedTitle")}
        onChange={handleInputChange}
      />
    </Grid>
  );
});