import React from "react";
import { OldRoot } from "oldRoot";
import { organizationRoutes } from "./organizationRoutes";
import { businessRoutes } from "./businessRoutes";
import { suppliersRoutes } from "./suppliersRoutes";
import { supportRoutes } from "./supportRoutes";
import { controlPanelRoutes } from "./controlPanelRoutes";

export const routes = [
  {
    path: "/org",
    component: () => <OldRoot />,
  },
  {
    path: "/biz",
    component: () => <OldRoot />,
  },
  {
    path: "/mov",
    component: () => <OldRoot />,
  },
  ...organizationRoutes,
  ...businessRoutes,
  ...supportRoutes,
  ...controlPanelRoutes,
  ...suppliersRoutes

];
