import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  label: { fontSize: 18 },
  input: { marginTop: 10, marginBottom: 10 }
}));

export const SelectOrderByDate = observer(() => {
  const store = useEditCouponTypeStore();
  const classes = useStyles();
  const { orderByDate } = store;

  const handleDateChange = (date) => {
    store.orderByDate = date;
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <InputLabel className={classes.label} shrink>
        {t("editCouponType.selectOrderByDate")}
      </InputLabel>
      <KeyboardDateTimePicker
        maxDate={new Date()}
        ampm={false}
        value={orderByDate}
        onChange={handleDateChange}
        openTo="year"
        format="HH:mm DD/MM/YYYY"
        invalidDateMessage={orderByDate ? true : false}
      />
    </Grid>
  );
});
