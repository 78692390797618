import { dashboardTypes } from "screens/loginV2/store/logic/dashboardTypes";
import navigationConfigOrganization from "./navigationConfigOrganization";
import navigationConfigBusiness from "./navigationConfigBusiness";
import navigationConfigSupport from "./navigationConfigSupport";
import navigationConfigSuppliers from "./navigationConfigSuppliers";
import navigationConfigControlPanel from "./navigationConfigControlPanel";

export function getNavigationConfigByDashboardType(dashboardType) {
  switch (dashboardType) {
    case dashboardTypes.BUSINESS:
      return navigationConfigBusiness;
    case dashboardTypes.ORGANIZATION:
      return navigationConfigOrganization;
    case dashboardTypes.SUPPORT:
      return navigationConfigSupport;
    case dashboardTypes.CONTROL_PANEL:
      return navigationConfigControlPanel;
    case dashboardTypes.SUPPLIERS:
      return navigationConfigSuppliers;
    default:
      return navigationConfigOrganization;
  }
}
