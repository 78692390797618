import React from 'react';
import { observer } from "mobx-react";
import { Grid } from '@material-ui/core';
import { NotificationNavigationDropDown } from './notificationNavigationDropDown';
import { ExternalLinkInput } from './externalLinkInput';
import { NotificationType } from './notificationType';
import { NotificationText } from './notificationText';


export const NotificationDetails = observer(() => {
    return (
        <Grid >
            <NotificationNavigationDropDown />
            <ExternalLinkInput />
            <NotificationType />
            <NotificationText />
        </Grid>
    );
})