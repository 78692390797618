import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { styles } from "./styles";
import { screenTypes } from "commonStores/userInfoStore/screenTypes";
import { SideMenuBiz } from "./components/sideMenuBiz";
import { getDashboardMode } from "common/getDashboardMode";
import { dashBoardModes } from "common/dashDashBoardModes";
import { SideMenuOrg } from "./components/sideMenuOrg";
import { SideMenuMov } from "./components/sideMenuMov";

class SideMenuComponent extends Component {
  onClickCouponsInfo = () => {
    this.props.rootStore.userInfoStore.setCurrentScreen(
      screenTypes.SCREEN_BIZ_PURCHASES
    );
    this.props.history.push(screenTypes.SCREEN_BIZ_PURCHASES);
  };

  onClickBranchesInfo = () => {
    this.props.rootStore.userInfoStore.setCurrentScreen(
      screenTypes.SCREEN_BIZ_BRANCHES_INFO
    );
    this.props.history.push(screenTypes.SCREEN_BIZ_BRANCHES_INFO);
  };

  onClickLogout = () => {
    this.props.rootStore.userInfoStore.logout();
  };

  render() {
    const { classes, rootStore, location } = this.props;
    const { pathname } = location;
    const dashboardMode = getDashboardMode(pathname);
    const { isLoggedIn } = rootStore.userInfoStore;
    const shouldShowDrawer = isLoggedIn && !pathname.includes("login")
    return shouldShowDrawer ? (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.toolbar} />
        {dashboardMode === dashBoardModes.MODE_BIZ ? <SideMenuBiz /> : null}
        {dashboardMode === dashBoardModes.MODE_ORG ? <SideMenuOrg /> : null}
        {dashboardMode === dashBoardModes.MODE_MOV ? <SideMenuMov /> : null}
        <Divider />
        <List>
          <ListItem button key="logout" onClick={this.onClickLogout}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary="התנתק" />
          </ListItem>
        </List>
      </Drawer>
    ) : null;
  }
}
export const SideMenu = withRouter(
  withStyles(styles)(inject("rootStore")(observer(SideMenuComponent)))
);
