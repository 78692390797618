import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const Score = observer(() => {
  const classes = useStyles();
  const store = useScreenCreateAddNewBusinessStore();
  const { scoreError } = store;

  const handleInputChange = (event) => {
    if (event.target.value < 0) {
      store.setScoreError(true);
      return;
    } else if (!event.target.value) {
      store.setScoreEmptyError(t("screenAddNewBusiness.Score"));
      return;
    }
    store.setScoreEmptyError(null);
    store.setScoreError(null);
    store.setScore(Number(event.target.value));
  };

  return (
    <TextField
      required
      variant="outlined"
      fullWidth
      name="Score"
      label={t("screenAddNewBusiness.Score") + " (Score)"}
      type="number"
      onChange={handleInputChange}
      onWheel={handleBlockScroll}
      className={classes.input}
      error={scoreError}
      helperText={scoreError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
    />
  );
});