import React from "react";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { SupplierIdSelect } from './components/SupplierIdSelect';
import { AddToExistingCouponTypeGroupCheckBox } from './components/addToExistingCouponTypeGroupCheckBox';
import { CouponTypesGroupSelect } from './components/couponTypesGroupSelect'
import { CouponTypeGroupTitle } from './components/couponTypeGroupTitle';

export const SupplierCouponType = observer(() => {
  return (
    <Grid item xs={12}>
    <SupplierIdSelect />
    <CouponTypeGroupTitle />
    <AddToExistingCouponTypeGroupCheckBox />
    <CouponTypesGroupSelect />
    </Grid>
  );
});
