import HomeIcon from '@material-ui/icons/Home';
import TableChartIcon from "@material-ui/icons/TableChart";
import GroupIcon from '@material-ui/icons/Group';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { t } from "common/localization/translate";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import WeightsIcon from '@material-ui/icons/FitnessCenter';

export default [
  {
    title: "תפריט",
    pages: [
      {
        title: t("sideBar.home.title"),
        href: `/${dashboardPaths.ORGANIZATION}/home`,
        icon: HomeIcon
      },
      {
        title: t("sideBar.coupons.title"),
        href: "/dashboards",
        icon: TableChartIcon,
        children: [
          {
            title: t("screenPurchases.table.title"),
            href: `/${dashboardPaths.ORGANIZATION}/purchases`
          },
          {
            title: t("screenPurchaseUsersInfo.table.title"),
            href: `/${dashboardPaths.ORGANIZATION}/purchaseUsersInfo`
          },
          {
            title: t("screenCouponsInInventory.table.title"),
            href: `/${dashboardPaths.ORGANIZATION}/couponsInInventory`
          }
        ]
      },
      {
        title: t("sideBar.subSegmentUsers.title"),
        href: `/${dashboardPaths.ORGANIZATION}/subSegmentUsers`,
        icon: GroupIcon
      },
      {
        title: t("sideBar.userConfirmaions.title"),
        href: "/organization/confirmations",
        icon: PlaylistAddCheckIcon
      },
      {
        title:t("sideBar.multiSegmentUsers.title"),
        href: `/${dashboardPaths.ORGANIZATION}/multiSegmentUsers`,
        icon: GroupIcon
      },
      {
        title:t("sideBar.appOpenByHours.title"),
        href: `/${dashboardPaths.ORGANIZATION}/appOpenByHours`,
        icon: AccountCircleIcon
      },
      {
        title:t("sideBar.healthAndFitnessData.title"),
        href: `/${dashboardPaths.ORGANIZATION}/healthAndFitnessData`,
        icon: WeightsIcon
      },
      {
        title:t("sideBar.traines.title"),
        href: `/${dashboardPaths.ORGANIZATION}/traineesInfo`,
        icon: SupervisedUserCircleIcon
      },
      {
        title:t("sideBar.staff.title"),
        href: `/${dashboardPaths.ORGANIZATION}/staffInfo`,
        icon: SupervisedUserCircleIcon
      },
      {
        title:t("sideBar.appOpenByHours.hideCoupon"),
        href: `/${dashboardPaths.ORGANIZATION}/hideCouponTypes`,
        icon: VisibilityOffIcon
      }
    ]
  }
];
