import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const CouponTypeGroupTitle = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();

  const handleInputChange = (event) => {
    if(!event.target.value) {
        store.groupTitleErrorText = t("addNewCouponType.groupTitleErrorText");
      return;
    }
    store.groupTitleErrorText = null;
    store.groupNewTitle = event.target.value;
  };
  if(store.isExistingCouponTypeGroup || !store.isSupplierDashboardCouponType){
      return null;
  }

  return (
    <TextField
      required
      variant="outlined"
      fullWidth
      label={t("addNewCouponType.groupTitle")}
      onChange={handleInputChange}
      className={classes.input}
    />
  );
});
