import React from "react";
import { TextField, makeStyles, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../../..";
import { t } from "common/localization/translate";
import { getTranslationTextsQuestion } from "screens/controlPanel/editArticle/store/logic/getTranslationTexts";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
    marginTop: 10,
    maxWidth: 450
  }
}));

export const TranslateQuestionInput = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();
  const componentValue = store.selectedQuestion ? getTranslationTextsQuestion(store.selectedQuestion, store.selectedLanguage) : "";

  const handleInputChange = (event) => {
    store.selectedQuestion.translation_texts ? store.selectedQuestion.translation_texts.body[store.selectedLanguage] = event.target.value : store.selectedQuestion.translation_texts  = {body: {[store.selectedLanguage]: event.target.value}};
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <TextField
        variant="outlined"
        value={componentValue}
        fullWidth
        label={t("editArticle.translateQuestion")}
        onChange={handleInputChange}
      />
    </Grid>
  );
});