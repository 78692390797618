import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import moment from 'moment';
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
    textAlign: "center",
    flex: "auto",
  },

}));

export const CreatedAt = observer(() => {
  const store = useEditBusinessStore();
  const classes = useStyles();

  return (
        <TextField
          disabled
          variant="outlined"
          label={t("editBusiness.businessCreatedAt")}
          fullWidth
          value={moment(store.businessCreatedAt).format('HH:mm ,DD/MM/YYYY')}
          className={classes.input}
        />
  );
});
