import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const DaysRangeInput = observer(() => {
  const classes = useStyles();
  const store = useEditCouponTypeStore();

  const handleInputChange = (event) => {
    if (!event.target.value) {
      return;
    }
    store.daysRangeInput = Number(event.target.value);
    store.hasChallengeBeenEdited = true;
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      label={t("addNewCouponType.daysRangeInput") + " *"}
      onChange={handleInputChange}
      type="number"
      onWheel={handleBlockScroll}
      className={classes.input}
      value={store.daysRangeInput || ""}
    />
  );
});
