import { t } from "common/localization/translate";

export const activationTypes = [
  { activationTypeId: "0", name: t("addNewCouponType.ActivationTypeByCode") },
  { activationTypeId: "1", name: t("addNewCouponType.ActivationTypeByLink")  },
  { activationTypeId: "2", name: t("addNewCouponType.ActivationTypeBothCodeAndLink")  },
  { activationTypeId: "4", name: t("addNewCouponType.ActivationTypeBothCodeAndPhone")  },
  { activationTypeId: "5", name: t("addNewCouponType.ActivationTypeSupplierId")  },
  { activationTypeId: "6", name: t("addNewCouponType.ActivationTypePickUp")  },
  { activationTypeId: "7", name: t("addNewCouponType.ActivationTypeDonation")  },
];