import React from "react";
import { t } from "common/localization/translate";
import { Typography, Grid } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({

    toolTipIcon: {
        color: theme.palette.primary.main,
        fontSize: "32px",
        marginLeft: 15,
        paddingTop: '5px'
    },
    title: {
        fontSize: '25px',
        marginTop: 10,
        marginLeft: 2
    },
    subTitle: {
        marginLeft: 4
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 20,
    },
}));


export const PageTitles = () => {
    const classes = useStyles();
    const screenToolTip = (
        <div>
            {t("organizationDashboard.confirmations.tooltip1")} <br />
            {t("organizationDashboard.confirmations.tooltip2")} <br />
        </div>
    );
    return (
        <Grid>
            <Grid className={classes.header} >
                <Typography className={classes.title} component="h2" gutterBottom variant="overline">
                    {t("screenConfirmationUser.title")}
                </Typography>
                <Tooltip title={screenToolTip} >
                    <HelpIcon className={classes.toolTipIcon} />
                </Tooltip>
            </Grid>
            <Grid>
                <Typography variant="subtitle2" className={classes.subTitle}>
                    {t("screenConfirmationUser.subtitle")}
                </Typography>
            </Grid>
        </Grid>
    );
}