import React from "react";
import { decorate, action, observable, computed } from "mobx";
import { getConfirmationsData as getScreenDataServer } from "./server/getScreenData";
import { validateIdNum } from './logic/validateIdNumber'

const ScreenConfirmationsUsersStoreContext = React.createContext({});
export const ScreenConfirmationsUsersDataProvider =
ScreenConfirmationsUsersStoreContext.Provider;
  export const useScreenConfirmationsUseresStore = () =>
  React.useContext(ScreenConfirmationsUsersStoreContext);

class ScreenConfirmationsUsersStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }

  initProperties() {
    this.userIdNum = null;
    this.errorCode = null;
    this.isFetching = false;
    this.user = null;
    this.allowToAccessToConfirmations=this.rootStore.userInfoStore.isShowSubSegmentUsersScreen;
  }

  setErrorCode(errorCode){
    this.errorCode=errorCode;
  }
  setUserId(userIdNum){
    this.userIdNum=userIdNum;
  }
  submitIdNum(){
    const errors = validateIdNum(this.userIdNum);
    this.setErrorCode(errors);
    if (!errors) {
      this.getUserData();
    }
  }


  async getUserData(){
     this.setUserData(null);
    this.setIsFetching(true);
    const segmentId = this.rootStore.userInfoStore.currentSegment
    ? this.rootStore.userInfoStore.currentSegment.segmentId
    : null;
    const response= await getScreenDataServer(this.userIdNum, segmentId);
    this.setErrorCode(response.errorCode);
    this.setUserData(response);
    this.setIsFetching(false);
  }

  setUserData(response){
      this.user=response;
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }

   get confirmationsUserTableData() {
    return this.user;
  }

}

decorate(ScreenConfirmationsUsersStore, {
  allowToAccessToConfirmations:observable,
  userIdNum: observable,
  isFetching: observable,
  errorCode: observable,
  user: observable,
  confirmationsUserTableData: computed,
  setUserData:action.bound,
  setErrorCode:action.bound,
  setUserId: action.bound,
  setErrorText : action.bound,
  initProperties: action.bound,
  setIsFetching: action.bound,
});

export function createScreenConfirmationsUsersStore(rootStore) {
  const store = new ScreenConfirmationsUsersStore(rootStore);
  return store;
}
