import { decorate, action, flow, observable } from 'mobx';
import { getMainData as doGetMainData } from './server/getMainData';
import { getStartOfMonth, getNow, toISOString } from 'common/dateAndTime';

export class StoreMain {

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.initProperties();

    }

    initProperties() {
        this.startAt = getStartOfMonth();
        this.endAt = getNow();
        this.totalChargesFromOrganizations = 0;
        this.totalBillForMovement = 0;
    }

    getMainData = flow(function* () {
        const data = yield doGetMainData(toISOString(this.startAt), toISOString(this.endAt));
        this.totalChargesFromOrganizations = data.totalChargesFromOrganizations;
        this.totalBillForMovement = data.totalBillForMovement;
    });

    setIsLoading(loading) {
        this.isLoading = loading;
    }

    setStartAt(date) {
        this.startAt = date;
    }

    setEndAt(date) {
        this.endAt = date;
    };

    onChangeRange(startDate, endDate) {
        this.startAt = startDate;
        this.endAt = endDate;
        this.getMainData();
    }
}

decorate(StoreMain, {
    startAt: observable,
    endAt: observable,
    totalChargesFromOrganizations: observable,
    totalBillForMovement: observable,
    isLoading: observable,
    getMainData: action.bound,
    setStartAt: action.bound,
    setEndAt: action.bound,
    onChangeRange: action.bound,
    initProperties: action.bound
});