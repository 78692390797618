import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { BusinessDescription } from "./components/BusinessDescription";
import { BusinessHours } from "./components/BusinessHours";
import { BusinessBranchToAdd } from "./components/businessBranchToAdd";
import { BusinessName } from "./components/BusinessName";
import { BusinessLogo } from "./components/BusinessLogo";
import { PrimaryCategorySelect } from "./components/PrimaryCategorySelect";
import { SegmentSelect } from "./components/SegmentChoice";
import { SubCategorySelect } from "./components/SubCategorySelect";
import { SubtitleInput } from "./components/SubtitleInput";
import { t } from "common/localization/translate";
import { BusinessLogoUrl } from "./components/businessLogoUrl";
import { CreatedAt } from "./components/createdAt";
import { BusinessId } from "./components/businessId";
import { BusinessUuid } from "./components/businessUuid";
import { SubCategoriesToDelete } from "./components/subCategoriesToDelete";
import { RemoveBranch } from "./components/removeBranch";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 10,
    textAlign: "center",
    flex: "auto",
    color: theme.palette.primary.main,
  },
  input: {
    marginBottom: 50,
    marginTop: 20
  }
}));

export const General = () => {
  const classes = useStyles();
  return (
    <div className={classes.input}>
      <Typography className={classes.title} component="h1" variant="h2">
        {t("screenAddNewBusiness.GeneralInfo")}
      </Typography>
        <BusinessName />
        <CreatedAt />
        <BusinessId />
        <BusinessUuid />
        <SubtitleInput />
        <BusinessDescription />
        <BusinessLogo />
        <BusinessLogoUrl />
        <PrimaryCategorySelect />
        <SubCategoriesToDelete />
        <SubCategorySelect />
        <BusinessHours />
        <RemoveBranch />
        <BusinessBranchToAdd />
        <SegmentSelect />
    </div>
  );
};