import { httpCall } from "common/httpCall";

export async function createBusiness({ businessName, businessHours, businessLogo, businessLocation, subtitle, businessWebsite, 
    businessFacebook, businessInstagram, businessDescription, score, isOnline, businessTags, healthScore, contactName, 
    contactEmail, contactPhone, customerServicePhone, isKosher, isVeg, isLocal, isUnhealthy, isActive, customerServiceEmail, segments,
    primaryCategory, subCategories, limitationType, limitationParams, descriptiveTags, isEmailAtPurchase, isEmailAtLike, 
    isEmailAtNotification, retainer, commission, commissionPercent, startWorkingWithBusinessDate, supplierId }) {

    const res = await httpCall("controlpanel/addNewBusiness", { businessName, businessHours, businessLogo, businessLocation,
      subtitle, businessWebsite, businessFacebook, businessInstagram, businessDescription, score, isOnline, businessTags, 
      healthScore, contactName, contactEmail, contactPhone, customerServicePhone, isKosher, isVeg, isLocal, isUnhealthy, isActive,
      customerServiceEmail, segments, primaryCategory, subCategories, limitationType, limitationParams, descriptiveTags, 
      isEmailAtPurchase, isEmailAtLike, isEmailAtNotification, retainer, commission, commissionPercent, 
      startWorkingWithBusinessDate, supplierId });

  return res;
}
