import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { CommonTable } from '../../../common/ui/commonTable.js';
// import { DAY_TIME_FORMAT, parseISOString } from '../../../common/dateAndTime';
// import { format } from 'date-fns/esm';
import { Root } from '../../../common/ui/root.js';
import { DatesPicker } from '../../../common/ui/datePicker/index.js';
import { InfoCard } from 'componentsRumble/infoCard/index.js';


export class SummaryOfLoadingsPerOrganizationComponent extends Component {

    constructor(props) {
        const { startAt, endAt } = props.rootStore.movement.storeMain;
        super();
        props.rootStore.movement.StoreSummaryOfLoadingsPerOrganization.setStartAt(startAt);
        props.rootStore.movement.StoreSummaryOfLoadingsPerOrganization.setEndAt(endAt);
    }

    componentDidMount() {
        this.props.rootStore.movement.StoreSummaryOfLoadingsPerOrganization.getSummeryLoadsPerOrganization();
        document.title = "Loadings summary";
    }


    render() {
        const { startAt,
            endAt,
            onChangeRange,
            loadsPerOrganization,
            countOfLoadings,
            totalChargeFromOrganizations } = this.props.rootStore.movement.StoreSummaryOfLoadingsPerOrganization;
        const countOfLoadsText = `מספר ההטענות : ${countOfLoadings}`;
        const totalBillText = `סה"כ לגבייה : ₪${totalChargeFromOrganizations}`;
        const headerColumns = [
            { id: 'org name', label: 'שם הארגון' },
            { id: 'total to charge', label: 'סה"כ לגבייה מהארגון' },
            { id: 'count of loads', label: 'כמות ההטענות' },
            { id: 'org cost', label: 'מחיר להטענה' },
            { id: 'type of load', label: 'סוג ההטענה' },
        ];

        const renderRow = (item) =>
            <TableRow hover>
                <TableCell >{item['org name']}</TableCell>
                <TableCell >{item['total to charge']}</TableCell>
                <TableCell >{item['count of loads']}</TableCell>
                <TableCell >{item['org cost']}</TableCell>
                <TableCell >{item['type of load']}</TableCell>
            </TableRow>

        return <Root>
            <DatesPicker startAt={startAt} endAt={endAt} onChangeRange={onChangeRange} />
            <InfoCard info={countOfLoadsText} />
            <InfoCard info={totalBillText} />
            <CommonTable
                defaultSortByColumn='title'
                renderRow={renderRow}
                items={loadsPerOrganization}
                headerColumns={headerColumns} />
        </Root>
    }
}
export const SummaryOfLoadingsPerOrganization = inject("rootStore")(observer(SummaryOfLoadingsPerOrganizationComponent));