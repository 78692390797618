import React from "react";
import { CssBaseline, Typography, Container } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { t } from "common/localization/translate";
import { UserDeleteCheckButton } from "./components/userDeleteCheckButton";
import { UserIdInput } from "./components/userIdInput";
import { DeleteUserSuccessPopUp } from "../popUps/deleteUserSuccess";
import { AreYouSurePopUp } from "../popUps/areYouSurePopUp";
import { DeleteUserErrorPopUp } from "../popUps/deleteUserError";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 30,
    flex: "auto",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: 10,
  },
}));

export const Form = observer(() => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Grid className={classes.paper}>
        <Typography className={classes.title} component="h1" variant="h1">
          {t("deleteUser.title")}
        </Typography>
        <form className={classes.form} noValidate>
          <UserIdInput />
          <UserDeleteCheckButton />
          <AreYouSurePopUp />
          <DeleteUserSuccessPopUp />
          <DeleteUserErrorPopUp />
        </form>
      </Grid>
    </Container>
  );
});
