export const styles = {
    paper: {
        marginBottom: 20,
        padding: 15
    },
    grid: {
        width: '100%',
    },
    backgroundColor: {
        backgroundColor: '#4ed2a8'
    }
};