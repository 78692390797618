import React from "react";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "../../../../index";
import { t } from "common/localization/translate";
import Select from "react-select";
import { customSelectStyles } from './selectStyle';
import { Grid, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const BusinessSelect = observer(() => {
  const store = useEditCouponTypeStore();
  const classes = useStyles();
  
  const handleChange = (event) => {
    store.selectedBusinessId = Number(event.business_id);
    store.getCouponTypesList(store.selectedBusinessId);
    store.getBusinessData(store.selectedBusinessId);
  };
  
  return (
    <Grid className={classes.input}>
      <Select
        options={store.businessesList}
        getOptionLabel={(business) => `${business.name} - ${business.business_id}`}
        onChange={handleChange}
        styles={customSelectStyles}
        placeholder={t("addNewCouponType.BusinessIdSelect")}
        />
    </Grid>
  );
});
