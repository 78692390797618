import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const Price = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();

  const handleInputChange = (event) => {
    if (event.target.value < 0) {
      store.priceError = true;
      return;
    }
    store.priceError = false;
    store.priceInCurrency = event.target.value;
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      name="Price"
      label={t("addNewCouponType.Price")}
      type="number"
      onChange={handleInputChange}
      onWheel={handleBlockScroll}
      className={classes.input}
      error={store.priceError}
      helperText={store.priceError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
    />
  );
});
