import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewAuctionStore } from "screens/controlPanel/addNewAuction/store";
import { t } from "common/localization/translate";

export const IsSprintCheckbox = observer(() => {
  const store = useAddNewAuctionStore();

  const handleChange = (event) => {
    store.isSprintAuction = event.target.checked;
  };

  return (
    <FormControlLabel
      control={
      <Checkbox
      value={store.isSprintAuction}
      onChange={handleChange} />}
      label={t("addNewAuction.addNewAuctionIsSprintCheckbox")}
    />
  );
});