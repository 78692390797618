export const calcActiveDurationTimeInMinutes = (durationTimeNumber,timeType) => {
  if (!durationTimeNumber || !timeType) {
    return;
  }
  let durationTimeInMinutes;

  switch (timeType.value) {
    case "TIME_TYPE_MINUTES":
      durationTimeInMinutes = durationTimeNumber;
      break;
    case "TIME_TYPE_HOURS":
      durationTimeInMinutes = durationTimeNumber * 60;
      break;
    case "TIME_TYPE_DAYS":
      durationTimeInMinutes = durationTimeNumber * 60 * 24;
      break;
    case "TIME_TYPE_WEEKS":
      durationTimeInMinutes = durationTimeNumber * 60 * 24 * 7;
      break;
    case "TIME_TYPE_MONTHS":
      durationTimeInMinutes = durationTimeNumber * 60 * 24 * 7 * 4.35;
      break;
    case "TIME_TYPE_YEARS":
      durationTimeInMinutes = durationTimeNumber * 60 * 24 * 7 * 4.35 * 12;
      break;
    default:
      durationTimeInMinutes = durationTimeNumber;
  }

  return durationTimeInMinutes;
};
