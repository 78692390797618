import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { observer } from "mobx-react-lite";
import { useAddNewCouponTypeStore } from 'screens/controlPanel/addCouponType/store';
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.white,
      fontSize: 16,
      fontFamily: "Assistant",
      fontWeight: "bold",
    },
  },
}));

export const NegNumberErrorPopUp = observer(() => {
  const store = useAddNewCouponTypeStore();
  const {isNegNumberErrorPopUpOpen} = store;
  const classes = useStyles();

  const handleClose = () => {
    store.isNegNumberErrorPopUpOpen = false;
  };

  return (
    <div>
      <Dialog open={isNegNumberErrorPopUpOpen}>
        <DialogTitle className={classes.title}>
          {t("screenAddNewBusiness.ErrorPopUpTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("screenAddNewBusiness.NegativeNumberError")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t("suppliers.supplierHome.verifyCodesOkPopUp")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});