import { t } from "common/localization/translate";
import { updateUserSegmentErrorCodes } from "./errorCodes ";

export function handleServerError(serverErrorCode) {
  switch (serverErrorCode) {
    case updateUserSegmentErrorCodes.USER_WITHOUT_PERMISSION:
      return t("updateUserSegment.UserUnauthorized");
    case updateUserSegmentErrorCodes.USER_IS_NOT_EXIST:
      return t("updateUserSegment.UserIdNotExist");
    case updateUserSegmentErrorCodes.SEGMENT_IS_NOT_EXIST:
      return t("updateUserSegment.InvalidInputNumber");
    case updateUserSegmentErrorCodes.INVALID_INPUT_REASON_TEXT:
      return t("updateUserSegment.InvalidReason");
    case updateUserSegmentErrorCodes.NOT_SAME_APP_TYPE:
      return t("updateUserSegment.InvalidInputNumber");
    default:
      return t("updateUserSegment.generalServerError");
  }
}
