import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/HelpOutline';
import { t } from "common/localization/translate";
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 500,
    },
    tooltip: {
      fontSize: 14,
    },
    toolTipIcon: {
      color: theme.palette.primary.main
    },
    label: {
      marginBottom: 5
  },
  })
);

export const SegmentSelect = observer(() => {
    const classes = useStyles();
    const store = useEditBusinessStore();
    const { segmentsList, businessSegments } = store;

    let segments = businessSegments ? businessSegments.map(segmentId => !segmentId ? segmentsList[0] : segmentsList[segmentId]) : [];

    const handleChange = (event, values) => {
      if(values.length === 0) {
        store.segmentsEmptyError = t("screenAddNewBusiness.SegmentChoice");
        store.businessSegments = values;
        return;
      }
      store.segmentsEmptyError = null;
      store.businessSegments = values.map(value => Number(value.segment_id));
    };

    return (
      <Grid className={classes.root}>
            <InputLabel className={classes.label}>
            {t("screenAddNewBusiness.SegmentChoice") + " *"}
              <Tooltip title={t("screenAddNewBusiness.SegmentTooltip")} placement="top-start">
                <HelpIcon className={classes.toolTipIcon}/>
              </Tooltip>
            </InputLabel>
        <Autocomplete
          multiple
          options={segmentsList}
          value={segments}
          getOptionLabel={option => option.name}
          filterSelectedOptions
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </Grid>
    );
});