import React from "react";
import { observer, useObserver } from "mobx-react";
import { t } from "common/localization/translate";
import MUIDataTable from "mui-datatables";
import { useScreenCouponsStore } from "../store/index";
import { UnSuccessCancelCoupun } from "./popUps/UnSuccessCancelCoupun";
import { useRootStore } from "commonStores/analytics/rootStoreContext";


export const CouponsTable = observer(() => {
  const store = useScreenCouponsStore();
  const { userInfoStore } = useRootStore();
  const { dashboardFeatures } = userInfoStore;

  return useObserver(()=>{
    const isShowOrderIdForCouponsScreenSupportDashboard = dashboardFeatures && dashboardFeatures.isShowOrderIdForCouponsScreenSupportDashboard;
    const {
      couponsUserTableData,
      isFetching,
      errorCode,
      userName,
      setCurrentRow,
    } = store;
    const { columns } = getTableData(isFetching, isShowOrderIdForCouponsScreenSupportDashboard);
  
    const onRowsSelect = (data) => {
      const { dataIndex: index } = data[0];
      setCurrentRow(index);
    };
  
    const tableOptions = { ...options, onRowsSelect };
  
    if (errorCode || (couponsUserTableData.length == 0 && !userName)) {
      return null;
    }
  
    return (
      <div>
        <MUIDataTable
          title={t("screenCouponsUser.table.title", { userName })}
          data={couponsUserTableData}
          columns={columns}
          options={tableOptions}
        />
        <UnSuccessCancelCoupun />
      </div>
    );
  })
});

const getTableData = (isFetching, isShowOrderIdForCouponsScreenSupportDashboard) => {
  const columns = [
    {
      name: "date",
      label: t("screenCouponsUser.table.col.date"),
    },
    {
      name: "business",
      label: t("screenCouponsUser.table.col.business"),
    },
    {
      name: "supplier",
      label: t("screenCouponsUser.table.col.supplier"),
    },
    {
      name: "couponTitle",
      label: t("screenCouponsUser.table.col.couponTitle"),
    },
    {
      name: "identifier",
      label: t("screenCouponsUser.table.col.couponCode"),
    },
    {
      name: "expireDate",
      label: t("screenCouponsUser.table.col.expireDate"),
    },
  ];
  
  if(isShowOrderIdForCouponsScreenSupportDashboard){
    columns.splice(4, 1, { name: "couponCodeTitle", label: t("screenPurchaseUsersInfo.table.col.couponCodeTitle")});
  }

  return { columns };
};

const options = {
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  responsive: "scrollMaxHeight",
  selectableRows: "single",
  customToolbarSelect: () => { },
  onRowsDelete: () => false,
  textLabels: {
    body: {
      noMatch: t("screenCouponsUser.table.col.noMatch"),
    },
    selectedRows: {
      text: t("screenCouponsUser.table.chosen"),
      delete: "Delete",
      deleteAria: "Delete Selected Rows",
    },
  },
};
