import React from "react";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "../../../../index";
import { t } from "common/localization/translate";
import Select from "react-select";
import { customSelectStyles } from './selectStyle';
import { Grid } from "@material-ui/core";

export const CouponTypeSelect = observer(() => {
  const store = useEditCouponTypeStore();
  
  const handleChange = (event) => {
    store.selectedCouponTypeId = Number(event.coupon_type_id);
    store.getCouponData(store.selectedCouponTypeId);
    // store.getCouponTypeGroup(store.selectedCouponTypeId);
  };

  const getIsActiveText = (isActive) => {
    return isActive ? t("editCouponType.active") : t("editCouponType.inactive");
  };
  
  return (
    <Grid>
      <Select
        options={store.couponTypesList}
        getOptionLabel={(coupon) => `${coupon.title} - ${coupon.coupon_type_id} - ${getIsActiveText(coupon.is_active)}`}
        onChange={handleChange}
        styles={customSelectStyles}
        placeholder={t("editCouponType.chooseCouponLabel")}
        />
    </Grid>
  );
});
