import React from "react";
import TextField from "@material-ui/core/TextField";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

export const HealthScore = observer(() => {
  const store = useEditCouponTypeStore();
  const minValue = 0;

  const handleInputChange = (event) => {
    if(event.target.value < minValue){
      store.healthScoreError = true;
      return;
    }
    store.healthScoreError = false;
    store.healthScore = event.target.value;
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      label={t("screenAddNewBusiness.HealthScore") + " (Health Score)"}
      type="number"
      value={store.healthScore || ""}
      onChange={handleInputChange}
      onWheel={handleBlockScroll}
      error={store.healthScoreError}
      helperText={store.healthScoreError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
    />
  );
});
