import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Score } from "./components/Score";
import { HealthScore } from "./components/HealthScore";
import { IsKosherCheckbox } from "./components/IsKosherCheckbox";
import { IsActiveCheckbox } from "./components/IsActiveCheckbox";
import { IsUnhealthyCheckbox } from "./components/IsUnhealthyCheckbox";
import { IsLocalCheckbox } from "./components/IsLocalCheckbox";
import { IsOnlineCheckbox } from "./components/IsOnlineCheckbox";
import { IsVegCheckbox } from "./components/IsVegCheckbox";
import { StartWorkingWithBusinessDate } from "./components/StartWorkingWithBusinessDate";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 10,
    textAlign: "center",
    flex: "auto",
    color: theme.palette.primary.main,
  },
  input: {
    marginBottom: 50,
  }
}));

export const CompanyInfo = () => {
  const classes = useStyles();
  return (
    <div className={classes.input}>
      <Typography className={classes.title} component="h1" variant="h2">
        {t("screenAddNewBusiness.CompanyInfo")}
      </Typography>
        <StartWorkingWithBusinessDate />
        <Score />
        <HealthScore />
        <IsKosherCheckbox />
        <IsUnhealthyCheckbox />
        <IsLocalCheckbox />
        <IsOnlineCheckbox />
        <IsVegCheckbox />
        <IsActiveCheckbox />
    </div>
  );
};