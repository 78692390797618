import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { observer } from "mobx-react-lite";
import { userUpdateSegmentStore } from "../../index";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.white,
      fontSize: 16,
      fontFamily: "Assistant",
      fontWeight: "bold",
    },
  },
}));

export const UpdateUserSegmentSuccessPopUp = observer(() => {
  const store = userUpdateSegmentStore();
  const classes = useStyles();

  const handleClose = () => {
    store.isSuccessUpdateUserSegment = false;
    window.location.reload();
  };

  return (
    <Dialog open={store.isSuccessUpdateUserSegment}>
      <DialogTitle className={classes.title}>
        {t("updateUserSegment.SuccessPopUpTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("updateUserSegment.updateUserSegmentSuccess")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {t("suppliers.supplierHome.verifyCodesOkPopUp")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
