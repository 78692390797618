import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: { marginBottom: 10 },
}));

export const StepsAmountInput = observer((props) => {
  const classes = useStyles();
  const store = useEditCouponTypeStore();
  const { isGoalBasedChallenge } = props;

  const handleInputChange = (event) => {
    store.stepsAmountInput = Number(event.target.value);
    store.hasChallengeBeenEdited = true;
  };

  if (isGoalBasedChallenge) {
    return null;
  }
  return (
    <TextField
      variant="outlined"
      fullWidth
      label={t("addNewCouponType.StepsAmountInput")}
      onChange={handleInputChange}
      type="number"
      onWheel={handleBlockScroll}
      className={classes.input}
      value={store.stepsAmountInput || ""}
    />
  );
});
