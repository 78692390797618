import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from "react-router";
import { getDashboardMode } from 'common/getDashboardMode';
import { getRedirectTo } from './getRedirectTo';

class DashboardRouteComponent extends Component {

    render() {
        const { component: Component, rootStore, location, ...rest } = this.props;
        const dashboardMode = getDashboardMode(location.pathname);
        const redirectTo = getRedirectTo(dashboardMode);
        const { isLoggedIn } = rootStore.userInfoStore;
        return <Route {...rest} render={(props) => (
            isLoggedIn
                ? <Component {...props} />
                : <Redirect to={redirectTo} />
        )} />
    }
}

export const DashboardRoute = withRouter(inject("rootStore")(observer(DashboardRouteComponent)));