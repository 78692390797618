import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@material-ui/styles"
const useStyles = makeStyles((theme) => ({
  container: {
    "& > *": {
      height: "100%",
      padding: theme.spacing(0.5),
    }
  }
}));

export const TitlesOrderDetails = observer(() => {
  const classes = useStyles();
  return (
    <Grid className={classes.container} >
      <Typography component="h2" gutterBottom variant="overline">
        {t("screenOrderDetails.title")}
      </Typography>
      <Typography variant="subtitle2">
        {t("screenOrderDetails.subtitle")}
      </Typography>
    </Grid>
  );
});
