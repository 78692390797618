export const MAX_YEAR = 2020;
export const MIN_YEAR = 2017;

export const MAX_MONTH = 11;
export const MIN_MONTH = 0;

export const NO_OPTION_SELECTED = -1;

export const MONTHS = [
  { key: -1, value: "בחר חודש" },
  { key: MIN_MONTH, value: "ינואר" },
  { key: 1, value: "פברואר" },
  { key: 2, value: "מרץ" },
  { key: 3, value: "אפריל" },
  { key: 4, value: "מאי" },
  { key: 5, value: "יוני" },
  { key: 6, value: "יולי" },
  { key: 7, value: "אוגוסט" },
  { key: 8, value: "ספטמבר" },
  { key: 9, value: "אוקטובר" },
  { key: 10, value: "נובמבר" },
  { key: MAX_MONTH, value: "דצמבר" },
];

export const YEARS = [
  { key: -1, value: "בחר שנה" },
  { key: MIN_YEAR, value: "2017" },
  { key: 2018, value: "2018" },
  { key: 2019, value: "2019" },
  { key: MAX_YEAR, value: "2020" },
];