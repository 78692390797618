import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { useScreenSwitchBusinessStore } from "../index";
import { t } from "common/localization/translate";
import CircularProgress from '@material-ui/core/CircularProgress';
import useRouter from "utils/useRouter";
import { useRootStore } from "commonStores/analytics/rootStoreContext";

const useStyles = makeStyles((theme) => ({
    buttons: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 15,
        width: 200,
        height: 40,
        color: theme.palette.white,
        marginRight: 5,
        marginTop: 5,
        "&:hover": {
            backgroundColor: theme.palette.primary.white,
        }
    },
    title: {
        marginBottom: 30,
        flex: "auto",
      }
}));

export const SwitchToBusinessButton = observer(() => {
    const store = useScreenSwitchBusinessStore();
    const { userInfoStore } = useRootStore();
    const classes = useStyles();
    const { history } = useRouter();
    const { businessIdEmptyError } = store;

    const handleCheck = async () => {

        if (businessIdEmptyError) {
            store.isEmptyBusinessIdErrorPopUpOpen = true;
            return;
        };

        await store.switchToChosenBusiness();

        if (store.switchingStatus) {
            userInfoStore.clearUserInfoStore();
            history.push(`/${userInfoStore.dashboardType}/login`);
            return;
        };

        store.isFailedToChangeBusinessErrorPopUpOpen = true;
    };
    
    const getSwitchToBusinessButtonContent = store.isFetching ? <CircularProgress color='#fffff' size={20} /> : t("switchBusiness.switchToChosenBusiness");
    return (
        <Grid className={classes.title} item xs={12}>
        <Button onClick={handleCheck} variant="contained" className={classes.buttons}>
        {getSwitchToBusinessButtonContent}
        </Button>
        </Grid>
    )
});