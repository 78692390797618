import React from "react";
import { decorate, action, observable } from "mobx";
import { getAppOpenByHours } from "../store/routes/getAppOpenByHours";
import { getPeriodsForAppOpenByHours } from "../store/logic/getPeriodsForAppOpenByHours";
import { getMoment } from "../store/logic/getMoment";

const ScreenAppOpenByHoursStoreContext = React.createContext({});
export const ScreenAppOpenByHoursStoreProvider = ScreenAppOpenByHoursStoreContext.Provider;
export const useScreenAppOpenByHoursStore = () => React.useContext(ScreenAppOpenByHoursStoreContext);

class ScreenAppOpenByHoursStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }

  initProperties() {
    this.periodOptions = getPeriodsForAppOpenByHours();
    this.currentPeriodIndexAppOpen = 1;
    const moment = getMoment('he');
    this.startDate = moment().subtract('days', 3);
  }

  getScreenData = async () => {
    this.setIsFetching(true);
    const period = this.periodOptions[this.currentPeriodIndexAppOpen];
    const segmentId = Number(this.rootStore.userInfoStore.currentSegmentId);
    const data = await getAppOpenByHours(segmentId, this.startDate, period.type);
    this.setAppOpenByHoursCountGraphData(data);
    this.setIsFetching(false);
  }

  setStartDate(startDate) {
    this.startDate = startDate;
    this.getScreenData(true);
  }

  setAppOpenByHoursCountGraphData(appOpenByHoursCountGraphData) {
    this.appOpenByHoursCountGraphData = appOpenByHoursCountGraphData;
  }

  setPeriodFromStartDate(periodFromStartDate) {
    this.periodFromStartDate = periodFromStartDate;
  }

  setCurrentPeriodIndexAppOpen(index) {
    this.currentPeriodIndexAppOpen = index;
    this.getScreenData();
  }


  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }

}

decorate(ScreenAppOpenByHoursStore, {
  isFetching: observable,
  segmentAppOpenByHoursCountsInPeriod: observable,
  segmentAppOpenByHoursCountsInPeriodDistinct: observable,
  periodOptions: observable,
  currentPeriodIndexAppOpen: observable,
  appOpenByHoursCountGraphData: observable,
  startDate: observable,
  getScreenData: action.bound,
  initProperties: action.bound,
  setStartDate: action.bound,
  setCurrentPeriodIndexAppOpen: action.bound,
  setIsFetching: action.bound,
  setPeriodFromStartDate: action.bound,
  setAppOpenByHoursCountGraphData: action.bound

});

export function createScreenAppOpenByHoursStore(rootStore) {
  const store = new ScreenAppOpenByHoursStore(rootStore);
  return store;
}
