import React from "react";
import { makeStyles, Select, MenuItem } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../../..";
import { getArticleAnswers } from "screens/controlPanel/editArticle/store/server/getArticleAnswers";

const useStyles = makeStyles(() => ({
  input: {
    marginLeft: 20,
    marginBottom: 10,
    minWidth: 200,
    maxWidth: 300
  },
}));

export const TranslateQuestionsSelect = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();

  const handleChange = async (event) => {
    store.selectedQuestion = event.target.value;
    store.answerArray = await getArticleAnswers(store.selectedQuestion.question_id);
  };
  
  const questionContent = store.questionArray.map((question) => (<MenuItem value={question}>{question.body}</MenuItem>));
 
  return (
      <Select onChange={handleChange} value={store.selectedQuestion} className={classes.input}>
        {questionContent}
      </Select>
  );
});
