import { MAX_YEAR, NO_OPTION_SELECTED, MIN_YEAR } from "../consts";

export function incSelectedKeyYear(component) {
    if (component.selectedKeyYear === MAX_YEAR) {
        return;
    }
    if (component.selectedKeyYear === NO_OPTION_SELECTED) {
        component.selectedKeyYear = MIN_YEAR;
    } else {
        component.selectedKeyYear++;
    }
    if (component.isYearOrMonthNull) {
        return;
    }
    component.updateStartEndDate();
}
