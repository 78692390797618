import React, { Component } from 'react';
import { Route } from "react-router-dom";

import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';

import { SideMenu } from './componentsRumble/sideMenu';
import { RumbleAppBar } from './componentsRumble/appBar';
import { Root } from './componentsRumble/root';
import { DashboardRoute } from 'componentsRumble/dashBoardRoute';
import { screenTypes } from 'commonStores/userInfoStore/screenTypes';
import { Main } from 'screens/movment/main';
import { Purchases as PurchasesBiz } from './screens/biz/purchases';
import { PurchasesSummaryByBranch } from 'screens/biz/purchasesSummaryByBranch';
import { Loadings } from 'screens/org/loadings';
import { Login } from './screens/login';
import { Purchases as PurchasesOrg } from './screens/org/purchases';
import { SummaryOfLoadingsPerOrganization } from 'screens/movment/summaryOfLoadingsPerOrganization';
import { SummaryOfPurchasesByBusiness } from 'screens/movment/summaryOfPurchasessByBusiness';

export class OldRoot extends Component {
    render() {
        return <Root>
            <RumbleAppBar />
            <SideMenu />
            <DashboardRoute path={screenTypes.SCREEN_BIZ_PURCHASES} component={PurchasesBiz} />
            <DashboardRoute path={screenTypes.SCREEN_BIZ_BRANCHES_INFO} component={PurchasesSummaryByBranch} />
            <DashboardRoute path={screenTypes.SCREEN_ORG_LOADINGS} component={Loadings} />
            <DashboardRoute path={screenTypes.SCREEN_ORG_PURCHASES} component={PurchasesOrg} />
            <DashboardRoute path={screenTypes.SCREEN_MOV_MAIN} component={Main} />
            <DashboardRoute path={screenTypes.SCREEN_MOV_SUM_OF_LOADINGS_PER_ORG} component={SummaryOfLoadingsPerOrganization} />
            <DashboardRoute path={screenTypes.SCREEN_MOV_SUM_OF_PURCHASES_BY_BIZ} component={SummaryOfPurchasesByBusiness} />
            <Route path={screenTypes.SCREEN_ORG_LOGIN} exact component={Login} />
            <Route path={screenTypes.SCREEN_BIZ_LOGIN} exact component={Login} />
            <Route path={screenTypes.SCREEN_MOV_LOGIN} exact component={Login} />
        </Root>
    }
}