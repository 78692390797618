import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from "react-router";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { screenTypes } from 'commonStores/userInfoStore/screenTypes'
import { Icon } from 'componentsRumble/images/images';
import { sendAnalyticsEvent } from 'common/analyticsEvent';
import { analyticsEvents } from 'common/analyticsEvent/events';

class SideMenuBizComponent extends Component {

    onClickCouponsInfo = () => {
        this.props.history.push(screenTypes.SCREEN_BIZ_PURCHASES);
        sendAnalyticsEvent(analyticsEvents.EVENT_BIZ_SIDEBAR_PRESSED_PURCHASES);
    }

    onClickBranchesInfo = () => {
        this.props.history.push(screenTypes.SCREEN_BIZ_BRANCHES_INFO);
        sendAnalyticsEvent(analyticsEvents.EVENT_BIZ_SIDEBAR_PRESSED_PURCHASES_BY_BRANCH);
    }

    render() {
        const { location, rootStore } = this.props;
        const { userInfoStore } = rootStore;
        const { isSupportFitCredit } = userInfoStore;
        const { pathname } = location;
        const purchasesLabel = isSupportFitCredit ? 'סה"כ כניסות' : "מימושים";
        const purchasesByBranchLabel = isSupportFitCredit ? "חלוקה לסניפים" : "מימושים לפי סניף";
        return <List>
            <ListItem selected={pathname === screenTypes.SCREEN_BIZ_PURCHASES} button key='purchases' onClick={this.onClickCouponsInfo}>
                <ListItemIcon> <Icon src="https://ucarecdn.com/e003ffad-a178-4017-a12e-5e78593bb480/Purchases.png" /> </ListItemIcon>
                <ListItemText primary={purchasesLabel} />
            </ListItem>
            <ListItem selected={pathname === screenTypes.SCREEN_BIZ_BRANCHES_INFO} button key='purchasesByBranch' onClick={this.onClickBranchesInfo}>
                <ListItemIcon><Icon src="https://ucarecdn.com/195939d0-2158-498b-af01-d237960c8e2b/RealizationsbyBranches.png" /></ListItemIcon>
                <ListItemText primary={purchasesByBranchLabel} />
            </ListItem>
        </List>
    }
}
export const SideMenuBiz = withRouter(inject("rootStore")(observer(SideMenuBizComponent)));
