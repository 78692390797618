import React from 'react';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/';
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom:10,
  }
}));

export const CommissionPercentage = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();
  const { commissionPercentError } = store;
  const minValue = 0;
  const maxValue = 100;
  
  const handleInputChange = (event) => {
    if(event.target.value < minValue || event.target.value > maxValue) {
      store.commissionPercentError = true;
      return;
    };
    store.commissionPercentError = null;
    store.commissionPercent = event.target.value;
  };

  return (
      <TextField
        variant="outlined"
        fullWidth
        label={t("screenAddNewBusiness.CommissionPercent")}
        type="number"
        name="Commission Percent"
        value = {store.commissionPercent ? store.commissionPercent : ""}
        onChange={handleInputChange}
        onWheel={handleBlockScroll}
        className={classes.input}
        error = {commissionPercentError}
        helperText = {commissionPercentError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
      />
  );
});