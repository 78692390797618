import React from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import SimpleCube from "common/ui/simpleCube";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../../store";
import { formatNumber } from "common/formatNumber";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Tooltip } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    row: {
        width: "100%"
    },
    reversedIcon: {
        color: theme.palette.primary.main
    },
    img: {
        height: 35
    }
}));

export const ImpressionCube = observer(() => {
    const { isFetching, amountOfImpresionsForBusiness } = useScreenHomeStore();

    const amountOfImpresionsForBusinessFormatted = formatNumber(amountOfImpresionsForBusiness);

    const classes = useStyles();

    return (
        <Grid item sm={12} lg={3} className={classes.row}>
            <Tooltip title={t("business.home.impressionCube.tooltip")}>
                <div>
                    <SimpleCube
                        label={t("business.home.impressionCube.title")}
                        isFetching={isFetching}
                        isWithConstatHeight={true}
                        value={amountOfImpresionsForBusinessFormatted}
                        icon={<VisibilityIcon />}
                        withoutMargin={true}
                    />
                </div>
            </Tooltip>
        </Grid>
    );
});
