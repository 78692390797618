import React, { useState } from "react";
import { Form } from "./store/components/form/index";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { observer } from "mobx-react";
import { createEditBalanceStore, EditBalanceStoreProvider } from "./store/index";

export const ManualEditUserBalance = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createEditBalanceStore(rootStore));

  return (
    <EditBalanceStoreProvider value={store}>
      <Form store={store} />
    </EditBalanceStoreProvider>
  );
});
