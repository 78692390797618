import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { useScreenCouponsStore } from "../store/index";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";
import { CommonButton } from "common/ui/commonButton";
import { couponsErrorCodes } from "../store/logic/couponsErrorCodes"


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.white,
      fontSize: 14,
    },
  },
  buttons: {
    fontSize: 14,
    marginLeft: 21,
    marginTop: 18,
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.secondGraphColor,
    }
  },
  dialogDiv: { width: 500, height: 200 },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
  }
}));

export const CancelHiddenCouponPopUp = observer(() => {
  const store = useScreenCouponsStore();
  const { couponName } = store;
  const classes = useStyles();

  const onClose = () => {
    store.exposeCouponPopUp(false);
  };
  if (!store.statusMessegeHiddingCoupon) {
    return;
  }

  const errorCode = store.statusMessegeHiddingCoupon.errorCode;
  let text = t("screenCouponsUser.successCouponHidden", { couponName })
    if (errorCode == couponsErrorCodes.ERROR_TYPE_COUPON_NOT_HIDDEN ) {
      text = t("screenCouponsUser.errors.notHidden", { couponName });
    }
    if (errorCode == couponsErrorCodes.ERROR_TYPE_RATE_LIMITER_IS_EXCEEDED ) {
      text = t("screenCouponsUser.Modal.limitReached")
    }

  return (
    <div>
      <Dialog
        className={classes.dialog}
        open={store.isExposeCouponPopUp}
        onClose={store.isExposeCouponPopUp}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={classes.dialogDiv}>
          <DialogTitle className={classes.root} id="responsive-dialog-title">
            {t("screenCouponsUser.cancelHiddingCoupon")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{text}</DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttonsContainer}>
            <CommonButton className={classes.buttons} onClick={onClose}>
              {t("screenCouponsUser.popUp.closeButton")}
            </CommonButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
});
