import React from "react";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../..";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
    width: 450
  },
}));

export const ArticleHtmlInput = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();
  const articleHtml = store.articleHtml || "";

  const handleInputChange = (event) => {
    store.articleHtml = event.target.value;
  };

  return (
      <TextField
        fullWidth
        required
        variant="outlined"
        multiline
        rows={7}
        value={articleHtml}
        onChange={handleInputChange}
        label={t("editArticle.articleHtml")}
        className={classes.input}
      />
  );
});