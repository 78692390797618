import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { Page } from "components";
import { Typography, Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import {
  createScreenSubSegmentUsersStore,
  ScreenSubSegmentUserssStoreProvider,
} from "./store";
import { t } from "common/localization/translate";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import { Redirect } from "react-router-dom";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flex: 1,
  },
  container: {
    "& > *": {
      height: "100%",
    },
  },
  toolTipIcon: {
    color: theme.palette.primary.main,
    marginLeft: 15,
    fontSize: "25px",
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    marginTop: 1,
  },
  title: {
    fontSize: '25px',
    lineHeight: '18px',
    marginTop: 2,
  },
}));

export const SubSegmentUsers = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenSubSegmentUsersStore(rootStore));
  const classes = useStyles();
  useEffect(() => {
    store.getScreenData();
  }, []);

  const { subSegmentUsersTableData, isFetching } = store;

  const { title, options, columns } = getTableData(isFetching);
  if(!rootStore.userInfoStore.isShowSubSegmentUsersScreen){
    return  <Redirect to={{ pathname: `/${dashboardPaths.ORGANIZATION}/home` }} />
  }
  return (
    <ScreenSubSegmentUserssStoreProvider value={store}>
      <Page className={classes.root} title={title}>
        <Grid className={classes.header} item lg={6} xs={12}>
          <Typography className={classes.title} component="h2" gutterBottom variant="overline">
            {title}
          </Typography>
            <Tooltip title={t("organizationDashboard.subSegmentUsers.tooltip")} classes={classes}>
              <HelpIcon className={classes.toolTipIcon} />
            </Tooltip>
        </Grid>
        <MUIDataTable
          className={classes.table}
          title={title}
          data={subSegmentUsersTableData}
          columns={columns}
          options={options}
        />
      </Page>
    </ScreenSubSegmentUserssStoreProvider>
  );
});

const getTableData = (isFetching) => {
  const columns = [
    {
      name: "subSegmentName",
      label: t("screenSubSegmentUsers.table.col.subSegmentName"),
    },
    {
      name: "count",
      label: t("screenSubSegmentUsers.table.col.count"),
    },
  ];

  const options = {
    filter: false,
    download: true,
    print: false,
    viewColumns: false,
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data); 
    },
    downloadOptions: { filename: 'usersInOrganizations.csv'},
    responsive: "scrollMaxHeight",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: isFetching ? "...Loading" : 'Sorry, no matching records found',
      }
    }
  };
  const title = t("screenSubSegmentUsers.table.title");
  return { title, options, columns };
};
