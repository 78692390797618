import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";
import { Grid } from "@material-ui/core";

export const IsCreditCheckbox = observer(() => {
  const store = useEditCouponTypeStore();
  
  const handleChange = (event) => {
    store.isCreditRequired = event.target.checked;
    store.priceInCurrency = null;
  };

  return (
    <Grid>
      <FormControlLabel
        control={<Checkbox checked={store.isCreditRequired ? true : false} onChange={handleChange} />}
        label={t("addNewCouponType.isCreditRequired")}
        />
    </Grid>
  );
});
