import React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";

export const HorizontalSpace = observer((props) => {
    const { widthSize } = props;

    return (
        <Grid xs={widthSize || 1} />
    );
});
