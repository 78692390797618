import React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { LoadingCodesInputErrors } from './loadingCodesInputErrors';

export const LoadingCodesInput = observer((props) => {
    const { store } = props;
    
    const handleText = (e) => {
        store.codes = e.target.value;
    }


    return (
            <Grid item xs={12} spacing={3}>
                <TextareaAutosize id='codes' error={true} rowsMax={6} cols="40" rows="5" placeholder={t("suppliers.supplierHome.insertCodesPlaceHolder")} onChange={handleText} />
                <LoadingCodesInputErrors/>
            </Grid>
    )
});

