import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewAuctionStore } from "screens/controlPanel/addNewAuction/store";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import { t } from "common/localization/translate";
import { convertDateToMoment } from "common/dateAndTime/moment";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 18,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

export const EndAuctionDate = observer(() => {
  const store = useAddNewAuctionStore();
  const classes = useStyles();

  const handleDateChange = (date) => {
    if(!date) {
      store.endAuctionDateEmptyError = t("addNewAuction.addNewAuctionEndDate");
      return null;
    }
    store.endAuctionDateEmptyError = null;
    store.endAuctionDate = convertDateToMoment(date).toISOString();
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <InputLabel className={classes.label} shrink>
        {t("addNewAuction.addNewAuctionEndDate")+ " *"}
      </InputLabel>
      <KeyboardDateTimePicker
        disablePast
        ampm={false}
        value={store.endAuctionDate}
        onChange={handleDateChange}
        format="HH:mm DD/MM/YYYY"
        invalidDateMessage={store.endAuctionDate ? true : false}
      />
    </Grid>
  );
});