import React from "react";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    "&::placeholder": {
      fontSize: 13,
    },
  },
  root: {
    marginBottom: 15,
  }
}));

export const BusinessHours = observer(() => {
  const classes = useStyles();

  const store = useScreenCreateAddNewBusinessStore();
  const { businessHours } = store;

  const handleInputChange = (event) => {
    store.setBusinessHours(event.target.value);
  };

  return (
    <div className={classes.root}>
      <TextField
        InputProps={{ classes: { input: classes.input } }}
        id="outlined-multiline-static"
        label={t("screenAddNewBusiness.BusinessHours")}
        multiline
        rows={6}
        value={businessHours}
        placeholder={t("screenAddNewBusiness.BusinessHoursPlaceHolder")}
        variant="outlined"
        onChange={handleInputChange}
      />
    </div>
  );
});