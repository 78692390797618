import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../index";
import { t } from "common/localization/translate";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    buttons: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 15,
        width: 200,
        height: 40,
        color: theme.palette.white,
        marginRight: 5,
        marginTop: 5,
        "&:hover": {
            backgroundColor: theme.palette.primary.white,
        }
    },
    title: {
        marginBottom: 30,
        flex: "auto",
      }
}));

export const EditArticleButton = observer(() => {
    const store = useEditArticleStore();
    const classes = useStyles();

    const handleCheck = async () => {
        await store.submitEditArticle();
      };

    const getCreateBusinessButtonContent = store.isFetching ? <CircularProgress color='#fffff' size={20} /> : t("editBusiness.submitEditCoupon");
    return (
        <Grid className={classes.title} item xs={12}>
        <Button onClick={handleCheck} variant="contained" className={classes.buttons}>
        {getCreateBusinessButtonContent}
        </Button>
      </Grid>
    )
});