import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { getErrorTextByErrorCode } from "screens/organization/healthAndFitnessData/store/logic/getErrorTextByErrorCode";
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from "screens/organization/healthAndFitnessData/store/storeHealthAndFitnessDataStoreContext";

const useStyles = makeStyles(() => ({
    input: {
        width: "180px",
    },
}));

export const IdNumInput = observer(() => {
    const store = useHealthAndFitnessDataStore();
    const inputProps = { maxLength: 10 };
    const classes = useStyles();
    const errorText = getErrorTextByErrorCode(store.errorCodeId);

    const handleInputChange = (event) => {
        store.setUserIdNum(event.target.value);
    };
    const isError = store.errorCodeId ? true : false;

    return (
        <Grid item sm={4} lg={2} spacing={2}>
            <TextField
                className={classes.input}
                id="standard-basic"
                type="string"
                error={isError}
                placeholder={t("screenHealthAndFitnessData.search.placeholder")}
                helperText={errorText}
                inputProps={inputProps}
                onChange={handleInputChange}
            />
        </Grid>
    );
});
