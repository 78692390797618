import { t } from "common/localization/translate";

export function getTraineesInfoTableColumns(isPoliceSegment) {
    const columnsPolice = [
        { name: "participantNameAtExternalSheetData", label: t("screenTrainesIfo.table.col.policeName") },
        { name: "idNum", label: t("screenTrainesInfo.table.col.policeId") },
        { name: "phone", label: t("screenTrainesIfo.table.col.policePhone") },
        { name: "createdAt", label: t("screenTrainesInfo.table.col.registrationDate") },
        { name: "lastOpen", label: t("screenTrainesInfo.table.col.lastLoginDate") },
        { name: "email", label: t("screenTrainesInfo.table.col.email") },
        { name: "avgStepsLast30Days", label: t("screenTrainesInfo.table.col.avgStepsLast30Days") },
        { name: "lastDepositDate", label: t("screenTrainesInfo.table.col.lastDepositDate") },
        { name: "countAppOpenLast30Days", label: t("screenTrainesInfo.table.col.countAppOpenLast30Days") },
        { name: "lastArticleReadDate", label: t("screenTrainesInfo.table.col.lastArticleReadDate") },
        { name: "lastArticleReadName", label: t("screenTrainesInfo.table.col.lastArticleReadName") },
        { name: "countReadAtricleInLast6Month", label: t("screenTrainesInfo.table.col.countReadAtricleInLast6Month") },
    ];

    const columnsDefault = [
        {
            name: "segmentName",
            label: t("screenTrainesInfo.table.col.segmentName"),
        },
        {
            name: "groupsName",
            label: t("screenTrainesInfo.table.col.groupName"),
        },
        {
            name: "nutritionistsName",
            label: t("screenTrainesInfo.table.col.nutriationName"),
        },
        {
            name: "trainersName",
            label: t("screenTrainesInfo.table.col.trainerName"),
        },
        {
            name: "participantNameAtExternalSheetData",
            label: t("screenTrainesIfo.table.col.traineName"),
        },
        {
            name: "idNum",
            label: t("screenTrainesInfo.table.col.traineId"),
        },
        {
            name: "phone",
            label: t("screenTrainesIfo.table.col.trainePhone"),
        },
        {
            name: "isSignedUp",
            label: t("screenTrainesInfo.table.col.conect"),
        },
        {
            name: "createdAt",
            label: t("screenTrainesInfo.table.col.registrationDate"),
        },
        {
            name: "lastOpen",
            label: t("screenTrainesInfo.table.col.lastLoginDate"),
        },
        {
            name: "email",
            label: t("screenTrainesInfo.table.col.email"),
        },
        {
            name: "userName",
            label: t("screenTrainesInfo.table.col.rumbleNickName"),
        },
        {
            name: "avgStepsLast30Days",
            label: t("screenTrainesInfo.table.col.avgStepsLast30Days"),
        },

        {
            name: "lastDepositDate",
            label: t("screenTrainesInfo.table.col.lastDepositDate"),
        },
        {
            name: "countAppOpenLast30Days",
            label: t("screenTrainesInfo.table.col.countAppOpenLast30Days"),
        },
        {
            name: "countPurchaseRumbleAcoSystemCoupons",
            label: t(
                "screenTrainesInfo.table.col.countPurchaseRumbleAcoSystemCoupons"
            ),
        },
        {
            name: "lastPurchaseAtidCouponDate",
            label: t("screenTrainesInfo.table.col.lastPurchaseAtidCouponDate"),
        },
        {
            name: "lastPurchaseAtidCouponName",
            label: t("screenTrainesInfo.table.col.lastPurchaseAtidCouponName"),
        },
        {
            name: "lastArticleReadDate",
            label: t("screenTrainesInfo.table.col.lastArticleReadDate"),
        },
        {
            name: "lastArticleReadName",
            label: t("screenTrainesInfo.table.col.lastArticleReadName"),
        },
        {
            name: "countReadAtricleInLast6Month",
            label: t("screenTrainesInfo.table.col.countReadAtricleInLast6Month"),
        },
    ];

    return isPoliceSegment ? columnsPolice : columnsDefault;
}