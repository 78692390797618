import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../../..";
import { makeStyles } from "@material-ui/core/";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
    marginTop: 15,
    marginLeft: 10
  }
}));

export const IsLeadCheckbox = observer(() => {
  const store = useEditArticleStore();
  const classes = useStyles();

  const handleChange = (event) => {
    store.isLead = event.target.checked;
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox className={classes.input} checked={store.isLead} onChange={handleChange} />
        }
        label={t("editArticle.addLead")}
      />
    </div>
  );
});