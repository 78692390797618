const PERSIST_KEY = 'webBusinessDashboard';

export function setLocalInfoObject(localInfoObject) {
    const json = JSON.stringify(localInfoObject);
    localStorage.setItem(PERSIST_KEY, json);
}

export function clearLocalInfoObject() {
    localStorage.removeItem(PERSIST_KEY);
}

export function getLocalInfoObject() {
    const json = localStorage.getItem(PERSIST_KEY);
    return JSON.parse(json);
}

export function createLocalInfoObjectBiz(businessName, businessId, businessImage, token, isSupportFitCredit, isSupportHealthCoins) {
    return { businessName, businessId, businessImage, token, isSupportFitCredit, isSupportHealthCoins };
}

export function createLocalInfoObjectOrg(segmentName, token, segmentLogo) {
    return { segmentName, token, segmentLogo };
}

export function createLocalInfoObjectMov(token) {
    return { token };
}