import React from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { ResetButton } from "./resetButton";
import { UnblockUniqueDeviceIdButton as UnblockButton } from "./unblockUniqueDeviceId";
import { useScreenEditUserInfoStore } from "../store/index";
import { CardHeader, Divider } from "@material-ui/core";
import { UserDetailsInputs } from "./userDetailsInputs";
import { HelperTexts } from "./helperTexts";

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
}));


export const UserInfoForm = observer((props) => {
  const classes = useStyles();
  const store = useScreenEditUserInfoStore();
  if (!store.userId) {
    return null;
  }

  return (
    <form className={classes.form}>
      <CardHeader />
      <UserDetailsInputs />
      <br />
      <Divider />
      <ResetButton />
      <UnblockButton />
      <HelperTexts />
    </form>
  );
});
