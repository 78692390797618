import { COLORS } from 'common/ui/colors';

export const styles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        justifyContent: 'space-between',
        backgroundColor: COLORS.RUMBLE_GREEN
    },
    businessLabel: {
        marginLeft: 20
    },
    businessText: {
        marginLeft: 5
    },
    container: {
        justifyContent: 'space-between'
    }
})