import EditIcon from '@material-ui/icons/Edit';
import React from "react";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../../..";
import IconButton from '@material-ui/core/IconButton';
import { editAnswerById } from 'screens/controlPanel/editArticle/store/server/editAnswerById';
import { getArticleAnswers } from "screens/controlPanel/editArticle/store/server/getArticleAnswers";

export const EditAnswerButton = observer(() => {
    const store = useEditArticleStore();

    const editAnswer = async () => {
        if(!store.selectedAnswer.body || !store.selectedAnswer.answer_id) {
            return;
        }
        const res = await editAnswerById({answerId: store.selectedAnswer.answer_id, answerBody: store.selectedAnswer.body, isCorrect: store.isCorrectAnswer, questionId: store.selectedQuestion.question_id, isLead: store.isLead});
        if(res) {
            store.selectedAnswer = "";
            store.isCorrectAnswer = "";
            store.isLead = false;
            store.answerArray = await getArticleAnswers(store.selectedQuestion.question_id);
        }
    }

    return(
        <IconButton color="primary" component="span">
          <EditIcon onClick={editAnswer}/>
        </IconButton>
    )
});