import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { useEditArticleStore } from "../../../../..";
import { TranslateAnswerSelect } from "./translateAnswerSelect";
import { TranslateAnswerButton } from "./translateAnswerButton";
import { TranslateAnswerInput } from "./translateAnswerInput";
import { quizActions } from "screens/controlPanel/editArticle/store/logic/quizActions";

const useStyles = makeStyles((theme) => ({
  stater: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
  },
}));

export const TranslateAnswerComponents = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();

  if (store.quizAction !== quizActions.TRANSLATE) {
    return null;
  }
  return (
    <span className={classes.stater}>
      <TranslateAnswerInput />
      <TranslateAnswerButton />
      <TranslateAnswerSelect />
    </span>
  );
});
