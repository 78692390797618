import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";
import { Grid } from "@material-ui/core";

export const IsKosherCheckbox = observer(() => {
  const store = useScreenCreateAddNewBusinessStore();
  const { isKosher } = store;

  const handleChange = (event) => {
    store.setIsKosher(event.target.checked);
  };

  return (
    <Grid>
      <FormControlLabel
        control={
          <Checkbox value={isKosher} onChange={handleChange} name="isKosher" />
        }
        label={t("screenAddNewBusiness.isKosher")}
      />
    </Grid>
  );
});