import React from "react";
import {
  InputLabel,
  Select,
  makeStyles,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { limitationTypes } from "../../../../../logic/limitationTypes";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  category: {
    minWidth: 300,
    border: 1,
    borderColor: "black",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const LimitationTypeSelect = observer(() => {
  const store = useAddNewCouponTypeStore();
  const { limitationType } = store;
  const classes = useStyles();

  const handleChange = (event) => {
    store.limitationType = event.target.value;
    store.limitationParams = null;
  };

  return (
    <Grid item sm={4}>
      <InputLabel id="demo-simple-select-outlined-label">
        {t("screenAddNewBusiness.ChooseLimitationType")}
      </InputLabel>
      <Select
        onChange={handleChange}
        className={classes.category}
        value={limitationType}
        renderValue={selected=>selected.name}
      >
        {limitationTypes.map((limitationType) => {
          return (
            <MenuItem key={limitationType.type} value={limitationType}>
              {limitationType.name}
            </MenuItem>
          );
        })}
      </Select>
    </Grid>
  );
});
