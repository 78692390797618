import Excel from "exceljs";
import { createSheetSum } from "./createSheetSum";
import { createSheetAppOpenRate } from "./createSheetAppOpenRate";
import { createSheetBonuses } from "./createSheetBonuses";
import { createSheetNewUserRate } from "./createSheetNewUserRate";
import { createSheetReferal } from "./createSheetReferal";
import { setSheetProps } from "./setSheetProps";
import { downloadWorksheet } from "./downloadWorksheet";
import { createSheetActiveUsers } from "./createSheetActiveUsers";
import { t } from "common/localization/translate";

export async function getHomeExcel(storeHome) {
  const { activeUsersCountGraphData, arrayOfMeausermentsForChart } = storeHome;

  const workbook = new Excel.Workbook();

  const sheetSum = workbook.addWorksheet(`סיכומים`);
  const sheetAppOpenRate = workbook.addWorksheet(`קצב פתיחת האפליקציה`);
  const sheetBonuses = arrayOfMeausermentsForChart ? workbook.addWorksheet(t("screenHome.meausermentsTotal.title")) : workbook.addWorksheet(`התפלגות בונוסים`);
  const sheetNewUserRate = workbook.addWorksheet(`קצב הצטרפות חברים חדשים`);
  const sheetReferal = workbook.addWorksheet(`חבר מביא חבר`);

  setSheetProps([sheetSum, sheetAppOpenRate, sheetBonuses,sheetNewUserRate, sheetReferal]);
  createSheetSum(sheetSum, storeHome);
  createSheetAppOpenRate(sheetAppOpenRate, storeHome);
  createSheetBonuses(sheetBonuses, storeHome);
  createSheetNewUserRate(sheetNewUserRate, storeHome);
  createSheetReferal(sheetReferal, storeHome);

  if (activeUsersCountGraphData) {
    const sheetActiveUsersRate = workbook.addWorksheet(`קצב גדילת משתמשים פעילים`);
    setSheetProps([sheetActiveUsersRate]);
    createSheetActiveUsers(sheetActiveUsersRate, storeHome);
  }

  downloadWorksheet(workbook);
}
