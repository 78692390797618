import React from "react";
import {
  InputLabel,
  TextField,
  makeStyles,
  createStyles
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() =>
  createStyles({
    inputRoot: {
      width: 600,
    },
    root: {
      marginBottom: 15,
    }
  })
);

export const SubCategorySelect = observer(() => {
  const store = useScreenCreateAddNewBusinessStore();
  const { categoriesList } = store;

  const classes = useStyles();

  const handleChange = (event, values) => {
    store.setSubCategories(values.map(value => value.category_id))
  };

  return (
    <div className={classes.root}>
      <InputLabel>{t("screenAddNewBusiness.ChooseSubcategoriesLabel")}</InputLabel>
      <Autocomplete
        multiple
        options={categoriesList}
        getOptionLabel={option => option.name}
        filterSelectedOptions
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    </div>
  );
});