import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { BusinessFacebook } from "./components/BusinessFacebook";
import { InstagramUrl } from "./components/InstagramUrl";
import { SiteUrl } from "./components/SiteUrl";
import { Tags } from "./components/Tags";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 10,
    textAlign: "center",
    flex: "auto",
    color: theme.palette.primary.main,
  },
  input: {
    marginBottom: 50,
  }
}));

export const MediaForm = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.input}>
      <Typography className={classes.title} component="h1" variant="h2">
        {t("screenAddNewBusiness.Media")}
      </Typography>
      <Grid item xs={12}>
        <BusinessFacebook />
      </Grid>
      <Grid item xs={12}>
        <InstagramUrl />
      </Grid>
      <Grid item xs={12}>
        <SiteUrl />
      </Grid>
      <Grid item xs={12}>
        <Tags />
      </Grid>
    </Grid>
  );
};