import React from "react";
import { Checkbox, Grid, FormControlLabel } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";

export const IsOnlineCheckbox = observer(() => {
  const store = useAddNewCouponTypeStore();
  const { isOnline } = store;

  const handleChange = (event) => {
    store.isOnline = event.target.checked;
  };

  return (
    <Grid>
      <FormControlLabel control={<Checkbox value={isOnline} onChange={handleChange} />} label={t("screenAddNewBusiness.isOnline")} />
    </Grid>
  );
});
