import { t } from "common/localization/translate";

export const timeTypes = [
  { value: "TIME_TYPE_MINUTES", name: t("addNewCouponType.TimeTypeMinutes") },
  { value: "TIME_TYPE_HOURS", name: t("addNewCouponType.TimeTypeHours") },
  { value: "TIME_TYPE_DAYS", name: t("addNewCouponType.TimeTypeDays")},
  { value: "TIME_TYPE_WEEKS", name: t("addNewCouponType.TimeTypeWeeks") },
  { value: "TIME_TYPE_MONTHS", name: t("addNewCouponType.TimeTypeMonths") },
  { value: "TIME_TYPE_YEARS", name: t("addNewCouponType.TimeTypeYears") },
];
