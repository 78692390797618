import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { Page } from "components";
import { Typography, Grid } from "@material-ui/core";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenPurchasesStore, ScreenPurchasesStoreProvider } from "./store";
import { t } from "common/localization/translate";
import { PurchasesTable } from "./components/purchasesTable";
import { DatePicker } from "./components/datePicker";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flex: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontSize: '25px',
    marginTop: 5,
  },
  row: {
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  toolTipIcon: {
    color: theme.palette.primary.main,
    marginLeft: 15,
    fontSize: "25px",
  },
  toolTipGrid: {
    marginRight: 'auto',
  },
}));

export const Purchases = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenPurchasesStore(rootStore));
  const classes = useStyles();
  useEffect(() => {
    store.getScreenData();
  }, []);

  return (
    <ScreenPurchasesStoreProvider value={store}>
      <Page className={classes.root} title={t("screenPurchases.table.title")}>
        <Grid className={classes.header} item lg={6} xs={12}>
          <Typography className={classes.title} component="h2" gutterBottom variant="overline">
            {t("screenPurchases.table.title")}
          </Typography>
            <Grid className={classes.toolTipGrid}>
              <Tooltip title={t("organizationDashboard.purchases.tooltip")} classes={classes}>
                <HelpIcon className={classes.toolTipIcon} />
              </Tooltip>
            </Grid>
        </Grid>
          <Grid container lg={12} sm={12} className={classes.row}>
          <Grid item lg={3} sm={12} className={classes.row}>
            <DatePicker />
          </Grid>
        </Grid>
        <PurchasesTable />
      </Page>
    </ScreenPurchasesStoreProvider>
  );
});
