import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const Price = observer(() => {
  const classes = useStyles();
  const store = useEditCouponTypeStore();

  const handleInputChange = (event) => {
    store.priceInCurrency = event.target.value;
  };

  if(!store.isCreditRequired){
    return null;
  }
  return (
    <TextField
      variant="outlined"
      fullWidth
      label={t("addNewCouponType.Price")}
      type="number"
      onChange={handleInputChange}
      onWheel={handleBlockScroll}
      value={store.priceInCurrency || ""}
      className={classes.input}
    />
  );
});
