import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Page } from "components";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenHideCouponTypesStore, ScreenHideCouponTypesStoreProvider } from "./store";
import { t } from "common/localization/translate";
import { LoadingSpinner } from "common/ui/loadingSpinner";
import { Titles } from './components/titles';
import { CouponTypesDropDown } from './components/couponTypesDropDown';
import { HideCouponTypeButton } from './components/hideCouponTypeButton';
import { Grid } from "@material-ui/core";
import { AreYouSureHideCouponTypePopUp } from './components/areYouSureHideCouponTypePopUp';
import { SuccessHideCouponTypePopUp } from './components/successHideCouponTypePopUp';
import { Redirect } from "react-router-dom";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
    height: "100%",
  },
  container:{
    marginTop:10
  }
}));

export const HideCouponTypes = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenHideCouponTypesStore(rootStore));
  const classes = useStyles();
  useEffect(() => {
   store.getScreenData();
  }, []);
  const { isFetching } = store;

  if (!rootStore.userInfoStore.isShowHideCouponTypesScreen) {
    return (
      <Redirect to={{ pathname: `/${dashboardPaths.ORGANIZATION}/home` }} />
    );
  }

  return (
    <ScreenHideCouponTypesStoreProvider value={store}>
      <LoadingSpinner isFetching={isFetching}>
        <Page className={classes.root} title={t("screenHome.overview.title")}>
        <Titles />
        <Grid className={classes.container} container spacing={3} >
          <CouponTypesDropDown />
          <HideCouponTypeButton />
          <AreYouSureHideCouponTypePopUp/>
          <SuccessHideCouponTypePopUp/>
          </Grid>
        </Page>
      </LoadingSpinner>
    </ScreenHideCouponTypesStoreProvider>
  );
});
