import React from "react";
import { observer } from "mobx-react";
import { CountInput } from "./CountInput";
import { DayInput } from "./DayInput";

export const PurchaseCountLimitation = observer(() => {
  return (
    <div>
      <CountInput />
      <DayInput />
    </div>
  );
});
