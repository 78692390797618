import React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { BusinessSelect } from "./components/businessSelect";
import { CouponTypeSelect } from "./components/couponTypeSelect";
import { CouponTitle } from "./components/couponTitle";
import { CouponDescription } from "./components/couponDescription";
import { ExternalLink } from "./components/externalLink";
import { CurrentCouponImageLink } from "./components/currentCouponImageLink";
import { CouponImage } from "./components/couponImage";
import { Coins } from "./components/coins";
import { ActivationTypeSelect } from "./components/activationTypeSelect";
import { Instructions } from "./components/instructions";
import { IsOnline } from "./components/isOnline";
import { IsOneTime } from "./components/isOneTime";
import { CodeGenerationInput } from "./components/codeGeneration/index";
import { ExpiresAt } from "./components/expiresAt";
import { ActiveDurationTime } from "./components/durationTime/activeDurationTime";
import { CouponCreditPrice } from "./components/couponCreditPrice/couponCreditPrice";
import { GeneralInfoSubTitle } from "./components/subTitle";
import { ClalitSmallImage } from "./components/clalitSmallImage";
import { CurrentSmallImageLink } from "./components/currentSmallImageLink";
import { WarningText } from "./components/warningText";
import { UnderTitleText } from "./components/underTitleText";
import { UnderPurchaseButtonText } from "./components/underPurchaseButtonText";
import { SelectOrderByDate } from "./components/selectOrderByDate";
import { PatchSelect } from "./components/patchSelect";
import { IsChallengeCoupon } from "./components/ChallengeCoupon/challengeCouponEdit";
import { ChallengeCouponGenerationInputs } from "./components/ChallengeCoupon/components/challengeTypeGenerator";

const useStyles = makeStyles((theme) => ({
  gridWidth: {
    width: "50%",
  }, 
}));

export const GeneralInfo = observer(() => {
  const classes = useStyles();

  return (
    <Grid className={classes.gridWidth} container spacing={2}>
      <GeneralInfoSubTitle />
      <Grid item xs={12}>
        <BusinessSelect />
        <CouponTypeSelect />
        <CouponTitle />
        <CouponDescription />
        <ExternalLink />
        <CouponImage />
        <CurrentCouponImageLink />
        <ClalitSmallImage />
        <CurrentSmallImageLink />
        <Coins />
        <CouponCreditPrice />
        <ActivationTypeSelect />
        <UnderTitleText />
        <UnderPurchaseButtonText />
        <WarningText />
        <Instructions />
        <CodeGenerationInput />
        <ExpiresAt />
        <ActiveDurationTime />
        <IsOnline />
        <IsOneTime />
        <IsChallengeCoupon />
        <ChallengeCouponGenerationInputs />
        <SelectOrderByDate />
        <PatchSelect />
        {/* <SupplierCouponType /> */}
      </Grid>
    </Grid>
  );
});
