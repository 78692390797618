import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { observer } from 'mobx-react-lite';
import { useScreenHideCouponTypesStore } from "../store";
import { makeStyles } from "@material-ui/styles";
import { t } from 'common/localization/translate';

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 16,
            fontFamily: 'Assistant',
            fontWeight: 'bold'
        }
    },
    buttons: {
        fontSize: 14,
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          color: theme.palette.white,
          backgroundColor: theme.palette.primary.secondGraphColor
        }
      },
}));

export const AreYouSureHideCouponTypePopUp = observer(() => {
    const store = useScreenHideCouponTypesStore();
    const classes = useStyles();
    

    const handleClose = () => {
        store.isAreYouSureHideCouponTypePopUp= false;
    };

    const handleHideCoupon = () =>{
        store.isAreYouSureHideCouponTypePopUp = false;
        store.hideCouponType();
    }

    return (
        <div>
            <Dialog open={store.isAreYouSureHideCouponTypePopUp} >
                <DialogTitle className={classes.title} >{t("organizationDashboard.hideCouponType.AreYouSurePopUpTitle")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("organizationDashboard.hideCouponType.AreYouSurePopUpContent", {couponTitle:store.couponTypeTitle})}
                    <br></br>{t("organizationDashboard.hideCouponType.AreYouSurePopUpContent2")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleHideCoupon} className={classes.buttons} autoFocus >{t("general.yes")}</Button>
                    <Button onClick={handleClose} className={classes.buttons} autoFocus >{t("general.no")}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
})