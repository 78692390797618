import find from "lodash/find";
import { getSegmentIdByPath } from "./getSegmentIdByPath";

export async function updateStoreByTypeOrganization(store, serverRes, pathname) {
  const { allowedSegments, isAllowedHideCouponTypes } = serverRes;
  store.isAllowedHideCouponTypes = isAllowedHideCouponTypes;
  store.setAllowedSegments(allowedSegments);

  const segmentIdByPath = getSegmentIdByPath(pathname);
  const currentSegmentInArray = getCurrentSegment(store.currentSegmentId, allowedSegments, segmentIdByPath);
  const currentSegmentToSet = currentSegmentInArray || store.currentSegment;
  store.setCurrentSegmentId(currentSegmentToSet.segmentId);
  store.setCurrentSegment(currentSegmentToSet);
  store.setDashboardFeatures(currentSegmentToSet.dashboardFeatures);
}
const getCurrentSegment = (segmentIdInStorage, allowedSegments, segmentIdByPath) => {
  const segmentIdToFind = segmentIdByPath || segmentIdInStorage;
  if (!segmentIdToFind) {
    return null;
  }
  if (!isMultiSegmentId(segmentIdToFind)) {
    return find(allowedSegments, (item) => {
      if(!isMultiSegmentId(item.segmentId)){
        return Number(item.segmentId) === Number(segmentIdToFind);
      }
       return item.segmentId && Number(item.segmentId.mainSegmentId) === Number(segmentIdToFind)
    });
  }
  return find(allowedSegments, (item) => {
    return item.segmentId && Number(item.segmentId.mainSegmentId) === Number(segmentIdToFind.mainSegmentId);
  });
};

function isMultiSegmentId(segmentId){
  return typeof segmentId === "object";
}