import AddIcon from '@material-ui/icons/Add';
import React from "react";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../../..";
import IconButton from '@material-ui/core/IconButton';
import { addAnswer as addAnswerToDb } from 'screens/controlPanel/editArticle/store/server/addAnswer';
import { getArticleAnswers } from 'screens/controlPanel/editArticle/store/server/getArticleAnswers';


export const AddAnswerButton = observer(() => {
    const store = useEditArticleStore();

    const addAnswer = async () => {
        if(!store.answerToAdd || !store.selectedQuestion) {
            return;
        }
        const res = await addAnswerToDb({answer: store.answerToAdd, questionId: store.selectedQuestion.question_id, isCorrect: store.isCorrectAnswer});
        if(res) {
            store.answerToAdd = "";
            store.isCorrectAnswer ="";
            store.answerArray = await getArticleAnswers(store.selectedQuestion.question_id);
        }
    }

    return(
        <IconButton color="primary" component="span">
          <AddIcon onClick={addAnswer}/>
        </IconButton>
    )
});