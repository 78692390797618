import React from "react";
import { Select, makeStyles, MenuItem, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { ExternalCouponGenerationTypeSelect } from "./ExternalCouponGenerationTypeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { externalCouponGenerationTypes } from "../../../../../../../logic/externalCouponGenerationTypes";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export const ChooseExternalInputType = observer(() => {
  const store = useEditCouponTypeStore();
  const { codesFromExternalCouponCodesInputType } = store;

  const handleChange = (event) => {
    store.setCodeGenerationParameters = null;
    store.codesFromExternalCouponCodesInputType = event.target.value;
  };

  const classes = useStyles();
  const externalInputOptions = externalCouponGenerationTypes.map(option => {return <MenuItem key={option.value} value={option}>{option.name}</MenuItem>});

  return (
    <Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel>{t("addNewCouponType.SelectExternalType")}</InputLabel>
          <Select
            value={codesFromExternalCouponCodesInputType || ""}
            onChange={handleChange}
            className={classes.formControl}
            renderValue={selected=>selected.name}
          >
            {externalInputOptions}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <ExternalCouponGenerationTypeSelect
        />
      </Grid>
    </Grid>
  );
});
