import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import useRouter from "utils/useRouter";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { t } from "common/localization/translate";
import { getSegmentPathById } from "commonStores/analytics/userInfoStore/logic/getSegmentPathById";

const useStyles = makeStyles(theme => ({
  logoutButton: {
    marginLeft: theme.spacing(8),
    fontSize: '12px',
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
    fontSize: '17px',
  }
}));

const MobileLogoutButton = () => {
  const rootStore = useRootStore();
  const classes = useStyles();
  const { history} = useRouter();

  const handleLogout = () => {
    const { dashboardType, clearUserInfoStore, currentSegmentId } = rootStore.userInfoStore;
    if(!currentSegmentId){
      clearUserInfoStore();
      history.push(`/${dashboardType}/login`);
      return;
    }
    const segmentPath = getSegmentPathById(currentSegmentId);
    clearUserInfoStore();
    history.push(`/${dashboardType}/login/${segmentPath}`);
  };

  return (
    <Button
      className={classes.logoutButton}
      color="inherit"
      onClick={handleLogout}
    >
      <InputIcon className={classes.logoutIcon} />
      {t("topBar.logout.title")}
    </Button>
  );
};

export default MobileLogoutButton;
