import React from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import SimpleCube from "common/ui/simpleCube";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../../store";
import { formatNumber } from "common/formatNumber";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Tooltip } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    row: {
        width: "100%"
    },
    reversedIcon: {
        color: theme.palette.primary.main
    },
    img: {
        height: 35
    }
}));

export const PurchasesCube = observer(() => {
    const { isFetching, amountOfPurchaseCouponsForBusiness } = useScreenHomeStore();

    const amountOfPurchaseCouponsForBusinessFormatted = formatNumber(amountOfPurchaseCouponsForBusiness);

    const classes = useStyles();

    return (
        <Grid item sm={12} lg={3} className={classes.row}>
            <Tooltip title={t("business.home.purchasesCube.tooltip")}>
                <div>
                    <SimpleCube
                        isWithoutMargin={true}
                        isFetching={isFetching}
                        isWithConstatHeight={true}
                        label={t("business.home.purchasesCube.title")}
                        icon={<ShoppingCartIcon />}
                        value={amountOfPurchaseCouponsForBusinessFormatted}
                        withoutMargin={true}
                    />
                </div>
            </Tooltip>
        </Grid>
    );
});
