import React from "react";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const BusinessDescription = observer(() => {
  const classes = useStyles();

  const store = useScreenCreateAddNewBusinessStore();

  const handleInputChange = (event) => {
    if(!event.target.value){
      store.setBusinessDescriptionEmptyError(t("screenAddNewBusiness.BusinessDescription"))
      return;
    }
    store.setBusinessDescriptionEmptyError(null)
    store.setBusinessDescription(event.target.value);
  };

  return (
    <>
      <TextField
        fullWidth
        required
        variant="outlined"
        id="outlined-multiline-static"
        multiline
        rows={3}
        onChange={handleInputChange}
        label={t("screenAddNewBusiness.BusinessDescription")}
        className={classes.input}
      />
    </>
  );
});