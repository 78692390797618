import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { KeyboardDatePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import { t } from "common/localization/translate";
import { convertDateToMoment } from "common/dateAndTime/moment";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 18,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

export const StartDateChallenge = observer(() => {
  const store = useEditCouponTypeStore();
  const classes = useStyles();

  const handleDateChange = (date) => {
    if (!date) {
      return;
    }
    store.startChallengeDate = convertDateToMoment(date).format("YYYY-MM-DD");
    store.hasChallengeBeenEdited = true;
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <InputLabel className={classes.label} shrink>
        {t("addNewCouponType.selectStartDay") + " *"}
      </InputLabel>
      <KeyboardDatePicker
        value={store.startChallengeDate ? store.startChallengeDate : null}
        onChange={(date) => handleDateChange(date)}
        format="DD/MM/YYYY"
        invalidDateMessage={store.startChallengeDate ? true : false}
      />
    </Grid>
  );
});
