import React from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import WaveChart from "common/ui/waveChart";
import { t } from "common/localization/translate";
import { CardContent } from "@material-ui/core";
import { LoadingSpinner } from "common/ui/loadingSpinner";
import { useScreenAppOpenByHoursStore } from "../store/index";
import PerfectScrollbar from "react-perfect-scrollbar";
const useStyles = makeStyles(() => ({
    chart: {
        height: "100%",
    },
    inner: {
        height: 375,
        minWidth: 500,
    },
}));

export const AppOpenByHoursGraph = observer(() => {
    const store = useScreenAppOpenByHoursStore();
    const classes = useStyles();
    const { isFetching, appOpenByHoursCountGraphData } = store;
    const counts = appOpenByHoursCountGraphData ? appOpenByHoursCountGraphData.map((item) => item.count) : [];
    const labels = appOpenByHoursCountGraphData ? appOpenByHoursCountGraphData.map((item) => item.day) : [];
    const graphData = [{ dataArray: counts, textLabel: t("screenAppOpenByHours.openAppCountsChart.labelValueType") }];

    return (
        <CardContent>
            <PerfectScrollbar>
                <div className={classes.inner}>
                    <LoadingSpinner isFetching={isFetching} className={classes.chart}>
                        <WaveChart
                            className={classes.chart}
                            labels={labels}
                            linesData={graphData}
                        />
                    </LoadingSpinner>
                </div>
            </PerfectScrollbar>
        </CardContent>

    );
});

