import React from "react";
import { TextField, makeStyles, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 15,
  }
}));

export const BusinessName = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();

  const handleInputChange = (event) => {
    if(!event.target.value) {
      store.businessNameEmptyError = t("screenAddNewBusiness.BusinessName");
      store.businessName = event.target.value;
      return;
    }
    store.businessNameEmptyError = null;
    store.businessName = event.target.value;
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <TextField
        variant="outlined"
        value={store.businessName ? store.businessName : ""}
        required
        fullWidth
        id="businessname"
        label={t("screenAddNewBusiness.BusinessName")}
        onChange={handleInputChange}
      />
    </Grid>
  );
});