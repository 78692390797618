import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { configureMobx } from "./common/configureMobx";
import { createBrowserHistory } from "history";

import "./mixins/chartjs";
import "./mixins/moment";
import "./mixins/validate";
import "./mixins/prismjs";
import "./mock";
import "./assets/scss/index.scss";
import { createStore } from "commonStores/rootStore";
import { renderRoutes } from "react-router-config";
import { Provider as MobxProvider } from "mobx-react";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import theme from "./theme";
import { routes } from "./routes";
import { RootStoreProvider } from "./commonStores/analytics/rootStoreContext";
import { createRootStore } from "commonStores/analytics/rootStore";
import ThemeProvider from "theme/themeProvider";

configureMobx();
const browserHistory = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const store = createStore();
const rootStore = createRootStore(browserHistory.location.pathname);

class App extends Component {
  render() {
    return (
      <RootStoreProvider value={rootStore}>
        <MobxProvider rootStore={store}>
          <StylesProvider jss={jss}>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Router history={browserHistory}>{renderRoutes(routes)}</Router>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </StylesProvider>
        </MobxProvider>
      </RootStoreProvider>
    );
  }
}

export default App;
