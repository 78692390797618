import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../..";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 500,
      "& > * + *": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
      },
    },
  })
);

export const SegmentSelect = observer(() => {
    const classes = useStyles();
    const store = useEditArticleStore();
    const { segmentsOptionsList, segments } = store;

    let selectedSegments = [];

    if (segments) {
      selectedSegments = segments.map(segmentId => !segmentId ? segmentsOptionsList[0] : segmentsOptionsList[segmentId]);
    }

    const handleChange = (event, values) => {
      store.segments = values.map(value => Number(value.segment_id));
    };

    return (
      <Grid className={classes.root}>
            <InputLabel >
            {t("screenAddNewBusiness.SegmentChoice") + " *"}
            </InputLabel>
        <Autocomplete
          multiple
          value={selectedSegments}
          options={segmentsOptionsList}
          getOptionLabel={option => option.name}
          filterSelectedOptions
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </Grid>
    );
});