import React from "react";
import { decorate, action, observable } from "mobx";
import { timeTypes } from "./logic/timeTypes";
import { t } from "common/localization/translate";
import { limitationTypes } from "./logic/limitationTypes";
import { createNewCouponType } from "./server/createNewCouponType";
import { getBusinessIds } from "./server/getBusinessIds";
import { getSuppliers } from "./server/getSuppliers";
import { getDescriptiveTagsCategories } from "../../addNewBuisness/store/server/getDescriptiveTagsCategories";
import { getDescriptiveTagsByCategory } from "../../addNewBuisness/store/server/getDescriptiveTagsByCategory";
import { getCouponTypesGroupServer } from './server/getCouponTypesGroupServer';
import { getBusinessById } from "./server/getBusinessById";
import { promotionTypes } from "./logic/promotionTypes";
import { getOutOfStockRuleIds } from "./server/getOutOfStockRuleIds";
import { getPatches } from "./server/getPatches";
import { externalCouponGenerationTypes } from "../../editCouponType/store/logic/externalCouponGenerationTypes";
import { languages } from "../../editArticle/store/logic/languages";
import { segmentIds } from "./logic/segmentIds";
import { isSupplierSegment } from "./logic/isSupplierSegment";

const AddNewCouponTypeStoreContext = React.createContext({});

export const AddNewCouponTypeStoreProvider =
  AddNewCouponTypeStoreContext.Provider;

export const useAddNewCouponTypeStore = () =>
  React.useContext(AddNewCouponTypeStoreContext);

const defaultHealthScore = 25;
const defaultScore = 10;

class AddNewCouponTypeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  };

  initProperties() {
    this.primaryCategory = [];
    this.descriptiveTagsCategoriesList = [];
    this.descriptiveTagsByCategoryList = [];
    this.descriptiveTags = [];
    this.outOfStockRuleIds = [];
    this.tags = null;
    this.score = defaultScore;
    this.healthScore = defaultHealthScore;
    this.couponCreationStatus = false;
    this.errorCode = false;
    this.timeType = timeTypes[0];
    this.couponTitleEmptyError = t("addNewCouponType.CouponTitle");
    this.selectedPaymentMethodEmptyError = t("addNewCouponType.paymentOptionSelect.emptyError");
    this.businessIdEmptyError = t("addNewCouponType.BusinessIdSelect");
    this.coinsEmptyError = t("addNewCouponType.Coins");
    this.activationTypeEmptyError = t("addNewCouponType.ActivationTypeLabel");
    this.challengeTypeSelectError = t("addNewCouponType.challengeTypeSelectLabel");
    this.activeDurationMinutesEmptyError = t("addNewCouponType.ActiveDurationTime");
    this.codeGenerationStrategyEmptyError = t("addNewCouponType.CodeGenerationStrategyLabel");
    this.couponImageEmptyError = t("addNewCouponType.Image");
    this.daysRangeInputEmptyError = t("addNewCouponType.daysRangeInput")
    this.daysSuccessionInputEmptyError = t("addNewCouponType.daysSuccessionInput");
    this.startChallengeDateEmptyError = t("addNewCouponType.selectStartDay");
    this.limitationType = limitationTypes[2];
    this.externalLink = null;
    this.criterionId = null;
    this.stepsAmountInput = null;
    this.daysRangeInput = null;
    this.daysSuccessionInput = null;
    this.startChallengeDate = null;
    this.couponDescription = null;
    this.couponImage = null;
    this.coins = null;
    this.priceInCurrency = null;
    this.instructionText = null;
    this.supplierId = null;
    this.activationType = null;
    this.challengeTypeSelected = null;
    this.expiresAt = null;
    this.activeDurationMinutes = null;
    this.isOnline = false;
    this.isOneTime = false;
    this.isChallenge = false;
    this.isAdminOnly = false;
    this.limitationParams = null;
    this.isActive = false;
    this.isTranslate = false;
    this.selectedOutOfStockRule = null;
    this.promotionType = promotionTypes[0];
    this.couponTypesGroup = [];
    this.segments = [];
    this.codesFromExternalCouponCodesInputType = externalCouponGenerationTypes[0];
    this.patches = [];
    this.selectedLanguage = languages.ARABIC.languageCode;
    this.getBusinessIdsList();
    this.getDescriptiveTagsCategoriesList();
    this.getOutOfStockRuleIds();
    this.getPatches();
  };
  async getCouponTypesGroup() {
    const couponTypesGroup = await getCouponTypesGroupServer({ supplierId: this.supplierId });
    this.couponTypesGroup = couponTypesGroup;
  };

  async getBusinessIdsList() {
    this.businessIdsList = await getBusinessIds();
  };

  async getSuppliersList() {
    const suppliersForSegmentId = this.segments.includes(segmentIds.CLALIT_SEGMENT_ID) ? segmentIds.CLALIT_SEGMENT_ID : segmentIds.OVDIM_BARI_SEGMENT_ID;
    this.suppliersList = await getSuppliers(suppliersForSegmentId);
  };

  async getOutOfStockRuleIds() {
    this.outOfStockRuleIds = await getOutOfStockRuleIds();
  };
  async getPatches() {
    this.patches = await getPatches();
  };

  async submitCreateCouponType() {
    this.isFetching = true;
    const res = await createNewCouponType({
      couponTitle: this.couponTitle, couponDescription: this.couponDescription, businessId: this.businessId, externalLink: this.externalLink, couponImage: this.couponImage, coins: this.coins, price: this.priceInCurrency, activationType: this.activationType, instructionText: this.instructionText, supplierId: this.supplierId, expiresAt: this.expiresAt,  activeDurationMinutes: this.activeDurationMinutes, isOnline: this.isOnline, isOneTime: this.isOneTime,
      limitationType: this.limitationType.type, limitationParams: this.limitationParams, descriptiveTags: this.descriptiveTags, tags: this.tags, score: this.score, healthScore: this.healthScore, isActive: this.isActive, codeGenerationStrategy: this.codeGenerationStrategy, codeGenerationParameters: this.codeGenerationParameters, isSupplierDashboardCouponType: this.isSupplierDashboardCouponType, isExistingCouponTypeGroup: this.isExistingCouponTypeGroup,
      groupNewTitle: this.groupNewTitle, chosenCouponTypeGroupId: this.chosenCouponTypeGroupId, chosenGroupTitle: this.chosenGroupTitle, promotionType: this.promotionType.name, outOfStockRule: this.selectedOutOfStockRule, clalitSmallImage: this.clalitSmallImage, costToSupplier: this.costToSupplier, warningText: this.warningText, isAdmin: this.isAdminOnly, underTitleText: this.underTitleText, freePurchaseButtonText: this.underPurchaseButtonText, patch: this.selectedPatch,
      sizes: this.sizes, colors: this.colors, isTranslate: this.isTranslate, translatedTitle: this.translatedTitle, translatedInstructions: this.translatedInstructions, translatedDescription: this.translatedDescription, language: this.selectedLanguage, segments: this.segments, selectedPaymentMethod: this.selectedPaymentMethod, criterionId: this.criterionId, challengeParams: { isChallenge: this.isChallenge, challengeTypeSelected: this.challengeTypeSelected,  stepsAmountInput: this.stepsAmountInput, startChallengeDate: this.startChallengeDate, daysRangeInput: this.daysRangeInput, daysSuccessionInput: this.daysSuccessionInput }
    });

    this.errorCode = res.errorCode;
    this.couponCreationStatus = res.isSuccess;
    this.isFetching = false;
  };

  async getDescriptiveTagsCategoriesList() {
    const tagsCategories = await getDescriptiveTagsCategories();
    tagsCategories.forEach((item, i) => { item.id = i + 1 });
    this.descriptiveTagsCategoriesList = tagsCategories;
  };

  async getDescriptiveTagsByCategoryList() {
    const descriptiveTags = await getDescriptiveTagsByCategory({ categoryTags: this.descriptiveTagsCategory });
    this.descriptiveTagsByCategoryList = descriptiveTags[0].descriptive_tags;
  };

  async getBusinessData() {
    const businessData = await getBusinessById(this.businessId);
    this.tags = businessData.tags;
    this.segments = businessData.segments;
    if(isSupplierSegment(this.segments)) {
      this.costToSupplierEmptyError = t("addNewCouponType.emptyCostToSupplier");
    }
    this.getSuppliersList();
  };
};

decorate(AddNewCouponTypeStore, {
  couponTitle: observable,
  couponTitleEmptyError: observable,
  businessId: observable,
  businessIdEmptyError: observable,
  couponImageEmptyError: observable,
  couponDescription: observable,
  coins: observable,
  coinsEmptyError: observable,
  codeGenerationStrategy: observable,
  codeGenerationStrategyEmptyError: observable,
  codeGenerationParameters: observable,
  codesFromExternalCouponCodesInputType: observable,
  instructionText: observable,
  couponImage: observable,
  daysToExpire: observable,
  activeDurationMinutes: observable,
  activeDurationMinutesError: observable,
  activeDurationMinutesEmptyError: observable,
  healthScore: observable,
  score: observable,
  stepsAmountInput: observable,
  daysRangeInput: observable,
  daysSuccessionInput: observable,
  startChallengeDateEmptyError: observable,
  startChallengeDate: observable,
  isOneTime: observable,
  isChallenge:observable,
  challengeTypeSelectError: observable,
  daysRangeInputEmptyError:observable,
  daysSuccessionInputEmptyError: observable,
  isOnlyCoupon: observable,
  expiresAt: observable,
  priceInCurrency: observable,
  isOnline: observable,
  criterionId: observable,
  healthScoreError: observable,
  scoreError: observable,
  limitationType: observable,
  limitationParams: observable,
  limitationTimeError: observable,
  limitationCountError: observable,
  activationType: observable,
  challengeTypeSelected: observable,
  activationTypeEmptyError: observable,
  externalLink: observable,
  descriptiveTagsCategory: observable,
  descriptiveTags: observable,
  descriptiveTagsCategoriesList: observable,
  descriptiveTagsByCategoryList: observable,
  tags: observable,
  timeType: observable,
  isCreditRequired: observable,
  isActive: observable,
  supplierId: observable,
  coinsError: observable,
  priceError: observable,
  errorCode: observable,
  isNegNumberErrorPopUpOpen: observable,
  isEmptyFieldErrorPopUpOpen: observable,
  isSupplierDashboardCouponType: observable,
  couponCreationStatus: observable,
  businessIdsList: observable,
  suppliersList: observable,
  couponTypesGroup: observable,
  isExistingCouponTypeGroup: observable,
  isFetching: observable,
  chosenCouponTypeGroupId: observable,
  groupNewTitle: observable,
  groupTitleErrorText: observable,
  groupPriceErrorText: observable,
  chosenCouponTypeErrorText: observable,
  isSupportSupplierDashboard: observable,
  notSupprtSupplierDashboardErrorText: observable,
  chosenGroupTitle: observable,
  supplierErrorText: observable,
  promotionType: observable,
  selectedOutOfStockRule: observable,
  outOfStockRuleIds: observable,
  clalitSmallImage: observable,
  costToSupplier: observable,
  segments: observable,
  warningText: observable,
  isAdminOnly: observable,
  underTitleText: observable,
  underPurchaseButtonText: observable,
  patches: observable,
  selectedPatch: observable,
  selectedPaymentMethod: observable,
  selectedPaymentMethodEmptyError: observable,
  sizes: observable,
  colors: observable,
  isTranslate: observable,
  translatedTitle: observable,
  translatedDescription: observable,
  translatedInstructions: observable,
  selectedLanguage: observable,
  costToSupplierEmptyError: observable,
  initProperties: action.bound,
});

export function createAddNewCouponTypeStore(rootStore) {
  const store = new AddNewCouponTypeStore(rootStore);
  return store;
};