import React from "react";
import { Container, CssBaseline, Typography,} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";
import { GeneralInfo } from "./generalInfo";
import { Grid } from "@material-ui/core";
import { InternalInfo } from "./internalInfo";
import { EditCouponTypeButton } from "./editCouponButton";
import { EditCouponTypeSuccessPopUp } from "./popUps/editCouponTypeSuccess";
import { EditCouponTypeErrorPopUp } from "./popUps/editCouponTypeError";

const useStyles = makeStyles((theme) => ({
  title: {
    flex: "auto",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    marginBottom: 10,
  }
}));

export const Form = observer(() => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Grid className={classes.paper}>
        <Typography className={classes.title} component="h1" variant="h1">
          {t("editCouponType.editCouponTitle")}
        </Typography>
        <form className={classes.form} noValidate>
          <GeneralInfo />
          <InternalInfo />
          <EditCouponTypeButton />
          <EditCouponTypeSuccessPopUp />
          <EditCouponTypeErrorPopUp />
        </form>
      </Grid>
    </Container>
  );
});
