import React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../store";
import { t } from "common/localization/translate";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/styles";


  const useStyles = makeStyles((theme) => ({
    tooltip: {
        fontSize: 14,
      },
      toolTipIcon: {
        color: theme.palette.primary.main
      },
      label :{
        fontSize:18,
        fontWeight: 'bold'
    },
  }));

export const RefundSupplier = observer((props) => {
    const { isProjectManager } = props;

    if(isProjectManager) {
      return null;
    }
    
    const store = useScreenHomeStore();
    const classes = useStyles();
    const handleRefund = (e) => {
        if (e.target.value < 0) {
            e.target.value = 0
        }
        store.refund = e.target.value
    }

    return (
        <Grid item xs={12} spacing={3}>
            <InputLabel className={classes.label} shrink >{t("suppliers.supplierHome.insertRefund")}</InputLabel>
            <TextField type="number" error={false} onChange={handleRefund} helperText={''} />
            <Tooltip title={t("suppliers.supplierHome.toolTipRefund")} >
                <HelpIcon className={classes.toolTipIcon} />
            </Tooltip>
        </Grid>
    )
});

