import React from "react";
import { decorate, action, observable } from "mobx";
import { login as loginServer } from "./server/login";
import { t } from "common/localization/translate";
import { setHttpHeaders } from "common/setHttpHeaders";
import { getDashboardByPathName } from "./logic/getDashboardByPathName";
import { getStorageKeyByDashobardType } from "commonStores/userInfoStore/localStorage/getStorageKeyByDashobardType";
import { createLocalDataObject, setLocalData } from "commonStores/userInfoStore/localStorage/localPersistence";
import { getErrorTextByErrorCode } from '../../organization/login/store/logic/getErrorTextByErrorCode';
import { resetPasswordSendEmail } from '../../organization/login/store/server/resetPassword';

const ScreenLoginStoreContext = React.createContext({});
export const ScreenLoginStoreProvider = ScreenLoginStoreContext.Provider;
  export const useScreenLoginStore = () =>
  React.useContext(ScreenLoginStoreContext);

class ScreenLoginStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
  
  login = async (history, pathname) => {
    const loginInfo = { email: this.email, password: this.password };
    if(!this.email || !this.password){
      this.setErrorText(t("screenLogin.description"));
      return;
    }
    const currentDashboardType = getDashboardByPathName(pathname);
    const { isSuccess, extra, errorCode } = await loginServer(loginInfo, currentDashboardType);
    if (isSuccess) {
      const { token, allowedSegments, currentSegment, currentBusiness } = extra;
      const segmentToShow = currentSegment || allowedSegments ? allowedSegments[0] : null ;
      setHttpHeaders(token);
      this.rootStore.userInfoStore.initProperties({ token, segmentId:segmentToShow, businessId:currentBusiness, dashboardType:currentDashboardType });
      this.saveLocalData(currentDashboardType, token, segmentToShow, currentBusiness);
      const newPath = `/${currentDashboardType}/home`;
      history.push(newPath);
    } else {
      if (extra && extra.isResetPasswordNeeded) {
        this.isResetPasswordNeeded = extra.isResetPasswordNeeded;
        this.appType = extra.appType;
        this.email = extra.email;
      }
      const errorText = getErrorTextByErrorCode(errorCode);
      this.setErrorText(errorText);
    }
  }

  resetPassword = async () => {
    if (!this.email) {
      this.setErrorText(t("screenLogin.editPassword.errorText"));
    } else {
      this.setErrorText(null);
      this.isShowSuccessResetPasswordPopUp = true;
    }
    await resetPasswordSendEmail({ email: this.email });
  }

  setEmail(email) {
    this.email = email;
  }

  setPassword(password) {
    this.password = password;
  }

  setErrorText(errorText) {
    this.errorText = errorText;
  }

  saveLocalData = async (dashboardType, token, currentSegment, currentBusiness) => {
    const key = getStorageKeyByDashobardType(dashboardType);
    const data = createLocalDataObject(token, currentSegment, currentBusiness);
    await setLocalData(key, data);
  }
}

decorate(ScreenLoginStore, {
  email: observable,
  password: observable,
  errorText: observable,
  appType: observable,
  isResetPasswordNeeded:observable,
  isShowSuccessResetPasswordPopUp:observable,
  setEmail: action.bound,
  setPassword: action.bound,
  setErrorText: action.bound,
  login: action.bound,
  initProperties: action.bound
});

export function createScreenLoginStore(rootStore) {
  const store = new ScreenLoginStore(rootStore);
  return store;
}
