import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";
import { LimitationInput } from "./limitationSelect/LimitationInput";
import { LimitationTypeSelect } from "./limitationSelect/LimitationTypeSelect";
import { DescriptiveTagSelector } from "./descriptiveTags";
import { Tags } from "./tags";
import { Score } from "./score";
import { HealthScore } from "./healthScore";
import { PromotionTypeSelect } from "./promotionTypeSelect";
import { IsActive } from "./isActive";
import { CostToSupplier } from "./costToSupplier";
import { IsAdminOnly } from "./isAdminOnly";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    textAlign: "center",
    flex: "auto",
    marginTop: 30,
    marginBottom: 10,
    fontWeight: "normal",
    color: theme.palette.primary.main,
  },
  gridWidth: {
    width: "50%",
  },
}));

export const InternalInfo = observer(() => {
  const classes = useStyles();

  return (
    <Grid className={classes.gridWidth} container spacing={2}>
      <Typography className={classes.subtitle} component="h1" variant="h2">
        {t("addNewCouponType.AddCouponTypeInternalSubitle")}
      </Typography>
      <Grid item xs={12}>
        <CostToSupplier />
        <LimitationTypeSelect />
        <LimitationInput />
        <DescriptiveTagSelector />
        <Tags />
        <Score />
        <HealthScore />
        <PromotionTypeSelect />
        <IsAdminOnly />
        <IsActive />
      </Grid>
    </Grid>
  );
});
