import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const Retainer = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();
  const { retainerError } = store;

  const handleInputChange = (event) => {
    if (event.target.value < 0) {
      store.retainerError = true;
      return;
    }
    store.retainerError = null;
    store.retainer = event.target.value;
  };

  return (
    <div className={classes.input}>
      <TextField
        required
        placeholder="0"
        value={store.retainer ? store.retainer : ""}
        variant="outlined"
        fullWidth
        name="Retainer"
        label={t("screenAddNewBusiness.Retainer") + " (Retainer)"}
        type="number"
        onChange={handleInputChange}
        onWheel={handleBlockScroll}
        error={retainerError}
        helperText={retainerError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
      />
    </div>
  );
});
