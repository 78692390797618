import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import { CommonButton } from "common/ui/commonButton";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import { observer } from "mobx-react";
import { useScreenSendNotificationStore } from "../store/index";
import { validateFieldsBeforeSendNotification } from '../store/logic/validateFieldsBeforeSendNotification';
import { t } from 'common/localization/translate';

const useStyles = makeStyles((theme) => ({
    toolTipIcon: {
        color: theme.palette.primary.main,
        marginRight: 10,
    }
}));

export const SendButtons = observer(() => {
    const classes = useStyles();
    const store = useScreenSendNotificationStore();

    const sendSelfNotificationToCheck = () => {
        validateFieldsBeforeSendNotification(store)
        store.sendNotificationToUser(true);
    }
    const sendNotificationToUser = () => {
        validateFieldsBeforeSendNotification(store)
        store.sendNotificationToUser();
    }

    return (
        <CardActions>
            <CommonButton onClick={sendSelfNotificationToCheck} >{t("support.sendNotificationScreen.selfSendButton")} </CommonButton>
            <CommonButton disabled={!store.isSentSelfNotification} onClick={sendNotificationToUser} >{t("support.sendNotificationScreen.sendToUser")}</CommonButton>
            <Tooltip title={t("support.sendNotificationScreen.toolTipContent")} >
                <HelpIcon className={classes.toolTipIcon} />
            </Tooltip>
        </CardActions>
    );
})