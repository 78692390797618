import React from "react";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const RandomCodeMessage = observer(() => {
  const classes = useStyles();

  return (
    <Grid className={classes.input}>
      <Typography variant="body1">
        {t("addNewCouponType.RandomCodeMessage")}
      </Typography>
    </Grid>
  );
});
