import React from "react";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

export const ActiveDurationInput = observer(() => {
  const store = useEditCouponTypeStore();
  const { activeDurationMinutes } = store;
  const minValue = 0;

  const handleChange = (event) => {
   if(event.target.value >= minValue){
    store.activeDurationMinutes = event.target.value;
   }
  };

  return (
    <TextField
      required
      label={t("addNewCouponType.TimeInputPlaceholder")}
      variant="outlined"
      type="number"
      onChange={handleChange}
      onWheel={handleBlockScroll}
      value={activeDurationMinutes || ""}
    />
  );
});
