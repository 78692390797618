import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from '../../../../..';
import { t } from "common/localization/translate";

export const IsEmailAtLikeCheckbox = observer(() => {
    const store = useScreenCreateAddNewBusinessStore();
    const { isEmailAtLike } = store;
  
    const handleChange = (event) => {
      store.setIsEmailAtLike(event.target.checked);
    };
  
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              value={isEmailAtLike}
              onChange={handleChange}
              name="isEmailAtLike"
            />
          }
          label={t("screenAddNewBusiness.isEmailAtLike")}
        />
      </div>
    );
  });