import React from "react";
import { InputLabel, Select, makeStyles, MenuItem, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { codeGenStrategies } from "screens/controlPanel/addCouponType/store/logic/codeGenStrategies";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  category: {
    margin: theme.spacing(1),
    minWidth: 300,
    border: 1,
    borderColor: "black",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const CodeGenerationStrategyDropdown = observer(() => {
  const store = useEditCouponTypeStore();
  const { codeGenerationStrategy } = store;
  const classes = useStyles();

  const codeGenStrategyOptions = codeGenStrategies.map((strategy) => {return <MenuItem key={strategy.strategyId} value={strategy.strategyId}>{strategy.name}</MenuItem>});

  const handleChange = (event) => { 
    store.codeGenerationStrategy = event.target.value;
    store.codeGenerationParameters = null;
  };

  return (
    <Grid item sm={4}>
      <InputLabel>
        {t("addNewCouponType.CodeGenerationStrategyLabel") + " *"}
      </InputLabel>
      <Select
        value={codeGenerationStrategy || ""}
        onChange={handleChange}
        className={classes.category}
      >
        {codeGenStrategyOptions}
      </Select>
    </Grid>
  );
});
