import React from "react";
import { decorate, action, observable } from "mobx";
import { getBusinessIdsList } from "screens/controlPanel/editBusiness/store/server/getBusinessIdsList";
import { addNewAuction } from "./server/addNewAuction";
import { getSegments } from "../../addNewBuisness/store/server/getSegments";
import { emptyFieldsError } from "../logic/emptyFieldsErrors";

const AddNewAuctionStoreContext = React.createContext({});

export const AddNewAuctionStoreProvider = AddNewAuctionStoreContext.Provider;

export const useAddNewAuctionStore = () =>
  React.useContext(AddNewAuctionStoreContext);

class AddNewAuctionStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }
  
  initProperties() {
    const DEFAULT_EMAIL_BCC_TO_SEND_PERFORMANCE_REPORT =
    "nivg@rumble.co.il,einavw@rumble.co.il";
    this.businessEmptyError = emptyFieldsError.ADD_AUCTION_EMPTY_FIELD_ERROR_BUSINESS;
    this.auctionTitleEmptyError = emptyFieldsError.ADD_AUCTION_EMPTY_FIELD_ERROR_TITLE;
    this.startAuctionDateEmptyError = emptyFieldsError.ADD_AUCTION_EMPTY_FIELD_ERROR_START_DATE;
    this.endAuctionDateEmptyError = emptyFieldsError.ADD_AUCTION_EMPTY_FIELD_ERROR_END_DATE;
    this.costToBusinessEmptyError = emptyFieldsError.ADD_AUCTION_EMPTY_FIELD_ERROR_COST_TO_BUSINESS;
    this.auctionDescriptionEmptyError = emptyFieldsError.ADD_AUCTION_EMPTY_FIELD_ERROR_DESCRIPTION;
    this.startSprintDateEmptyError = emptyFieldsError.ADD_AUCTION_EMPTY_FIELD_ERROR_START_SPRINT_DATE;
    this.selectedSegmentsEmptyError = emptyFieldsError.ADD_AUCTION_EMPTY_FIELD_ERROR_SEGMENT_CHOICE;
    this.auctionImageEmptyError = emptyFieldsError.ADD_AUCTION_EMPTY_FIELD_ERROR_IMAGE;
    this.bccEmail = DEFAULT_EMAIL_BCC_TO_SEND_PERFORMANCE_REPORT;
    this.businessId = null;
    this.businessName = null;
    this.isFetching = false;
    this.startAuctionDate = null;
    this.endAuctionDate = null;
    this.externalLinkUrl = null;
    this.costToBusiness = "";
    this.externalLinkButtonText = null;
    this.stepsBidEmptyError = "Steps Bid";
    this.isSprintAuction = false;
    this.startSprintDate = null;
    this.segmentsList = [];
    this.isEmptyFieldErrorPopUpOpen = false;
    this.errorCode = false;
    this.auctionCreationStatus = false;
    this.getBusinessByIdsList();
    this.getSegmentsList();
  }

  async getBusinessByIdsList() {
    this.businessIdsList = await getBusinessIdsList(this.businessId);
  }

  async getSegmentsList() {
    this.segmentsList = await getSegments();
  }

  async submitAddNewAuction() {
    this.isFetching = true;
    const DEFAULT_STEPS_BID = "5";

    const genericSegmentToNull = this.selectedSegments.indexOf(0);
    this.selectedSegments[genericSegmentToNull] = null;
    const selectedSegments = JSON.stringify(this.selectedSegments);

    const res = await addNewAuction({
      business: { id: this.businessId, name: this.businessName }, title: this.auctionTitle, startAt: this.startAuctionDate, endAt: this.endAuctionDate, costToBusiness: this.costToBusiness, externalLink: this.externalLinkUrl, externalLinkButtonText: this.externalLinkButtonText, performanceReportBcc: this.bccEmail, bidStep: this.stepsBid ? this.stepsBid : DEFAULT_STEPS_BID, decription: this.auctionDescription, coinsEffectiveFrom: this.startSprintDate, segments: selectedSegments, auctionImage: this.auctionImage
    });

    this.errorCode = res.errorCode;
    this.auctionCreationStatus = res.isSuccess;
    this.isFetching = false;
  }
}

decorate(AddNewAuctionStore, {
  isFetching: observable,
  title: observable,
  businessId: observable,
  businessName: observable,
  businessEmptyError: observable,
  businessIdsList: observable,
  auctionTitle: observable,
  auctionTitleEmptyError: observable,
  startAuctionDate: observable,
  startAuctionDateEmptyError: observable,
  endAuctionDate: observable,
  endAuctionDateEmptyError: observable,
  costToBusiness: observable,
  costToBusinessEmptyError: observable,
  externalLinkUrl: observable,
  externalLinkButtonText: observable,
  stepsBid: observable,
  stepsBidEmptyError: observable,
  bccEmail: observable,
  auctionDescription: observable,
  auctionDescriptionEmptyError: observable,
  isSprintAuction: observable,
  startSprintDate: observable,
  startSprintDateEmptyError: observable,
  segmentsList: observable,
  selectedSegments: observable,
  selectedSegmentsEmptyError: observable,
  selectedBusinessName: observable,
  auctionCreationStatus: observable,
  errorCode: observable,
  isEmptyFieldErrorPopUpOpen: observable,
  auctionImage: observable,
  auctionImageEmptyError: observable,
  initProperties: action.bound,
});

export function createAddNewAuctionStore(rootStore) {
  const store = new AddNewAuctionStore(rootStore);
  return store;
}
