import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { CommonTable } from 'common/ui/commonTable.js';
import { Root } from 'common/ui/root';
import { DatesPicker } from 'common/ui/datePicker/index';
import { InfoCard } from 'componentsRumble/infoCard';

export class PurchasesSummaryByBranchComponent extends Component {

  componentDidMount() {
    this.props.rootStore.biz.storePurchasesSummaryByBranch.getDataPurchasesSummaryByBranch();
  }

  render() {
    const { purchasesSummaryByBranch, onChangeRange, endAt, startAt, purchasesCountTotal } = this.props.rootStore.biz.storePurchasesSummaryByBranch;
    const purchasesCountTotalText = `מספר הכניסות: ${purchasesCountTotal}`;
    const headerColumns = [
      { id: 'branch', label: 'שם הסניף' },
      { id: 'count', label: 'מספר כניסות' }
    ];

    const renderRow = item => <TableRow hover>
      <TableCell>{item.branchName}</TableCell>
      <TableCell>{item.count}</TableCell>
    </TableRow>

    return <Root>
      <DatesPicker
        endAt={endAt}
        startAt={startAt}
        onChangeRange={onChangeRange} />
      <InfoCard info={purchasesCountTotalText} />
      <CommonTable
        defaultSortByColumn='branch'
        renderRow={renderRow}
        itemKey='branchId'
        items={purchasesSummaryByBranch}
        headerColumns={headerColumns} />
    </Root>
  }
}

export const PurchasesSummaryByBranch = inject("rootStore")(observer(PurchasesSummaryByBranchComponent));