import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PurchasedCouponTable } from 'componentsRumble/purchasedCouponTable';

export class PurchasesComponent extends Component {

    componentDidMount() {
        this.props.rootStore.org.storePurchases.getDataPurchases();
    }


    render() {
        const { purchasedCoupons } = this.props.rootStore.org.storePurchases;
        return <PurchasedCouponTable purchasedCoupons={purchasedCoupons} />
    }
}

export const Purchases = inject("rootStore")(observer(PurchasesComponent));