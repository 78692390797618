import React from "react";
import { decorate, action, observable, computed } from "mobx";
import { validateOrderId } from "./logic/validateOrderId";
import { getOrderDetailsServer} from "./server/getOrderDetailsServer";



const ScreenOrderDetailsStoreContext = React.createContext({});
export const ScreenOrderDetailsDataProvider = ScreenOrderDetailsStoreContext.Provider;
export const useScreenOrderDetailsStore = () =>
  React.useContext(ScreenOrderDetailsStoreContext);


class ScreenOrderDetailsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  setOrderId(orderId) {
    this.orderId = orderId;
  }

  setOrderDetails(orderDetails) {
    this.orderDetails = orderDetails;
  }

  setErrorCodes(errorCode) {
    this.errorCode = errorCode;
  }


  getScreenData = async () => {
    this.setOrderDetails(null);
    this.setIsFetching(true);
    const segmentId = this.rootStore.userInfoStore.currentSegment
      ? this.rootStore.userInfoStore.currentSegment.segmentId
      : null;
    const response = await getOrderDetailsServer(this.orderId, segmentId);
    this.setErrorCodes(response.errorCode);
    this.setOrderDetails(response.extra);
    this.setIsFetching(false);
  }

  submitIdNum() {
    const errors = validateOrderId(this.orderId);
    this.setErrorCodes(errors);
    if (!errors) {
      this.getScreenData();
    }
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }
}


decorate(ScreenOrderDetailsStore, {
  isFetching: observable,
  userIdNum: observable,
  userName: observable,
  orderDetails: observable,
  errorCode: observable,
  setUserName: action.bound,
  submitIdNum: action.bound,
  setUserBalance: action.bound,
  setUserIdNum: action.bound,
  setErrorCodes: action.bound,
  setIsFetching: action.bound,
  getScreenData: action.bound,
});


export function createOrderDetailsUsersStore(rootStore) {
  const store = new ScreenOrderDetailsStore(rootStore);
  return store;
}