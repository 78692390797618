import { httpCall } from "common/httpCall";

export async function switchUserSegment({
  newSegmentId,
  userIdForChange,
  textReasonForChange,
}) {
  const res = await httpCall("admin/updateSegmentForUser", {
    newSegmentId,
    userIdForChange,
    textReasonForChange,
  });
  return res;
}
