import axios from 'axios';
import { getBaseUrl } from './getBaseUrl';

export async function httpCall(url, data) {
    const baseUrl = getBaseUrl();
    const urlWithBase = `${baseUrl}${url}`;
    const res = await axios({
        url: urlWithBase,
        method: 'POST',
        data,
    });
    return res.data;
}