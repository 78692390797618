import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { observer } from "mobx-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { Grid } from "@material-ui/core";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  inputRoot: {
    width: 310
  }
}));
export const DescriptiveTagSelect = observer(() => {
  const store = useScreenCreateAddNewBusinessStore();
  const { descriptiveTagsByCategoryList } = store;
  
  const classes = useStyles();

  const handleChange = (event, values) => {
    if(values.length === 0) {
      store.setDescriptiveTagsEmptyError(t("screenAddNewBusiness.DescriptiveTagSelect"))
      return;
    }
    store.setDescriptiveTagsEmptyError(null)
    store.setDescriptiveTags(values);
  };

  return (
    <Grid>
      <InputLabel>{t("screenAddNewBusiness.DescriptiveTagSelect") + " *"}</InputLabel>
      <Autocomplete
        multiple
        disableCloseOnSelect={true}
        id="segments"
        options={descriptiveTagsByCategoryList}
        filterSelectedOptions
        onChange={handleChange}
        classes={classes}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    </Grid>
  );
});