export function setSheetProps(sheets) {
    sheets.forEach(sheet => {
        sheet.views = [
            {
                rightToLeft: true
            }
        ];
        sheet.columns = [
            {
                width: 60
            },
            {
                width: 60
            },
            {
                width: 60
            },
            {
                width: 60
            }
        ];
    });
}