import React from "react";
import { observer } from "mobx-react";
import { AutoGenInputLength } from "./AutGenInputLength";
import { AutoGenInputCount } from "./AutoGenInputCount";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const AutoGenInput = observer(() => {
  const classes = useStyles();

  return (
    <Grid className={classes.input}>
      <AutoGenInputLength />
      <AutoGenInputCount />
    </Grid>
  );
});
