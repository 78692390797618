import React from "react";
import { decorate, observable } from "mobx";
import { getSendNotificationScreenData } from './server/getSendNotificationScreenData';
import { validateIdNum } from "../../services/validateIdNum";
import { sendNotificationToUserServer } from './server/sendNotificationToUserServer';
import { errorCodes } from '../../services/errorCodes';
import { notificationNavigationsList } from './logic/notificationNavigationsList';

const ScreenSendNotificationToUserStoreContext = React.createContext({});
export const ScreenSendNotificationToUserProvider =
  ScreenSendNotificationToUserStoreContext.Provider;
export const useScreenSendNotificationStore = () =>
  React.useContext(ScreenSendNotificationToUserStoreContext);

class ScreenSendNotificationToUserStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }


  getScreenData = async () => {
    try {
      this.isFetching = true;
      const segmentId = this.rootStore.userInfoStore.currentSegment
        ? this.rootStore.userInfoStore.currentSegment.segmentId
        : null;
      const response = await getSendNotificationScreenData({ userIdNum: this.userIdNum, segmentId });
      this.navigateTo = notificationNavigationsList[0].value;
      if (!response.isSuccess) {
        this.errorCode = response.errorCode;
      }
      this.userName = response.extra.userName;
      this.userIdNumToSendNot = response.extra.idNumber;
      this.isFetching = false;
    } catch (e) {
      this.isFetching = false;
    }
  };

  submitIdNum = () => {
    this.isSentSelfNotification = false;
    this.userName = null;
    this.errorCode = null;
    const isValidId = validateIdNum(this.userIdNum);
    if (isValidId) {
      this.getScreenData();
      return;
    }
    this.errorCode = errorCodes.ERROR_TYPE_INVALID_DATA;
    return;
  }

  sendNotificationToUser = async (isSelfSendingForTesting) => {
    if (this.isEmptyNotificationText || this.isEmptyLink || this.isEmptyNotificationType) {
      return;
    }
    const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
    const data = await sendNotificationToUserServer({segmentId, isSelfSendingForTesting, text: this.text, navigateTo: this.navigateTo, link: this.link,idNum: this.userIdNumToSendNot, isExternalNot: this.isExternalNot, isInternalNot: this.isInternalNot});
    this.isSentSelfNotification = true;
    this.isSuccessSendNotification = data.isSuccess;
    return;
  }


}




decorate(ScreenSendNotificationToUserStore, {
  userIdNum: observable,
  userName: observable,
  userIdNumToSendNot: observable,
  isFetching: observable,
  errorCode: observable,
  text: observable,
  link: observable,
  navigateTo: observable,
  isExternalNot: observable,
  isInternalNot: observable,
  isSentSelfNotification: observable,
  isEmptyNotificationText: observable,
  isEmptyLink: observable,
  isEmptyNotificationType: observable,
  isSuccessSendNotification:observable
});

export function createScreenSendNotificationToUserStore(rootStore) {
  const store = new ScreenSendNotificationToUserStore(rootStore);
  return store;
}
