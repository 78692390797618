import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { ArticleName } from "../hebrewComponents/articleName";
import { TranslatedArticleName } from "./translatedTitle";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
  },
}));

export const ArticleNameTranslate = observer(() => {
  const classes = useStyles();

  return (
    <Grid className={classes.paper}>
      <ArticleName />
      <TranslatedArticleName />
    </Grid>
  );
});