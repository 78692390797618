import React from "react";
import { makeStyles } from "@material-ui/styles";
import { t } from "common/localization/translate";
import { CardActions } from "@material-ui/core";
import { useScreenEditUserInfoStore } from "../store";
import { CommonButton } from "common/ui/commonButton";

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    padding:0,
    marginTop: 10,
  },
  styledButton: {
    marginTop: 30,
    fontSize: 14,
    padding:10
  },
}));

export const ResetButton = (props) => {
  const store = useScreenEditUserInfoStore();
  const classes = useStyles();

  const handleReset = async (event) => {
    event.preventDefault();
    await store.submitResetPassword();
  };

  return (
    <CardActions className={classes.confirmButton}>
      <CommonButton className={classes.styledButton} onClick={handleReset}>
        {t("screenEditUserInfo.button.resetPassword")}
      </CommonButton>
    </CardActions>
  );
};
