import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";
import { handleBlockScroll } from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const Retainer = observer(() => {
  const classes = useStyles();
  const store = useScreenCreateAddNewBusinessStore();
  const { retainerError } = store;

  const handleInputChange = (event) => {
    if (event.target.value < 0) {
      store.setRetainerError(true);
      return;
    }
    store.setRetainerError(null);
    store.setRetainer(Number(event.target.value));
  };

  return (
    <div>
      <TextField
        required
        placeholder="0"
        variant="outlined"
        fullWidth
        name="Retainer"
        label={t("screenAddNewBusiness.Retainer") + " (Retainer)"}
        type="number"
        onChange={handleInputChange}
        onWheel={handleBlockScroll}
        className={classes.input}
        error={retainerError}
        helperText={retainerError ? t("screenAddNewBusiness.NegativeNumberError") : ""}
      />
      <p>{t( "screenAddNewBusiness.RetainerInstructions")}</p>
    </div>
  );
});
