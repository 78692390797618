import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { Page } from "components";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenPricingStore, ScreenPricingStoreProvider } from "./store";
import { t } from "common/localization/translate";
import { LoadingSpinner } from "common/ui/loadingSpinner";
import { PricingModal } from './components/PricingModal'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
    height: "100%",
  },
  container: {
    "& > *": {
      height: "100%",
      padding: theme.spacing(3)
    },
    row: {
      height: "100%",
    },
  },
}));
export const Pricing = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenPricingStore(rootStore));
  const classes = useStyles();

  const { isFetching } = store;
  return (
    <ScreenPricingStoreProvider value={store}>
      <LoadingSpinner isFetching={isFetching} >
        <Page className={classes.root} title={t("business.home.title")}>
          <Grid justify='center' className={classes.container} container  spacing={3}>
          <PricingModal />
          </Grid>
        </Page>
      </LoadingSpinner>
    </ScreenPricingStoreProvider>)

});

