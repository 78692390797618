import React from "react";
import { makeStyles, Select, MenuItem } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../../..";

const useStyles = makeStyles(() => ({
  input: {
    marginLeft: 20,
    marginBottom: 10,
    minWidth: 200,
    maxWidth: 300
  },
}));

export const TranslateAnswerSelect = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();

  const handleChange = (event) => {
    store.selectedAnswer = event.target.value;
  };
  
  const answerOptions = store.answerArray.map((answer) => (<MenuItem value={answer}>{answer.body}</MenuItem>));
 
  return (
      <Select onChange={handleChange} value={store.selectedAnswer} className={classes.input}>
        {answerOptions}
      </Select>  );
});
