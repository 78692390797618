import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";
import { Grid, Checkbox } from "@material-ui/core";


export const IsAdminOnly = observer(() => {
    const store = useEditCouponTypeStore();

    const handleChange = (event) => {
        store.isAdminOnly = event.target.checked;
        store.previousAttributes = { ...store.previousAttributes, isShowAdminOnly: event.target.checked };
    };

    return (
        <Grid item xs={12}>
            <FormControlLabel
                control={<Checkbox onChange={handleChange} checked={store.isAdminOnly ? true : false} />}
                label={t("editArticle.isAdmin")}
            />
        </Grid>
    );
});
