import React from "react";
import { Checkbox, Grid, FormControlLabel } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";

export const IsOneTimeCheckbox = observer(() => {
  const store = useAddNewCouponTypeStore();
  const { isOneTime } = store;

  const handleChange = (event) => {
    store.isOneTime = event.target.checked;
  };

  return (
    <Grid>
      <FormControlLabel control={<Checkbox value={isOneTime} onChange={handleChange} />} label={t("addNewCouponType.IsOneTime")}/>
    </Grid>
  );
});
