import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useScreenOrderDetailsStore } from "../store/index";
import MUIDataTable from "mui-datatables";


export const OrderDetailsTable = observer(() => {
  const store = useScreenOrderDetailsStore();
  const { orderDetails, isFetching, errorCode } = store;
  const { options, columns } = getTableData(isFetching);

  if (errorCode || !orderDetails) {
    return null;
  }

  return (
    <MUIDataTable
      title={t("screenOrderDetails.title")}
      data={[orderDetails]}
      columns={columns}
      options={options}
    />
  );
});

const getTableData = () => {
      const columns = [
      {
      name: "couponName",
      label: t("screenOrderDetails.table.col.couponTitle"),
      },
        {
          name: "date",
          label: t("screenOrderDetailsUser.table.col.date"),
        },
        {
          name: "businessName",
          label: t("screenOrderDetails.table.col.business"),
        },
        {
          name: "userName",
          label: t("screenOrderDetails.table.col.userName"),
        },
        {
          name: "idNum",
          label: t("screenOrderDetails.table.col.idNum"),
        },
        {
          name: "email",
          label: t("screenOrderDetails.table.col.email"),
        },
        {
          name: "phone",
          label: t("screenOrderDetails.table.col.phone"),
        },
        {
          name: "fullAddressFormatted",
          label: t("screenOrderDetails.table.col.address"),
        }
  ];

  const options = {
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    responsive: "scrollMaxHeight",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: t("screenUserBalanceUser.table.col.noMatch"),
      },
    },
  };
  return { options, columns };
};
