import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { CouponTitleWithTranslate } from "./components/translation/couponTitleWithTranslate";
import { CouponDescriptionWithTranslate } from "./components/translation/couponDescriptionWithTranslate";
import { CouponImage } from "./components/CouponImage";
import { Coins } from "./components/Coins";
import { CreditRequired } from "./components/couponCreditPrice/CreditRequired";
import { BusinessIdSelector } from "./components/BusinessIdSelect";
import { ExternalLink } from "./components/ExternalLink";
import { CouponInstructionsWithTranslate } from "./components/translation/couponInstructionsWithTranslate";
import { CodeGenerationStrategyDropdown } from "./components/CodeGeneration/components/CodeGenerationStrategyDropdown";
import { CodeGenerationInput } from "./components/CodeGeneration/CodeGenerationIndex";
import { SelectExpiresAtDate } from "./components/SelectExpiresAtDate";
import { ActiveDurationTime } from "./components/durationTime/ActiveDurationTime";
import { IsOnlineCheckbox } from "./components/IsOnlineCheckbox";
import { IsOneTimeCheckbox } from "./components/IsOneTimeCheckbox";
import { IsChallengeCoupon } from "./components/challengeCoupon/index";
import { t } from "common/localization/translate";
import { SupplierCouponType } from "./components/supplierCouponType/supplierCouponType";
import { ActivationTypeSelect } from "./components/ActivationTypeSelect";
import { WarningText } from "./components/warningText";
import { UnderTitleText } from "./components/underTitleText";
import { UnderPurchaseButtonText } from "./components/underPurchaseButtonText";
import { PatchSelect } from "./components/patchSelect";
import { SizeAndColor } from "./components/sizeAndColor/sizeAndColor";
import { IsTranslate } from "./components/translation/isTranslate";
import { LanguageSelect } from "./components/translation/languageSelect";
import { ChallengeCouponGenerationInputs } from "./components/challengeCoupon/components/challengeTypeGenerator";

const useStyles = makeStyles((theme) => ({
  subtitle: { textAlign: "center", flex: "auto", marginTop: 30, marginBottom: 10, fontWeight: "normal", color: theme.palette.primary.main },
  gridWidth: { width: "50%"}
}));

export const GeneralInfo = observer(() => {
  const classes = useStyles();

  return (
    <Grid className={classes.gridWidth} container spacing={2}>
      <Typography className={classes.subtitle} component="h1" variant="h2">
        {t("addNewCouponType.AddCouponTypeExternalSubtitle")}
      </Typography>
      <Grid item xs={12}>
        <BusinessIdSelector />
        <IsTranslate />
        <LanguageSelect />
        <CouponTitleWithTranslate />
        <CouponDescriptionWithTranslate />
        <CouponInstructionsWithTranslate />
        <ExternalLink />
        <CouponImage />
        <Coins />
        <CreditRequired />
        <ActivationTypeSelect />
        <SizeAndColor />
        <UnderTitleText />
        <UnderPurchaseButtonText />
        <WarningText />
        <CodeGenerationStrategyDropdown />
        <CodeGenerationInput />
        <SelectExpiresAtDate />
        <ActiveDurationTime />
        <IsOnlineCheckbox />
        <IsOneTimeCheckbox />
        <IsChallengeCoupon />
        <ChallengeCouponGenerationInputs />
        <SupplierCouponType />
        <PatchSelect />
      </Grid>
    </Grid>
  );
});
