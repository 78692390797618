import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useScreenHideCouponTypesStore } from "../store";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { t } from 'common/localization/translate';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    width:350,
    fontSize:12
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  menuItems:{
    width:550,
    fontSize:11
  }
}));

export const CouponTypesDropDown = observer(() => {
  const store = useScreenHideCouponTypesStore();
  const classes = useStyles();

  const handleChange = (event, item) => {
    store.chosenCouponTypeId = event.target.value;
    store.couponTypeTitle = item.props.name;
  };

  if (!store.couponTypesList) {
    return null;
  }

  const menuItems = store.couponTypesList.map(item => <MenuItem className={classes.menuItems} value={item.couponTypeId} name={item.couponTitle}> {item.couponTitle} </MenuItem>);
  return (
    <Grid item xs={12} spacing={3}>
      <FormControl className={classes.formControl} size="small" >
        <InputLabel className={classes.label} shrink>{t("organizationDashboard.hideCouponType.selectCouponType")} </InputLabel>
        <Select  value={store.chosenCouponTypeId} onChange={handleChange} className={classes.selectEmpty}>
          {menuItems}
        </Select>
      </FormControl>
    </Grid>
  );
})