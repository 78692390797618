import React from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { Grid, CardContent } from "@material-ui/core";
import { UserNameInput } from "./components/userNameInput";
import { EmailInput } from "./components/emailInput";
import { SaveButton } from "./components/saveButton";
import { PhoneInput } from "./components/phonInput";
import { EntitlementStatus } from "../entitlementStatus/entitlementStatus";

const useStyles = makeStyles((theme) => ({
  noPadding: {
    padding: 0,
    width: '100%',
  },
}));

export const UserDetailsInputs = observer((props) => {
  const classes = useStyles();

  return (
    <CardContent className={classes.noPadding}>
      <Grid container spacing={4}>
        <UserNameInput />
        <EmailInput />
        <PhoneInput/>
        <EntitlementStatus />
        <SaveButton />
      </Grid>
    </CardContent>
  );
});
