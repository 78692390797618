import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { t } from "common/localization/translate";
import { useScreenCouponsInInventoryStore } from "../store";

const useStyles = makeStyles(theme => ({
  searchButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 15,
    marginTop: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

export const ShowNowInInventoryButton = () => {
  const classes = useStyles();

  const { getScreenData } = useScreenCouponsInInventoryStore();

  const handlePressCurrentInventory = () => {
    getScreenData(true);
  };
  return (
    <Button
      className={classes.searchButton}
      onClick={handlePressCurrentInventory}
      size="large"
      variant="contained"
    >
      {t("screenCouponsInInventory.button.allInInventory")}
    </Button>
  );
};
