import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const SubtitleInput = observer(() => {
  const classes = useStyles();
  const store = useScreenCreateAddNewBusinessStore();

  const handleInputChange = (event) => {
    store.setSubtitle(event.target.value);
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      label={t("screenAddNewBusiness.Subtitle")}
      onChange={handleInputChange}
      className={classes.input}
    />
  );
});