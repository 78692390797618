import React from "react";
import { decorate, action, observable } from "mobx";
import { getAuctionList } from "../store/server/getAuctionList";
import { getBusinessIdsList } from "../../editBusiness/store/server/getBusinessIdsList"
import { getAuctionById } from "../store/server/getAuctionById";
import { getSegments } from "../../addNewBuisness/store/server/getSegments";
import { updateAuction } from "./server/editAuction";
import { convertDateToMoment } from "common/dateAndTime/moment";
import { setSelectedSegments } from "./logic/setSelectedSegments";
import { setSelectedSegmentsToDb } from "./logic/setSelectedSegmentsToDb";

const EditAuctionStoreContext = React.createContext({});

export const EditAuctionStoreProvider = EditAuctionStoreContext.Provider;

export const useEditAuctionStore = () =>
  React.useContext(EditAuctionStoreContext);

class EditAuctionStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }
  
  initProperties() {
    this.auctionName = null;
    this.isEmptyFieldErrorPopUpOpen = false;
    this.isSprintAuction = false;
    this.errorCode = false;
    this.getAuctionListToDisplay();
    this.getBusinessListToDisplay();
    this.getSegmentsList();
  }

  async getAuctionNameById(){
    const auctionNameInList = this.auctionList.find(element => element.auction_id == this.auctionId);
    this.auctionTitle = auctionNameInList.name;
  }
 
  async getAuctionListToDisplay(){
    this.auctionList = await getAuctionList(); 
  }

  async getAuctionDataById(){
   const auctionData = await getAuctionById(this.auctionId);
   this.auctionDescription = auctionData[0].description;
   this.businessName = auctionData[0].business_data.businessName;
   this.businessId = auctionData[0].business_data.businessId;
   this.startAuctionDate = auctionData[0].start_at;
   this.endAuctionDate = auctionData[0].end_at;
   this.bccEmail = auctionData[0].extra.performanceReportBcc;
   this.costToBusiness = auctionData[0].extra.costToBusiness;
   this.externalLinkUrl = auctionData[0].affilifate_link;
   this.externalLinkButtonText = auctionData[0].affiliate_button_text;
   this.stepsBid = auctionData[0].bid_step;
   this.startSprintDate = auctionData[0].coins_effective_from;
   this.isSprintAuction = auctionData[0].coins_effective_from ? true : false;
   this.selectedSegments = setSelectedSegments(auctionData[0].segments, this.segmentsList);    
   this.imageURL = auctionData[0].image_url;
  }

  async getBusinessListToDisplay(){
    this.businessList = await getBusinessIdsList();
  }
  
  async getSegmentsList() {
    this.segmentsList = await getSegments();
  };

  async submitEditAuction() {
    this.isFetching = true;
    
    const selectedSegmentsToDB = JSON.stringify(setSelectedSegmentsToDb(this.selectedSegments));

    const auctionEndDate = convertDateToMoment(this.endAuctionDate).toISOString();
    const auctionStartDate = convertDateToMoment(this.startAuctionDate).toISOString();
    const startSprintDateEdit = convertDateToMoment(this.startSprintDate).toISOString();

    const res = await updateAuction({business: { id: this.businessId, name: this.businessName }, auction: {name: this.auctionTitle, id: this.auctionId }, title: this.auctionTitle, startAt: auctionStartDate, endAt: auctionEndDate, costToBusiness: this.costToBusiness, externalLink: this.externalLinkUrl, externalLinkButtonText: this.externalLinkButtonText, performanceReportBcc: this.bccEmail, bidStep: this.stepsBid, description: this.auctionDescription, coinsEffectiveFrom: startSprintDateEdit, segments: selectedSegmentsToDB, auctionImage: this.auctionImage, imageURL: this.imageURL})
    
    this.errorCode = res.errorCode;
    this.auctionEditingStatus = res.isSuccess;
    this.isFetching = false;
  }
}

decorate(EditAuctionStore, {
  isFetching: observable,
  businessId: observable,
  businessName: observable,
  auctionData: observable,
  auctionIsEmptyError: observable,
  costToBusinessEmptyError: observable,
  auctionDescriptionEmptyError: observable,
  startSprintDateEmptyError: observable,
  selectedSegmentsEmptyError: observable,
  startAuctionDateEmptyError: observable,
  endAuctionDateEmptyError: observable,
  stepsBidEmptyError: observable,
  auctionList: observable,
  auctionName: observable,
  auctionId: observable,
  businessList: observable,
  auctionTitleEmptyError: observable,
  auctionTitle: observable,
  auctionDescription: observable,
  startAuctionDate: observable,
  bccEmail: observable,
  endAuctionDate: observable,
  costToBusiness: observable,
  externalLinkUrl: observable,
  externalLinkButtonText: observable,
  stepsBid: observable,
  isSprintAuction: observable,
  startSprintDate: observable,
  selectedSegments: observable,
  segmentsList: observable,
  imageURL: observable,
  auctionImage: observable,
  isEmptyFieldErrorPopUpOpen: observable,
  auctionEditingStatus: observable,
  errorCode: observable,
  initProperties: action.bound,
});

export function createEditAuctionStore(rootStore) {
  const store = new EditAuctionStore(rootStore);
  return store;
}