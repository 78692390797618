import HomeIcon from "@material-ui/icons/Home";
import { t } from "common/localization/translate";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import StorefrontIcon from "@material-ui/icons/Storefront";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import GavelIcon from '@material-ui/icons/Gavel';

export default [
  {
    title: "תפריט",
    pages: [
      {
        title: t("sideBar.home.title"),
        href: `/${dashboardPaths.CONTROL_PANEL}/home`,
        icon: HomeIcon,
      },
      {
        title: t("sideBar.businessDashboardUser.title"),
        href: `/${dashboardPaths.CONTROL_PANEL}/businessDashboardUser`,
        icon: BusinessCenterIcon,
      },
      {
        title: t("screenAddNewBusiness.AddBusinessFormTitle"),
        href: `/${dashboardPaths.CONTROL_PANEL}/addNewBusiness`,
        icon: StorefrontIcon,
      },
      {
        title: t("editBusiness.editBusinessFormTitle"),
        href: `/${dashboardPaths.CONTROL_PANEL}/editBusiness`,
        icon: EditIcon,
      },
      {
        title: t("addNewCouponType.AddCouponTypeFormTitle"),
        href: `/${dashboardPaths.CONTROL_PANEL}/addCouponType`,
        icon: ConfirmationNumberIcon,
      },
      {
        title: t("editCouponType.editCouponTitle"),
        href: `/${dashboardPaths.CONTROL_PANEL}/editCouponType`,
        icon: EditIcon,
      },
      {
        title: t("editArticle.editArticleTitle"),
        href: `/${dashboardPaths.CONTROL_PANEL}/editArticle`,
        icon: DescriptionIcon,
      },
      {
        title: t("editBalance.title"),
        href: `/${dashboardPaths.CONTROL_PANEL}/editBalance`,
        icon: AccountBalanceWalletIcon,
      },
      {
        title: t("deleteUser.title"),
        href: `/${dashboardPaths.CONTROL_PANEL}/deleteUser`,
        icon: DeleteForeverIcon,
      },
      {
        title: t("updateUserSegment.title"),
        href: `/${dashboardPaths.CONTROL_PANEL}/updateUserSegment`,
        icon: PhonelinkSetupIcon,
      },
      // {
      //   title: t("businessReport.title"),
      //   href: `/${dashboardPaths.CONTROL_PANEL}/techOpsReports`,
      //   icon: CloudDownloadIcon,
      // },
      {
        title: t("addNewAuction.addNewAuctionFormTitle"),
        href: `/${dashboardPaths.CONTROL_PANEL}/addNewAuction`,
        icon: GavelIcon,
      },
      {
        title: t("editAuction.editAuctionFormTitle"),
        href: `/${dashboardPaths.CONTROL_PANEL}/editAuction`,
        icon: EditIcon,
      },
    ],
  },
];
