import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const CustomerServiceEmail = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();

  const handleInputChange = (event) => {
    store.customerServiceEmail = event.target.value;
  };

  return (
    <TextField
      value={store.customerServiceEmail ? store.customerServiceEmail : ""}
      variant="outlined"
      fullWidth
      label={t("screenAddNewBusiness.CustomerServiceEmail")}
      onChange={handleInputChange}
      className={classes.input}
    />
  );
});