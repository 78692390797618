import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useAddNewAuctionStore } from "screens/controlPanel/addNewAuction/store";
import { handleBlockScroll} from "common/ui/handleBlockScroll";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const StepsBid = observer(() => {
  const classes = useStyles();
  const store = useAddNewAuctionStore();

  const handleInputChange = (event) => {
    if (!event.target.value) {
      store.stepsBidEmptyError = "Steps Bid";
      store.stepsBid = null;
    }
    store.stepsBidEmptyError = null;
    store.stepsBid = event.target.value;
  };

  return (
    <TextField
      required
      variant="outlined"
      fullWidth
      label={"Steps Bid"}
      type="number"
      onChange={handleInputChange}
      onWheel={handleBlockScroll}
      className={classes.input}
      value={store.stepsBid? store.stepsBid : ""}
    />
  );
});