import { getStartEndOfMonth } from "common/dateAndTime";

export function updateStartEndDate(component) {
    const { onChangeRange } = component.props;
    const { start, end } = getStartEndOfMonth(component.selectedKeyYear, component.selectedKeyMonth);
    component.startAt = start;
    component.endAt = end;
    onChangeRange(start, end);
}

