import { couponsErrorCodes } from "../../store/logic/couponsErrorCodes"
import { t } from "common/localization/translate";

export function getPopUpText({ couponName, errorCode }) {

    if (errorCode == couponsErrorCodes.ERROR_TYPE_RATE_LIMITER_IS_EXCEEDED) {
        return t("screenCouponsUser.Modal.limitReached");
    }
    if (errorCode == couponsErrorCodes.ERROR_TYPE_CANCELINING_COUPON_WITH_DELIVERY_TYPE_IS_ILLEGAL) {
        return t("screenCouponsUser.popUp.deliveryCouponCancelation")
    }

    if (errorCode == couponsErrorCodes.ERROR_TYPE_COUPON_ALREADY_HIDDEN) {
        return t("screenCouponsUser.popUp.alreadyHidden", { couponName })
    }

    return;

}

