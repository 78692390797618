import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
    input: {
        marginBottom: 10,
    },
}));

export const ExternalProductIdBySupplierInput = observer(() => {
    const classes = useStyles();
    const store = useEditCouponTypeStore();

    if (!store.isCreditRequired) {
        return null;
    }

    const handleInputChange = ({ target }) => {
        store.previousAttributes = { ...store.previousAttributes, externalProductIdBySupplier: target.value };
        store.externalProductIdBySupplier = target.value;
    };

    return (
        <TextField
            variant="outlined"
            fullWidth
            label={t("addNewCouponType.externalProductIdBySupplier")}
            onChange={handleInputChange}
            value={store.externalProductIdBySupplier || ''}
            className={classes.input}
        />
    );
});
