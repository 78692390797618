import React from "react";
import { Container, CssBaseline, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { t } from "common/localization/translate";
import { AuctionSelectEdit } from "./auctionSelectEdit.js";
import { BusinessSelectEdit } from "./businessSelectEdit.js";
import { AuctionTitleEdit } from "./auctionTitleEdit";
import { AuctionDescriptionEdit } from "./auctionDescriptionEdit.js"
import { StartAuctionDateEdit } from "./startAuctionDateEdit";
import { EndAuctionDateEdit } from "./endAuctionDateEdit";
import { BccEmailEdit } from "./bccEmailEdit";
import { CostToBusinessEdit } from "./costToBusinessEdit";
import { ExternalLinkUrlEdit } from "./externalLinkEdit";
import { ExternalLinkButtonText } from "./externalLinkButtonTextEdit";
import { StepsBidEdit } from "./stepsBidEdit";
import { IsSprintCheckboxEdit } from "./sprint/isSprintCheckboxEdit";
import { StartSprintDateEdit } from "./sprint/sprintStartDateEdit";
import { SegmentSelectEdit } from "./segmentSelectEdit";
import { AuctionImagePreviousUrl } from "./previousAuctionImage";
import { AuctionImageEdit } from "./auctionImageEdit";
import { SubmitEditAuctionButton } from "./submitEditAuctionButton";
import { AuctionEditingSuccessPopUp } from "../popUps/editAuctionSuccessPopUp";
import { EmptyFieldErrorPopUp } from "../popUps/emptyFieldsErrorPopUpEdit";
import { AuctionEditingFailedPopUp } from "../popUps/editAuctionFailPopUp";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 30,
    flex: "auto",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", 
    marginTop: theme.spacing(3),
    marginBottom: 10,
  },

  gridWidth: {
    width: "50%",
  },
}));

export const Form = observer(() => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Grid className={classes.paper}>
        <Typography className={classes.title} component="h1" variant="h1">
          {t("editAuction.editAuctionFormTitle")}
        </Typography>
        <form className={classes.form} noValidate>
        <Grid className={classes.gridWidth} container spacing={2}>
         <AuctionSelectEdit />
         <BusinessSelectEdit />
         <AuctionTitleEdit />
         <AuctionDescriptionEdit />
         <StartAuctionDateEdit />
         <EndAuctionDateEdit />
         <Grid className={classes.gridWidth} spacing={2}>
           <AuctionImageEdit />
           <AuctionImagePreviousUrl />
         </Grid>
         <BccEmailEdit />
         <CostToBusinessEdit />
         <ExternalLinkUrlEdit />
         <ExternalLinkButtonText />
         <StepsBidEdit />
         <IsSprintCheckboxEdit />
         <StartSprintDateEdit />
         <SegmentSelectEdit />
         <SubmitEditAuctionButton />
         <EmptyFieldErrorPopUp />
         <AuctionEditingSuccessPopUp />
         <AuctionEditingFailedPopUp />
         </Grid>
        </form>
      </Grid>
    </Container>
  );
});