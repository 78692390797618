import { decorate, action, flow, observable } from 'mobx';
import { getSummeryLoadsPerOrganization as doGetSummeryLoadsPerOrganization } from './server/getSummeryLoadsPerOrganization';

export class StoreSummaryOfLoadingsPerOrganization {

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();    
  }

  initProperties(){
    this.startAt = this.rootStore.movement.storeMain.startAt;
    this.endAt = this.rootStore.movement.storeMain.endAt;
    this.loadsPerOrganization = [];
    this.totalChargeFromOrganizations = 0; // Default value
    this.countOfLoadings = 0; // Default value
  }

  getSummeryLoadsPerOrganization = flow(function* () {
    const data = yield doGetSummeryLoadsPerOrganization(this.startAt, this.endAt);
    this.loadsPerOrganization = data.loadsPerOrganization;
    this.totalChargeFromOrganizations = data.totalChargeFromOrganizations;
    this.countOfLoadings = data.countOfLoadings;
  });

  setStartAt(date) {
    this.startAt = date;
  }

  setEndAt(date) {
    this.endAt = date;
  };

  onChangeRange(startDate, endDate) {
    this.startAt = startDate;
    this.endAt = endDate;
    this.getSummeryLoadsPerOrganization();
  }
}

decorate(StoreSummaryOfLoadingsPerOrganization, {
  loadsPerOrganization: observable,
  totalBillForOrganization: observable,
  countOfLoadings: observable,
  getDataPurchases: action.bound,
  startAt: observable,
  endAt: observable,
  setStartAt: action.bound,
  setEndAt: action.bound,
  onChangeRange: action.bound,
  initProperties: action.bound
});