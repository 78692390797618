import React from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import SimpleCube from "common/ui/simpleCube";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../../store";
import { formatNumber } from "common/formatNumber";
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles(() => ({
    row: {
        width: "100%"
    }
}));

export const FacebookCube = observer(() => {
    const { isFetching, clicksOnFacebookBusiness} = useScreenHomeStore();

    const clicksOnFacebookBusinessFormatted = formatNumber(
        clicksOnFacebookBusiness
    );


    const classes = useStyles();

    return (
        <Grid item sm={12} lg={3} className={classes.row}>
            <div>
                <SimpleCube
                    isWithoutMargin={true}
                    isFetching={isFetching}
                    label={t("business.home.cubes.facebook")}
                    isWithConstatHeight={true}
                    icon={<FacebookIcon />}
                    value={clicksOnFacebookBusinessFormatted}
                    withoutMargin={true}
                />
            </div>
        </Grid>
    );
});
