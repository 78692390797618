import { t } from "common/localization/translate";

export function filterScreensBySegmentFeaturesAndPermissions({ navigationConfig, isShowTrainesInfoScreen, isShowSubSegmentUsersScreen, isShowMultiSegmentUsersScreen, isShowAppOpenByHours, isShowOrderDetailsScreen, isShowStepsAndGoalsScreen,isShowHideCouponTypesScreen,isShowSendNotificationScreen, isAllowedHideCouponTypes, isAllowedToSendNotification, isShowSwitchBusinessScreen, isProjectManager, isShowHealthAndFitnessData, isHideCoachesInfoScreen, isSupplier  }){
  const filterdMenu = { pages: navigationConfig[0].pages };
    if(!isShowStepsAndGoalsScreen){
      filterdMenu.pages = filterdMenu.pages.filter(item => item.title !== t("sideBar.support.dailyStepsAndGoals"));
    }
    if(!isShowOrderDetailsScreen){
      filterdMenu.pages = filterdMenu.pages.filter(item => item.title !== t("screenOrderDetails.title"));
    }
    if (!isShowTrainesInfoScreen) {
      filterdMenu.pages = filterdMenu.pages.filter(item => item.title !== t('sideBar.staff.title') &&  item.title !== t('sideBar.traines.title'));
    }
    if (isHideCoachesInfoScreen) {
      filterdMenu.pages = filterdMenu.pages.filter(item => item.title !== t('sideBar.staff.title'));
    }
    if (!isShowHealthAndFitnessData) {
      filterdMenu.pages = filterdMenu.pages.filter(item => item.title !== t('sideBar.healthAndFitnessData.title'));
    }
    if (!isShowSubSegmentUsersScreen) {
      filterdMenu.pages = filterdMenu.pages.filter(item => item.title !== t('sideBar.userConfirmaions.title') && item.title !== t('sideBar.subSegmentUsers.title'));
    }
    if (!isShowMultiSegmentUsersScreen) {
      filterdMenu.pages = filterdMenu.pages.filter(item => item.title !== t('sideBar.multiSegmentUsers.title'));
    }
    if (!isShowAppOpenByHours) {
      filterdMenu.pages = filterdMenu.pages.filter(item => item.title !== t('sideBar.appOpenByHours.title'));
    }
    
    if (!isShowHideCouponTypesScreen || !isAllowedHideCouponTypes) {
      filterdMenu.pages = filterdMenu.pages.filter(item => item.title !== t('sideBar.appOpenByHours.hideCoupon'));
    }

    if (!isAllowedToSendNotification || !isShowSendNotificationScreen) { 
      filterdMenu.pages = filterdMenu.pages.filter(item => item.title !== 'שליחת נוטיפיקציה');
    }

    if (!isShowSwitchBusinessScreen) {
      filterdMenu.pages = filterdMenu.pages.filter(item => item.title !== t('business.sideBar.switchBusiness.title'));
    }
    if (isProjectManager == 24) {
      filterdMenu.pages = filterdMenu.pages.filter(item => item.title == t("suppliers.sideBar.validations") || item.title == t("suppliers.billingReportSystem.projectManagerSupplier.title") || item.title == t("suppliers.supplierAndCouponReports.title") || item.title == t('suppliers.projectManagerHome.title') || item.title == t("sideBar.home.title"));
    }

    if (isProjectManager == 17) {
      filterdMenu.pages = filterdMenu.pages.filter(item => item.title == t("suppliers.sideBar.validations") || item.title == t("suppliers.billingReportSystem.projectManagerSupplier.title") || item.title == t("suppliers.supplierAndCouponReports.title") || item.title == t("sideBar.home.title"));
    }

    if (isSupplier) {
      filterdMenu.pages = filterdMenu.pages.filter(item => item.title == t("sideBar.home.title"));
    }

    
    const { pages } = filterdMenu.pages ? filterdMenu : navigationConfig[0];
    return pages;
}