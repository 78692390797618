import { writeToRow } from "./writeToRow";

export function createSheetNewUserRate(sheet, storeHome) {

    const { countNewUsersInSegment } = storeHome;

    const col1 = 'תאריך';
    const col2 = 'כמות';
    writeToRow(sheet, [col1, col2], 1);
    let rowNumber = 2;
    countNewUsersInSegment.forEach(({ date, count }) => {
        writeToRow(sheet, [date, count], rowNumber);
        rowNumber++;
    });
}