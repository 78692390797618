import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { useScreenCouponsStore } from "../../store/index";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.white,
      fontSize: 14,
    },
  },
  dialogDiv: {
    width: 600,
    height: 250,
  },
  buttons: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
    color: theme.palette.white,
    marginLeft: 21,
    marginTop: 18,
    "&:hover": {
      backgroundColor: theme.palette.primary.secondGraphColor,
      color: theme.palette.white,
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
  }
}));

export const SuccessCancelCouponPopUp = observer(() => {
  const store = useScreenCouponsStore();
  const { isSuccessCancelCoupon, setIsSuccessCancelCoupon } = store;
  const classes = useStyles();
  const handleClose = () => {
    setIsSuccessCancelCoupon(false);
  };

  return (
    <div>
      <Dialog
        className={classes.dialog}
        open={isSuccessCancelCoupon}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={classes.dialogDiv}>
          <DialogTitle className={classes.root} id="responsive-dialog-title">
            {t("screenCouponsUser.cancelCoupon")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("screenCouponsUser.popUp.cancelCouponSuccess")}
              <br />
              {t("screenCouponsUser.popUp.cancelCouponSuccessMessege")}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttonsContainer}>
            <Button
              className={classes.buttons}
              size="small"
              type="submit"
              variant="contained"
              onClick={handleClose}
            >
              {t("screenCouponsUser.popUp.closeButton")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
});
