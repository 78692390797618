import React from "react";
import {
  InputLabel,
  Select,
  makeStyles,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from 'screens/controlPanel/addCouponType/store';
import { t } from "common/localization/translate";
import { isSupplierSegment } from "screens/controlPanel/addCouponType/store/logic/isSupplierSegment";

const useStyles = makeStyles((theme) => ({
  category: {
    minWidth: 300,
    border: 1,
    borderColor: "black",
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
}));

export const OutOfStockRuleSelect = observer(() => {
  const store = useAddNewCouponTypeStore();
  const ruleOptions = store.outOfStockRuleIds.map((rule) => {return <MenuItem key={rule.rule_id} value={rule}>{rule.title}</MenuItem>});
  const classes = useStyles();

  const handleChange = (event) => {
    store.selectedOutOfStockRule = event.target.value;
  };

  if(!isSupplierSegment(store.segments) ) {
    return null;
  }

  return (
    <Grid className={classes.selectEmpty} item xs={9}>
      <InputLabel >
        {t("addNewCouponType.selectOutOfStockRule")}
      </InputLabel>
      <Select
        onChange={handleChange}
        className={classes.category}
        value={store.selectedOutOfStockRule || ""}
      >
        {ruleOptions}
      </Select>
    </Grid>
  );
});
