import React from "react";
import { decorate, action, observable, computed } from "mobx";
import { getCouponsDataServer } from "./server/getCouponsDataServer";
import { deleteCouponServer } from "./server/deleteCouponServer";
import { cancelHiddingCouponServer } from "./server/cancelHiddingCouponServer";
import { getCouponsTableData } from "./logic/getCouponsTableData";
import { validatePhoneNumber } from '../../editUserInfo/store/logic/validatePhoneNumber';
import { errorCodes } from '../../services/errorCodes';
import { validateIsraeliIdStructure } from "common/validateIsraeliIdStructure/validateIsraeliIdStructure";

const ScreenCouponsStoreContext = React.createContext({});
export const ScreenCouponsUsersDataProvider =
  ScreenCouponsStoreContext.Provider;
export const useScreenCouponsStore = () =>
  React.useContext(ScreenCouponsStoreContext);

class ScreenCouponsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
  }

  initProperties() {
    this.currentRow = null;
    this.isNowRowSelected = false;
  }




  setUserIdentifier(userIdentifier) {
    this.userIdentifier = userIdentifier;
  }

  setUserCoupons(value) {
    this.userCoupons = value;
  }

  setUserName(userName) {
    this.userName = userName;
  }

  setErrorCodes(errorCode) {
    this.errorCode = errorCode;
  }

  deleteCouponPopUp(isDeleteCouponPopUp) {
    this.isDeleteCouponPopUp = isDeleteCouponPopUp;
  }

  exposeCouponPopUp(isExposeCouponPopUp) {
    this.isExposeCouponPopUp = isExposeCouponPopUp;
    this.cancelHiddingCoupon();
  }

  async cancelHiddingCoupon() {
    this.setStatusMessegeHiddingCoupon(null);
    const { couponId } = this.userCoupons[this.currentRow];
    const segmentId = this.rootStore.userInfoStore.currentSegment
      ? this.rootStore.userInfoStore.currentSegment.segmentId
      : null;
    const response = await cancelHiddingCouponServer(
      this.userIdentifier,
      segmentId,
      couponId
    );
    this.setStatusMessegeHiddingCoupon(response);
  }

  setCurrentRow(currentRow) {
    const isDifferentRow = this.currentRow != currentRow;
    this.setIsNowRowSelected(isDifferentRow);
    this.currentRow = isDifferentRow ? currentRow : null;
  }

  setStatusMessegeHiddingCoupon(statusMessegeHiddingCoupon) {
    this.statusMessegeHiddingCoupon = statusMessegeHiddingCoupon;
  }

  setIsSuccessCancelCoupon(isSuccess) {
    this.isSuccessCancelCoupon = isSuccess;
  }

  setDeleteCouponErrorCode(errorCode) {
    this.deleteCouponErrorCode = errorCode;
  }

  async deleteCoupon() {
    this.setIsSuccessCancelCoupon(false);
    this.setDeleteCouponErrorCode(null);
    const { couponId } = this.userCoupons[this.currentRow];
    const segmentId = this.rootStore.userInfoStore.currentSegment
      ? this.rootStore.userInfoStore.currentSegment.segmentId
      : null;
    const response = await deleteCouponServer(
      this.userIdentifier,
      segmentId,
      couponId
    );
    this.setIsSuccessCancelCoupon(response.isSuccess);
    this.setDeleteCouponErrorCode(response.errorCode);
    this.deleteCouponPopUp(false);
  }

  setCouponName() {
    const { couponName } = this.userCoupons[this.currentRow];
    this.couponName = couponName;
  }

  getScreenData = async () => {
    try{
    this.setUserCoupons(null);
    this.setIsFetching(true);
    const segmentId = this.rootStore.userInfoStore.currentSegment
      ? this.rootStore.userInfoStore.currentSegment.segmentId
      : null;
    const response = await getCouponsDataServer(this.userIdentifier, segmentId);
    const userName = response.userName;
    this.setUserName(userName);
    this.setErrorCodes(response.errorCode);
    this.setUserCoupons(response.userCouponsWithDateFormat);
    this.setIsFetching(false);
  } catch(e){
    this.setErrorCodes(errorCodes.ERROR_TYPE_USER_NOT_EXISTS);
    this.setIsFetching(false);
  }
  };

  submitIdentifier() {
    this.initProperties()
    this.setIsNowRowSelected(false);
    this.setUserName(null);
    this.setUserName(null);
    const isValidId = validateIsraeliIdStructure(this.userIdentifier);
    const isValidPhone = validatePhoneNumber(this.userIdentifier, true);
    if(this.rootStore.userInfoStore.isWithSearchByPhoneOption){
      if(isValidId || isValidPhone){ 
        this.getScreenData();
        return;
      }
      this.setErrorCodes(errorCodes.ERROR_TYPE_INVALID_DATA);
      return;
    }
    if(!isValidId){
      this.setErrorCodes(errorCodes.ERROR_TYPE_INVALID_DATA);
      return;
    }
    this.getScreenData();
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }

  setIsNowRowSelected(isSelected) {
    this.isNowRowSelected = isSelected;
  }

  get couponsUserTableData() {
    return getCouponsTableData(this);
  }
}

decorate(ScreenCouponsStore, {
  successCancelCouponPopUp: observable,
  isUnSuccessCancelCouponPopUp: observable,
  isFetching: observable,
  deleteCouponErrorCode: observable,
  userIdentifier: observable,
  userName: observable,
  userCoupons: observable,
  statusMessegeHiddingCoupon: observable,
  isDeleteCouponPopUp: observable,
  errorCode: observable,
  couponName: observable,
  currentRow: observable,
  isSuccessCancelCoupon: observable,
  isExposeCouponPopUp: observable,
  isNowRowSelected: observable,
  couponsUserTableData: computed,
  setUserName: action.bound,
  setIsSuccessCancelCoupon: action.bound,
  setDeleteCouponErrorCode: action.bound,
  setSuccessCancelCouponPopUp: action.bound,
  setUnSuccessCancelCouponPopUp: action.bound,
  setCouponName: action.bound,
  setCurrentRow: action.bound,
  exposeCouponPopUp: action.bound,
  setStatusMessegeHiddingCoupon: action.bound,
  deleteCouponPopUp: action.bound,
  deleteCoupon: action.bound,
  setUserCoupons: action.bound,
  setUserIdentifier: action.bound,
  setErrorCodes: action.bound,
  setIsFetching: action.bound,
  getScreenData: action.bound,
  setIsNowRowSelected: action.bound,
  initProperties: action.bound,

});

export function createScreenCouponsUsersStore(rootStore) {
  const store = new ScreenCouponsStore(rootStore);
  return store;
}
