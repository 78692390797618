import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

export const IsEmailAtLikeCheckbox = observer(() => {
    const store = useEditBusinessStore();
  
    const handleChange = (event) => {
      store.isEmailAtLike = event.target.checked;
    };
  
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={store.isEmailAtLike ? true : false}
              onChange={handleChange}
              name="isEmailAtLike"
            />
          }
          label={t("screenAddNewBusiness.isEmailAtLike")}
        />
      </div>
    );
  });