import { t } from "common/localization/translate";

export const notificationNavigationsList = [
    { text: t("sendNotToUserSceern.navigationList.homeScreen"), value: 'HOME' },
    { text: t("sendNotToUserSceern.navigationList.articlesScreen"), value: 'ARTICLES' },
    { text: t("sendNotToUserSceern.navigationList.externalLink"), value: 'OPEN_URL' },
    { text: t("sendNotToUserSceern.navigationList.activitiesScreen"), value: 'ACTIVITIES' },
    { text: t("sendNotToUserSceern.navigationList.contentWorld"), value: 'CONTENT_OPTIONS' }
];

