import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { ActionSelect } from "./actionSelect";
import { EditQuestionsComponents } from "./editQuestion/editQuestions";
import { TranslateQuestionsComponents } from "./translateQuestions/translateQuestions";
import { TranslateAnswerComponents } from "./translateAnswers/translateAnswer";
import { AddQuestionsComponents } from "./addQuestion/addQuestion";
import { AddAnswersComponents } from "./addAnswers/addAnswers";
import { EditAnswersComponents } from "./editAnswer/editAnswer";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
    title: {
      marginBottom: 30,
      flex: "auto",
    },
  }));

export const QuizComponents = observer(() => {

const classes = useStyles();
      
  return (
    <Grid>
        <Typography className={classes.title} component="h1" variant="h1">
            {t("editArticle.quizTitle")}
        </Typography>
      <ActionSelect />
      <EditQuestionsComponents />
      <TranslateQuestionsComponents />
      <TranslateAnswerComponents />
      <AddQuestionsComponents />
      <AddAnswersComponents />
      <EditAnswersComponents />
    </Grid>
  );
});