import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const CustomerServicePhone = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();

  const handleInputChange = (event) => {
    store.businessPhone = event.target.value;
  };

  return (
    <TextField
      variant="outlined"
      value={store.businessPhone ? store.businessPhone : ""}
      fullWidth
      type="tel"
      label={t("screenAddNewBusiness.CustomerServicePhone")}
      onChange={handleInputChange}
      className={classes.input}
    />
  );
});