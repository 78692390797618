import React from "react";
import { TableRow, TableCell } from "@material-ui/core";

export const UserNameConfirmations = (props) => {
  const { confirmationsData, columns } = props;

  return (
    <TableRow>
      <TableCell>{columns.userName}</TableCell>
      <TableCell>{confirmationsData.name}</TableCell>
    </TableRow>
  );
};
