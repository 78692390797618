import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import { TableHeader } from './components/tableHeader';
import { TableToolBar } from './components/tableToolBar';
import { TablePagination } from './components/TablePagination';
import { styles } from './styles';
import { getTableRow } from './logic/getTableRow';

class CommonTableComponent extends React.Component {

  constructor(props) {
    super(props);
    const { defaultSortByColumn } = props;
    this.state = {
      order: 'desc',
      orderBy: defaultSortByColumn,
      page: 0,
      rowsPerPage: 10,
    };
  }

  getRow = () => {

    const { items, itemKey, renderRow } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const rows = getTableRow(items, order, orderBy, rowsPerPage, page, itemKey, renderRow);
    return rows;
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {

    const { items, classes, headerColumns, tableTitle } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const length = items.length;

    return (
      <Paper>
        <TableToolBar tableTitle={tableTitle} />
        <Table className={classes.table}>
          <TableHeader
            headerColumns={headerColumns}
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
          />
          <TableBody>
            {this.getRow()}
          </TableBody>
        </Table>
        <TablePagination
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage} />
      </Paper>
    );
  }
}

export const CommonTable = withStyles(styles)(CommonTableComponent);