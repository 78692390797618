import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";

export const IsActive = observer(() => {
  const store = useEditCouponTypeStore();

  const handleChange = (event) => {
    store.isActive = event.target.checked;
  };

  return (
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            checked={store.isActive ? true : false}
          />
        }
        label={t("addNewCouponType.IsActive")}
      />
  );
});
