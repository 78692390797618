import React from "react";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../..";
import { t } from "common/localization/translate";
import { TextField, makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
    marginTop: 10,
    maxWidth: 450,
  },
}));

export const LeadContactEmail = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();
  const { leadContactEmail } = store;

  const handleInputChange = (event) => {
    store.leadContactEmail = event.target.value;
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <TextField
        variant="outlined"
        value={leadContactEmail}
        fullWidth
        label={t("screenAddNewBusiness.ContactEmail")}
        onChange={handleInputChange}
      />
    </Grid>
  );
});
