import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const BusinessContactEmail = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();

  const handleInputChange = (event) => {
    if(!event.target.value) {
      store.contactEmailEmptyError = t("screenAddNewBusiness.ContactEmail");
      store.contactEmail = event.target.value;
      return;
    }
    store.contactEmailEmptyError = null;
    store.contactEmail = event.target.value;
  };

  return (
    <TextField
      required
      value={store.contactEmail ? store.contactEmail : ""}
      variant="outlined"
      fullWidth
      label={t("screenAddNewBusiness.ContactEmail")}
      onChange={handleInputChange}
      className={classes.input}
    />
  );
});