import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from "react-router";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { screenTypes } from 'commonStores/userInfoStore/screenTypes'
import { Icon } from 'componentsRumble/images/images';
import { sendAnalyticsEvent } from 'common/analyticsEvent';
import { analyticsEvents } from 'common/analyticsEvent/events';

class SideMenuMovComponent extends Component {

    onClickMain = () => {
        this.props.history.push(screenTypes.SCREEN_MOV_MAIN);
        sendAnalyticsEvent(analyticsEvents.EVENT_MOV_SIDEBAR_PRESSED_MAIN);
    }

    onClickSummaryOfLoadingsPerOrganization = () => {
        this.props.history.push(screenTypes.SCREEN_MOV_SUM_OF_LOADINGS_PER_ORG);
        sendAnalyticsEvent(analyticsEvents.EVENT_MOV_SIDEBAR_PRESSED_SUMMARY_OF_LOADINGS_PER_ORGANIZATION);
    }

    onClickSummaryOfPurchasesByBusiness = () => {
        this.props.history.push(screenTypes.SCREEN_MOV_SUM_OF_PURCHASES_BY_BIZ);
        sendAnalyticsEvent(analyticsEvents.EVENT_MOV_SIDEBAR_PRESSED_SUMMERY_OF_PURCHASES_BY_BUSINESS);
    }

    render() {
        const { location } = this.props;
        const { pathname } = location;
        return <List>
            <ListItem selected={pathname === screenTypes.SCREEN_MOV_MAIN} button key='main' onClick={this.onClickMain}>
                <ListItemIcon><Icon src="https://ucarecdn.com/3991f5c7-9f4a-443e-9d1a-ccde4bdd2291/main.png" /></ListItemIcon>
                <ListItemText primary='ראשי' />
            </ListItem>
            <ListItem selected={pathname === screenTypes.SCREEN_MOV_SUM_OF_LOADINGS_PER_ORG} button key='SummaryOfLoadingsPerOrganization' onClick={this.onClickSummaryOfLoadingsPerOrganization}>
                <ListItemIcon><Icon src="https://ucarecdn.com/ba1f670e-ee7a-44c3-bb9d-2ac0f2f731be/summerygym.png" /></ListItemIcon>
                <ListItemText primary='סיכום טעינות לפי אירגונים' />
            </ListItem>
            <ListItem selected={pathname === screenTypes.SCREEN_MOV_SUM_OF_PURCHASES_BY_BIZ} button key='SummaryOfPurchasesByBusiness' onClick={this.onClickSummaryOfPurchasesByBusiness}>
                <ListItemIcon><Icon src="https://ucarecdn.com/6450ea9d-7390-4187-bd52-9e21be07cba4/summeryBusiness.png" /></ListItemIcon>
                <ListItemText primary='סיכום כניסות לפי עסק' />
            </ListItem>
        </List>
    }
}
export const SideMenuMov = withRouter(inject("rootStore")(observer(SideMenuMovComponent)));
