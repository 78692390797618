import React, { useState } from "react";
import { Form } from "./store/components/form/index";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { observer } from "mobx-react";
import {createEditArticleStore, EditArticleStoreProvider} from "./store/index";

export const EditArticle = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createEditArticleStore(rootStore));

    return (
      <EditArticleStoreProvider value={store}>
        <Form store={store} />
      </EditArticleStoreProvider>
    );
});