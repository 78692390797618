import React from "react";
import { makeStyles, Grid } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useEditAuctionStore } from "screens/controlPanel/editAuction/store";
import { t } from "common/localization/translate";
import { createEncodedLogo } from "../../../addNewBuisness/store/components/form/components/GeneralInfo/createEncodedLogo";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
  },
  div: {
      marginTop: 10,
      marginBottom: 10,
  }
}));

export const AuctionImageEdit = observer(() =>{
  const classes = useStyles();
  const store = useEditAuctionStore();

  const handleInputChange = async (event) => {
    if (event.target.files[0]) {
      store.auctionImage = await createEncodedLogo(event.target.files[0]);
      store.imageURL = null;
    }
}

  return (
    <Grid className={classes.div}>
      <label className={classes.label}>
        {"* " + t("AddNewAuction.AddNewAuctionImage")}
      </label>
      <br />
      <input type="file" onChange={handleInputChange} />
    </Grid>
  );
});