import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { CouponTitle } from "../CouponTitle";
import { TranslateTitle } from "./translateTitle";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    width: "100%"
  },
}));

export const CouponTitleWithTranslate = observer(() => {
  const classes = useStyles();

  return (
    <Grid className={classes.paper}>
      <CouponTitle />
      <TranslateTitle />
    </Grid>
  );
});