import React from "react";
import { decorate, action, observable } from "mobx";
import { getAllBusinessesData } from "./server/getAllBusinessesData";
import { createUser } from "./server/createUser";
import { t } from "common/localization/translate";
import { createBusinessDashboardUserErrorCodes } from "./logic/createBusinessDashboardUserErrorCodes";
import { validateEmail } from "./logic/validateEmail";

const ScreenCreateBusinessDashboardUserStoreContext = React.createContext({});
export const ScreenCreateBusinessDashboardUserStoreProvider = ScreenCreateBusinessDashboardUserStoreContext.Provider;
export const useScreenCreateBusinessDashboardUserStore = () =>
  React.useContext(ScreenCreateBusinessDashboardUserStoreContext);

class ScreenCreateBusinessDashboardUserStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  setErrorCode(errorCode) {
    this.setSuccessText(null);
    this.errorCode = errorCode;
  }

  setSuccessText(text) {
    this.successText = text;
  }

  setBusinessEmail(businessEmail) {
    this.businessEmail = businessEmail;
  }

  setBusinessContactName(businessContactName) {
    this.businessContactName = businessContactName;
  }

  validaCreateUser(){

    if(!this.businessId){
      this.setErrorCode(createBusinessDashboardUserErrorCodes.ERROR_TYPE_EMPTY_BUSINESS_ID);
    }

    if(!this.businessContactName){
      this.setErrorCode(createBusinessDashboardUserErrorCodes.ERROR_TYPE_INVALID_CONTACT_NAME);
    }

    const isValidEmail = validateEmail(this.businessEmail);
    if(!isValidEmail){
      this.setErrorCode(createBusinessDashboardUserErrorCodes.ERROR_TYPE_INVALID_EMAIL);
    }
  }

  async submitCreateUser() {
    this.validaCreateUser();
    if(this.errorCode){
      return;
    }
    this.isFetching = true;
    const res = await createUser(this.businessEmail, this.businessContactName, this.businessId);
    this.isFetching = false;
    this.setErrorCode(res.errorCode);
    if(res.isSuccess){
      this.setSuccessText(t('screenBusinessDashboardUser.createUser.success'));
    }
  }

  getScreenData = async () => {
    const res = await getAllBusinessesData();
    this.businessesData = res;
  }
}

decorate(ScreenCreateBusinessDashboardUserStore, {
  businessesData : observable,
  businessId : observable,
  businessName : observable,
  businessEmail : observable,
  businessContactName : observable,
  businessPhone : observable,
  errorCode : observable,
  successText : observable,
  isFetching : observable,
  getScreenData: action.bound,
  validaCreateUser : action.bound,
  submitCreateUser : action.bound
});

export function createScreenCreateBusinessDashboardUserStore(rootStore) {
  const store = new ScreenCreateBusinessDashboardUserStore(rootStore);
  return store;
}
