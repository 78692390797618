import React from "react";
import { TextField, makeStyles, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../../..";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
    marginTop: 10,
    maxWidth: 450
  }
}));

export const EditQuestionInput = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();

  const handleInputChange = (event) => {
    if(!store.selectedQuestion){
      return;
    }
    store.selectedQuestion.body = event.target.value;
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <TextField
        variant="outlined"
        value={store.selectedQuestion ? store.selectedQuestion.body : ""}
        fullWidth
        label={t("editArticle.editQuestion")}
        onChange={handleInputChange}
      />
    </Grid>
  );
});