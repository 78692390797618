import React from 'react';
import Grid from '@material-ui/core/Grid';

export const Root = ({ children }) => (<div style={{ padding: 20 }}>
  <Grid
    container
    direction="column"
  >
    {children}
  </Grid>
</div>); 