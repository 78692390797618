


import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { getErrorMsg } from './logic/getErrorMsg';

const styles = theme => ({
    text: {
        color: 'red',
    }
});

class ErrorBoxComponent extends Component {

    render() {
        const { classes, rootStore } = this.props;
        const { errorCode } = rootStore.loginStore;
        const errorMsg = getErrorMsg(errorCode);
        return errorCode ? <Typography className={classes.text}>{errorMsg}</Typography> : null;
    }
}

export const ErrorBox = withStyles(styles)(inject("rootStore")(observer(ErrorBoxComponent)));
