import React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../../store";
import { t } from "common/localization/translate";
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    label: {
        fontSize: 18,
        fontWeight: 'bold'
    }
}));

export const SelectDate = observer(() => {
    const classes = useStyles();
    const store = useScreenHomeStore();
    const defalutDate = moment().subtract(1, 'months').toDate();
    const maxDate = new Date(moment().subtract(1, 'month').endOf('month').toDate());
    const minDate = new Date(moment().subtract(1, 'years').startOf('month').toDate());

    const handleDateChange = (selectedDate) => {
        if(selectedDate > maxDate || selectedDate < minDate){
            store.month = defalutDate;
            return;
        }
        store.month = selectedDate.toDate(); 
    };

    return (
        <Grid item xs={12}>
            <InputLabel className={classes.label} shrink>
                {t("suppliers.projectManagerHome.selectMonthAndYear")}
            </InputLabel>
            <DatePicker
                defaultValue={defalutDate}
                minDate={minDate}
                maxDate={maxDate}
                views={["year", "month"]}
                value={store.month || defalutDate}
                onChange={handleDateChange}
            />
        </Grid>
    )

});

