import React from "react";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  inputRoot: {
    width: 310
  },
}));
export const CouponTypesGroupSelect = observer(() => {
  const store = useAddNewCouponTypeStore();
  const classes = useStyles();

  const handleChange = (event,value) => {
    store.chosenCouponTypeGroupId = value.groupId;
    store.chosenGroupTitle = value.couponTitle
  };

  if(!store.isExistingCouponTypeGroup || store.couponTypesGroup.length==0){
      return null;
  }
  
  return (
    <div>
      <Autocomplete
        options={store.couponTypesGroup}
        getOptionLabel={couponTypeGroup =>`${couponTypeGroup.groupId} - ${couponTypeGroup.couponTitle}`}
        onChange={handleChange}
        classes={classes}
        renderInput={(params) => (
          <TextField {...params} label={t("addNewCouponType.selectGroup")} variant="outlined"/>)}/>
    </div>
  );
});