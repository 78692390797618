import React from "react";
import { TextField, makeStyles, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const UnderTitleText = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();

  const handleInputChange = (event) => {
    store.underTitleText = event.target.value;
  };

  return (
    <Grid>
      <TextField
        variant="outlined"
        fullWidth
        label={t("addNewCouponType.underTitleText")}
        onChange={handleInputChange}
        className={classes.input}
      />
    </Grid>
  );
});
