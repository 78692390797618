/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { useScreenLoginStore } from "../store";

const useStyles = makeStyles(theme => ({
  root: {},
  errorText: {
    color: "red",
    height: theme.spacing(5),
    marginTop: theme.spacing(1)
  }
}));

const ErrorText = observer(props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { errorText } = useScreenLoginStore();

  return <Typography className={classes.errorText}>{errorText}</Typography>;
});

export default ErrorText;
