import HomeIcon from "@material-ui/icons/Home";
import { t } from "common/localization/translate";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import TelegramIcon from '@material-ui/icons/Telegram';
import AutorenewIcon from '@material-ui/icons/Autorenew';

export default [
  {
    title: "תפריט",
    pages: [
      {
        title: t("business.sideBar.switchBusiness.title"),
        href: `/${dashboardPaths.BUSINESS}/switchBusiness`,
        icon: AutorenewIcon,
      },
      {
        title: t("sideBar.home.title"),
        href: `/${dashboardPaths.BUSINESS}/home`,
        icon: HomeIcon,
      },
      {
        title: t("business.sideBar.pricing.title"),
        href: `/${dashboardPaths.BUSINESS}/pricing`,
        icon: TelegramIcon,
      },
    ],
  },
];
