/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import useRouter from "utils/useRouter";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { t } from "common/localization/translate";
import { getSegmentPathById } from "commonStores/analytics/userInfoStore/logic/getSegmentPathById";
import { dashboardPaths } from '../../../../../screens/loginV2/store/logic/dashboardPaths';

const useStyles = makeStyles(theme => ({
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  }
}));

const LogoutButton = () => {
  const rootStore = useRootStore();
  const classes = useStyles();
  const { history } = useRouter();

  const handleLogout = () => {
    const { dashboardType, clearUserInfoStore, currentSegmentId } = rootStore.userInfoStore;
    
    if(!currentSegmentId || dashboardType == dashboardPaths.SUPPORT){
      clearUserInfoStore();
      history.push(`/${dashboardType}/login`);
      return;
    }
    const segmentPath = getSegmentPathById(currentSegmentId);
    clearUserInfoStore();
    history.push(`/${dashboardType}/login/${segmentPath}`);
  };

  return (
    <Button
      className={classes.logoutButton}
      color="inherit"
      onClick={handleLogout}
    >
      <InputIcon className={classes.logoutIcon} />
      {t("topBar.logout.title")}
    </Button>
  );
};

export default LogoutButton;
