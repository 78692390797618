import React from "react";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const FixedCodeInput = observer(() => {
  const store = useAddNewCouponTypeStore();
  const { codeGenerationParameters } = store;
  const classes = useStyles();

  const handleChange = (event) => {
    if(!event.target.value) {
      store.codeGenerationStrategyEmptyError = t("addNewCouponType.InputFixedCouponLabel");
      return;
    }
    store.codeGenerationStrategyEmptyError = null;
    store.codeGenerationParameters = {...codeGenerationParameters, [event.target.name]: event.target.value};
  };
  return (
    <Grid className={classes.input}>
      <TextField
        name="fixedCode"
        onChange={handleChange}
        label={t("addNewCouponType.InputFixedCouponLabel")}
        />
    </Grid>
  );
});
