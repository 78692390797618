import React from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { SubmitButton } from "./components/submitButton";
import { IdNumInput } from "./components/idNumInput";
import { useScreenSendNotificationStore } from "../../store/index";

const useStyles = makeStyles((theme) => ({
  row: {
    width: "100%",
    display: "flex",
    marginLeft: 0.5
  },
}));

export const IdNumForm = observer(() => {
  const store = useScreenSendNotificationStore();
  const classes = useStyles();

  const onSubmit = (e) => {
    e.preventDefault();
    store.submitIdNum();
  };

  return (
    <form className={classes.row} onSubmit={onSubmit}>
      <Grid className={classes.row} container lg={12} xs={12} spacing={1}>
        <IdNumInput />
        <SubmitButton />
      </Grid>
    </form>
  );
});
