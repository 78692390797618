import React from "react";
import { observer } from "mobx-react";
import { makeStyles, Typography } from "@material-ui/core/";
import { SelectTimeType } from "./components/selectTimeType";
import { ActiveDurationInput } from "./components/activeDurationInput";
import { t } from "common/localization/translate";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  timeType: {
    marginLeft: 10,
  },
  subtitle: {
    marginTop:6,
    marginBottom: 6,
    fontWeight: "normal"
  },
}));

export const ActiveDurationTime = observer(() => {
  const classes = useStyles();

  return (
    <Grid>
      <Typography className={classes.subtitle} component="h4" variant="h6">
        {t("addNewCouponType.ActiveDurationTime")}
      </Typography>
      <Grid className={classes.container}>
        <ActiveDurationInput />
        <span className={classes.timeType}>
          <SelectTimeType />
        </span>
      </Grid>
  </Grid>
  );
});
