import React from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import PieChart from "common/ui/pieChart";
import { useScreenHomeStore } from "../../../store";


const useStyles = makeStyles(theme => ({

    chartContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    chart: {
        height: 281
      }
}));

export const PieChartMeasurements = observer(() => {
    const classes = useStyles();
    const { arrayOfMeausermentsForChart } = useScreenHomeStore();
    return (

            <div className={classes.chartContainer}>
                <PieChart className={classes.chart} data={arrayOfMeausermentsForChart} />
            </div>
           
    );
});

