import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { Page } from "components";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { t } from "common/localization/translate";
import { createProjectManagerSupplierStore, ProjectManagerSupplierStoreProvider} from "./store/index";
import { SupplierCodes } from "../home/components/supplier/supplierCodes";
import { SuccessEnterCodesPopUp } from "../home/components/supplier/successEnterCodesPopUp";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    width: "100%",
    height: "100%",

  },
}));
export const ProjectManagerSupplier = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createProjectManagerSupplierStore(rootStore));
  const classes = useStyles();
  
  useEffect(() => { 
    store.getScreenData()
  }, []);
  if (!store.isProjectManager) {
    return null;
  }

  return (
    <ProjectManagerSupplierStoreProvider value={store}>
      <Page title={t("suppliers.projectManagerSupplier.title")} className={classes.root}  >
        <Grid container spacing={3} >
          <SupplierCodes isProjectManager={store.isProjectManager} store={store}/>
          <SuccessEnterCodesPopUp store={store}/>
        </Grid>
      </Page>
    </ProjectManagerSupplierStoreProvider>
  )
});

