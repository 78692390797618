import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditAuctionStore } from "screens/controlPanel/editAuction/store";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const ExternalLinkButtonText = observer(() => {
  const classes = useStyles();
  const store = useEditAuctionStore();

  const handleInputChange = (event) => {
    if (!event.target.value) {
      return null;
    }
    store.externalLinkButtonText = event.target.value;
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      label={t("addNewAuction.addNewAuctionExternalButtonText")}
      onChange={handleInputChange}
      className={classes.input}
      value={store.externalLinkButtonText ? store.externalLinkButtonText : ""}
      placeholder={t("addNewAuction.addNewAuctionExternalButtonDefaultText")}
    />
  );
});
