import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Retainer } from "./components/Retainer";
import { CommissionPercentage } from "./components/CommissionPercentage";
import { Commission } from "./components/Commission";
import { IsEmailAtLikeCheckbox } from "./components/IsEmailAtLikeCheckbox";
import { IsEmailAtNotificationCheckbox } from "./components/IsEmailAtNotificationCheckbox";
import { IsEmailAtPurchaseCheckbox } from "./components/IsEmailAtPurchaseCheckbox";
import { LimitationInput } from "./components/limitationSelect/LimitationInput";
import { LimitationTypeSelect } from "./components/limitationSelect/LimitationTypeSelect";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 10,
    textAlign: "center",
    flex: "auto",
    color: theme.palette.primary.main,
  },
  input: {
    marginBottom: 50
  }
}));

export const AccountInfo = () => {
  const classes = useStyles();
  return (
    <div className={classes.input}>
      <Typography className={classes.title} component="h1" variant="h2">
        {t("screenAddNewBusiness.AccountInfo")}
      </Typography>
        <Retainer />
        <Commission />
        <CommissionPercentage />
        <IsEmailAtPurchaseCheckbox />
        <IsEmailAtNotificationCheckbox />
        <IsEmailAtLikeCheckbox />
        <LimitationTypeSelect />
        <LimitationInput />
    </div>
  );
};