import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { observer } from "mobx-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import { t } from "common/localization/translate";
import { useBillingReportSystemStore } from "../../store/index"; 

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 500,
      "& > * + *": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
      },
    },
    tooltip: {
      fontSize: 14,
    },
    toolTipIcon: {
      color: theme.palette.primary.main
    }
  })
);

export const SupplierMultiSelect = observer(() => {
    const classes = useStyles();
    const store = useBillingReportSystemStore();
    const { suppliers } = store;

    const handleChange = (event, values) => {;
      store.selectedSuppliers = values.map(value => Number(value.supplier_id));
    };

    if(!store.isPerSupplier){
        return null;
    }

    return (
      <Grid className={classes.root}>
            <InputLabel >
            {t("suppliers.billingReportSystem.selectSupplier") + " *"}
            </InputLabel>
        <Autocomplete
          multiple
          id="segments"
          options={suppliers}
          getOptionLabel={option => option.name}
          filterSelectedOptions
          disableCloseOnSelect={true}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </Grid>
    );
});