import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditAuctionStore } from "screens/controlPanel/editAuction/store";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 18,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

export const StartSprintDateEdit = observer(() => {
  const store = useEditAuctionStore();
  const classes = useStyles();

  if(!store.startSprintDate){
    store.startSprintDateEmptyError = t("addNewAuction.addNewAuctionStartSprintDate");
  }

  const handleDateChange = (date) => {
    if (!date) {
      store.startSprintDateEmptyError = t("addNewAuction.addNewAuctionStartSprintDate");
      store.startSprintDate = null;
      return;
    }
    store.startSprintDateEmptyError = null;
    store.startSprintDate = date;
  };

  if (!store.isSprintAuction) {
    store.startSprintDate = null;
    return null;
  }
  return (
    <Grid item xs={12} className={classes.input}>
      <InputLabel className={classes.label} shrink>
        {t("addNewAuction.addNewAuctionStartSprintDate") + " *"}
      </InputLabel>
      <KeyboardDateTimePicker
        ampm={false}
        value={store.startSprintDate}
        onChange={handleDateChange}
        format="HH:mm DD/MM/YYYY"
      />
    </Grid>
  );
});