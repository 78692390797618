import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
    marginTop: 10
  },
}));

export const CouponTitle = observer(() => {
  const classes = useStyles();
  const store = useEditCouponTypeStore();

  const handleInputChange = (event) => {
      store.couponTitle = event.target.value;
  };

  return (
    <TextField
      required
      variant="outlined"
      fullWidth
      label={t("addNewCouponType.CouponTitle")}
      onChange={handleInputChange}
      className={classes.input}
      value={store.couponTitle || ""}
    />
  );
});
