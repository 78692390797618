import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export const ComboBox = (props) => {
  const { options, label, onChange } = props;

  return (
      <Autocomplete
        onChange={onChange}
        id="combo-box-demo"
        options={options}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            label={label}
            variant="outlined"
          />
        )}
      />
  );
};
