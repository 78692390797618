import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

export const IsLocalCheckbox = observer(() => {
  const store = useEditBusinessStore();

  const handleChange = (event) => {
    store.isLocal = event.target.checked;
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox checked={store.isLocal ? true : false} onChange={handleChange} name="isLocal" />
        }
        label={t("screenAddNewBusiness.isLocal")}
      />
    </div>
  );
});