import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { observer } from "mobx-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { Grid } from "@material-ui/core";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  inputRoot: {
    width: 310
  }
}));

export const DescriptiveTagSelect = observer(() => {
  const store = useAddNewCouponTypeStore();
  const classes = useStyles();
  const { descriptiveTags, descriptiveTagsByCategoryList } = store;

  const handleChange = (event, values) => {
    store.descriptiveTags = values;
  };

  return (
    <Grid>
      <InputLabel>{t("screenAddNewBusiness.DescriptiveTagSelect")}</InputLabel>
      <Autocomplete
        multiple
        disableCloseOnSelect={true}
        value={descriptiveTags ? descriptiveTags : ""}
        options={descriptiveTagsByCategoryList}
        filterSelectedOptions
        classes={classes}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    </Grid>
  );
});
