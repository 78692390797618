import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import { observer } from "mobx-react";
import { Grid } from '@material-ui/core';
import { useScreenSendNotificationStore } from "../store/index";
import { notificationNavigationsList } from '../store/logic/notificationNavigationsList';
import { t } from 'common/localization/translate';

const useStyles = makeStyles(() => ({
    title: {
        fontSize: 15,
        fontWeight: 'bold'
    }
}));

export const NotificationNavigationDropDown = observer(() => {
    const classes = useStyles();
    const store = useScreenSendNotificationStore();

    const locations = notificationNavigationsList;
    const menuItems = locations.map(item => <MenuItem value={item.value}>{item.text}</MenuItem>);
    
    const handleChange = (event) => {
        store.navigateTo = event.target.value;
        store.isSentSelfNotification = false;
    };

    return (
        <Grid>
            <Typography className={classes.title} gutterBottom>{t("support.sendNotificationScreen.notificationNavigation")}</Typography>
            <Select value={store.navigateTo} onChange={handleChange} inputProps={{ 'display': 'block' }} displayEmpty  >
                {menuItems}
            </Select>
        </Grid>
    );
})