import React from "react";
import { observer } from "mobx-react";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import { FixedCode } from "./components/fixedCode/fixedCode";
import { CodeGenerationStrategyDropdown } from "./components/CodeGenerationStrategyDropdown";
import { codeGenerationStrategies } from "./codeGenerationStrategies";
import { RandomCode } from "./components/randomCode/randomCode";
import { ExternalCode } from "./components/externalInput/externalCode";

export const CodeGenerationInput = observer(() => {
  const store = useEditCouponTypeStore();

  switch (store.codeGenerationStrategy) {
    case codeGenerationStrategies.RANDOM_CODE:
      store.codeGenerationStrategyEmptyError = null;
      return (
        <RandomCode />
      );
    case codeGenerationStrategies.FIXED_CODE:
      return (
        <FixedCode />
      );
    case codeGenerationStrategies.CODES_FROM_EXTERNAL_COUPON_CODES:
      return (
        <ExternalCode />
      );
    default:
      return <CodeGenerationStrategyDropdown />;
  }
});
