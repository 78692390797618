import React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from "@material-ui/styles";


  const useStyles = makeStyles((theme) => ({
      label :{
        fontSize:20,
        fontWeight: 'bold'
    },
  }));

export const ProjectManagerHomeTitle = observer(() => {
    const classes = useStyles();


    return (
        <Grid item xs={12} spacing={3}>
            <InputLabel className={classes.label} shrink >{t("suppliers.ProjectManagerHome.MonthlyReportTitle")}</InputLabel>
        </Grid>
    )
});

