import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { CommonButton } from "common/ui/commonButton";
import { useScreenConfirmationsUseresStore } from "../../../store/index";
import { Grid } from "@material-ui/core";
export const ButtonConfirmations = () => {
    const { isFetching } = useScreenConfirmationsUseresStore();
    return (
        <Grid item sm={4} lg={3}>
            <CommonButton disabled={isFetching} >
                <SearchIcon />
            </CommonButton>
        </Grid>
    );
}

