export function setLocalData(key, data) {
  const json = JSON.stringify(data);
  localStorage.setItem(key, json);
}

export function clearLocalData(key) {
  localStorage.removeItem(key);
}

export function getLocalData(key) {
  const json = localStorage.getItem(key);
  return JSON.parse(json || "{}");
}

export function createLocalDataObject(token, segmentId, businessId) {
  return { token, segmentId, businessId };
}
