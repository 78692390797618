import React from "react";
import { decorate, observable, computed } from "mobx";
import { t } from "common/localization/translate";
import { getProjectManagerClalitScreenData } from "../server/getProjectManagerClalitScreenData";
import { sendRejectCodes as sendRejectCodesToServer } from "../server/sendRejectCodes";
import { getBillingReport as getBillingReportServer } from "./server/getBillingReport";
import moment from "moment";

const BillingReportSystemStoreContext = React.createContext({});
export const BillingReportSystemStoreProvider = BillingReportSystemStoreContext.Provider;
export const useBillingReportSystemStore = () =>
  React.useContext(BillingReportSystemStoreContext);

class BillingReportSystemStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  getScreenData = async () => {
    const data = await getProjectManagerClalitScreenData();
    this.suppliers = data.extra.suppliers;
    this.isProjectManager = data.extra.isProjectManager;
    this.selectedMonthDate = moment().subtract(1, 'month');
  }
  sendRejectCodes = async () => {
    try {
      this.isRejectingCodes = true;
      this.numberOfCodesRejected = await sendRejectCodesToServer({codesToReject: this.codes, isDeliverySystem: this.isDeliverySystem});
      this.codes = null;
      document.getElementById('codes').value=null;
      this.isRejectingCodes = false;
      this.isSuccessRejectCodes = true;
    } catch (error) {
      this.errorReportFaildText = t("suppliers.projectManagerHome.generalErrorSendReport");
      this.isFetching = false;

    }
  }

  getAllPurchaseReports = async () => {
    try {
      this.isFetching = true;
      await getBillingReportServer({isFinal: this.isFinal, selectedMonthDate: this.selectedMonthDate, selectedSuppliers: this.selectedSuppliers});
      this.isFetching = false;
    }
    catch (error) {
      this.errorReportFaildText = t("suppliers.projectManagerHome.generalErrorSendReport");
    }
  }
  get numberOfCodes(){
    if(this.codes){
      const codeArray = this.codes.split('\n');
      const cleanCodeArray = codeArray.filter(code => code.length > 0);
      return cleanCodeArray.length;
    }
    return null;
  }
}

decorate(BillingReportSystemStore, {
    isProjectManager: observable,
    codes: observable,
    isFinal: observable,
    isFetching: observable,
    isDeliverySystem: observable,
    numberOfCodesRejected: observable,
    isRejectingCodes: observable,
    isSuccessRejectCodes: observable,
    selectedMonthDate: observable,
    suppliers: observable,
    isPerSupplier: observable,
    selectedSuppliers: observable,
    numberOfCodes: computed
});

export function createBillingReportSystemStore(rootStore) {
  const store = new BillingReportSystemStore(rootStore);
  return store;
}
