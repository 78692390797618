import AuthLayout from "../layouts/Auth";
import { Login as LoginV2 } from "screens/loginV2";
import { Home } from "screens/business/home";
import { Pricing } from "screens/business/pricing";
import { SwitchBusiness} from "screens/business/switchBusiness";
import Dashboard from "layouts/Dashboard";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";

export const businessRoutes = [
  {
    path: `/${dashboardPaths.BUSINESS}/login`,
    component: AuthLayout,
    routes: [
      {
        path:`/${dashboardPaths.BUSINESS}/login`,
        exact: true,
        component: LoginV2,
      },
    ],
  },
  {
    path: `/${dashboardPaths.BUSINESS}`,
    component: Dashboard,
    routes: [
      {
        path: `/${dashboardPaths.BUSINESS}/home`,
        exact: true,
        component: Home,
      },
      {
        path: `/${dashboardPaths.BUSINESS}/pricing`,
        exact: true,
        component: Pricing,
      },
      {
        path: `/${dashboardPaths.BUSINESS}/switchBusiness`,
        exact: true,
        component: SwitchBusiness,
      },
    ],
  },
];
