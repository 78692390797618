import React from "react";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { PurchaseCountLimitation } from "./components/PurchaseCountLimitation";
import { PurchaseCountInCalendarMonthLimitation } from "./components/PurchaseCountInCalendarMonthLimitation";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const LimitationInput = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();
  const { limitationType } = store;

  switch (limitationType.type) {
    case "purchase_count":
      return (
      <PurchaseCountLimitation className={classes.input}/>
      );
    case "purchase_count_in_calendar_month":
      return (
      <PurchaseCountInCalendarMonthLimitation className={classes.input}/>
      );
    default:
      return null
  }
});