import AddIcon from '@material-ui/icons/Add';
import React from "react";
import { observer } from "mobx-react";
import { useEditArticleStore } from "../../../../..";
import IconButton from '@material-ui/core/IconButton';
import { translateQuestionById } from 'screens/controlPanel/editArticle/store/server/translateQuestionById';
import { getArticleQuestions } from 'screens/controlPanel/editArticle/store/server/getArticleQuestions';

export const TranslateQuestionButton = observer(() => {
    const store = useEditArticleStore();
    const translateQuestion = async () => {
        if(!store.selectedQuestion.translation_texts) {
            return;
        }
        await translateQuestionById({questionId: store.selectedQuestion.question_id, questionTranslation: store.selectedQuestion.translation_texts});
        store.selectedQuestion = "";
        store.questionArray = await getArticleQuestions(store.selectedArticle);
    }

    return(
        <IconButton color="primary" component="span">
          <AddIcon onClick={translateQuestion}/>
        </IconButton>
    )
});