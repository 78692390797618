import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";

export const IsVegCheckbox = observer(() => {
  const store = useScreenCreateAddNewBusinessStore();
  const { isVeg } = store;

  const handleChange = (event) => {
    store.setIsVeg(event.target.checked);
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox value={isVeg} onChange={handleChange} name="isVeg" />
        }
        label={t("screenAddNewBusiness.isVeg")}
      />
    </div>
  );
});