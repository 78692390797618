import { errorCodes } from "../../../services/errorCodes";
import { userDailyStepsAndGoalsErrors } from "./userDailyStepsAndGoalsErrors";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { t } from "common/localization/translate";

export const getDailyStepsAndGoalsErrorTextByErrorCode = (errorCode) => {
    const rootStore = useRootStore();
    if (!errorCode) {
        return;
    }
    switch (errorCode) {
        case errorCodes.ERROR_TYPE_USER_NOT_EXISTS:
            return rootStore.userInfoStore.isWithSearchByPhoneOption ? t("screenDailyStepsAndGoals.errors.userNotExsist.idOrPhone") : userDailyStepsAndGoalsErrors.userNotExsist;
        case errorCodes.ERROR_TYPE_INVALID_DATA:
            return userDailyStepsAndGoalsErrors.invalidInput;
        case errorCodes.ERROR_TYPE_ID_TOO_SHORT:
            return userDailyStepsAndGoalsErrors.tooShort;
        case errorCodes.ERROR_TYPE_NOT_ONLY_NUMBERS:
            return userDailyStepsAndGoalsErrors.onlyNumbers;
        case errorCodes.ERROR_TYPE_RATE_LIMITER_IS_EXCEEDED:
            return userDailyStepsAndGoalsErrors.limitReached;
        default:
            return userDailyStepsAndGoalsErrors.userNotExsist;
    }
};