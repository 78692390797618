import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { observer } from "mobx-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useEditBusinessStore } from "../../../../../index";
import { Grid } from "@material-ui/core";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  inputRoot: {
    width: 310
  }
}));

export const DescriptiveTagSelect = observer(() => {
  const store = useEditBusinessStore();
  const { descriptiveTagsByCategoryList } = store;
  
  const classes = useStyles();

  const handleChange = (event, values) => {
    if(values.length === 0) {
      store.descriptiveTagsEmptyError = t("screenAddNewBusiness.DescriptiveTagSelect");
      store.descriptiveTags = values;
      return;
    }
    store.descriptiveTagsEmptyError = null;
    store.descriptiveTags = values;
  };

  return (
    <Grid>
      <InputLabel>{t("screenAddNewBusiness.DescriptiveTagSelect") + " *"}</InputLabel>
      <Autocomplete
        multiple
        disableCloseOnSelect={true}
        options={descriptiveTagsByCategoryList}
        value={store.descriptiveTags ? store.descriptiveTags : ""}
        filterSelectedOptions
        onChange={handleChange}
        classes={classes}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    </Grid>
  );
});