import React from "react";
import { observer } from "mobx-react";
import { CountInput } from "./CountInput";
import { DayInput } from "./DayInput";
import { Grid } from "@material-ui/core";

export const PurchaseCountLimitation = observer(() => {
  return (
    <Grid>
      <CountInput />
      <DayInput />
    </Grid>
  );
});
