import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 10,
  },
}));

export const SiteUrl = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();

  const handleInputChange = (event) => {
    store.businessWebsite = event.target.value;
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      value={store.businessWebsite ? store.businessWebsite : ""}
      label={t("screenAddNewBusiness.SiteUrl")}
      onChange={handleInputChange}
      className={classes.input}
    />
  );
});