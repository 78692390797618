import EditIcon from '@material-ui/icons/Edit';
import { t } from "common/localization/translate";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import RedeemIcon from '@material-ui/icons/Redeem';
import BarChartIcon from '@material-ui/icons/BarChart';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

export default [
  {
    title: t("sideBar.menu.title"),
    pages: [
      {
        title: t("sideBar.benefits.title"),
        href: `/${dashboardPaths.SUPPORT}/getUserCoupons`,
        icon: RedeemIcon,
      },
      {
        title: t("sideBar.userBalance.title"),
        href: `/${dashboardPaths.SUPPORT}/userBalance`,
        icon: BarChartIcon
      },
      {
        title: t("sideBar.support.editUserInfo"),
        href: `/${dashboardPaths.SUPPORT}/editUserInfo`,
        icon: EditIcon,
      },
      {
        title: t("sideBar.support.dailyStepsAndGoals"),
        href: `/${dashboardPaths.SUPPORT}/dailyStepsAndGoals`,
        icon: DirectionsRunIcon,
      },
      {
        title: t("sideBar.support.orderDetails"),
        href: `/${dashboardPaths.SUPPORT}/orderDetails`,
        icon: ShoppingBasketIcon,
      },
      {
        title: t("sideBar.support.sendNotificationToUser"),
        href: `/${dashboardPaths.SUPPORT}/sendNotificationToUser`,
        icon: NotificationsActiveIcon
      },
    ],
  },
];
