import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { useEditArticleStore } from "../../../../..";
import { AnswerSelect } from "./answerSelect";
import { EditAnswerInput } from "./editAnswerInput";
import { EditAnswerButton } from "./editAnswerButton";
import { IsCorrectCheckbox } from "./isCorrectCheckbox";
import { quizActions } from "screens/controlPanel/editArticle/store/logic/quizActions";
import { IsLeadCheckbox } from "./isLeadCheckbox";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  stater: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
  },
}));

export const EditAnswersComponents = observer(() => {
  const classes = useStyles();
  const store = useEditArticleStore();

  if (store.quizAction !== quizActions.EDIT || !store.selectedQuestion) {
    return null;
  }
  return (
    <Grid>
      <span className={classes.stater}>
        <EditAnswerInput />
        <IsCorrectCheckbox />
        <EditAnswerButton />
        <AnswerSelect />
      </span>
      <Grid>
        <IsLeadCheckbox />
      </Grid>
    </Grid>
  );
});
