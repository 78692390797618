import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useScreenCreateAddNewBusinessStore } from "../../../../..";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: 10,
  },
}));

export const BusinessContactEmail = observer(() => {
  const classes = useStyles();
  const store = useScreenCreateAddNewBusinessStore();

  const handleInputChange = (event) => {
    if(!event.target.value){
      store.setContactEmailEmptyError(t("screenAddNewBusiness.ContactEmail"))
      return;
    }
    store.setContactEmailEmptyError(null)
    store.setContactEmail(event.target.value);
  };

  return (
    <TextField
      required
      autoComplete="fname"
      variant="outlined"
      fullWidth
      id="businesslogo"
      label={t("screenAddNewBusiness.ContactEmail")}
      onChange={handleInputChange}
      className={classes.input}
    />
  );
});