import React from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { Typography, Grid, Button } from "@material-ui/core";
import { t } from "common/localization/translate";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import ChangeSegmentDropDown from "./changeSegmentDropDown";
import { segmentsPaths } from "commonStores/analytics/userInfoStore/logic/getSegmentPathById";
import { useScreenTraineesInfoStore } from "../store";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flex: 1,
  },
  container: {
    "& > *": {
      height: "100%",
    },
  },
  toolTipIcon: {
    color: theme.palette.primary.main,
    marginLeft: 15,
    fontSize: "25px",
  },
  title: {
    fontSize: "25px",
    lineHeight: "18px",
    marginTop: 2,
  },
  rightSide: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  leftSide: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

export const Header = observer(() => {
  const store = useScreenTraineesInfoStore();
  const { currentSegment } = store.rootStore.userInfoStore;
  const segmentIds = currentSegment && currentSegment.segmentId && currentSegment.segmentId.segmentIds;
  const isPoliceSegment = segmentIds && segmentIds.includes(segmentsPaths.POLICE.id);
  const toolTipText = isPoliceSegment ?  t('screenTrainesInfo.tooltip.police') : t('screenTrainesInfo.tooltip.text1');
  const classes = useStyles();

  return (
    <Grid container justify="space-between" spacing={3}>
      <Grid item md={6} xs={12} className={classes.rightSide}>
        <Typography className={classes.title} component="h2" gutterBottom variant="overline">
          {t("screenTrainesInfo.table.title")}
        </Typography>
        <Tooltip title={toolTipText}>
          <HelpIcon className={classes.toolTipIcon} />
        </Tooltip>
      </Grid>
      <Grid item md={6} xs={12} className={classes.leftSide}>
          <ChangeSegmentDropDown />
          <Button
            className={classes.searchButton}
            onClick={store.sendExcelMail}
            variant="contained"
          >
            {t("screenCouponsInInventory.downloadExcel")}
          </Button>
      </Grid>
    </Grid>
  );
});