import MaterialTablePagination from '@material-ui/core/TablePagination';

import React, { Component } from 'react'

export class TablePagination extends Component {
  render() {
    const { count, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } = this.props;
    return <MaterialTablePagination
      rowsPerPageOptions={[5, 10, 25, 50]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      labelRowsPerPage={'שורות בעמוד:'}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} מתוך ${count}`}
      backIconButtonProps={{
        'aria-label': 'Previous Page',
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page',
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  }
}
