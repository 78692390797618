import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { t } from "common/localization/translate";
import { Grid, makeStyles, Checkbox } from "@material-ui/core";
import { segmentIds } from "screens/controlPanel/addCouponType/store/logic/segmentIds";

const useStyles = makeStyles(() => ({
  grid: {
    marginBottom: 10,
  }
}));

export const IsTranslate = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();

  const handleChange = (event) => {
    store.isTranslate = event.target.checked;
  };

  if(!store.segments.includes(segmentIds.CLALIT_SEGMENT_ID) && !store.segments.includes(segmentIds.TEST_SEGMENT_ID)) {
    return null;
  }

  return (
    <Grid item xs={12} className={classes.grid}>
      <FormControlLabel
        control={<Checkbox onChange={handleChange} />}
        label={t("addNewCouponType.isTranslate")}
      />
    </Grid>
  );
});
