import React from "react";
import { CssBaseline, Typography, Container } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { t } from "common/localization/translate";
import { UpdateUserSegmentButton } from "./components/updateUserSegmentButton";
import { UserIdInput } from "./components/userIdInput";
import { SelectSegment } from "./components/selectSegment";
import { UpdateSegmentReason } from "./components/updateSegmentReason";
import { UpdateUserSegmentSuccessPopUp } from "../popUps/updateUserSegmentSuccess";
import { UpdateUserSegmentErrorPopUp } from "../popUps/updateUserSegmentError";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 30,
    flex: "auto",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: 10,
  },
}));

export const Form = observer(() => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Grid className={classes.paper}>
        <Typography className={classes.title} component="h1" variant="h1">
          {t("updateUserSegment.title")}
        </Typography>
        <form className={classes.form} noValidate>
          <UserIdInput />
          <SelectSegment />
          <UpdateSegmentReason />
          <UpdateUserSegmentButton />
          <UpdateUserSegmentSuccessPopUp />
          <UpdateUserSegmentErrorPopUp />
        </form>
      </Grid>
    </Container>
  );
});