import React from "react";
import { InputLabel, Select, makeStyles, MenuItem, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useEditCouponTypeStore } from "screens/controlPanel/editCouponType/store";
import find from "lodash/find";

const useStyles = makeStyles((theme) => ({
    category: {
        margin: theme.spacing(1),
        minWidth: 300,
        border: 1,
        borderColor: "black",
    },
    selectEmpty: {
        marginBottom: 10,
        marginTop: 20
    }
}));

export const PatchSelect = observer(() => {
    const store = useEditCouponTypeStore();
    const classes = useStyles();

    const patchOptions = store.patches.map(patch => <MenuItem key={patch.patch_id} value={patch}>{patch.title}</MenuItem>);

    const handleChange = (event) => {
        store.selectedPatch = event.target.value;
    };

    const getSelected = (selected) => {
        const patch = find(store.patches, p => p.parameters.url == selected.url);
        if (patch) {
            return patch.title;
        }
        return selected.title;
    }

    return (
        <Grid className={classes.selectEmpty} item xs={9}>
            <InputLabel>{t("addNewCouponType.choosePatch")}</InputLabel>
            <Select
                onChange={handleChange}
                className={classes.category}
                value={store.selectedPatch}
                renderValue={getSelected}
            >
                {patchOptions}
            </Select>
        </Grid>
    );
});