import { writeToRow } from "./writeToRow";

export function createSheetSum(sheetSum, storeHome) {
  	const { selectedSegmentName } = storeHome;

    let col1 = `ממוצע שיפור ${selectedSegmentName} החודש`;
    let col2 = `מספר חברי ${selectedSegmentName} כעת`;
    let col3 = `מספר חברי ${selectedSegmentName} שהצטרפו בשבוע האחרון`;
    let col4 = `כל מטבעות חברי ${selectedSegmentName} `;
    const { totalCurrentSegmentMembers, sumOfCoinsOfCurrentSegmentUsers, lastWeekNewUsers, segmentImprovement } = storeHome;
    writeToRow(sheetSum, [col1, col2, col3, col4], 1);
    writeToRow(sheetSum, [segmentImprovement, totalCurrentSegmentMembers, lastWeekNewUsers, sumOfCoinsOfCurrentSegmentUsers], 2);
}