import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Root } from '../../../common/ui/root.js';
import { DatesPicker } from '../../../common/ui/datePicker/index.js';
import { InfoCard } from 'componentsRumble/infoCard/index.js';
import { screenTypes } from 'commonStores/userInfoStore/screenTypes.js';

class MainComponent extends Component {

    componentDidMount() {
        this.props.rootStore.movement.storeMain.getMainData();
        document.title = "Main";
    }

    render() {
        const {
            startAt,
            endAt,
            onChangeRange,
            totalChargesFromOrganizations,
            totalBillForMovement } = this.props.rootStore.movement.storeMain;
        const totalToChargeFromOrganizationsText = `סך הכל לגבייה : ₪${totalChargesFromOrganizations}`;
        const totalBillForMovementText = `סך הכל לתשלום : ₪${totalBillForMovement}`;
        return <Root>
            <DatesPicker
                startAt={startAt}
                endAt={endAt}
                onChangeRange={onChangeRange} />
            <InfoCard info={totalToChargeFromOrganizationsText} history={this.props.history} redirectTo={screenTypes.SCREEN_MOV_SUM_OF_LOADINGS_PER_ORG} />
            <InfoCard info={totalBillForMovementText} history={this.props.history} redirectTo={screenTypes.SCREEN_MOV_SUM_OF_PURCHASES_BY_BIZ} />
        </Root>
    }
}

export const Main = inject("rootStore")(observer(MainComponent));