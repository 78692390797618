import React from 'react';

export const RumbleLogoWhite = () => {
    return <img alt="rumble logo" src="https://ucarecdn.com/27e74bed-8b7a-41b8-ac9b-7fe02647d142/logow.png" width="126" height="65"></img>
}

export const RumbleLogo = () => {
    return <img alt="rumble logo" src="https://ucarecdn.com/f78c1107-4771-4a60-812e-c474f77f001e/rumbleLogoEnglish.png" width="126" height="65"></img>
}

export const GymPassLogo = () => {
    return <img alt="gymPass" src="https://ucarecdn.com/aaa1713f-638f-4095-b1fa-58d16f7822cd/gympassmovement.png" />
}

export const CustomLogo = (props) => {
    return <img alt="gymPass" src={props.src} width="126" height="65" />
}

