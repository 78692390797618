import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { CodeGenerationStrategyDropdown } from "../CodeGenerationStrategyDropdown";
import { t } from "common/localization/translate";

export const RandomCode = observer(() => {
  return (
    <Grid>
      <CodeGenerationStrategyDropdown />
      <Typography variant="body1">
        {t("addNewCouponType.RandomCodeMessage")}
      </Typography>
    </Grid>
  );
});
