import React from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
  input: {
    "&::placeholder": {
      fontSize: 13,
    },
  },
  textbox: {
    marginBottom: 10
  }
}));

export const BusinessBranchToAdd = observer(() => {
  const classes = useStyles();
  const store = useEditBusinessStore();

  const handleInputChange = (event) => {
    store.branchesToAdd = event.target.value;
  };

  return (
    <TextField
      InputProps={{ classes: { input: classes.input } }}
      value={store.branchesToAdd ? store.branchesToAdd : ""}
      label={t("editBusiness.addNewBusinessBranch")}
      multiline
      rows={6}
      placeholder={t("screenAddNewBusiness.BusinessLocationPlaceholder")}
      variant="outlined"
      className={classes.textbox}
      onChange={handleInputChange}
    />
  );
});