import React, { useState } from "react";
import { Form } from "./store/components/index";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { observer } from "mobx-react";
import { createEditAuctionStore, EditAuctionStoreProvider } from "./store/index";

export const EditAuction = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createEditAuctionStore(rootStore));
  const { userInfoStore } = useRootStore();
  const { isLoggedIn } = userInfoStore;

  if (isLoggedIn) {
    return (
      <EditAuctionStoreProvider value={store}>
        <Form store={store} />
      </EditAuctionStoreProvider>
    );
  }
});