import React from "react";
import { t } from "common/localization/translate";

const style = {
  color: "red",
};

export const ImageTooLargeMessage = (props) => {
  const isImageTooLarge = props.isImageTooLarge;
  if (isImageTooLarge) {
    return <p style={style}>{t("screenAddNewBusiness.ImageTooLargeMessage")}</p>;
  }
  return null;
};