import { UserBalanceErrors } from "./errorTextType";
import { errorCodes } from "../../../services/errorCodes";
import { useRootStore } from "commonStores/analytics/rootStoreContext";

export const getBalanceErrorTextByErrorCode = (errorCode) => {
    const rootStore = useRootStore();
    if (!errorCode) {
        return;
    }
    switch (errorCode) {
        case errorCodes.ERROR_TYPE_USER_NOT_EXISTS:
            return rootStore.userInfoStore.isWithSearchByPhoneOption ? UserBalanceErrors.userNotExsistIdOrPhone : UserBalanceErrors.userNotExsist;
        case errorCodes.ERROR_TYPE_INVALID_DATA:
            return UserBalanceErrors.invalidInput;
        case errorCodes.ERROR_TYPE_ID_TOO_SHORT:
            return UserBalanceErrors.tooShort;
        case errorCodes.ERROR_TYPE_NOT_ONLY_NUMBERS:
            return UserBalanceErrors.onlyNumbers;
        case errorCodes.ERROR_TYPE_RATE_LIMITER_IS_EXCEEDED:
            return UserBalanceErrors.limitReached;
        default:
            return UserBalanceErrors.userNotExsist;
    }
};