import React, { useState } from "react";
import { makeStyles, Grid } from "@material-ui/core/";
import { observer } from "mobx-react";
import { useAddNewCouponTypeStore } from "screens/controlPanel/addCouponType/store";
import { ImageTooLargeMessage } from "./ImageTooLargeMessage";
import { t } from "common/localization/translate";
import { createEncodedLogo } from "../../../../../../addNewBuisness/store/components/form/components/GeneralInfo/createEncodedLogo";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
  },
  input:{
    marginBottom: 10,
  }
}));

export const CouponImage = observer(() => {
  const classes = useStyles();
  const store = useAddNewCouponTypeStore();
  const maxImageSize = 45000;
  const [isImageTooLarge, setIsImageTooLarge] = useState(false);

  const handleInputChange = async (event) => {
    if (!event.target.files[0]) {
      store.couponImageEmptyError=t("addNewCouponType.Image");
      return;
    }
    if (event.target.files[0].size > maxImageSize) {
      setIsImageTooLarge(true);
      return;
    }
    store.couponImageEmptyError=null;
    setIsImageTooLarge(false);
    const encodedCouponImage = await createEncodedLogo(event.target.files[0]);
    store.couponImage = encodedCouponImage;
}

  return (
    <Grid>
      <label className={classes.label}>{t("addNewCouponType.Image")}</label>
      <br />
      <input className={classes.input} type="file" onChange={handleInputChange} />
      <ImageTooLargeMessage isImageTooLarge={isImageTooLarge} />
    </Grid>
  );
});
