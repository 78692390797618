import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { EmailSentSuccessfullyPopUp } from "./EmailSentSuccessfullyPopUp";
import { PricingImage } from "./pricingModal/pricingImage";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 2000,
    alignSelf: "center",
    justifyContent: "center",
  },
  content: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(2),
    maxWidth: 1000,
    margin: "0 auto",
  }
}));

export const PricingModal = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <EmailSentSuccessfullyPopUp />
      <div className={classes.content}>
        <Grid container spacing={8}>
          <PricingImage />
        </Grid>
      </div>
    </div>
  );
};
