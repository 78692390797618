import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import { useEditBusinessStore } from "../../../../../index";
import { t } from "common/localization/translate";

export const IsVegCheckbox = observer(() => {
  const store = useEditBusinessStore();
  const { isVeg } = store;

  const handleChange = (event) => {
    store.isVeg = event.target.checked;
  };

  return (
    <div>
      <FormControlLabel
        control={<Checkbox checked={isVeg ? true : false} onChange={handleChange} name="isVeg" />}
        label={t("screenAddNewBusiness.isVeg")}
      />
    </div>
  );
});